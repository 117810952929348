import http from "@/axios";
import OfflineProcessor from "./Processor/OfflineProcessor";

class IncestService{ 

   /**
    * Show data from storage via offline and online
    * @param patient 
    * @param patientCase 
    * @param networkAvailable 
    * @returns 
    */
   async show(patient: number, patientCase: number, networkAvailable: boolean){
         if(networkAvailable){
            return await http.get(`patients/${patient}/cases/${patientCase}/intakeForm/incestCase/show`);
         }else{
            return await OfflineProcessor.show(patient, patientCase);
         }
    }

    /**
     * Update data from storage via offline and online
     * @param incestId 
     * @param patient 
     * @param patientCase 
     * @param networkAvailable 
     * @param payload 
     * @returns 
     */
   async update(incestId: number,patient: number, patientCase: number,networkAvailable: boolean, payload: any){
         if(networkAvailable){
            return await http.post(`patients/${patient}/cases/${patientCase}/intakeForm/incestCase/update`, payload);
         }else{ 
            return await OfflineProcessor.updateCreate(incestId, patient, patientCase, payload);
         }
    }
}
export default new IncestService();