import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Form from "@/classes/Cases/CasePatientAddress/Offline/Formatters/CasePatientAddressForm"
import useFormatFormStructure from "@/helpers/Offlinehelpers/useFormatFormStructure";
import Storage from "@/helpers/storage";
import FormOptions from "@/classes/Forms/FormOptions";  
import ResidenceType from "@/enums/ResidenceType"; 
import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import Relationships from "@/classes/Forms/Relationships";

const options  = new FormOptions();

const storage = new Storage();
const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const table = "case_patient_addresses";
const relationship = new Relationships();

class OfflineProcessor extends Syncable {

    /**
     * Render index page
     * @param cases_id 
     * @returns 
     */
   async index(cases_id: number, patient: number){
       await sqlite.connect();
       const query = `SELECT * FROM ${table} WHERE cases_id = ${cases_id} AND deleted_at IS NULL` 
       const patientAddresses = await sqlite.db.select(query); 
    //    console.log(patientAddresses);
    //    const viewForm =  patientAddresses.map((item) => {
    //         console.log(item.res);
    //             return { 
    //                 address_type: item.residence_type ,
    //                 permanent_address: item.address,
    //                 region: item.region_id,
    //                 province: item.province_id,
    //                 city_municipality: item.city_id,
    //                 barangay: item.barangay_id,
    //                 directions: item.directions
    //             } 
    //         })
        
    //     let data; 
    //     if(patientAddresses.length > 0 ){
    //         if(intake){
    //             data = viewForm;
    //         }else{
    //             data = patientAddresses;
    //         }
    //     }else{
    //         data = [];
    //     }
    //     console.log(data);
    //     console.log(intake);
       return {
           'data': {
               'items': {
                   data:  patientAddresses.length > 0 ? patientAddresses : [],
                   total: patientAddresses.length,
                   patient: await PatientOfflineProcessor.getFullName(patient)
               }
           },
       };
   }


   /**
    * Update or Create data from storage
    * @param patient
    * @param patientCase
    * @param payload
    */
   async updateCreate(patient:number, patientCase: any, patientAddress: any, payload: any) {
     
       this.table = table;
       let structuredForm;


       if(patientAddress){
           structuredForm  = useFormatFormStructure(payload, Form.updateForm(payload));
       }else{
           structuredForm  = useFormatFormStructure(payload, Form.createForm());
       }
     
       const encoded_by = JSON.parse(storage.get('user')); 
       
       structuredForm.cases_id = patientCase;
       structuredForm.encoded_by =  encoded_by.id; 

       await sqlite.connect();
       const patientAddresses = await this.sync(sqlite.db, [structuredForm]); 
      
       return {
           data: {
               data: {
                   item: patientAddresses
               }
           }
       }
    
   }

    /**
    * Render form data
    * @returns data
    */
    async create(patient: number){
        await sqlite.connect();
        return {
            data : {  
                regions: await options.regions(),
                provinces: await options.provinces(),
                cities:await options.cities(),
                barangays:await options.barangays(), 
                residenceTypes: ResidenceType,
                patient: await PatientOfflineProcessor.getFullName(patient),
            }
        }
    }

   /**
    * Render additional form data 
    * @param patient 
    * @param patientCase 
    * @param patientAddress 
    * @returns 
    */
   async edit( patient: any, patientCase: any, patientAddress: any){
       await sqlite.connect();
       const query = `SELECT * FROM ${table} WHERE  cases_id = ${patientCase} AND id = ${patientAddress} LIMIT 1`
       const item = await sqlite.db.select(query)
      
       return {
           data : {
               item: {
                   ...item,
               },
               patient: await PatientOfflineProcessor.getFullName(patient),
               regions: await options.regions(),
               provinces: await options.provinces(),
               cities:await options.cities(),
               barangays:await options.barangays(), 
               residenceTypes: ResidenceType
           }
       }
   }

   /**
    * Delete data from storage
    * @param patientAddress 
    * @param patientCase 
    * @returns 
    */
   async delete(patientAddress:number, patientCase: number, forceDelete = false){
       await sqlite.connect();
       const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${patientCase}`);

       if (forceDelete) {
            await this.forceDelete(patientAddress, patientCase)
        }

        // check if record exist offline
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${patientAddress} AND cases_id=${patientCase}`);
        if (response.length > 0) {
            const item = response[0];

            if (item.is_created_offline) {
                await this.forceDelete(patientAddress, patientCase);
            } else {
                const form = {
                    id: patientAddress,
                    is_sync: false,
                    synching_remarks: null,
                    is_deleted_offline: true,
                    deleted_at: relationship.dateFormatFromIso(new Date),
                }
                await sqlite.db.updateWithFilters(table, form, { cases_id: patientCase, id: patientAddress });
            }
        }
       return this.index(patientCase, cases[0].id);
   }

   async getCount(case_id: number) {
        const response = await sqlite.db.select(`SELECT count(id) as count FROM ${table} WHERE cases_id=${case_id}  AND (deleted_at IS NULL OR deleted_at = 0)`);
        return response.length > 0 ? response[0].count : 0;
    }

    async syncToOffline(case_id: number, payload: any) {
        await sqlite.connect();
        const form = useFormatFormStructure(payload, Form.syncToOffline(payload));
        form.cases_id = case_id;
        syncable.table = table;
        const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, form.id);
        if (checkRecord) {
            return;
        }
        await syncable.sync(sqlite.db, [DefaultFormStructureBuild.resetStatus(form)]);
    }

    async forceDelete(guardian: number, case_id: number) {
        await sqlite.db.delete(table,{id: guardian, cases_id: case_id});
    }
}


export default new OfflineProcessor();