import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../../defaultMigration";

export const case_conference_aggravating_factors:SchemaTableOptions = {
    name:<string> 'case_conference_aggravating_factors',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: "case_conference_id", value: "INTEGER UNSIGNED" },
        { column: "aggravatingFactors", value: "LONGTEXT NOT NULL" },
        { column: "mitigatingFactors", value: "LONGTEXT NOT NULL" },
		{ column: "other_aggravating", value: "LONGTEXT NULL" },
		{ column: "other_mitigating", value: "LONGTEXT NULL" },
		{ column: "encoded_by", value: "INTEGER UNSIGNED" },
		{ column: "action", value: "INT UNSIGNED" },
        ...defaultMigration
    ]
}