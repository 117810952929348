<template>
    <user-layout>
        <div class="fixed lg:top-[72px] top-16 bg-white z-10 mb-6 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] -mx-4 w-full">
            <a v-if="!showNotif" @click="router.back()">
                <button-component
                    customClass="tertiary text-black hover:text-primary-600 transition py-4"
                >
                    <!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2"> -->
                    <arrow-left-icon class="object-contain w-6 h-6 mr-2" />
                    <span class="text-sm font-semibold">Back</span>
                </button-component>
            </a>
            <button-component
                v-if="showNotif"
                @click="showNotif = ''"
                customClass="tertiary text-black hover:text-primary-600 transition py-4"
            >
                <!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2"> -->
                <arrow-left-icon class="object-contain w-6 h-6 mr-2" />
                <span class="text-sm font-semibold">Back to list</span>
            </button-component>
        </div>
        <div class="lg:mt-[72px] mt-14">
            <div class="flex md:flex-row flex-col relative">
                <div class="md:w-1/2 w-full md:border-r left_con border-neutral-100">
                    <div class="flex items-center justify-between p-6 border-b border-neutral-100">
                        <p>
                            <span class="px-2 py-[3px] text-xs font-bold text-white bg-primary-600 rounded-[20px]">{{ unreadNotif }} Unread</span>
                        </p>
                        <span v-if="unreadNotif > 0" class="text-sm transition cursor-pointer text-neutral-900 hover:text-primary-600" @click="markAllAsRead">Mark all as Read</span>
                    </div>
                    <div class="h-[calc(100vh-200px)] overflow-auto">
                        <notification-card 
                            v-for="item in items.data" :key="item"
                            :title="item.data.subject"
                            :date="item.data.updated_at"
                            :description="renderShortDescription(item)"
                            :notifNew="item.data.read_at"
                            @click="markAsRead(item.id, item.data.read_at)"
                        />
                    </div>

                    <div class="mt-6 flex justify-center">
                        <simple-pagination 
                            :showRows="false" 
                            :currentPage="items.current_page" 
                            :last-page="items.last_page" 
                            @update="(value: any) => paginate(value)"
                        />
                    </div>
                </div>
                <div class="md:w-1/2 w-full right_con md:h-[calc(100vh-130px)]overflow-auto">
                    <template v-for="item in items.data" :key="item">
                        <div v-if="showNotif == item.id" class="md:static absolute top-0 right-0 bg-white h-full overflow-auto">
                            <notification-details
                                :title="item.data.subject"
                                :date="item.data.updated_at"
                                :description="renderLongDescription(item)"
                                :routeLink="item.data.properties.route"
                                :buttonText="item.data.properties.button_name"
                            />
                        </div>
                    </template>
                </div>

                
            </div>
        </div>
    </user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';

import NotificationCard from './Components/NotificationCard.vue';
import NotificationDetails from './Components/NotificationDetails.vue';

import Notifications from "@/classes/Notifications";

import SimplePagination from "@/components/partials/SimplePagination.vue";

import { filters, items } from "@/interfaces/pages/FilterPageInterface";

import assignValues from "@/helpers/assignValues"
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

import { useRouter } from 'vue-router';

const router = useRouter();

const unreadNotif = ref<number>(0);
const showNotif = ref<string>('');
const setPage = ref(1);

const init = (): void => {
    Notifications.index({ page: setPage.value })
        .then(({ data }) => {
            console.log(data.item);
            filters.value = assignValues(filters.value, data);
            unreadNotif.value = data.unread;
            items.value = data.item;
        })
        .catch((error) => (console.log(error)))
}

const paginate = ({ page, rows }): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })

    setPage.value = page;
    init();
};

const markAsRead = (notification: string, read_at: null | string): void => {
    
    showNotif.value = notification

    if (read_at == null || typeof read_at == 'undefined'){
        Notifications.markAsRead(notification)
            .then(() => (init()))
            .catch((error) => (console.log(error)))
    }
}


const markAllAsRead = (): void => {
    Notifications.markAllAsRead()
        .then(() => (init()))
        .catch((error) => (console.log(error)))
}

const renderShortDescription = (item: any) => {
    return item.data?.message || item.data?.contents?.content;
}

const renderLongDescription = (item: any) => {
    if (item.data?.message) {
        return `${item.data?.message} ${item.data?.html || ''}`;
    }
    return item.data?.contents?.content;
}

onMounted(() => {
    init()
})
</script>