<template>
	<user-layout
		hasBack
		:backLink="caseId ? `/patient/${patientId}/cases/${caseId}/interventions` : `/intervention/${patientId}/view`"
	>
		<div class="max-w-screen-lg mx-auto my-6">
			<div class="flex flex-col w-full lg:items-start lg:justify-between lg:flex-row">
				<section-title fontSmall :title="`${formStructure.label} Intervention`" class="shrink-0">
					<p class="text-sm font-medium text-neutral-600">
						Please fill out all the required fields with the red asterisk (<span class="text-red-600">*</span>)
					</p>
				</section-title>
				<p class="mt-3 text-lg font-medium lg:pl-4 lg:mt-0 md:text-2xl text-neutral-600">Patient's Name:
					<span class="block font-semibold md:inline-block text-neutral-900">{{ patient?.name ?? "" }}</span>
				</p>
			</div>
			<form class="grid grid-cols-6 gap-6 mt-6" v-if="!loading">
				<div class="col-span-6 border-t border-gray-200"></div>
				<div
					class="col-span-full"
					v-if="errors?.message"
				>
					<p
						class="text-[10px] mt-1"
						:class="errors?.message ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.message }}
					</p>
				</div>

				<div class="col-span-6" v-if="!caseId">
					<multi-select
						:options="cases"
						id="cases_id"
						name="case_number"
						label="Case Number"
						placeholder="Select.."
						searchable
						required
						v-model="form.cases_id"
						:error="errors?.cases_id" 
						@update:modelValue="getPatientAge"
					/>
				</div>

				<template v-for="(field, index) in formStructure?.fields" :key="index">
					<form-field 
						:field="field"
						:form="form.params"
						:errors="errors"
						:patient="patient"
					/>
				</template>

				<div class="flex items-center col-span-6 space-x-3 justify end" >
					<div class="flex items-center justify-end w-full space-x-3">
						<button-component
							btnWidth="quinary"
							@click="
								router.replace(
									caseId
										? `/patient/${patientId}/cases/${caseId}/interventions`
										: `/intervention/${patientId}/view`
								)
							"
							>Cancel</button-component
						>

						<button-component 
							btnWidth="primary" 
							@click="showConfirmation = true"
						>
							Save Record
						</button-component>
					</div>
				</div>
			</form>
		</div>

		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="validate()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Save Record"
			content="Are you sure you wish to save this record?" 
		/>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Success!"
			:content="`${formStructure.label} Intervention has been Created`" 
		/>

		<page-loader :show="loading" />
		<page-loader :show="processing" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, type PropType, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minLength, helpers, integer, maxLength} from '@vuelidate/validators'

import pickBy from "lodash/pickBy";

import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import PageLoader from "@/components/loaders/PageLoader.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

import InterventionService from "@/classes/Interventions/InterventionService"
import ErrorValidation from "./Components/Classess/ErrorValidation";

import FormField from "@/views/interventions/Components/Input/FormField.vue"

import { errorResponse } from "@/helpers/errorMessage";

import { useNetworkStore } from "@/store/network";

//Interfaces
import { SelectInterface } from "@/interfaces/pages/BasePageInterface";
import { PatientInterface } from "@/interfaces/pages/InterventionForm/InterventionFormInterface";

//Classes
const router = useRouter();
const route = useRoute();
const networkStore = useNetworkStore();

//Route params
const patientId = ref<number | null>(route?.params?.patient);
const caseId = ref<number | null>(route?.params?.case ?? null);
const interventionType = ref<number>(route?.params?.interventionType);

//Interfaces
const patient = ref<PatientInterface>({});

//Strings
const successMessage = ref<string>("Data has been successfully created!");

//Any
const formStructure = ref<any>({
	label: ""
});

//Number
const selectedCase = ref<number|null>(route?.params?.case ?? null);

const form = ref<any>({
	cases_id: selectedCase.value,
	interventionType: interventionType.value,
	params: [],
})

const errors =ref<any>([]);
const cases =ref<SelectInterface[]>([]);

//Booleans
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);
const processing = ref<boolean>(false);
const isNetworkAvailable = computed<boolean>(():boolean => networkStore.isNetworkAvailable);

const init = ():void => {
	loading.value = true;
	InterventionService
		.create(patientId.value, interventionType.value, caseId.value, isNetworkAvailable.value)
		.then(({data}) => {
			patient.value = data.patient
			formStructure.value = data.form 

			if(!caseId.value){
				cases.value = data.cases
                setDefaultCaseNumber();
			}
		})
		.finally(() => {
			loading.value = false;
		});
}

const isRequired = (value:any, vm:any):boolean => {
	return vm.is_required;
}

//Validations
const rules = computed(() => {

	const rules = {
		cases_id : {
			required,
		},
		params: {
			$each: helpers.forEach({
				value: { 
					requiredIfFuction: requiredIf(isRequired),
				},
			})
		}
	}; 

	return rules;
})

const vuelidate = useVuelidate(rules, form);

const validate = async ():void => {
	const result = vuelidate.value.$validate();
	console.log(result);
	processing.value = false;
	loading.value = true;
	showConfirmation.value = false;
	await result
		.then(async (res) => {
			if(res) {
				await save()
			} else {
				console.log(res);
				errors.value = ErrorValidation.getErrors(vuelidate.value)
			}
		}).catch((err) => {
			console.log(err);
		})
		.finally(() => {
			loading.value = false;
		});
}

const save = async (): void => {
	loading.value = true;
	showConfirmation.value = false;
	
	await InterventionService
		.store(
			patientId.value, 
			interventionType.value, 
			{
				cases_id: form.value.cases_id,
				interventionType: interventionType.value,
				params: pickBy(form.value.params)
			},
			isNetworkAvailable.value
		)
		.then(() => {
			showSuccess.value = true;
		})
		.catch((error: object) => {
			if(error?.data?.errors){
				errors.value = errorResponse(error?.data?.errors);
			}
		})
		.finally(() => {
			loading.value = false;
		});
};

const redirectSuccess = (): void => {
	showSuccess.value = false;

	const route = caseId?.value ? `/patient/${patientId.value}/cases/${caseId.value}/interventions` : `/intervention/${patientId.value}/view`;

	router.replace(route);
};

const getPatientAge = (value:number):void => {
	loading.value = true;
	
	if(value){
		const index = cases.value.map((item:any) => item.id).indexOf(value);
	
		patient.value.patient_age = cases.value[index].computed_age;
		patient.value.patient_birthday = cases.value[index].birthdate;
		patient.value.patient_education = cases.value[index].educational_attainment_id;
		patient.value.patient_guardian = cases.value[index].guardian;
		patient.value.patient_sex = cases.value[index].patient_sex;

		form.value.cases_id = value;
	} else {
		patient.value.patient_age = null;
		patient.value.patient_birthday = null;
		patient.value.patient_education = null;
		patient.value.patient_guardian = null;
		patient.value.patient_sex = null;
	}

	setTimeout(() => {
		loading.value = false;
	}, 300);
}

const setDefaultCaseNumber = () => {
    if (cases.value.length === 1) {
        const item = cases.value[0];
        form.value.cases_id = item.id;
    }
}

onMounted(() => {
	init()
})

</script>

