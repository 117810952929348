import http from "@/axios";
import { Perpetrator } from "@/interfaces/Cases/Perpetrator";
// import CasePerpetratorOfflineForm from "../Forms/Cases/CasePerpetratorOfflineForm";
// import DB from "@/classes/Databases/Crud";
// import SqliteRecordChecker from "../Forms/SqliteRecordChecker";
// import PaginationPerpetrator from "../Databases/PaginationPerpetrator";
// import { items } from "@/interfaces/pages/FilterPageInterface";
// import OfflineCasePerpetrator from "../Forms/Cases/OfflineCasePerpetrator";

// const db = new DB("case_perpetrators");
// const dbCase = new DB("cases");
// const dbPerpetrator = new DB("perpetrators", new PaginationPerpetrator("perpetrators"));
// const checkRecord = new SqliteRecordChecker();
// const dbDraft = new DB("drafts");

class PerpetratorService implements Perpetrator {
	async getPatient(patient: number, patientCase: number): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators/get-patient`).catch(error => error);
	}

	async index(patient: number, patientCase: number, payload: object): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators`, { params: payload }).catch(error => error);
	}

	async lists(patient: number, patientCase: number, payload: any): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators/lists`, { params: payload }).catch(error => error);
	}

	async link(patient: number, patientCase: any, payload: any): Promise<any> {
		return await http.post(`patients/${patient}/cases/${patientCase}/perpetrators/link`, payload).catch(error => error);
	}

	async create(patient: number, patientCase: number, payload: object): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators/create`, { params: payload }).catch(error => error);
	}

	async store(patient: number, patientCase: any, payload: any): Promise<any> {
        patientCase = parseInt(patientCase);
		return await http.post(`patients/${patient}/cases/${patientCase}/perpetrators`, payload).catch(error => error);
	}

	async edit(
		patient: number,
		patientCase: number,
		perpetrator: number,
		payload: any
	): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators/${perpetrator}/edit`, {
			params: payload,
		}).catch(error => error);
	}

	async update(
		patient: number,
		patientCase: any,
		perpetrator: number,
		payload: any
	): Promise<any> {
		return await http.put(
			`patients/${patient}/cases/${patientCase}/perpetrators/${perpetrator}/update`,
			payload
		).catch(error => error);
	}

	async delete(
		patient: number,
		patientCase: any,
		perpetrator: number,
		payload: any
	): Promise<any> {
		return await http.delete(
			`patients/${patient}/cases/${patientCase}/perpetrators/${perpetrator}/delete`,
			payload
		).catch(error => error);
	}
}

export default new PerpetratorService();
