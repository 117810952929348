<template>
    <button-component
        customClass="quaternary"
        @click="action"
    >
        <!-- <img src="/assets/icon/ic-export.svg" alt="export_icon" class="object-contain w-4 h-4 mr-1 -ml-2 icon"> -->
        <export-icon class="object-contain w-4 h-4 mr-1 -ml-2 icon" />
        <span>{{ title }}</span>
    </button-component>
</template>

<script setup lang="ts">
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import ExportIcon from "@/components/icons/exportIcon.vue";

const props = defineProps({
    title: {
        type: String,
        default: "Export"
    }
});
const emit = defineEmits(['click'])

const action = () => {
    emit("click");
}
</script>
