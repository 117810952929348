import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "./defaultMigration";

export const perpetrators:SchemaTableOptions = {
    name:<string> 'perpetrators',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'first_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'last_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'middle_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'gender', value: 'TINYINT UNSIGNED'},
        { column: 'birthday_classification', value: 'TINYINT UNSIGNED'},
        { column: 'birthdate', value: 'DATE DEFAULT NULL'},
        { column: 'approximate_age_year', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'approximate_age_month', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'contacts', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'nickname', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'perpetrator_relationship_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'other_relationship', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'occupation', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'nationality_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'religion_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'current_location', value: 'TINYINT UNSIGNED DEFAULT NULL'},
        { column: 'perpetrator_location', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'is_minor', value: 'BOOLEAN DEFAULT FALSE'},
        { column: 'parent_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'parent_contact_number', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'parent_relationship_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'perpetrator_information', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
        { column: 'identity', value: 'TINYINT UNSIGNED DEFAULT NULL'},
        { column: 'about_perpetrator', value: 'TEXT DEFAULT NULL'},
        { column: 'tag', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'details', value: 'TEXT DEFAULT NULL'},
        { column: 'other_nationality', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'other_religion', value: 'VARCHAR(255) DEFAULT NULL'},
        ...defaultMigration
    ]
}