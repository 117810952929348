import http from "@/axios";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";
import { SyncStatus } from "@/views/enums/SyncStatus";

const sqlite = new SqliteProcessor;
const table = 'case_perpetrators';
const syncable = new Syncable;
export default class SyncCasePerpetrators {
    
    async renderCasePerpetrator(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'case_perpetrators';
        await sqlite.connect();
        const perpetrators = await onlineSync.defaultQuery(table);
        if (perpetrators.length > 0) {
            for (const index in perpetrators) {
                const item = perpetrators[index];
                const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
                const form: any = item;
                form.table = table;
                form.type = 'Linked Perpetrators to Cases';
                form.url_view = cases.length > 0 ? `/patient/${cases[0].patient_id}/cases/${item.cases_id}/perpetrators/${item.perpetrator_id}/view` : ''
                form.can_view = item.cases_id && item.perpetrator_id ? true : false;
                form.patient_name = cases.length > 0 ? `Linked to Case of ${cases[0].first_name} ${cases[0].last_name}` : 'Unknown link to cases',
                form.status =  item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;

                if (isRenderData) {
                    items.push(form);
                }

                // process online synching
                if (!isRenderData) {                
                    hasError = await this.processForm(form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async processForm(item: any) {
        const onlineSync = new OnlineSynching;
        let hasError = false;

        if (!item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-case-perpetrator-create', item).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                await onlineSync.resetItemData(table, oldId);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-case-perpetrator-delete', item).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }
        }

        return hasError;
    }
}