import { Preferences } from "@capacitor/preferences";
import StorageInterface from "./Interfaces/StorageInterface";
export default class PrxLocalStorage implements StorageInterface {
    
    /**
     * Get from storage.
     * 
     * @param   string key
     * @return  Promise<any>
     */
    async get(key:string):Promise<any> {
        const { value } = await Preferences.get({ key: key })
        return value ? JSON.parse(value) : value
    }

    /**
     * Set into storage.
     * 
     * @param   string key
     * @param   any items
     * 
     * @return  Promise<void>
     */
    async set(key:string, items:any):Promise<void> {
        const value = JSON.stringify(items)
        await Preferences.set({ key, value })
    }

    /**
     * Clear from storage.
     * 
     * @param   string key
     * @return  Promise<void>
     */
    async clear(key:string):Promise<void> {
        await Preferences.remove({ key })
    }

    /**
     * Clear multiple from storage.
     * 
     * @param   Array<string> keys
     * @return  Promise<void>
     */
    async clearMultiple(keys:Array<string>):Promise<void> {
        keys.forEach((key) => this.clear(key));
    }
}