// Define an object with key-value pairs
const defaultMigration = [
    { column: "is_sync", value: "INT UNSIGNED DEFAULT FALSE" },
    { column: "is_sync_failed", value: "BOOLEAN DEFAULT FALSE"},
    { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
    { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
    { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },
    { column: "created_at", value: "TIMESTAMP" },
    { column: "updated_at", value: "TIMESTAMP" },
    { column: "deleted_at", value: "TIMESTAMP" },
    { column: "synching_remarks", value: "TEXT DEFAULT NULL" },
];
    
// Export the object as the default export
export default defaultMigration;
    
    