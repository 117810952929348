import CaseOfflineProcessor from "@/classes/Cases/CaseHistory/Offline/processor/CaseOfflineProcessor";
import { PatientServiceInterface } from "./Interfaces/PatientInterface";
import PatientOfflineProcessor from "./processor/PatientOfflineProcessor";
import SqliteProcessor from "@/processors/SqliteProcessor";

const sqlite = new SqliteProcessor;
class OfflineService implements PatientServiceInterface{
    async search(payload: object): Promise<any> {
        return {
            data: {
                items: await PatientOfflineProcessor.searchPatient(payload)
            }
        }
    }

    async create(payload: object): Promise<any> {
        return {
            data: await PatientOfflineProcessor.create(),
        }
    }

    async store(payload: object): Promise<any> {
        return {
            data: {
                data: await PatientOfflineProcessor.store(payload),
                message: 'Patient successfully created!'
            }
        }
    }

    async syncToOffline(payload: any, isCreated = false) {
        if (!payload?.patient) {
            return;
        }
        
        await sqlite.connect();
        if (isCreated) {
            await PatientOfflineProcessor.deleteExistingPatientRecord(payload.patient.id);
        }

        const patientIsNotSync = await PatientOfflineProcessor.checkUnsyncPatient(payload.patient.id);
        // sync patient to offline if records is already synched
        if (!patientIsNotSync) {
            await PatientOfflineProcessor.syncToOffline({ item: payload.patient});
        }
        const casesIsNotSync = await CaseOfflineProcessor.checkUnsyncCases(payload.cases.id);
        if (!casesIsNotSync) {
            await CaseOfflineProcessor.syncToOffline(payload.cases, true);
        }
    }
}

export default new OfflineService();