import http from "@/axios";
import { SafetyAssessment } from "@/classes/Cases/CaseImmediateAssessment/Interface/ImmediateAssessmentInterface";
import OfflineProcessor from "./Processor/OfflineProcessor";


class CaseImmediateService implements SafetyAssessment  {
    show(patient: number, patientCase: number, assessment: number, networkAvailable: boolean, payload: object): Promise<any> {
        throw new Error("Method not implemented.");
    }
    async create(patient: number, patientCase: number, networkAvailable: boolean, payload: object): Promise<any> {
       if(networkAvailable){
            return await http.get(`patients/${patient}/cases/${patientCase}/safety-assessment/create`, {
                params: payload,
            });
       }else{ 
            return await OfflineProcessor.create(patientCase);
       }
    }
    async store(patient: number, patientCase: number,  networkAvailable: boolean, payload: object): Promise<any> {
        if(networkAvailable){
            return await http.post(`patients/${patient}/cases/${patientCase}/safety-assessment`, payload);
        }else{ 
            return await OfflineProcessor.updateCreate(patient, patientCase, null, payload);
        } 
    }
    async edit(patient: number, patientCase: number, assessment: number, networkAvailable: boolean, payload: object): Promise<any> {
         if(networkAvailable){ 
            return await http.get(`patients/${patient}/cases/${patientCase}/safety-assessment/${assessment}/edit`, {
                params: payload,
            });
         }else{   
            return await OfflineProcessor.edit(patientCase, assessment);
         }
    }
   async update(patient: number, patientCase: number, assessment: number, networkAvailable: boolean, payload: object): Promise<any> {
        if(networkAvailable){
            return await http.put(
                `patients/${patient}/cases/${patientCase}/safety-assessment/${assessment}/update`,
                payload
            );
        }else{
            return await OfflineProcessor.updateCreate(patient, patientCase, assessment, payload);
        }
    } 

    async checIfCreated(id: number){
        return await OfflineProcessor.check(id);
    }

}
export default new CaseImmediateService()