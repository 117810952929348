import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";

class DataDictionaryOfflineForm {
    /**
     * Template form structure of patient table
     */
    formStructure () {
        return {
            id: null,
            name: null,
            content: null,
        }
    }

    fillForm(item: any) {
        const builder: any = {};
        const items = this.formStructure()
        // get default form structure template
        for (const index in items) {
            // match all existing indexes based on default form structure
            builder[index] = DefaultFormStructureBuild.fill(index, item);
        }
        
        return builder;
    }
}

export default new DataDictionaryOfflineForm();