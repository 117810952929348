import http from "@/axios";
import { DataDictionary } from "@/interfaces/Settings/DataDictionary";
import DataDictionaryOfflineService from "../DataDictionary/Offline/OfflineService";
 
class DataDictionaryService implements DataDictionary {

	async index(params: any = {}, fromSettings: boolean, networkAvailable = true): Promise<any> {
        if (networkAvailable) {
            return await http.get(fromSettings ? "settings/data-dictionaries" : "data-dictionaries", { params: params });
        } else {
            return await DataDictionaryOfflineService.index(params);
        }

	}

	async show(scopeBinding: any, params: any = {}, networkAvailable = true): Promise<any> {
        if (networkAvailable) {
            return await http.get(`data-dictionaries/${scopeBinding.dataDictionary}`, { params: params });
        } else {
            return await DataDictionaryOfflineService.view(scopeBinding.dataDictionary);
        }
	}

	async delete(scopeBinding: any): Promise<any> {
		return await http.delete(`settings/data-dictionaries/${scopeBinding.dataDictionary}/delete`);
	}

	async restore(scopeBinding: any): Promise<any> {
		return await http.patch(`settings/data-dictionaries/${scopeBinding.dataDictionary}/restore`);
	}

	async edit(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.get(`settings/data-dictionaries/${scopeBinding.dataDictionary}/edit`, { params: params });
	}

	async update(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.put(`settings/data-dictionaries/${scopeBinding.dataDictionary}/update`, params);
	}

	async store(params: any = {}): Promise<any> {
		return await http.post(`settings/data-dictionaries`, params);
	}
}

export default new DataDictionaryService();
