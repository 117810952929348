import http from "@/axios";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";
import PatientOfflineForm from "../Patients/Offline/Formatters/PatientOfflineForm";
import CaseOfflineProcessor from "../Cases/CaseHistory/Offline/processor/CaseOfflineProcessor";
import CaseOfflineForm from "../Cases/CaseHistory/Offline/Formatters/CaseOfflineForm";

const sqlite = new SqliteProcessor;
const table = 'cases';
const syncable = new Syncable;
export default class SyncCases {

    async renderCases(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'cases';
        await sqlite.connect();
        const cases = await onlineSync.defaultQuery(table);
        if (cases.length > 0) {
            for (const index in cases) {
                const item = cases[index];
                const patient = await sqlite.db.select(`SELECT * FROM patients WHERE id=${item.patient_id}`);
                const setForm = PatientOfflineForm.createPatientCaseForm()
                const transformedData = await CaseOfflineProcessor.editCase(item.id);
                const combinedForm = {
                    ...PatientOfflineForm.fillPatientForm(patient[0]),
                    ...CaseOfflineForm.fillCaseForm(transformedData),
                }
                const form = onlineSync.setForm(
                    onlineSync.setOfflineIdentifier(
                        transformedData,
                        combinedForm,
                    ), 
                    setForm,
                    item,
                );

                // unique object value per table
                form.table = table;
                form.type = "Cases"
                form.url_view = `/patient/${item.patient_id}/cases/${item.id}/edit`;
                form.patient_id = item.patient_id;
                form.can_view = item.patient_id ? true : false;

                if (isRenderData) {
                    items.push(form);
                }

                // process online synching
                if (!isRenderData) {
                    hasError = await this.processForm(form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

	async processForm(item: any) {
        const onlineSync = new OnlineSynching;
		let hasError = false;

        if (item.is_deleted_offline) {
			const response = await http.post("/attemp-synching-cases-delete", item).catch((error) => error);
			if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                await sqlite.db.delete('case_perpetrators', { cases_id: item.id });
				hasError = false;
			} else {
				hasError = await onlineSync.rollback(table, item.id, response);
			}

            return hasError;
		}

		if (item.is_updated_offline && !item.is_created_offline) {
			const response = await http.post("/attemp-synching-cases-update", item).catch((error) => error);
			if (response?.status === 200) {
                const data = response.data.data.downloadables;
				await onlineSync.resetItemData(table, item.id);
                // if (item.indigeneous_people_id === 'others') {
                //     await onlineSync.updateOthersDropdown('indigeneous_peoples', data.cases.indigeneous_people_id, item.other_indigeneous_people);
                // }
                // if (item.nationality_id === 'others') {
                //     await onlineSync.updateOthersDropdown('nationalities', data.cases.nationality_id, item.other_nationality);
                // }
                // if (item.religion_id === 'others') {
                //     await onlineSync.updateOthersDropdown('religions', data.cases.religion_id, item.other_religion);
                // }
				hasError = false;
			} else {
				hasError = await onlineSync.rollback(table, item.id, response);
			}

            return hasError;
		}

		if (item.is_created_offline) {
			const response = await http.post("/attemp-synching-cases-create", item).catch((error) => error);
			if (response?.status === 200) {
                const data = response.data.data.downloadables;
				const newId = response.data.data.case_details.id;
				const oldId = item.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                await this.updateRecordsConnectedToPatient(oldId, newId);
                await sqlite.db.update(table, { id: newId, case_number: response.data.data.case_details.case_number });
                // if (item.indigeneous_people_id === 'others') {
                //     await onlineSync.updateOthersDropdown('indigeneous_peoples', data.cases.indigeneous_people_id, item.other_indigeneous_people);
                // }
                // if (item.nationality_id === 'others') {
                //     await onlineSync.updateOthersDropdown('nationalities', data.cases.nationality_id, item.other_nationality);
                // }
                // if (item.religion_id === 'others') {
                //     await onlineSync.updateOthersDropdown('religions', data.cases.religion_id, item.other_religion);
                // }
				hasError = false;	
			} else {
				hasError = await onlineSync.rollback(table, item.id, response);
			}
		}		

		return hasError;
	}

    async updateRecordsConnectedToPatient(oldId: number, newId: number) {
        if (!oldId || !newId) {
            return false;
        }

        syncable.table = 'case_perpetrators';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_guardians';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_companions';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'intervention_data';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_diagnoses';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_mental_health_diagnoses';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_patient_addresses';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_referral_services';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_remarks';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_safety_assessments';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'cases_comorbidity';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'cases_endorsements';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'case_conferences';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'minutes';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'intake_contact_numbers';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'intake_email_addresses';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'intake_incest_cases';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'intake_personal_information';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);

        syncable.table = 'intake_person_related_to_cases';
        await syncable.updateSynchingCaseId(sqlite.db, oldId, newId);
    }
}
