import { SchemaMode, SchemaOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces"
import { migrations } from './tables/_migrations'
import { patients } from './tables/patients'
import { cases } from './tables/cases'
// Options
import { agencies } from './tables/Options/agencies'
import { aggravating_factors } from './tables/Options/aggravating_factors'
import { barangays } from './tables/Options/barangays'
import { behavior_types } from './tables/Options/behavior_types'
import { cities } from './tables/Options/cities'
import { comorbidities } from './tables/Options/comorbidities'
import { companion_relationships } from './tables/Options/companion_relationships'
import { departments } from './tables/Options/departments'
import { disabilities } from './tables/Options/disabilities'
import { educational_attainments } from './tables/Options/educational_attainments'
import { employment_statuses } from './tables/Options/employment_statuses'
import { guardian_relationships } from './tables/Options/guardian_relationships'
import { hospital_areas } from './tables/Options/hospital_areas'
import { housing_classifications } from './tables/Options/housing_classifications'
import { indigeneous_peoples } from './tables/Options/indigeneous_peoples'
import { intervention_type_lists } from './tables/Options/intervention_type_lists'
import { intervention_types } from './tables/Options/intervention_types'
import { mental_health_diagnoses } from './tables/Options/mental_health_diagnoses'
import { mitigating_factors } from './tables/Options/mitigating_factors'
import { nationalities } from './tables/Options/nationalities'
import { offices } from './tables/Options/offices'
import { perpetrator_relationships } from './tables/Options/perpetrator_relationships'
import { personnels } from './tables/Options/personnels'
import { positions } from './tables/Options/positions'
import { primary_diagnoses } from './tables/Options/primary_diagnoses'
import { provinces } from './tables/Options/provinces'
import { recommendation_options } from './tables/Options/recommendation_options'
import { referral_sources } from './tables/Options/referral_sources'
import { regions } from './tables/Options/regions'
import { relationships } from './tables/Options/relationships'
import { religions } from './tables/Options/religions'
import { risk_codes } from './tables/Options/risk_codes'
import { safety_assessment_questions } from './tables/Options/safety_assessment_questions'
import { socio_economic_statuses } from './tables/Options/socio_economic_statuses'
import { sought_services } from './tables/Options/sought_services'
import { telcpu_locations } from './tables/Options/telcpu_locations'
import { telcpus } from './tables/Options/telcpus'
import { civil_statuses } from "./tables/Options/civil_statuses" 
//Interventions
import { intervention_form_structure } from "./tables/intervention_form_structure"
import { intervention_data } from "./tables/intervention_data"
import { case_companions } from "./tables/Cases/case_companions"
import { case_patient_addresses } from "./tables/Cases/case_patient_addresses"
import { case_safety_assessments } from "./tables/Cases/case_safety_assessments"
import { case_safety_assessment_questions } from "./tables/Cases/case_safety_assessment_questions"

//Cases
import { cases_comorbidity } from "./tables/Cases/cases_comorbidity"
import { case_guardians } from "./tables/Cases/case_guardians";
import { case_diagnoses } from "./tables/Cases/case_diagnoses"
import { case_mental_health_diagnoses } from "./tables/Cases/case_mental_health_diagnoses"
import { case_perpetrators } from "./tables/Cases/case_perpetrators"
import { case_referral_services } from "./tables/Cases/case_referral_services"
import { case_remarks } from "./tables/Cases/case_remarks"
import { cases_endorsements } from "./tables/Cases/cases_endorsements"
//Case Intakes
import { intake_personal_information } from "./tables/Cases/Intake/intake_personal_information"
//Case Conference
import { case_conference_aggravating_factors } from "./tables/Cases/CaseConference/case_conference_aggravating_factors"
import { case_conference_recommendations } from "./tables/Cases/CaseConference/case_conference_recommendations"
import { case_conferences } from "./tables/Cases/CaseConference/case_conferences"
import { minutes } from "./tables/Cases/CaseConference/minutes"
// Perpetrators
import { perpetrators } from "./tables/perpetrators"
import { intake_person_related_to_cases } from "./tables/Cases/Intake/intake_person_related_to_cases"
import { intake_email_addresses } from "./tables/Cases/Intake/intake_email_addresses"
import { intake_incest_cases } from "./tables/Cases/Intake/intake_incest_cases"
import { intake_contact_numbers } from "./tables/Cases/Intake/intake_contact_numbers"
// Others
import { data_dictionaries } from "./tables/data_dictionaries"

export const schema:SchemaOptions = {
    database:<string> process.env.VUE_APP_DB_NAME,
    version:<number> 1,
    encrypted:<boolean> true,
    mode:<SchemaMode> 'partial',
    tables:<Array<SchemaTableOptions>> [
        migrations,
        patients,
        cases,
       
        //Case Other Information
        case_guardians, 
        case_companions, 
        case_patient_addresses,
        case_safety_assessments,
        case_safety_assessment_questions,
        //Case Intake
        intake_email_addresses,
        //Case Intake
        intake_incest_cases,
        intake_personal_information,
        intake_person_related_to_cases,
        intake_email_addresses, 
        intake_contact_numbers,
        // Options
        agencies,
        aggravating_factors,
        barangays,
        behavior_types,
        cities,
        civil_statuses,
        comorbidities,
        companion_relationships,
        departments,
        disabilities,
        educational_attainments,
        employment_statuses,
        guardian_relationships,
        hospital_areas,
        housing_classifications,
        indigeneous_peoples,
        intervention_type_lists,
        intervention_types,
        mental_health_diagnoses,
        mitigating_factors,
        nationalities,
        offices,
        perpetrator_relationships,
        personnels,
        positions,
        primary_diagnoses,
        provinces,
        recommendation_options,
        referral_sources,
        regions,
        relationships,
        religions,
        risk_codes,
        safety_assessment_questions,
        socio_economic_statuses,
        sought_services,
        telcpu_locations,
        telcpus,
        // Interventions
        intervention_form_structure,
        intervention_data,
        // Cases
        cases_comorbidity,
        case_guardians,
        case_diagnoses,
        case_mental_health_diagnoses,
        case_perpetrators,
        case_referral_services,
        case_remarks,
        cases_endorsements,
        // Case Conference
        case_conference_aggravating_factors,
        case_conference_recommendations,
        case_conferences,
        minutes,
        // Perpetrator
        perpetrators,
        // Others
        data_dictionaries,
    ]
}