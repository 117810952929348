<template>
	<div class="w-full">
		<div class="flex flex-col space-y-4">
			<p class="text-sm font-bold">Intervention</p>
			<a
				@click="
					patientId && caseId ? router.replace(`/patient/${patientId}/cases/${caseId}/interventions`) : null
				">
				<div
					class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
					:class="hasRecord ? 'pointer-events-none bg-[#E6E6E6]' : 'cursor-pointer bg-primary-400'">
					<div class="flex items-center space-x-2">
						<span
							class="text-xs py-0.5 px-2 rounded-[10px]"
							:class="hasRecord ? 'text-neutral-500 bg-[#D4D4D4]' : 'text-primary-600 bg-[#98C7FE]'"
							>{{ item?.interventions_count }}</span
						>
						<p class="text-sm font-bold" :class="hasRecord ? 'text-neutral-500' : 'text-primary-600'">
							Intervention
						</p>
					</div>
					<ChevronRightIcon
						class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
						:class="hasRecord ? 'text-neutral-500' : 'text-primary-600'" />
				</div>
			</a>

			<p v-if="hasPermission('can-manage-patient-guardian-companion-peretrator')" class="text-sm font-bold">
				Other Information
			</p>

			<div
				v-if="hasPermission('can-manage-patient-guardian-companion-peretrator')"
				class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
				:class="hasRecord ? 'pointer-events-none bg-[#E6E6E6]' : 'cursor-pointer bg-primary-400'"
				@click="showGuardianModal = true">
				<div class="flex items-center space-x-2">
					<span
						class="text-xs py-0.5 px-2 rounded-[10px]"
						:class="hasRecord ? 'text-neutral-500 bg-[#D4D4D4]' : 'text-primary-600 bg-[#98C7FE]'"
						>{{ item?.guardians_count }}</span
					>
					<p class="text-sm font-bold" :class="hasRecord ? 'text-neutral-500' : 'text-primary-600'">
						Guardian
					</p>
				</div>
				<ChevronRightIcon
					class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
					:class="hasRecord ? 'text-neutral-500' : 'text-primary-600'" />
			</div>

			<div
				v-if="hasPermission('can-manage-patient-guardian-companion-peretrator')"
				class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
				:class="hasRecord ? 'pointer-events-none bg-[#E6E6E6]' : 'cursor-pointer bg-primary-400'"
				@click="showCompanionModal = true">
				<div class="flex items-center space-x-2">
					<span
						class="text-xs py-0.5 px-2 rounded-[10px]"
						:class="hasRecord ? 'text-neutral-500 bg-[#D4D4D4]' : 'text-primary-600 bg-[#98C7FE]'"
						>{{ item?.companions_count }}</span
					>
					<p class="text-sm font-bold" :class="hasRecord ? 'text-neutral-500' : 'text-primary-600'">
						Informant
					</p>
				</div>
				<ChevronRightIcon
					class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
					:class="hasRecord ? 'text-neutral-500' : 'text-primary-600'" />
			</div>

			<div
				v-if="hasPermission('can-manage-patient-guardian-companion-peretrator')"
				class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
				:class="hasRecord ? 'pointer-events-none bg-[#E6E6E6]' : 'cursor-pointer bg-primary-400'"
				@click="showPatientAddressModal = true">
				<div class="flex items-center space-x-2">
					<span
						class="text-xs py-0.5 px-2 rounded-[10px]"
						:class="hasRecord ? 'text-neutral-500 bg-[#D4D4D4]' : 'text-primary-600 bg-[#98C7FE]'"
						>{{ item?.patient_addresses_count }}</span
					>
					<p class="text-sm font-bold" :class="hasRecord ? 'text-neutral-500' : 'text-primary-600'">
						Patient Address
					</p>
				</div>
				<ChevronRightIcon
					class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
					:class="hasRecord ? 'text-neutral-500' : 'text-primary-600'" />
			</div>

			<div
				v-if="hasPermission('can-manage-patient-guardian-companion-peretrator')"
				class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
				:class="hasRecord ? 'pointer-events-none bg-[#E6E6E6]' : 'cursor-pointer bg-primary-400'"
				@click="showPerpetratorModal = true">
				<div class="flex items-center space-x-2">
					<span
						class="text-xs py-0.5 px-2 rounded-[10px]"
						:class="hasRecord ? 'text-neutral-500 bg-[#D4D4D4]' : 'text-primary-600 bg-[#98C7FE]'"
					>
						{{ item?.perpetrators_count }}
					</span>
					<p class="text-sm font-bold" :class="hasRecord ? 'text-neutral-500' : 'text-primary-600'">
						Perpetrator
					</p>
				</div>
				<ChevronRightIcon
					class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
					:class="hasRecord ? 'text-neutral-500' : 'text-primary-600'" />
			</div>
			<a
				@click="
					patientId && caseId
						? $router.replace(
							item.intakes || intake
								? `/patient/${patientId}/cases/${caseId}/intake/${
										item.intakes?.id || intake?.id
								}/view`
								: `/patient/${patientId}/cases/${caseId}/intake/view`
						)
						: null
				">
				<div
					class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
					:class="hasRecord ? 'pointer-events-none bg-[#E6E6E6]' : 'cursor-pointer bg-primary-400'">
					<div class="flex items-center space-x-2">
						<p class="text-sm font-bold" :class="hasRecord ? 'text-neutral-500' : 'text-primary-600'">
							Intake
						</p>
					</div>
					<ChevronRightIcon
						class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
						:class="hasRecord ? 'text-neutral-500' : 'text-primary-600'" />
				</div>
			</a>

			<a
				@click="
					patientId && caseId
						? $router.replace(
							item.assessments || assessment
								? `/patient/${patientId}/cases/${caseId}/safety-assessment/${
										item.assessments?.id || assessment?.id
								}/view`
								: `/patient/${patientId}/cases/${caseId}/safety-assessment/create`
						)
						: null
				">
				<div
					class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
					:class="hasRecord ? 'pointer-events-none bg-[#E6E6E6]' : 'cursor-pointer bg-primary-400'">
					<div class="flex items-center space-x-2">
						<p class="text-sm font-bold" :class="hasRecord ? 'text-neutral-500' : 'text-primary-600'">
							Immediate Safety Assessment
						</p>
					</div>
					<ChevronRightIcon
						class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
						:class="hasRecord ? 'text-neutral-500' : 'text-primary-600'" />
				</div>
			</a>

			<div
				class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
				:class="hasRecord ? 'pointer-events-none bg-[#E6E6E6]' : 'cursor-pointer bg-primary-400'"
				@click="showCaseConferenceModal = true">
				<div class="flex items-center space-x-2">
					<span
						class="text-xs py-0.5 px-2 rounded-[10px]"
						:class="hasRecord ? 'text-neutral-500 bg-[#D4D4D4]' : 'text-primary-600 bg-[#98C7FE]'"
					>
						{{ item?.case_conferences_count }}

					</span>
					<p class="text-sm font-bold" :class="hasRecord ? 'text-neutral-500' : 'text-primary-600'">
						Case Conference
					</p>
				</div>
				<ChevronRightIcon
					class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
					:class="hasRecord ? 'text-neutral-500' : 'text-primary-600'" />
			</div>

			<!-- <a
				@click="
					patientId && caseId
						? ( item?.hasCaseConference 
							? $router.replace(`/patient/${patientId}/cases/${caseId}/case-conference/information?view=${item?.hasCaseConference}`)
							: $router.replace(`/patient/${patientId}/cases/${caseId}/case-conference/information`)
						)
						: null
				">
				<div
					class="rounded py-3 px-4 overflow-hidden w-full relative min-h-[72px] flex items-center justify-between"
					:class="[
						hasRecord ? 'pointer-events-none bg-[#E6E6E6] test' : 
						// item?.hasCaseConference ? 'pointer-events-none bg-[#E6E6E6] test2' : 
						'cursor-pointer bg-primary-400'
					]"
				>
					<div class="flex items-center space-x-2">
						<p class="text-sm font-bold"
							:class="[
								hasRecord ? 'text-neutral-500' :
								// item?.hasCaseConference ? 'text-neutral-500' :
								'text-primary-600'
							]"
						>
							Case Conference
						</p>
					</div>
					<ChevronRightIcon
						class="w-5 h-5 p-0.5 cursor-pointer stroke-[3]"
						:class="[
							hasRecord ? 'text-neutral-500' :
							!item?.hasCaseConference ? 'text-neutral-500' :
							'text-primary-600'
						]" />
				</div>
			</a> -->
		</div>

		<guardian-modal
			:show="showGuardianModal"
			@close="showGuardianModal = false"
			@confirm="showGuardianModal = false"
			hasContent />

		<companion-modal
			:show="showCompanionModal"
			@close="showCompanionModal = false"
			@confirm="showCompanionModal = false"
			hasContent />

		<patient-address-modal
			:show="showPatientAddressModal"
			@close="showPatientAddressModal = false"
			@confirm="showPatientAddressModal = false"
			hasContent />

		<perpetrator-modal
			:show="showPerpetratorModal"
			@close="showPerpetratorModal = false"
			@confirm="showPerpetratorModal = false"
			hasContent />

		<case-conference-modal
			:show="showCaseConferenceModal"
			@close="showCaseConferenceModal = false"
			@confirm="showCaseConferenceModal = false"
			hasContent />
	</div>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import GuardianModal from "./Modals/GuardianModal.vue";
import CompanionModal from "./Modals/CompanionModal.vue";
import PatientAddressModal from "./Modals/PatientAddressModal.vue";
import PerpetratorModal from "./Modals/PerpetratorModal.vue";
import CaseConferenceModal from "./Modals/CaseConferenceModal.vue";
import { useRouter, useRoute } from "vue-router";
import { hasPermission } from "@/classes/AuthService";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const recordStatusChecker = useRecordStatusChecker();
const isRecordDeleted = computed(() => recordStatusChecker.isDeletedGuardian);
const isRecordCreated = computed(() => recordStatusChecker.isCreatedGuardian);
const isInformantDeleted = computed(() => recordStatusChecker.isDeletedInformant);
const isInformantCreated = computed(() => recordStatusChecker.isCreatedInformant);
const isPatientAddressDeleted = computed(() => recordStatusChecker.isDeletedPatientAddress);
const isPatientAddressCreated = computed(() => recordStatusChecker.isCreatedPatientAddress);
const isPerpetratorDeleted = computed(() => recordStatusChecker.isDeletedPerpetrator);
const isPerpetratorCreated = computed(() => recordStatusChecker.isCreatedPerpetrator);
const router = useRouter();
const route = useRoute();

defineProps({
	hasRecord: {
		type: Boolean,
		default: false,
	},
	item: {
		type: Object,
	},
    otherDetailsCount: {
        type: Object,
        default: null,
    }
});

const showGuardianModal = ref<boolean>(false);
const showCompanionModal = ref<boolean>(false);
const showPatientAddressModal = ref<boolean>(false);
const showPerpetratorModal = ref<boolean>(false);
const showCaseConferenceModal = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const offlineParams = ref<string>(route.query.offline);
const intake = ref<object>(null);
const assessment = ref<object>(null);

const closeModals = () => {
    showGuardianModal.value = false;
    showCompanionModal.value = false;
    showPatientAddressModal.value = false;
    showPerpetratorModal.value = false;
}

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

watch(
    () => isRecordDeleted.value,
    () => {
        closeModals();
    }
);

watch(
    () => isRecordCreated.value,
    () => {
        closeModals();
    }
);

watch(
    () => isInformantDeleted.value,
    () => {
        closeModals();
    }
);

watch(
    () => isPatientAddressDeleted.value,
    () => {
        closeModals();
    }
);

watch(
    () => isPerpetratorDeleted.value,
    () => {
        closeModals();
    }
);

// onMounted(() => getData());
</script>
