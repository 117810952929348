<template>
	<div class="frm-table border rounded-lg border-neutral-100" :class="[dataTableClass]">
		<div class="align-middle inline-block w-full">
			<div v-if="hasSearch" class="flex md:flex-row flex-col md:items-center md:justify-between p-4 border-b border-neutral-100">
				<div class="flex items-center">
					<button type="submit" class="border-0 mr-3">
						<!-- <img
							src="/assets/icon/ic-search-2.svg"
							alt="search_icon"
							class="w-5 h-5 object-contain grayscale brightness-[0.3]" /> -->
						<search-two-icon class="w-5 h-5 object-contain grayscale brightness-[0.3]" />
					</button>
					<input
						type="text"
						name="search"
						id="search"
						v-model="searchText"
						@input="$emit('update:searchText', $event.target.value)"
						:placeholder="searchPlaceholder"
						class="p-0 text-neutral-600 placeholder:font-medium placeholder-neutral-400 border-0 focus:ring-0 focus:outline-none min-w-[400px]" />
				</div>
				<slot name="topContent"></slot>
			</div>
			<div :class="parentTableClass" class="overflow-auto max-w-[calc(100vw-34px)]">
				<table class="min-w-full divide-y border-none divide-gray-200" :class="[tableClass]">
					<!-- Header Slot -->
					<slot name="header">
						<thead class="bg-primary-100">
							<tr>
                                <td v-if="setActionOnStart" class="px-6 py-3 text-center text-xs text-neutral-900 font-semibold whitespace-nowrap">
                                    {{actionText}}
                                </td>
								<th
									v-for="(header, index) in headers"
									:key="`th-${index}`"
									class="px-6 py-6 text-left text-xs text-neutral-900 font-semibold whitespace-nowrap th-parent"
									:width="header.width">
									<span>{{ header.text }}</span>
									<!-- <ChevronUpDownIcon
										v-if="header.haveSort"
										class="w-5 h-5 text-neutral-900 cursor-pointer inline-block ml-2" /> -->
								</th>
								<th
									v-if="!noAction && !setActionOnStart"
									class="px-6 py-3 text-center text-xs text-neutral-900 font-semibold whitespace-nowrap">
									{{ actionText }}
								</th>
							</tr>
						</thead>
					</slot>
					<!-- Body Slot -->
					<tbody class="bg-white divide-y divide-gray-200 text-sm text-gray-500">
						<slot name="body" v-if="count > 0" />
						<template v-else>
							<tr>
								<td
									class="whitespace-nowrap text-center"
									:colspan="headers.length + (!noAction ? 1 : 0)">
									<div class="text-sm text-gray-400 py-4">
										{{ emptyText }}
									</div>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<slot name="tfoot"></slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import SearchTwoIcon from "@/components/icons/searchTwoIcon.vue";
import { boolean } from "zod";

const props = defineProps({
	modelValue: {
		default: null,
		required: true,
	},
	headers: {
		default: null,
		type: Object,
	},
	searchPlaceholder: {
		default: null,
		type: String,
	},
	parentTableClass: {
		default: null,
		type: String,
	},
	tableClass: {
		default: null,
		type: String,
	},
	dataTableClass: {
		default: null,
		type: String,
	},

	haveSort: {
		default: false,
		type: Boolean,
	},
	hasSearch: {
		default: false,
		type: Boolean,
	},

	/* Text for empty list */
	emptyText: {
		default: "No data to display.",
		type: String,
	},

	/* Hide action column */
	noAction: {
		default: false,
		type: Boolean,
	},

	/* Action header text */
	actionText: {
		default: "Actions",
		type: String,
	},

	count: {
		default: 0,
		type: Number,
	},
	search: {
		type: String,
		default: null,
	},
    setActionOnStart: {
        type: boolean,
        default: false,
    }
});

const searchText = ref(props.search);
</script>
