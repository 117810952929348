import { requiredIf, helpers, required, numeric, minLength, maxLength, minValue, maxValue } from "@vuelidate/validators";

const searchRules = (form: any) => {
    return {
        first_name: {
            required: helpers.withMessage('The first name field is required when last name is empty.', requiredIf(!form.last_name)),
        },
        last_name: {
            required: helpers.withMessage('The last name field is required when first name is empty.', requiredIf(!form.first_name)),
        },
    }
}

const createPatienCasetRules = (form: any) => {
    return {
        registered_at: {
            required: helpers.withMessage(errorMessage('patient registered'), required),
        },
        first_name: {
            required: helpers.withMessage(errorMessage('first name'), required),
        },
        last_name: {
            required: helpers.withMessage(errorMessage('last name'), required),
        },
        sex_at_birth: {
            required: helpers.withMessage(errorMessage('sex at name'), required),
        },
        approximate_age_month: {
            numeric: numeric,
            minValue: minValue(form.approximate_age_year > 0 ? 0 : 1),
            maxValue: maxValue(12),
            required: helpers.withMessage('The approximate age month field is required when birthdate is not present.', requiredIf(!form.birthdate)),
        },
        approximate_age_year: {
            numeric: numeric,
            minValue: minValue(form.approximate_age_month > 0 ? 0 : 1),
            required: helpers.withMessage('The approximate age year field is required when birthdate is not present.', requiredIf(!form.birthdate)),
        },
        birthdate: {
            required: helpers.withMessage('The birthdate field is required when approximate age month & year is not present.', requiredIf(!form.approximate_age_month && !form.approximate_age_year)),
        },
        referral_source_id: {
            required: helpers.withMessage(errorMessage('referral source'), required),
        },
        pwd_specify: {
            required: helpers.withMessage(errorMessage('pwd specify'), requiredIf(form.pwd != null && form.pwd != 7)),
        },
        intake_social_worker_id: {
            required: helpers.withMessage(
                'The Intake social worker field is required when none of Social worker / Physician / Mental health personnel are present.', 
                requiredIf(!form.social_worker_id && !form.physician_id && !form.mental_health_personnel_id)
            ),
        },
        social_worker_id: {
            required: helpers.withMessage(
                'The Intake social worker field is required when none of Social worker / Physician / Mental health personnel are present.', 
                requiredIf(!form.intake_social_worker_id && !form.physician_id && !form.mental_health_personnel_id)
            ),
        },
        physician_id: {
            required: helpers.withMessage(
                'The Physician field is required when none of Social worker / Intake social worker / Mental health personnel are present.', 
                requiredIf(!form.intake_social_worker_id && !form.social_worker_id && !form.mental_health_personnel_id)
            ),
        },
        mental_health_personnel_id: {
            required: helpers.withMessage(
                'The Mental health personnel field is required when none of Social worker / Intake social worker / Physician are present.', 
                requiredIf(!form.intake_social_worker_id && !form.social_worker_id && !form.physician_id)
            ),
        },
        sought_service_id: {
            required: helpers.withMessage(errorMessage('sought service'), required),
        },
        hospital_area_id: {
            required: helpers.withMessage(errorMessage('hospital area'), requiredIf(form.is_inpatient)),
        },
        hospital_number: {
            required: helpers.withMessage(errorMessage('hospital number'), requiredIf(form.is_inpatient && form.hospital_area_id != null)),
        },
        other_nationality: {
            required: helpers.withMessage(errorMessage('other nationality'), requiredIf(form.nationality_id == 'others')),
        },
        other_religion: {
            required: helpers.withMessage(errorMessage('other religion'), requiredIf(form.religion_id == 'others')),
        },
        other_indigeneous_people: {
            required: helpers.withMessage(errorMessage('specify'), requiredIf(form.indigeneous_people_id == 'others')),
        },
        company_contact_number: {
            numeric: helpers.withMessage('The company contact number must be numeric', numeric),
            minLength: helpers.withMessage('The company contact number must be 10 numbers', minLength(10)),
            maxLength: helpers.withMessage('The company contact number must be 10 numbers', maxLength(10)),
        }
    }
}

const errorMessage = (field: any) => {
    return `The ${field} field is required`; 
}

export default {
    searchRules,
    createPatienCasetRules,
}