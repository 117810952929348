<template>
    <base-modal
        :customClass="customClass"
        :show="show"
        :whiteBg="whiteBg"
    >
        <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-2 rounded-full bg-primary-300">
            <!-- <img src="/assets/icon/ic-lock-circle.svg" class="object-contain w-6 h-6" > -->
            <lock-circle-icon class="object-contain w-6 h-6" />
        </div>
        <DialogTitle
            as="p"
            class="text-xl font-bold text-center text-neutral-900 focus-visible:outline-none"
            tabindex="0"
        >
            {{ title }}
        </DialogTitle>
        <form class="flex flex-col space-y-6" @submit.prevent="confirm">
            <div class="text-sm leading-5 text-center text-gray-500">
                <p v-if="content">{{ content }}</p>
                <div class="w-full mx-auto mb-3">
                    <div class="flex justify-center space-x-3 mt-3">
                        <password-input 
                            name="password"
                            id="password"
                            placeholder="Password"
                            v-model="password"
                        />
                    </div>
                </div>

                <p v-if="error" class="text-[10px] mt-1 text-left" :class="error ? 'text-red-600' : 'text-gray-800'" id="email-error">
                    {{ error }}
                </p>
            </div>

            <div class="flex justify-between space-x-3 mt-9">
                <button-component :disabled="!password" type="submit" size="large" btnWidth="w-full" @click="confirm"> {{actionText}} </button-component>
            </div>
        </form>        
    </base-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";

import {
  DialogTitle,
} from '@headlessui/vue';
import BaseModal from '@/components/modals/BaseModal.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import lockCircleIcon from "@/components/icons/lockCircleIcon.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";

const emit = defineEmits(['confirm']);

const props = defineProps({
    show: {
        type: Boolean
    },
    title: {
        type: String,
    },
    content: {
        type: String,
    },
    actionText: {
        type: String,
    },
    customClass: {
        type: String,
        default: 'py-6 px-6 w-[480px]'
    },
    whiteBg: {
        type: Boolean,
        default: false
    },
    validate: {
        type: Boolean,
        default: true
    },
    isNetworkAvailable: {
        type: Boolean,
        default: true,
    },
    password: {
        type: String,
        default: null,
    },
    error: {
        type: String,
        default: null,
    }
})

const password = ref(props.password);

const confirm = (): void => {
    if (!password.value) {
        return;
    }
    emit('confirm', password.value); 
}
</script>