import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../../defaultMigration";


export const intake_email_addresses: SchemaTableOptions = {
   name:<string> 'intake_email_addresses',
   schema:<Array<SchemaColumnOptions>> [
       { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
       { column: 'cases_id', value: 'INTEGER UNSIGNED'}, 
       { column: 'label', value: 'VARCHAR(255) NOT NULL'}, 
       { column: 'email', value: 'VARCHAR(255) NOT NULL'}, 
       { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
       ...defaultMigration
   ]
}
