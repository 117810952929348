import {required, helpers, minLength, maxLength } from "@vuelidate/validators"
const createRules = (form: any): any => { 
   return {
        label: {
            required:  helpers.withMessage(errorMessage('label'), required)
        },
        number: {
            required: helpers.withMessage(errorMessage('number'), required),
            minLength: helpers.withMessage('The number must be 10 numbers', minLength(10)),
            maxLength: helpers.withMessage('The number must be 10 numbers', maxLength(10)),
        },  
   }
}

const errorMessage = (field: any) => {
   return `The ${field} field is required`;
}
export default {
   createRules,
}
