

class CaseSafetyAssessmentForm {

    viewForm(item: any){
        return {
            name: `${item.last_name} ${item.last_name}`, 
            case_number: item.case_number,
            assessment_date: item.assessment_date,
            is_assessment_patient_sibling: item.is_assessment_patient_sibling,
            appointment: item.appointment,
            safety_assessment_questions: item.questions,
            brief_family_background: item.brief_family_background,
            family_background: item.family_background,
            yes_count: item.yes_count,
            no_count:  item.no_count,
            unknown_count: item.unknown_count,
            riskCodes: item.risk_code_id,
            immediate_safety_plan: item.immediate_safety_plan,
            overall_risk_assessment: item.overall_risk_assessment,
        }
    }
    createForm(){
        return {
            cases_id: null,
            last_name: null,
            first_name: null,
            middle_name: null,
            assessment_date: null,
            is_assessment_patient_sibling: false,
            appointment: null,
            brief_family_background: null,
            family_background: null,
            yes_count:  0,
            no_count:  0,
            unknown_count:  0,
            risk_code_id:  null,
            immediate_safety_plan: null,
            overall_risk_assessment: null, 
            case_number: null
        }
    
    }
    updateForm(item: any){  
        return { 
            id: item?.id,
            cases_id: item?.cases_id,
            case_number: item?.case_number,
            assessment_date: item?.assessment_date,
            last_name: item?.last_name,
            first_name: item?.first_name,
            middle_name: item?.middle_name ??  null,
            is_assessment_patient_sibling: item?.is_assessment_patient_sibling || false,
            appointment: item?.appointment,
            brief_family_background: item?.brief_family_background || "",
            family_background: item?.family_background || "",
            yes_count: item?.yes_count,
            no_count: item?.no_count,
            unknown_count: item?.unknown_count,
            risk_code_id: item?.risk_code_id,
            immediate_safety_plan: item?.immediate_safety_plan || "",
            overall_risk_assessment: item?.overall_risk_assessment || "", 
        }
		
	}

    updateAutoFill(item: any){
        return {
            last_name: item?.last_name,
            first_name: item?.first_name,
            middle_name: item?.middle_name,
            case_number: item?.case_number,
            assessment_date: item?.assessment_date,
        }
    }
}
export default new CaseSafetyAssessmentForm();