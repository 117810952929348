import FormOptions from "@/classes/Forms/FormOptions";
import DateFormatter from "@/helpers/DateFormatter";
import SqliteProcessor from "@/processors/SqliteProcessor";
import { OfflineAction } from "@/views/enums/OfflineAction";
import Syncable from "@/extenders/models/Syncable";

const options  = new FormOptions();
const sqlite = new SqliteProcessor;
const syncable = new Syncable;

class RecommendationsService {
	async index(patient: number, patientCase: number, caseConference: number): Promise<any> {
        await sqlite.connect();
        const patientData = await sqlite.db.searchTable('patients', 'id', patient);
		const recommendations = await sqlite.db.select(`SELECT * FROM case_conference_recommendations where case_conference_id = ${caseConference} AND deleted_at IS NULL`);
		const recommendationOptions = await options.recommendationOptions();

		recommendationOptions.map((item:any) => {
			const index = recommendations.map((x:any) => x.recommendation_option_id).indexOf(item.id);

			let remarks = null;
			let checked = false;

			if(recommendations[index]?.remarks != null){
				remarks = recommendations[index].remarks;
				checked = true;			
			}

			item.remarks = remarks;
			item.checked = checked;

			return item;
		})

		return {
			data: {
				recommendationOptions: recommendationOptions,
				patient_name: `${patientData[0].first_name} ${patientData[0].last_name}`,
				item: {
					case_conference_id: caseConference,
					recommendations: recommendations,
				}
			}
		}
	}

	async storeRecommendationsOffline(items: any): Promise<void> {
        await sqlite.connect();
        syncable.table = 'case_conference_recommendations';
        for (const index in items.recommendations) {
            const item = items.recommendations[index];
            const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, item.id);
            if (checkRecord) {
                return;
            }
            await syncable.sync(sqlite.db, [item]);
        }
	}

	async store(patient: number, patientCase: number, caseConference: any, payload: any): Promise<any> {
        await sqlite.connect();
		const data:any = [];
		const notChecked:number[] = [];
        await sqlite.db.delete('case_conference_recommendations', { case_conference_id: caseConference});
		const recommendations = await sqlite.db.select(`SELECT * FROM case_conference_recommendations where case_conference_id = ${caseConference}  AND deleted_at IS NULL`);
		await payload.recommendations.forEach(async(item:any) => {
			const index = recommendations.map((item:any) => item.recommendation_option_id).indexOf(item.id)

			if(index != -1 ){
				if(item.checked){
					data.push(this.updateProcess(recommendations[index], item));
				} else {
					data.push(this.deleteProcess(recommendations[index]));
				}
			} else {
				if(item.checked){
					data.push(this.storeProcess(caseConference, item));
				}
			}

		});
        syncable.table = 'case_conference_recommendations';
        await syncable.sync(sqlite.db, data);
	}


	private storeProcess(caseConference:any, payload: any): any  {
		return {
			id:null,
			case_conference_id: parseInt(caseConference),
			recommendation_option_id: payload.id,
			remarks: payload.remarks,
			is_created_offline: true, 
			created_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			updated_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
            is_sync: false,
            synching_remarks: null,
		}
	}

	private updateProcess(recommendation: any, payload: any): any{

		return Object.assign(
			recommendation, 
			{ 
				remarks: payload.remarks, 
				is_updated_offline: true, 
				updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
                is_sync: false,
                synching_remarks: null,
			}
		);	
	}

	private deleteProcess(recommendation: any): any {

		return Object.assign(
			recommendation, 
			{ 
				remarks: null, 
				action: OfflineAction.DELETE_RECORD, 
				is_deleted_offline: true, 
				deleted_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
                is_sync: false,
                synching_remarks: null,
			}
		);	
	}
}

export default new RecommendationsService();