import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";

export const barangays:SchemaTableOptions = {
    name:<string> 'barangays',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'city_id', value: 'INTEGER UNSIGNED'},
        { column: 'code', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'name', value: 'VARCHAR(255) NOT NULL'},
    ]
}