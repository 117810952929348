import { AxiosRequestConfig } from "axios";
import http from "@/axios";

import { Patient } from "@/interfaces/PatientInterface"; 
// import DB from "@/classes/Databases/Crud"; 
// import PaginationPatient from "@/classes/Databases/PaginationPatient"; 
// import Storage from "@/helpers/storage"; 
// import CaseService from './Cases/CaseService'
import DraftPatientProcessor from "@/classes/Drafts/DraftPatientProcessor";
// import PaginationCases from "./Databases/PaginationCases";
// import { OfflineAction } from "@/views/enums/OfflineAction"; 

// const db = new DB("patients", new PaginationPatient("patients"));
// const dbCase = new DB("cases", new PaginationCases("cases")); 
class PatientService implements Patient {

	async patients(payload: object): Promise<any> {
		return await http.get("patients", { params: payload }).catch(error => error);
	}

	async create(payload: object): Promise<any> {
		return await http.get("patients/create", { params: payload }).catch(error => error);
	}

	async store(payload: object): Promise<any> {
		return await http.post("patients/", payload).catch(error => error);
	}

	async edit(patient: number, payload: any): Promise<any> {
		return await http.get(`patients/${patient}/edit`, { params: payload }).catch(error => error);
	}

	async update(patient: number, payload: any): Promise<any> {
		return await http.put(`patients/${patient}/update`, payload).catch(error => error);
	}

	async deleteDraft(draft: any): Promise<void> {
        console.log(draft)
		// const condition = draft.data.id ? { id: draft.data.id } : { offline_id: draft.data.offline_id };
		// CaseService.deleteDraft(draft.data.case).then(() => (db.delete(condition, 'patients'))).catch((error) => (console.error(error) ))
	}
		
	async drafts(): Promise<any> {
        console.log('drafts');
		// return await db.getDrafts('patients').then((patients) => { 
		// 	if (patients.length) {
		// 		return DraftPatientProcessor.processData(patients)
		// 	}

		// 	return [];
		// });
	}

	processSyncResults(patient: any): void {
		DraftPatientProcessor.processSyncResults(patient);
	}

    async saveOnlineToOfflinePatient(item: any): Promise<any> {
        console.log(item)
        // if (!item) {
        //     return null;
        // }

        // const checkIfRecordIsEditedOffline = await db.select(`SELECT * FROM patients WHERE id=${item.id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        // if (checkIfRecordIsEditedOffline.length > 0) {
        //     return;
        // }
        // const response =  await db.insert({
        //     id: item.id,
        //     last_name: item.last_name,
        //     first_name: item.first_name,
        //     middle_name: item.middle_name,
        //     birthdate: item.birthdate,
        //     content: JSON.stringify(item),
        //     encoded_by: item.encoded_by?.id,
        //     action: OfflineAction.DEFAULT,
        //     is_sync: 0,
        //     updated_at: "",
        //     deleted_at: "",
        // }, item.id, true, 'patients');

        // return response;
    }

    async saveOnlineToOfflineCase(item: any): Promise<any> {
        console.log(item);
        // if (!item) {
        //     return null;
        // }
        // const checkIfRecordIsEditedOffline = await dbCase.select(`SELECT * FROM cases WHERE id=${item.id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        // if (checkIfRecordIsEditedOffline.length > 0) {
        //     return;
        // }
        // const response = await dbCase.insert({
        //     id: item.id,
        //     patient_id: item.patient_id,
        //     case_number: item.case_number,
        //     initiated_at: item.initiated_at,
        //     content: JSON.stringify(item),
        //     encoded_by: item.patient?.encoded_by,
        //     action: OfflineAction.DEFAULT,
        //     is_sync: 0,
        //     updated_at: "",
        //     deleted_at: "",
        // }, item.id, true, 'cases')

        // return response;
    }
}

export default new PatientService();
