import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "./defaultMigration";

export const intervention_data:SchemaTableOptions = {
    name:<string> 'intervention_data',
    schema:<Array<SchemaColumnOptions>> [
        { column: "id", value: "INTEGER  PRIMARY KEY AUTOINCREMENT" },
		{ column: "offline_id", value: "INTEGER KEY" },
		
		{ column: "patient_id", value: "INTEGER UNSIGNED" },
		{ column: "cases_id", value: "INTEGER UNSIGNED" },
		{ column: "case_number", value: "TEXT NULL" },
		{ column: "intervention_type", value: "INTEGER UNSIGNED" },
		{ column: "intervention_type_name", value: "TEXT NULL" },
		{ column: "type", value: "TEXT NULL" },
		{ column: "type_type", value: "INTEGER UNSIGNED" },
		{ column: "type_id", value: "INTEGER UNSIGNED" },
		{ column: "subtype", value: "INTEGER NULL" },
		{ column: "subtype_type", value: "INTEGER NULL" },
		{ column: "subtype_id", value: "INTEGER NULL" },
		{ column: "schedule_date", value: "TIMESTAMP" },
		{ column: "done_on", value: "TIMESTAMP NULL" },
		{ column: "position_id", value: "INTEGER NULL" },
		{ column: "done_by_id", value: "INTEGER NULL" },
		{ column: "done_by", value: "TEXT NULL" },
		{ column: "data", value: "TEXT" },
		{ column: "action", value: "INT UNSIGNED" },
		{ column: "patient_name", value: "TEXT NULL" },
		{ column: "status", value: "TEXT NULL" },
        ...defaultMigration
    ]
}