import { CaseHistory } from "./Interface/CaseHistory";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import CaseOfflineProcessor from "./processor/CaseOfflineProcessor";
import SqliteProcessor from "@/processors/SqliteProcessor";
const sqlite = new SqliteProcessor;

class OfflineService implements CaseHistory {
    async index(patient: number, payload: object): Promise<any> {
        await sqlite.connect();
        return {
            data: {
                item: await PatientOfflineProcessor.viewPatientProfile(patient,payload),
                cases: await CaseOfflineProcessor.viewCaseCardHistory(patient, payload),
            }
        };
    }

    async latestInterventions(patient: number, case_id: number|null = null, payload: object, networkAvailable = true): Promise<any> {
        return null;
    }

    // sync to offline once page has been visited;
    async syncToOffline(payload: any): Promise<any> {
        await sqlite.connect();
        const patientIsNotSync = await PatientOfflineProcessor.checkUnsyncPatient(payload.item.id);
        if (!patientIsNotSync) {
            await PatientOfflineProcessor.syncToOffline(payload);
        }

        await CaseOfflineProcessor.syncToOffline(payload);
    }

    async create(patient: number, payload: any): Promise<any> {
        const defaultResponse= await PatientOfflineProcessor.createPatientCase();
        const patientResponse = await PatientOfflineProcessor.getLatestStoredPatient(patient);
        return {
            data: {
                ...defaultResponse,
                ...{
                    item: patientResponse
                }
            }
        }
    }

    async store(patient: number, payload: object): Promise<any> {
        return {
            data: {
                data: await CaseOfflineProcessor.storePatientCase(patient, payload),
                message: 'Patient successfully created!'
            }
        }
    }

    async edit(patient: number, case_id: number, payload: object): Promise<any> {
        const defaultResponse = await PatientOfflineProcessor.createPatientCase();
        const patientResponse = await PatientOfflineProcessor.getLatestStoredPatient(patient);
        const caseResponse = await CaseOfflineProcessor.editCase(case_id);
        return {
            data: {
                ...defaultResponse,
                ...{
                    patient: await PatientOfflineProcessor.transformEditPatient(patientResponse),
                    item: caseResponse,
                }
            }
        }
    }

    async update(patient: number, case_id: number, payload: object): Promise<any> {
        return {
            data: {
                data: await CaseOfflineProcessor.updateCase(patient, case_id, payload),
                message: 'Patient successfully updated!'
            }
        }
    }
}

export default new OfflineService();