import DateFormatter from "@/helpers/DateFormatter";
import Relationships from "../Forms/Relationships";

const relationship = new Relationships();
export const defaultCreateForm = ():object =>{
    return { 
        is_updated_offline: false,
        is_deleted_offline:  false, 
        updated_at:   null,
        deleted_at:   null, 
        is_sync:  false,
        is_sync_failed:  false,
        is_created_offline:   true, 
        created_at: relationship.dateFormatFromIso(new Date),
        // created_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'), 
        synching_remarks:  null
    }
}

export const defaultUpdateForm = ():object =>{
    return { 
        is_sync:  false,
        is_sync_failed:  false, 
        is_updated_offline:   true, 
        updated_at: relationship.dateFormatFromIso(new Date),
        // updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'), 
        synching_remarks:  null
    }
}

export const defaultDeleteForm = ():object =>{
    return { 
        is_sync:  false,
        is_sync_failed:  false, 
        is_deleted_offline:   true, 
        deleted_at: relationship.dateFormatFromIso(new Date),
        // deleted_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
        synching_remarks:  null
    }
}


export default {
    defaultCreateForm,
    defaultUpdateForm,
    defaultDeleteForm
}