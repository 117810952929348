<template>
	<user-layout
		hasBack
		backTitle="View Intake"
		:backLink="`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="gotoEdit()"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component
					customClass="primary md:w-auto w-full">
						<!-- <img
							src="/assets/icon/ic-pencil-underline.svg"
							alt="edit_icon"
							class="object-contain w-4 h-4 mr-1 icon" /> -->
						<pencil-underline-icon class="object-contain w-4 h-4 mr-1 icon" />
						<span>Edit</span>
					</button-component>
				</a>
				
				<button-component
                    v-if="isNetworkAvailable"
					size="large"
					customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 md:w-auto w-full md:mt-0 mt-6"
					@click="generatePDFAccess()"
				>
					<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
					<span>View PDF</span>
				</button-component>
			</div>
		</template>

		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ item?.name }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Intake" />
			</div>
			<div class="grid grid-cols-6 sm:gap-6 gap-3 items-start">
				<div class="col-span-6"></div>
				<p class="col-span-6 text-neutral-600 font-medium text-sm"> Personal Information </p>
				<div class="grid grid-cols-3 md:col-span-3 col-span-6 gap-3">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Full Name:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Sex:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.sex_form || item?.sex }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Gender:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.gender }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Birthdate:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.birthdate || "Unknown" }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p v-if="BirthdayClassification.APPROXIMATE_AGE == item?.birthday_classification"> Approximate Age: </p>
						<p v-else> Age: </p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p >{{ item?.age }} &nbsp;</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Civil Status:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.civil_status }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Contact Number:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.contacts }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Lives w/ Child:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.lives_w_child }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Case Number:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.case_number }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Primary Diagnosis:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.primary_diagnosis }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Informant relation to child:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.informant_relation_child }}</p>
					</div>
					<div class="col-span-1 md:pb-0 pb-2 md:border-b-0 border-b border-gray-100">
						<p class="font-medium">Other Relationship:</p>
					</div>
					<div class="col-span-2 pl-3 md:pb-0 pb-2 md:border-b-0 border-b border-gray-100">
						<p>{{ item?.other_relationship }}</p>
					</div>
				</div>
				<div class="grid grid-cols-3 md:col-span-3 col-span-6 gap-3">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Services sought:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.services_sought }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Social worker:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.social_worker }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">External referral from:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.external_referral }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Intra-PGH referral from:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.intra_referral }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">If inpatient, which ward referred:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.inpatient_referred }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Legal Status:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.legal_status }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Housing Classification:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.housing_classification }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Socio Eco Status:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.socio_economic_status }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">No. of Children in Family:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.no_children_in_family }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">No. of Household Members:</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.no_household_members }}</p>
					</div>
					<div class="col-span-1">
						<p class="font-medium">No. of Families in Household:</p>
					</div>
					<div class="col-span-2">
						<p>{{ item?.no_family_in_household }}</p>
					</div>
				</div>
			</div>

			<!-- Address and Present Location -->
			<div class="border-t border-gray-200 sm:mt-12 mt-6"></div>
			<div class="grid grid-cols-6 sm:gap-6 gap-3 items-start">
				<div class="col-span-6"></div>
				<p class="col-span-12 text-neutral-600 font-medium text-sm"> Address and Present Location </p>
				<div class="lg:col-span-12 col-span-6 relative w-full p-4 pr-8 border border-gray-200 rounded" v-for="address in item.addresses" :key="address.id">
					<table class="w-full">
						<tbody >
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Address Type:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ address?.address_type }}</p>
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Address:</p>
								</td>
								<td class="py-2">
									<p  class="font-normal">{{ address?.current }}</p> 
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Barangay:</p>
								</td>
								<td class="py-2">
									<p  class="font-normal">{{ address?.cur_barangay }}</p> 
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">City/Municipality/District:</p>
								</td>
								<td class="py-2">
									<p  class="font-normal">{{ address?.cur_city }}</p> 
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Province:</p>
								</td>
								<td class="py-2">
									<p  class="font-normal">{{ address?.cur_province }}</p> 
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Region:</p>
								</td>
								<td class="py-2">
									<p  class="font-normal">{{ address?.cur_region }}</p> 
								</td>
							</tr>
							<tr>
								<td class="w-[200px] py-2">
									<p class="font-medium">Direction:</p>
								</td>
								<td class="py-2">
									<p  class="font-normal">{{ address?.current_directions }}</p> 
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<!-- Persons Related to Case -->
			<div class="border-t border-gray-200 sm:mt-12 mt-6"></div>
			<div class="grid grid-cols-6 sm:gap-6 gap-3 items-start">
				<div class="col-span-6"></div>
				<p class="col-span-6 text-neutral-600 font-medium text-sm"> Persons Related to Case </p>
				<div class="lg:col-span-3 col-span-6 relative w-full">
                    <div class="mb-1 p-4 pr-8 border border-gray-200 rounded" v-for="li in item.familyComposition" :key="li.name">
                        <table class="w-full">
						<tbody>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Full Name:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ li?.name }}</p>
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Gender:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ li?.gender }}</p>
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Relationship:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ li?.relationship }}</p>
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Birthdate:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ li.birthdate }}</p>
								</td>
							</tr>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Age:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ li?.computed_age || li?.age }}</p>
								</td>
							</tr>
							<tr>
								<td class="w-[200px] py-2">
									<p class="font-medium">Contact Number:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ li.contacts || li.contact }}</p>
								</td>
							</tr>
						</tbody>
					</table>
                    </div>					
				</div>
			</div>

			<!-- Contact Numbers -->
			<div class="border-t border-gray-200 sm:mt-12 mt-5"></div>
			<div class="grid grid-cols-6 sm:gap-6 gap-3 items-start">
				<div class="col-span-6"></div>
				<p class="col-span-6 text-neutral-600 font-medium text-sm"> Contact Numbers </p>
				<div v-if="!item.contactNumbers" class="lg:col-span-3 col-span-6 relative w-full p-4 pr-8 border border-gray-200 rounded">
					<table class="w-full">
						<tbody>
							<tr class="border-b border-solid border-gray-100">
								<td class="w-[200px] py-2">
									<p class="font-medium">Label:</p>
								</td>
							</tr>
							<tr>
								<td class="w-[200px] py-2">
									<p class="font-medium">	Contact Numbers:</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<template v-else> 
					<div v-for="li in item.contactNumbers" :key="li.label" class="lg:col-span-3 col-span-6 relative w-full p-4 pr-8 border border-gray-200 rounded">
						<table class="w-full">
							<tbody>
								<tr class="border-b border-gray-100 border-solid">
									<td class="w-[200px] py-2">
										<p class="font-medium">Label:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ li?.label }}</p>
									</td>
								</tr>
								<tr>
									<td class="w-[200px] py-2">
										<p class="font-medium">	Contact Numbers:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ li?.number }}</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</template>
			</div>

			<!-- Email Addresses -->
			<div class="border-t border-gray-200 sm:mt-12 mt-6"></div>
			<div class="grid grid-cols-6 sm:gap-6 gap-3 items-start">
				<div class="col-span-6"></div>
				<p class="col-span-6 text-neutral-600 font-medium text-sm"> Email Addresses </p>
				<div v-if="!item.emailAdresses" class="lg:col-span-3 col-span-6 relative w-full p-4 pr-8 border border-gray-200 rounded">
					<table class="w-full">
						<tbody>
							<tr class="border-b border-solid border-gray-100">
								<td class="w-[200px] py-2">
									<p class="font-medium">Label:</p>
								</td>
							</tr>
							<tr>
								<td class="w-[200px] py-2">
									<p class="font-medium">	Email Address:</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<template v-else> 
					<div v-for="li in item.emailAdresses" :key="li.label" class="lg:col-span-3 col-span-6 relative w-full p-4 pr-8 border border-gray-200 rounded">
						<table class="w-full">
							<tbody>
								<tr class="border-b border-gray-100 border-solid">
									<td class="w-[200px] py-2">
										<p class="font-medium">Label:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ li?.label }}</p>
									</td>
								</tr>
								<tr>
									<td class="w-[200px] py-2">
										<p class="font-medium">	Email Address:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ li?.email }}</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</template>
			</div>


			<!-- Incest Cases -->
			<div class="border-t border-gray-200 sm:mt-12 mt-6"></div>
			<div class="grid grid-cols-6 sm:gap-6 gap-3 items-start">
				<div class="col-span-6"></div>
				<p class="col-span-6 text-sm font-medium text-neutral-600"> Incest Cases </p>
				<div class="col-span-6">
					<table class="w-full">
						<tbody>
							<tr class="border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Regular sleeping arrangement:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ item.incest?.sleeping_arrangement }}</p>
								</td>
							</tr>
							<tr class="align-top border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">Specify:</p>
								</td>
								<td class="py-2">
									<p class="font-normal">{{ item.incest?.others }}</p>
								</td>
							</tr>
							<tr class="align-top border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">	Same bed / mat with:</p>
								</td>
								<td class="py-2 frm-description">
									<ul>
										<li class="font-normal">{{ item.incest?.same_bed_with}}</li>
									</ul>
								</td>
							</tr>
							<tr class="align-top border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">	Same room with:</p>
								</td>
								<td class="py-2 frm-description">
									<ul>
										<li class="font-normal">{{ item.incest?.same_room_with}}</li>
									</ul>
								</td>
							</tr>
							<tr class="align-top border-b border-gray-100 border-solid">
								<td class="w-[200px] py-2">
									<p class="font-medium">	Living arrangement at time of abuse:</p>
								</td>
								<td class="py-2 frm-description">
									<ul v-for="li in item.incest?.abused.form" :key="li" >
										<li class="font-normal">{{ li }}</li>
									</ul>
								</td>
							</tr>
							<tr class="align-top">
								<td class="w-[200px] py-2">
									<p class="font-medium">	Living arrangement at present:</p>
								</td>
								<td class="py-2 frm-description">
									<ul v-for="li in item.incest?.present.form" :key="li" >
										<li class="font-normal">{{ li }}</li>
									</ul>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>


		</div>
		<confirmation-modal 
			:show="showConfirmation" 
			@cancel="back()"
			@confirm="gotoEdit()"
			action-text="Create" 
			cancel-text="Back" 
			title="Warning!"
			typeTwo
			:content=errorMessage 
		/>
	</user-layout>


</template>
<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import { EyeIcon } from "@heroicons/vue/24/solid";
import { useRouter, useRoute } from "vue-router";  
import { IntakeShow } from "@/interfaces/Cases/Intake"; 
import IntakeFormService from "@/classes/Cases/IntakeForms/IntakeFormService";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { BirthdayClassification } from "@/views/enums/BirthdayClassification"; 
import PencilUnderlineIcon from "@/components/icons/pencilUnderlineIcon.vue"; 
// ***************************************** Offline Imports ****************
import IndexPageService from "@/classes/Cases/Intake/IndexPage/IndexPageService";
import { useNetworkStore } from "@/store/network";
const networkStore = useNetworkStore();
 const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
// ***************************************** ********************************
const router = useRouter();
const route = useRoute();
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);   
const showConfirmation = ref<boolean>(false);
const item = ref<IntakeShow>({}); 
const errorMessage = ref<string>('')
const offlineParams = ref<string>(route.query.offline);

const getData = async (): Promise<any> => {
	if (isNetworkAvailable.value) {
        await fetch();
       
    } else {
 
        const response = await IndexPageService.view(patientId.value, caseId.value);	 
        if (!response?.hasCurrentAddress) {
            errorMessage.value = 'No current address set yet';
            showConfirmation.value = true;
            return;
        }
        Object.assign(item.value, response);
    } 
};

const fetch = async ():void => {
	// IntakeFormService
	await IntakeFormService
		.index(
			patientId.value,
			caseId.value,  
		)
		.then(({data}) => {
			console.log(data);
			
			Object.assign(item.value, data.item)
			console.log(item.value);
		}).catch((errors) => {
			console.log(errors);
			console.log(errors.response.data.message)
			if (errors.response.data.message == 'No permanent address set yet' || errors.response.data.message == 'No present address set yet') {
				errorMessage.value = errors.response.data.message;
			} else {
				errorMessage.value = "Intake has no data, would you like to fill up the form?"
			}
			showConfirmation.value = true;
		})
}

const gotoEdit = ():void => {
	router.replace(
		`/patient/${patientId.value}/cases/${caseId.value}/intake/personal-information${
			offlineParams.value ? '?offline=true' : ''
		}`
	)
}
const back = ():void => {
	showConfirmation.value = false
	router.replace(
		`/patient/${patientId.value}/cases/${caseId.value}/edit${offlineParams.value ? '?offline=true' : ''}`
	)
}

const generatePDFAccess = ():void => {
	IntakeFormService
		.generatePDFAccess(
			patientId.value,
			caseId.value
		)
		.then(({data}) => {
			if(data.token){
				downloadPDF(data.token)
			}
		}).catch((errors) => {
			console.log(errors);
		})
}

const downloadPDF = (token:string):void => {
	IntakeFormService
		.downloadPDF(
			patientId.value,
			caseId.value,
			token
		)
		.then(({data}) => {
			console.log(data);
		}).catch((errors) => {
			console.log(errors);
		})

}

onMounted(async () => {
	await getData();
});
</script>
