import InterventionService from "@/classes/Interventions/InterventionService"

class DownloadPdf {
    public generatePDFAccess = (intervention:number|string, isNetworkAvailable:boolean):void => {
        InterventionService
            .generatePDFAccess(
                intervention, isNetworkAvailable
            )
            .then((response:any) => {
                if(response?.data?.token){
                    this.downloadPDF(intervention, response?.data?.token, isNetworkAvailable)
                }
            }).catch((errors) => {
                console.log(errors);
            })
    }

    public downloadPDF = (interventionId:number|string, token:string|string, isNetworkAvailable:boolean):void => {
        InterventionService
            .downloadCourtHearing(
                interventionId,
                token,
                isNetworkAvailable
            )
            .then((response:any) => {
                console.log(response);
            }).catch((errors) => {
                console.log(errors);
            })
    }


    public generateTelepsychiatryPDFAccess = (intervention:number|string, isNetworkAvailable:boolean):void => {
        InterventionService
            .generatePDFAccess(
                intervention, isNetworkAvailable
            )
            .then((response:any) => {
                if(response?.data?.token){
                    this.downloaTelepsychiatrydPDF(intervention, response?.data?.token, isNetworkAvailable)
                }
            }).catch((errors) => {
                console.log(errors);
            })
    }

    public downloaTelepsychiatrydPDF = (interventionId:number|string, token:string|string, isNetworkAvailable:boolean):void => {
        InterventionService
            .downloadTelepsychiatry(
                interventionId,
                token,
                isNetworkAvailable
            )
            .then((response:any) => {
                console.log(response);
            }).catch((errors) => {
                console.log(errors);
            })
    }

    public generatePsychologicalTestingPDFAccess = (intervention:number|string, isNetworkAvailable:boolean):void => {
        InterventionService
            .generatePDFAccess(
                intervention, isNetworkAvailable
            )
            .then((response:any) => {
                if(response?.data?.token){
                    this.downloadPsychologicalTestingPDF(intervention, response?.data?.token, isNetworkAvailable)
                }
            }).catch((errors) => {
                console.log(errors);
            })
    }

    public downloadPsychologicalTestingPDF = (interventionId:number|string, token:string|string, isNetworkAvailable:boolean):void => {
        InterventionService
            .downloadPsychologicalTesting(
                interventionId,
                token,
                isNetworkAvailable
            )
            .then((response:any) => {
                console.log(response);
            }).catch((errors) => {
                console.log(errors);
            })
    }
}
export default new DownloadPdf();