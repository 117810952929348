import DB from "@/classes/Databases/Crud";
import Relationships from "@/classes/Forms/Relationships";
import { SyncStatus } from "@/views/enums/SyncStatus";
import SyncPatients from "./SyncPatients";
import SyncCases from "./SyncCases";
import SyncPerpetrators from "./SyncPerpetrators";
import SyncCasePerpetrators from "./SyncCasePerpetrators";
import SyncGuardians from "./SyncGuardians";
import SyncInformants from "./SyncInformants";
import SyncPatientAddresses from "./SyncPatientAddresses";
import SyncInterventions from "./SyncInterventions";
import SyncIntakes from "./SyncIntakes";
import SyncCaseConference from "./SyncCaseConference";
import SyncCaseConferenceFactors from "./SyncCaseConferenceFactors";
import SyncCaseConferenceRecommendations from "./SyncCaseConferenceRecommendations";
import SqliteProcessor from "@/processors/SqliteProcessor";
import PatientOfflineForm from "../Patients/Offline/Formatters/PatientOfflineForm";
import Syncable from "@/extenders/models/Syncable";

const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const relationship = new Relationships();
const syncPatient = new SyncPatients;
const syncCases = new SyncCases;
const syncPerpetrators = new SyncPerpetrators;
const syncCasePerpetrators = new SyncCasePerpetrators;
const syncGuardians = new SyncGuardians;
const syncInformants = new SyncInformants;
const syncPatientAddress = new SyncPatientAddresses;
const syncInterventions = new SyncInterventions;
const syncIntake = new SyncIntakes;
const syncCaseConference = new SyncCaseConference;
const syncCaseConferenceFactors = new SyncCaseConferenceFactors;
const syncCaseConferenceRecommendations = new SyncCaseConferenceRecommendations;
export default class OnlineSynching {

    // providing a default form details with value
    renderDefaultForm(template: any, item: any, data:any = null) {
        // set default value
        template.created_at = data.created_at;
        template.updated_at = data.updated_at;
        template.deleted_at = data.deleted_at;
        template.created_at_formatted = null;
        template.birthdate_formatted = null;
        template.patient_name = null;
        template.synching_remarks = data.synching_remarks;
        template.is_created_offline = data.is_created_offline;
        template.is_updated_offline = data.is_updated_offline;
        template.is_deleted_offline = data.is_deleted_offline;
        template.is_sync = false;

        for (const index in item) {
            if (item[index] != undefined) {
                template[index] = item[index];
            }
        }

        if (template.created_at) {
            template.created_at_formatted = relationship.dateFormat(template.created_at, 'yyyy-LL-dd hh:mm:ss a');
        }

        if (item.birthdate) {
            template.birthdate_formatted = PatientOfflineForm.renderComputedAge(item);
        }
        template.patient_name = `${item?.first_name} ${item?.last_name}`;

        if (template.is_sync_failed == false || template.is_sync_failed == undefined) {
            template.status = template.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC;
        } else {
            template.status = SyncStatus.RECORD_CONFLICT;
        }   

        return template;
    }

    async defaultQuery(table: string) {
        return await sqlite.db.select(`SELECT * FROM ${table} WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC`)
    }

    setForm(form: any, template: any, item: any = null) {
        return {
            ...template,
            ...this.renderDefaultForm(template, form, item),
        }
    }

    setOfflineIdentifier(item: any, form: any) {
        form.is_created_offline = item.is_created_offline;
        form.is_updated_offline = item.is_updated_offline;
        form.is_deleted_offline = item.is_deleted_offline;
        return form;
    }

    async resetItemData(table: string, id: number) {
        await sqlite.db.update(table, {
            id: id,
            synching_remarks: null,
            is_sync_failed: false,
            is_sync: true,
            is_created_offline: false,
            is_updated_offline: false,
            is_deleted_offline: false,
        })
    }

    async resetItemDataWithFilter(table: string, id: number, filter: object) {
        await sqlite.db.delete(table, { id: id });
        await sqlite.db.updateWithFilters(table, {
            id: id,
            synching_remarks: null,
            is_sync_failed: false,
            is_sync: true,
            is_created_offline: false,
            is_updated_offline: false,
            is_deleted_offline: false,
        }, filter)
    }

    async updateNewItemId(table: string, oldId: number, newId: number) {
        if (oldId == newId) {
            return;
        }
        
        await sqlite.db.delete(table,{ id: newId });
        await sqlite.db.updateWithFilters(table, { id: newId }, { id: oldId});
    }

    async removeRecord(table: string, id: number) {
        await sqlite.db.delete(table, { id: id });
    }

    async rollback(table: string, id: number, response: any) {
        const error = response?.response?.data;
        await sqlite.db.update(table, {
            id: id,
            synching_remarks: error.message || 'System Error',
            is_sync_failed: true,
        })
        return true;
    }

    async updateOthersDropdown(table: string, id: number, value: string) {
        syncable.table = table;
        await syncable.sync(sqlite.db, [{ name: value, id: id }]);
    }

    async renderPatients(isRenderData = false): Promise<any> {
        return await syncPatient.renderPatients(isRenderData);
    }

    async renderCases(isRenderData = false): Promise<any> {
        return await syncCases.renderCases(isRenderData);
    }

    async renderPerpetrators(isRenderData = false): Promise<any> {
        return await syncPerpetrators.renderPerpetrators(isRenderData);
    }

    async renderCasePerpetrator(isRenderData = false): Promise<any> {
        return await syncCasePerpetrators.renderCasePerpetrator(isRenderData);
    }

    async renderCaseGuardians(isRenderData = false): Promise<any> {
        return await syncGuardians.renderCaseGuardians(isRenderData);
    }

    async renderCaseInformant(isRenderData = false): Promise<any> {
        return await syncInformants.renderCaseInformant(isRenderData);
    }

    async renderCasePatientAddress(isRenderData = false): Promise<any> {
        return await syncPatientAddress.renderCasePatientAddress(isRenderData);
    }

    async processSynching(selectedForm: any): Promise<any> {
        return await syncPatient.processSynching(selectedForm);
    }

    async processCreateSynching(selectedForm: any): Promise<any> {
        return await syncPatient.processCreateSynching(selectedForm);
    }

    async renderInterventions(selectedForm: any): Promise<any> {
        return await syncInterventions.renderInterventions(selectedForm);
    }

    async renderIntakePersonnalInformation(isRenderData = false): Promise<any> {
        return await syncIntake.renderIntakePersonnalInformation(isRenderData);
    }

    async renderIntakePersonRelatedToCase(isRenderData = false): Promise<any> {
        return await syncIntake.renderIntakePersonRelatedToCase(isRenderData);
    }

    async renderIntakeContactNumber(isRenderData = false): Promise<any> {
        return await syncIntake.renderIntakeContactNumber(isRenderData);
    }

    async renderIntakeEmail(isRenderData = false): Promise<any> {
        return await syncIntake.renderIntakeEmail(isRenderData);
    }

    async renderIntakeIncestCase(isRenderData = false): Promise<any> {
        return await syncIntake.renderIntakeIncestCase(isRenderData);
    }

    async renderCaseSafeAssessment(isRenderData = false): Promise<any> {
        return await syncIntake.renderCaseSafeAssessment(isRenderData);
    }

    async renderCaseConferenceInformation(isRenderData = false): Promise<any> {
        return await syncCaseConference.render(isRenderData)
    }

    async renderCaseConferenceFactor(isRenderData = false): Promise<any> {
        return await syncCaseConferenceFactors.render(isRenderData)
    }

    async renderCaseConferenceRecommendation(isRenderData = false): Promise<any> {
        return await syncCaseConferenceRecommendations.render(isRenderData);
    }

    async deleteDraft(item: any) {
        await sqlite.connect();
        switch(item.table) {
            case "patients":
                await sqlite.db.delete(item.table, { id: item.id });
                await this.deleteCases(item.id);                
                break;
            case "cases":
                await sqlite.db.delete(item.table, { id: item.id });
                await this.deleteGuardian(item.id);
                await this.deleteInformant(item.id);
                await this.deletePatientAddress(item.id);
                await this.deleteCasePerpetrator(item.id);
                await this.deleteIntervention(item.id);
                await this.deleteCaseConferences(item.id);
                await this.deleteOtherCaseRelationships(item.id);
                break;
            case "case_guardians":
                await sqlite.db.delete(item.table, { id: item.id });
                break;
            case "case_companions":
                await sqlite.db.delete(item.table, { id: item.id });
                break;
            case "case_patient_addresses":
                await sqlite.db.delete(item.table, { id: item.id });
                break;
            case "case_perpetrators":
                await sqlite.db.delete(item.table, { id: item.id });
                break;
            case "perpetrators":
                await sqlite.db.delete(item.table, { id: item.id });
                await sqlite.db.delete('case_perpetrators', { perpetrator_id: item.id})
                break;
            case "intervention_data":
                await sqlite.db.delete(item.table, { id: item.id });
                break;
            case "case_conferences":
                await sqlite.db.delete(item.table, { id: item.id });
                await sqlite.db.delete('case_conference_recommendations', { case_conference_id: item.id });
                await sqlite.db.delete('case_conference_aggravating_factors', { case_conference_id: item.id });
                break;
            case "intake_personal_information":
                await sqlite.db.delete(item.table, { cases_id: item.cases_id });
                await sqlite.db.delete('intake_person_related_to_cases', { cases_id: item.cases_id });
                await sqlite.db.delete('intake_contact_numbers', { cases_id: item.cases_id });
                await sqlite.db.delete('intake_email_addresses', { cases_id: item.cases_id });
                await sqlite.db.delete('intake_incest_cases', { cases_id: item.cases_id });
                break;
            case "intake_person_related_to_cases":
                await sqlite.db.delete(item.table, { cases_id: item.cases_id });
                break;
            case "intake_contact_numbers":
                await sqlite.db.delete(item.table, { cases_id: item.cases_id });
                break;
            case "intake_email_addresses":
                await sqlite.db.delete(item.table, { cases_id: item.cases_id });
                break;
            case "intake_incest_cases":
                await sqlite.db.delete(item.table, { cases_id: item.cases_id });
                break;
            case "case_safety_assessments":
                await sqlite.db.delete(item.table, { cases_id: item.cases_id });
                await sqlite.db.delete('case_safety_assessment_questions', { id: item.id });
                break;
        }

        await this.checkDrafts();
    }

    async deleteCases(patient_id: number) {
        if (patient_id) {
            const response = await sqlite.db.select(`SELECT * FROM cases WHERE patient_id=${patient_id}`);
            if (response.length > 0) {
                for (const index in response) {
                    const item = response[index];
                    await sqlite.db.delete('cases', { id: item.id });
                    await this.deleteGuardian(item.id);
                    await this.deleteInformant(item.id);
                    await this.deletePatientAddress(item.id);
                    await this.deleteCasePerpetrator(item.id);
                    await this.deleteIntervention(item.id);
                    await this.deleteCaseConferences(item.id);
                }                
            }
        }
    }

    async deleteGuardian(case_id: number) {
        await sqlite.db.delete('case_guardians', { cases_id: case_id }); 
    }

    async deleteInformant(case_id: number) {
        await sqlite.db.delete('case_companions', { cases_id: case_id }); 
    }

    async deletePatientAddress(case_id: number) {
        await sqlite.db.delete('case_patient_addresses', { cases_id: case_id }); 
    }

    async deleteCasePerpetrator(case_id: number) {
        await sqlite.db.delete('case_perpetrators', { cases_id: case_id }); 
    }

    async deleteIntervention(case_id: number) {
        await sqlite.db.delete('intervention_data', { cases_id: case_id }); 
    }

    async deleteCaseConferences(case_id: number) {
        if (case_id) {
            const response = await sqlite.db.select(`SELECT * FROM case_conferences WHERE cases_id=${case_id}`);
            if (response.length > 0) {
                for (const index in response) {
                    const item = response[index];
                    await sqlite.db.delete('case_conferences', { id: item.id });
                    await sqlite.db.delete('case_conference_recommendations', { case_conference_id: item.id });
                    await sqlite.db.delete('case_conference_aggravating_factors', { case_conference_id: item.id });
                }                
            }
        }
    }

    async deleteOtherCaseRelationships(case_id: number) {
        const tables = [
            'case_diagnoses',
            'case_mental_health_diagnoses',
            'case_referral_services',
            'case_remarks',
            'case_safety_assessments',
            'cases_comorbidity',
            'cases_endorsements',
            'minutes',
            'intake_personal_information',
            'intake_incest_cases',
            'intake_email_addresses',
            'intake_person_related_to_cases',
            'intake_contact_numbers',        
        ];

        for (const index in tables) {
            await sqlite.db.delete(tables[index], { cases_id: case_id});
        }

    }

    async checkDrafts() {
        await sqlite.connect();
        await syncable.hasDraft(sqlite.db);
    }
}