import http from "@/axios";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";
import PatientOfflineForm from "../Patients/Offline/Formatters/PatientOfflineForm";

const sqlite = new SqliteProcessor;
const table = 'patients';
const syncable = new Syncable;
export default class SyncPatients {
    async renderPatients(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'patients';
        await sqlite.connect();
        const patients = await onlineSync.defaultQuery(table);
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const setForm = PatientOfflineForm.createPatientCaseForm();
                const form = onlineSync.setForm(PatientOfflineForm.fillSyncPatientForm(item), setForm, item);
                // unique object value per table
                form.table = table;
                form.type = "Patients"
                form.url_view = `/patient/${item.id}/view`;
                form.can_view = item.id ? true : false;
                form.patient_id = null;

                if (isRenderData) {
                    items.push(form);
                }

                // process online synching
                if (!isRenderData) {
                    hasError = await this.processForm(form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }

    }

    async processForm(item: any) {
        const onlineSync = new OnlineSynching;
        let hasError: any = false;
        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-patient-delete', item).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-patient-update', item).catch(error => error);
            if (response?.status === 200) {
                const data = response.data.data;
                await onlineSync.resetItemData(table, item.id);
                // if (item.indigeneous_people_id === 'others') {
                //     await onlineSync.updateOthersDropdown('indigeneous_peoples', data.indigenous_people.id, item.other_indigeneous_people);
                // }
                // if (item.nationality_id === 'others') {
                //     await onlineSync.updateOthersDropdown('nationalities', data.nationality.id, item.other_nationality);
                // }
                // if (item.religion_id === 'others') {
                //     await onlineSync.updateOthersDropdown('religions', data.religion.id, item.other_religion);
                // }
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline) {
            const onlineSync = new OnlineSynching;
            const response = await http.post('/attemp-synching-patient-create', item).catch(error => error);
            if (response?.status === 200) {
                const data = response.data.data;
                const newPatientId = response.data.data.id;
                const oldPatientId = item.id;
                
                await onlineSync.resetItemData(table, item.id);
                await onlineSync.updateNewItemId(table, oldPatientId, newPatientId);
                await this.updateRecordsConnectedToPatient(oldPatientId, newPatientId);
                // if (item.indigeneous_people_id === 'others') {
                //     await onlineSync.updateOthersDropdown('indigeneous_peoples', data.indigenous_people.id, item.other_indigeneous_people);
                // }
                // if (item.nationality_id === 'others') {
                //     await onlineSync.updateOthersDropdown('nationalities', data.nationality.id, item.other_nationality);
                // }
                // if (item.religion_id === 'others') {
                //     await onlineSync.updateOthersDropdown('religions', data.religion.id, item.other_religion);
                // }
                hasError = false;
            } else {                
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }
    }


    async processSynching(selectedForm: any): Promise<any> {
        const onlineSync = new OnlineSynching;
        let hasError = false;
        const patient = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${selectedForm.id} AND is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        if (patient.length > 0) {
            const item = patient[0];
            selectedForm.id = selectedForm.newId;
            const response = await http.post('/attemp-synching-patient-update', selectedForm).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemDataWithFilter(table, selectedForm.id, { id: item.id });
                await this.updateRecordsConnectedToPatient(item.id, selectedForm.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }
        }
        hasError = false;
        return hasError;
    }

    async processCreateSynching(selectedForm: any): Promise<any> {
        const onlineSync = new OnlineSynching;
        let hasError = false;
        const patient = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${selectedForm.id} AND is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        if (patient.length > 0) {
            const item = patient[0];
            selectedForm.id = selectedForm.newId;
            selectedForm.force_create_new_record = true;
            const response = await http.post('/attemp-synching-patient-create', selectedForm).catch(error => error);
            if (response?.status === 200) {
                const data = response.data.data;
                await onlineSync.resetItemData(table, item.id);
                await onlineSync.updateNewItemId(table, item.id, data.id);
                await this.updateRecordsConnectedToPatient(item.id, data.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }
        }
        hasError = false;
        return hasError;
    }

    async updateRecordsConnectedToPatient(oldPatientId: number, newPatientId: number) {
        if (!oldPatientId || !newPatientId) {
            return false;
        }
        
        syncable.table = 'cases';
        await syncable.updateSynchingPatientId(sqlite.db, oldPatientId, newPatientId);

        syncable.table = 'case_conferences';
        await syncable.updateSynchingPatientId(sqlite.db, oldPatientId, newPatientId);

        syncable.table = 'intervention_data';
        await syncable.updateSynchingPatientId(sqlite.db, oldPatientId, newPatientId);
    }
}