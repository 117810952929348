import { DefaultParameterSetting } from "@/enums/DefaultParameterSetting";
import SqliteProcessor from "@/processors/SqliteProcessor";

const sqlite = new SqliteProcessor;
const table = 'personnels';
class PersonnelProcessor {

    async getPersonnels(findIn: any = []) {
        if (findIn.length === 0) {
            return [];
        }

        await sqlite.connect();
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE position_id IN (${findIn.join(',')})`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
    }

    async mentalHealthStaticRadioButtonId() {
        await sqlite.connect();
        const response = await sqlite.db.select(`SELECT * FROM mental_health_diagnoses WHERE id=${DefaultParameterSetting.MENTAL_HEALTH_DIAGNOSIS}`);
        if (response.length > 0) {
            return response[0].id;
        } else {
            return null;
        }
	}

    async wcpuPersonnels() {
        await sqlite.connect();
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE is_wpcu=true`);
        if (response.length > 0) {
            return response?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}
    
    async personnels() {
        await sqlite.connect();
        const response = await sqlite.db.select(`SELECT * FROM ${table}`);

        if (response.length > 0) {
            return response?.map((item: any) => {
				return {
					id: item.id,
					value: item.id,
					label: item.name,
					name: item.name,
					office_id: item.office_id,
					position_id: item.position_id,
					department_id: item.department_id,
				};
			}) || [];
        } else {
            return [];
        }
    }
}

export default new PersonnelProcessor();