import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";
import { linkPerpetrator } from "../Interface/PerpetratorInterface";
import Relationships from "@/classes/Forms/Relationships";

const relationship = new Relationships();

class LinkPerpetratorOfflineForm {
    /**
     * Template form structure of case table
     */
    formStructure () {
        return {
            id: null,
            cases_id: null,
            perpetrator_id: null,
            first_name: null,
            last_name: null,
            middle_name: null,
            gender: null,
            birthday_classification: null,
            birthdate: null,
            approximate_age_year: null,
            approximate_age_month: null,
            contacts: null,
            nickname: null,
            perpetrator_relationship_id: null,
            other_relationship: null,
            occupation: null,
            nationality_id: null,
            religion_id: null,
            current_location: null,
            perpetrator_location: null,
            is_minor: false,
            parent_name: null,
            parent_contact_number: null,
            parent_relationship_id: null,
            perpetrator_information: null,
            encoded_by: null,
            identity: null,
            about_perpetrator: null,
            tag: null,
            details: null,
            other_nationality: null,
            other_religion: null,
        }
    }

    renderSyncToOffline (item: Array<linkPerpetrator>, isUpdate = false) {
        const builder: any = {};
        const items = this.formStructure()
        // get default form structure template
        for (const index in items) {
            // match all existing indexes based on default form structure
            builder[index] = DefaultFormStructureBuild.fill(index, item);

            // filter all dates fields and convert value into ISO
            const check = this.checkFormStrucuteDates().find((item) => item === index);
            if (check) {
                builder[index] = relationship.dateFormatFromIso(builder[index]);
            }
        }
        
        // usable only when updating record
        if (isUpdate) {
            return this.updatePatientForm(builder, item);
        }
        return builder;
    }

    updatePatientForm(builder: any, item: any) {
        builder.id = item.id;
        builder.is_sync = false;
        builder.synching_remarks = null;
        builder.is_updated_offline = true;
        builder.updated_at = relationship.dateFormatFromIso(new Date);
        return builder;
    }

    private checkFormStrucuteDates () {
        return [
            'birthdate',
        ]
    }
}

export default new LinkPerpetratorOfflineForm();

