<template>
    <div class="w-full">

        <!-- Create and Edit Mode -->
        <div class="grid grid-cols-12 gap-6">
            <div class="relative justify-center col-span-12"
            :class="isView ? 'cursor-not-allowed' : ''">
                <!-- <div v-if="isView" class="absolute top-0 left-0 z-10 w-full h-full bg-white bg-opacity-75"></div> -->
                <div class="grid grid-cols-2 gap-6"
                :class="isView ? 'pointer-events-none' : ''">
                    <template v-for="(item, index) in recommendationOptions" :key="index">
                        <div class="flex flex-col md:col-span-1 col-span-2 space-y-3">
                            <checkbox-input
                                :checked="item.checked"
                                :id="item.id"
                                :name="item.id"
                                :label="item.label"
                                class="peer"
                                @update:modelValue="(value) => resetForm(item, value)"
                                :readonly="isView"
                            />
                            <text-input
                                label="Remarks"
                                placeholder="Input remarks"
                                :name="'remarks_' + item.id"
                                :id="'remarks_' + item.id"
                                :disabled="!item.checked || isView"
                                textarea 
                                v-model="item.remarks"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";

const props = defineProps({
    recommendationOptions: {
        type: Object,
        required: true
    },
    form: {
        type: Object,
        required: true
    },
    isView: {
        type: Boolean,
        default: false
    }
});

const resetForm = (item: any, value: boolean) => {
    item.checked = value;
    item.remarks = '';
}
</script>