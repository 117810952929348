<template>
	<user-layout hasBack backLink="/perpetrator/index" mainCustomClass="resetView">
		<div class="grid lg:grid-cols-[384px_auto] grid-cols-1 items-start lg:h-[calc(100vh-154px)] overflow-hidden">
			<div class="sticky my-6 lg:pl-6 top-6">
				<div class="relative grid grid-cols-2 gap-3 p-6 border border-gray-200 rounded-2xl">
					<div class="col-span-2">
						<p class="text-xl font-semibold">{{ item?.name }}</p>
						<p class="text-xs font-medium text-neutral-500" v-if="item?.nickname">“{{ item?.nickname }}”</p>
					</div>
					<div class="col-span-2 border-t-[3px] border-gray-200"></div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ item?.gender_meta?.description }}</p>
						<p class="text-xs font-medium text-neutral-500">Gender</p>
					</div>
					<!-- <div class="col-span-1">
						<p class="mb-1 text-sm font-bold">Single</p>
						<p class="text-xs font-medium text-neutral-500">Civil Status</p>
					</div> -->
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ item?.identity_meta?.description }}</p>
						<p class="text-xs font-medium text-neutral-500">Type</p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ item?.is_mino ? "Yes" : "No" }}</p>
						<p class="text-xs font-medium text-neutral-500">Minor</p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ item?.birthdate ? DateFormatter.customDateFormat(item?.birthdate, 'MM/dd/yyy') : 'Unknown'}} &nbsp;</p>
						<p class="text-xs font-medium text-neutral-500">
							Birthdate
						</p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ item?.computed_age }} &nbsp;</p>
						<p v-if="BirthdayClassification.APPROXIMATE_AGE == item.birthday_classification"  class="text-xs font-medium text-neutral-500"> Approximate Age </p>
						<p v-else class="text-xs font-medium text-neutral-500"> Age </p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ item?.nationality?.name }}</p>
						<p class="text-xs font-medium text-neutral-500">Nationality</p>
					</div>
					<div class="col-span-1">
						<p class="mb-1 text-sm font-bold">{{ item?.religion?.name }}</p>
						<p class="text-xs font-medium text-neutral-500">Religion</p>
					</div>
					<div class="col-span-2">
						<a
							@click="
								router.push(
									`/perpetrator/${perpetratorId}/edit`
								)
							">
							<button-component customClass="quaternary w-full" size="large">
								<span>Show Profile of the Perpetrator</span>
							</button-component>
						</a>
					</div>
				</div>
			</div>

			<div class="flex flex-col h-full pb-20 space-y-4 lg:p-6 lg:overflow-y-auto lg:pb-0">
				<div class="flex items-center justify-between">
					<div class="relative min-w-[265px] md:w-auto w-full">
						<button
							type="submit"
							class="border-0 absolute top-0 left-0 z-[1] px-3 py-[14px] hover:bg-primary-600 rounded-l group transition"
							title="Search cases">
							<!-- <img
								src="/assets/icon/ic-search-2.svg"
								alt="search_icon"
								class="w-5 h-5 object-contain grayscale brightness-[0.3] group-hover:invert group-hover:brightness-0 transition" /> -->
							<search-two-icon class="w-5 h-5 object-contain grayscale brightness-[0.3] group-hover:invert group-hover:brightness-0 transition" />
						</button>
						<text-input
							type="text"
							placeholder="Search Cases"
							name="search_cases"
							id="search_cases"
							customClass="!pl-12 frm-input search"
							v-model="filterSearch" />
					</div>
				</div>
				<div class="flex flex-col space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0">
					<div class="w-full md:w-auto">
						<p class="block font-semibold">Case History</p>
						<p class="text-xs font-normal text-gray-700">Please select the Case Number to access the patient’s details linked to this perpetrator.</p>
					</div>
					<div class="self-end">
						<button
							type="button"
							class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 hover:bg-primary-100 w-max"
							@click="showFilter = true">
							<span class="text-xs font-medium text-neutral-600">Showing:</span>
							<span class="text-xs font-semibold">All Records ({{ cases?.total }})</span>
						</button>
						<FilterPanel
							:show="showFilter"
							@close="showFilter = false"
							@apply:reset="applyReset()"
							@apply:filters="applyFilters()">
							<template #fields>
								<div class="space-y-5">
									<date-picker
										id="date_initiated"
										name="date_initiated"
										label="Date Initiated"
										:range="true"
										v-model="filterInitiatedAt" />
									<date-picker
										id="date_created"
										name="date_created"
										label="Date Created"
										v-model="filterDate" />
								</div>
							</template>
						</FilterPanel>
					</div>
				</div>

				<template v-if="cases">
					<template v-for="item in cases.data" :key="item">
						<case-card
							:routeLink="`/patient/${
								item?.patient_id
							}/cases/${
								item.id || item.bid
							}/edit`"
							:date="item?.initiated_at_formatted"
							:caseNumber="item?.case_number"
							:primaryDiagnosis="item?.diagnoses?.primary_diagnoses?.name"
							:comorbidList="item?.comorbidities?.map((item) => item.name)"
							:perpetratorList="item?.perpetrators?.map((item) => item.name)" 
						/>
					</template>
					<div class="w-full px-6 border-t py-7" v-if="cases.data?.length > 0">
						<minify-pagination
							:numRows="numRows"
							@update:rows="(value: any) => changeRows(value)"
							@update:page="(value: number) => paginate(value)"
							:current-page="page"
							:last-page="lastPage"></minify-pagination>
					</div>
				</template>
			</div>
		</div>
		<page-loader :show="loading"/>
		
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed, watch 	} from "vue";
import PageLoader from "@/components/loaders/PageLoader.vue"
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import CaseCard from "@/components/cards/CaseCard.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { useRouter, useRoute } from "vue-router";
import PerpetratorService from "@/classes/Perpetrators/PerpetratorService";
import { PerpetratorShow } from "@/interfaces/PerpetratorInterface";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import { CardCaseIntervetionInterface, CardCaseFilterInterface } from "@/interfaces/Cases/Case"; 
import { BirthdayClassification } from "@/views/enums/BirthdayClassification";
import DateFormatter from "@/helpers/DateFormatter"; 
import { onIonViewWillEnter } from "@ionic/vue";
import SearchTwoIcon from "@/components/icons/searchTwoIcon.vue";
import { useNetworkStore } from "@/store/network"; 
import OnlineSynching from "@/classes/Synching/OnlineSynching";
import PerpetratorProcessor from "@/classes/Perpetrators/Offline/Processor/PerpetratorProcessor";
 
const router = useRouter();
const route = useRoute();
const showFilter = ref<boolean>(false);
const loading = ref<boolean>(false);
const perpetratorId = ref<number>(route.params.perpetrator);
const item = ref<PerpetratorShow>({});
const cases = ref<CardCaseIntervetionInterface[]>([]); 
const filterSearch = ref<string | null>(route.query.case_number);
const filterDate = ref<string | null>(route.query.date);
const filterInitiatedAt = ref<string | null>(route.query.date_initiated);
const page = ref<string | null>(route.query.page);
const lastPage = ref<string | null>(null);
const rows = ref<number | null>(route.query.rows || 5);
const numRows = computed<string | null>(() => (rows.value ? rows.value + " Rows" : "All")); 
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const onlineSync = new OnlineSynching();

const filters = computed<CardCaseFilterInterface>(() => {
	return {
		case_number: filterSearch.value,
		page: page.value,
		rows: rows.value,
		date_initiated: filterInitiatedAt.value,
		date: filterDate.value,
	};
});

const getData = async (search: any = null): Promise<any> => {
	loading.value = true;
	
	if (search) {
		filterSearch.value = search;
	}

    const response = await PerpetratorService.view(
		perpetratorId.value,
		pickBy({ ...filters.value }),
        isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
		item.value = response.data.item;
        if (isNetworkAvailable.value) {
            await PerpetratorProcessor.syncToOfflinePerpetrator(perpetratorId.value, item.value);
        }
        loading.value = false;
    } else {
        loading.value = false;
        console.log(response)
    }
};

const getCases = async (search: any = null): Promise<any> => {

    const response = await PerpetratorService.cases(
		perpetratorId.value,
		pickBy({ 
			...filters.value, 
			query: search
		}),
        isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        cases.value = response.data.items
        if (cases.value) {
			page.value = response.data.items.current_page;
			lastPage.value = response.data.items.last_page;
            console.log(page.value, lastPage.value);
		}
        loading.value = false;
    } else {
        loading.value = false;
        console.log(response)
    }
};

const reloadPage = async () => {
	return await router.push({
		path: `/perpetrator/${perpetratorId.value}/view`,
		query: pickBy(filters.value),
	});
};

const applyFilters = (search: any = null) => {
	reloadPage().then(() => {
		getCases(search);
		showFilter.value = false;
	});
};

const applyReset = () => {
	filterDate.value = "";
	filterInitiatedAt.value = "";
	reloadPage().then(() => {
		getData();
		showFilter.value = false;
	});
};

const changeRows = (row: any): void => {
	rows.value = row;
	applyFilters();
};

const paginate = (data: number): void => {
	page.value = data;
	applyFilters();
};

/**
 * WATCHERS
 */
watch(
	() => filterSearch.value,
	throttle((val: string) => {
		applyFilters(val);
	}, 1000)
);

watch(
    () => isNetworkAvailable.value,
    async () => {
        loading.value = false;
        await getData();
        await getCases();
        await onlineSync.checkDrafts();
    }
);
 
onIonViewWillEnter(async () => {
    await getData();
    await getCases();
    await onlineSync.checkDrafts()
});
 
</script>
