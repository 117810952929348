import FormOptions from "@/classes/Forms/FormOptions";
import PersonnelProcessor from "@/classes/Personnels/PersonnelProcessor";
import SqliteProcessor from "@/processors/SqliteProcessor";

const options  = new FormOptions();
const sqlite = new SqliteProcessor;
class OfflineService {

    public async interventionFieldOptions(option_class: string, option_column?: string, parent_value?: number): Promise<any>
    {
        await sqlite.connect();
        let option = []
        let personnels = [];
        let offices = [];
        switch (option_class) {
            case "App\\Models\\FormOptions\\Religion":
                option = await options.regions();
                break;
            case "App\\Models\\FormOptions\\CivilStatus":
                option = await options.civilStatusOptions();
                break;
            case "App\\Models\\FormOptions\\CompanionRelationship":
                option = await options.companionRelationships();
                break;
            case "App\\Models\\FormOptions\\Disability":
                option = await options.disabilities();
                break;
            case "App\\Models\\FormOptions\\EducationalAttainment":
                option = await options.educationalAttainments();
                break;
            case "App\\Models\\FormOptions\\EmploymentStatus":
                option = await options.employmentStatus();
                break;
            case "App\\Models\\FormOptions\\GuardianRelationship":
                option = await options.guardianRelationships();
                break;
            case "App\\Models\\FormOptions\\HospitalArea":
                option = await options.hospitalAreas();
                break;
            case "App\\Models\\FormOptions\\IndigeneousPeople":
                option = await options.indigeneous();
                break;
            case "App\\Models\\FormOptions\\PerpetratorRelationship":
                option = await options.perpetratorRelationships();
                break;
            case "App\\Models\\FormOptions\\ReferralSource":
                option = await options.referralSources();
                break;
            case "App\\Models\\FormOptions\\SocioEconomicStatus":
                option = await options.socioEconomicStatuses();
                break;
            case "App\\Models\\FormOptions\\SoughtService":
                option = await options.soughtServices();
                break;
            case "App\\Models\\StaticTables\\Nationality":
                option = await options.nationalities();
                break;
            case "App\\Models\\Accounts\\Department":
                option = await options.departments();
                break;
            case "App\\Models\\Settings\\TelcpuLocation":
                option = await options.telCpuLocations();
                break;
            case "App\\Models\\Settings\\Telcpu":
                offices = await options.telCpuOffices();
                option = await this.getChildOptions(offices, option_column ?? '', parent_value ?? 0)
                break;
            case "App\\Models\\Accounts\\Position":
                option = await options.assignedGroups();
                break;
            case "App\\Models\\Accounts\\Office":
                option = await options.offices();
                break;
            case "App\\Models\\Accounts\\Personnel":
                personnels = await PersonnelProcessor.personnels();
                option = await this.getChildOptions(personnels, option_column ?? '', parent_value ?? 0)
                break;
            default:
                option = [];
                break;
        }

        return {
            data: option
        }
    }

    private async getChildOptions(table: any, option_column: string, parent_value: number): Promise<any>
    {
        const dropdownOptions:any = [];
    
        table.forEach((item:any) => {
            if(item[option_column] == parent_value){
                dropdownOptions.push(item)
            }
        });
    
        return dropdownOptions;
    }
}

export default new OfflineService();