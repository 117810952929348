import { defaultCreateForm, defaultDeleteForm, defaultUpdateForm } from "@/classes/General/GeneralOfflineForms" 
import Relationships from "@/classes/Forms/Relationships";

const relationship = new Relationships();

class EmailForm{
    viewForm(item:any){   
        const data = item?.emailAddress?.map((item:any) => {  
            return {    
                id: item?.id,
                label: item?.label,
                email: item?.email,
                updated_at_formatted: relationship.dateFormat(item.updated_at || item.created_at, "LLLL dd, yyyy HH:mm")
            };
        }); 
        return data || [];
    }
    createForm = () => {
        return {
            label: null,
            email: null,
            cases_id: null,
            encoded_by: null,
            ...defaultCreateForm()
        }
    }
    updateForm = (item: any) => {
        return {
            id: item.id,
            label: item.label,
            email: item.email,
            cases_id: item.cases_id,
            encoded_by: item.encoded_by,
            ...defaultUpdateForm()
        }
    }
    deleteForm = (item: any) => { 
        return {
            id: item.id,
            label: item.label,
            email: item.email,
            ...defaultDeleteForm()
        }
    }
    
}

export default new EmailForm(); 