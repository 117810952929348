import CurrentLocation from "@/enums/CurrentLocation";
import Gender from "@/enums/Gender";
import FormOptions from "@/classes/Forms/FormOptions";
import PerpetratorIdentity from "@/enums/PerpetratorIdentity";
import PerpetratorProcessor from "../Processor/PerpetratorProcessor";

const options = new FormOptions();

class PerpetratorTemplateForm {
    async createTemplate() {
        return {
            currentLocations: CurrentLocation,
            genders: Gender,
            identities: PerpetratorIdentity,
            nationalities: await options.nationalities(),
            perpetratorRelationships: await options.perpetratorRelationships(),
            religions: await options.religions(),
        };
    }

    async createPerpetratorTemplate(patient: any) {
        return {
            ...await this.createTemplate(),
            patient: `${patient?.last_name}, ${patient?.first_name} ${patient?.middle_name || ''}`,
            unknownTag: `000(Offline) - Unknown Perpetrator of ${patient?.first_name} ${patient?.last_name}`,
        }
    }

    async editPerpetratorTemplate(patient: any, perpertrator_id: number, table: any = 'case_perpetrators') {
        return {
            ...await this.createTemplate(),
            patient: `${patient?.last_name}, ${patient?.first_name} ${patient?.middle_name || ''}`,
            unknownTag: `000(Offline) - Unknown Perpetrator of ${patient?.first_name} ${patient?.last_name}`,
            item: await PerpetratorProcessor.getLatestPerpetrator(perpertrator_id, table),
        }
    }
}

export default new PerpetratorTemplateForm();