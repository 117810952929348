import http from "@/axios";
import { PatientAddress } from "../CasePatientAddress/Offline/Interface/CasePatientInterface"; 
import OfflineProcessor from "./Offline/Processor/OfflineProcessor";
class PatientAddressService implements PatientAddress {

    /**
     * Render index page
     * @param patient 
     * @param patientCase 
     * @param payload 
     * @param networkAvailable 
     * @returns 
     */
   async index(patient: number, patientCase: number, payload: any, networkAvailable: boolean): Promise<any> {
       if(networkAvailable){
       return await http.get(`patients/${patient}/cases/${patientCase}/patient-addresses`, { params: payload }).catch(error => error);
       }else{
           const data = await OfflineProcessor.index(patientCase, patient);
           return {
               data: {
                   ...data.data,
                   patient: data.data.items.patient,
               }
           };
       }
   }

   /**
    * Render additional form data
    * @param patient 
    * @param patientCase 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async create(patient: number, patientCase: number, payload: object, networkAvailable: boolean): Promise<any> {
       if(networkAvailable){
           return await http.get(`patients/${patient}/cases/${patientCase}/patient-addresses/create`, {
               params: payload,
           }).catch(error => error);
       }else{ 
            const data = await OfflineProcessor.create(patient);
            return {
                data: {
                    ...data.data,
                }
            };
       }
   }

   /**
    * Store data from storage
    * @param patient 
    * @param patientCase 
    * @param patientAddress 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async store(patient: number, patientCase: number, patientAddress: number, payload: any, networkAvailable: boolean): Promise<any> {
       if(networkAvailable){
       return await http.post(`patients/${patient}/cases/${patientCase}/patient-addresses`, payload).catch(error => error);
       }else{
           return await OfflineProcessor.updateCreate(patient, patientCase, patientAddress, payload);
       }
   }

   /**
    * Edit data from storage
    * @param patient 
    * @param patientCase 
    * @param patientAddress 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async edit(
       patient: number,
       patientCase: number,
       patientAddress: number,
       payload: any,
       networkAvailable: boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.get(`patients/${patient}/cases/${patientCase}/patient-addresses/${patientAddress}/edit`, {
               params: payload,
           }).catch(error => error);
       }else{
           return await OfflineProcessor.edit(patient, patientCase, patientAddress);
       }
      
   }

   /**
    * Update data from storage
    * @param patient 
    * @param patientCase 
    * @param patientAddress 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async update(
       patient: number,
       patientCase: number,
       patientAddress: number,
       payload: any,
       networkAvailable: boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.put(
               `patients/${patient}/cases/${patientCase}/patient-addresses/${patientAddress}/update`,
               payload
           ).catch(error => error);
       }else{
           return await OfflineProcessor.updateCreate(patient, patientCase, patientAddress, payload);
       }
      
   }

   /**
    * Delete data from storage
    * @param patient 
    * @param patientCase 
    * @param patientAddress 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async delete(
       patient: number,
       patientCase: number,
       patientAddress: number,
       payload: any,
       networkAvailable: boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.delete(
               `patients/${patient}/cases/${patientCase}/patient-addresses/${patientAddress}/delete`,
               payload
           ).catch(error => error);
       }else{
           return await OfflineProcessor.delete(patientAddress, patientCase);
       }
      
   }
}


export default new PatientAddressService();