import http from "@/axios";
import { Case } from "@/interfaces/Cases/Case";
class CaseService implements Case {
	async store(patient: number, payload: any): Promise<any> {
		return await http.post(`patients/${patient}/cases/`, payload).catch(error => error);
	}

	async create(patient: number, payload: any): Promise<any> {
		return await http.get(`patients/${patient}/cases/create`, { params: payload }).catch(error => error);
	}

	async edit(patient: number, patientCase: number, payload: any): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/edit`, { params: payload }).catch(error => error);
	}

	async update(patient: any, patientCase: any, payload: any): Promise<any> {
        patient = parseInt(patient);
        patientCase = parseInt(patientCase);
	
		return await http.put(`patients/${patient}/cases/${patientCase}/update`, payload).catch(error => error);
	}

	async deleteDraft(draft: any): Promise<void> {
        console.log('delete draft')
	}

	async generatePDFAccess(patient:number, patientCase:number, payload:object): Promise<void> {
		return await http.post(`patients/${patient}/cases/${patientCase}/generatePDFAccess`, payload);
	}

	async downloadPatientRecord(patient:number, patientCase:number, token:string): Promise<void> {		
		window.open(`${process.env.VUE_APP_URL}pdf/${patientCase}/downloadPatientRecord/${token}`);
	}

	async downloadScar(patient:number, patientCase:number, token:string, payload:any): Promise<void> {		
		window.open(`${process.env.VUE_APP_URL}pdf/${patientCase}/downloadScar/${token}?${new URLSearchParams(payload).toString()}`);
	}
}

export default new CaseService();
