import {required, helpers } from "@vuelidate/validators"
const createRules = (form: any): any => { 
   return {
       residence_type: {
           required:  helpers.withMessage(errorMessage('residence type'), required)
       },
       address: {
           required: helpers.withMessage(errorMessage('address'), required)
       }, 
       region_id: {
          required:  helpers.withMessage(errorMessage('region'), required)
       },
       province_id: { 
           required:  helpers.withMessage(errorMessage('province'), required)
       },
       city_id: { 
           required:  helpers.withMessage(errorMessage('city'), required)
       },
       barangay_id: { 
           required:  helpers.withMessage(errorMessage('barangay'), required)
       },
   }
}


const errorMessage = (field: any) => {
   return `The ${field} field is required`;
}
export default {
   createRules,
}
