<template>
    <user-layout>
        <div class="w-full py-6 mx-auto">
            <div class="flex items-center justify-between">
                <section-title
                    title="Barangay"
                    description="View the relevant in the system."
                />
                <div class="flex items-center justify-end space-x-3">
                    <a 
                        v-if="hasPermission('can-create-department')"
                        href="/settings/barangay/create"
                    >
                        <button-component
                        >
                            <PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon"/>
                            <span>Create</span>
                        </button-component>
                    </a>
                </div>
            </div>
            <div class="mt-6">
				<div class="w-full border rounded-lg border-neutral-100">
					<div class="relative overflow-auto">
						<div class="absolute left-0 top-0 h-full whitespace-nowrap position w-[181px] shadow-[2px_0_16px_0px_rgb(0,0,0,0.08)]">
							<div class="px-6 py-[26px] bg-primary-100 text-sm font-semibold relative">Settings</div>
							<side-tab barangaySelected/>
						</div>

                        <div class="pl-[181px] min-h-[500px]">

                            <div class="tabs flex items-center justify-between pl-6 py-6 pr-3.5 border-b border-neutral-100">
                                <div class="flex items-center space-x-4">
                                    <tab-component :tabs="tabs" @update:tab="(value: string) => updateTab(value)" />
                                </div>
                            </div>
            
                            <div v-if="filters.tab != 'activity_logs'">
                                
                                <data-table 
                                    :headers="headers" 
                                    :no-action="false"
                                    :count="items.total"
                                    :hasSearch="true"
                                    searchPlaceholder="Search region"
                                    tableClass="header-frm-table"
                                    dataTableClass="border-0"
                                    @update:searchText="(value: string) => (filters.query = value)"
                                >
                                    <template #topContent>
                                        <div>
                                            <button type="button" class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 hover:bg-primary-100 w-max"
                                            @click="showFilter = true">
                                                <span class="text-xs font-medium text-neutral-600">Showing:</span>
                                                <span class="text-xs font-semibold">All Records</span>
                                            </button>
                                            <FilterPanel
                                                :show="showFilter"
                                                @close="showFilter = false"
                                                @apply:reset="applyReset()"
                                                @apply:filters="applyFilters()">
                                            >
                                                <template #fields>
                                                    <div class="space-y-5">
                                                        <date-picker 
                                                            id="date_created"
                                                            name="date_created"
                                                            label="Date Created"
                                                            v-model="filters.filterDate"
                                                            :range=true
                                                        />
                                                    </div>
                                                    <div class="col-span-1">
                                                        <multi-select 
                                                            searchable
                                                            :options="regionOptions"
                                                            id="region"
                                                            name="region"
                                                            label="Region"
                                                            placeholder="Select.."
                                                            v-model="filters.region_id"
                                                            @update:modelValue="regionChange()"
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-span-1">
                                                        <multi-select 
                                                            searchable
                                                            :options="provinceOptions"
                                                            id="province"
                                                            name="province"
                                                            label="Province"
                                                            placeholder="Select.."
                                                            v-model="filters.province_id"
                                                            @update:modelValue="provinceChange()"
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-span-1">
                                                        <multi-select 
                                                            searchable
                                                            :options="cityOptions"
                                                            id="city_municipality"
                                                            name="city_municipality"
                                                            label="City/Municipality/District"
                                                            placeholder="Select.."
                                                            v-model="filters.city_id"
                                                            @update:modelValue="cityChange()"
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-span-1">
                                                        <multi-select 
                                                            searchable
                                                            :options="barangayOptions"
                                                            id="barangay"
                                                            name="barangay"
                                                            label="Barangay"
                                                            placeholder="Select.."
                                                            v-model="filters.barangay_id"
                                                            required
                                                        />
                                                    </div>
            
                                                </template>
                                            </FilterPanel>
                                        </div>
                                    </template>
                                    <template v-slot:body>
                                        <template 
                                            v-for="item in items.data" :key="item"
                                        >
                                            <tr>
                                                <td class="px-6 py-[18px] text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item.code }}
                                                </td>
                                                <td class="px-6 py-[18px] text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.city }}
                                                </td>
                                                <td class="px-6 py-[18px] text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.name }}
                                                </td>
                                                <td class="px-6 py-[18px] text-sm text-gray-900 whitespace-nowrap">
                                                    {{ DateFormatter.defaultTimeStamp(item.created_at) }}
                                                </td>
                                                <td 
                                                    class="px-6 py-[18px] text-sm text-gray-900 whitespace-nowrap"
                                                >
                                                    <div 
                                                        class="flex items-center justify-center mx-auto w-max"
                                                    >
                                                        <template v-if="!filters.tab">
                                                            <edit-button 
                                                                v-if="hasPermission('can-update-department')"
                                                                @click="router.replace(`/settings/barangay/edit/${item.id}`)"
                                                            />
                                                            <delete-button 
                                                                v-if="hasPermission('can-archive-department')"
                                                                @click="showDeleteModal(item)" 
                                                            />
                                                        </template>
                        
                                                        <template v-if="filters.tab == 'archived'">
                                                            <restore-button 
                                                                v-if="hasPermission('can-archive-department')"
                                                                @click="showRestoreModal(item)" 
                                                            />
                                                        </template>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
            
                                </data-table>
                            </div>
                            <ActivityLogsTable
                                v-if="filters.tab == 'activity_logs'"
                                :items="items"
                            />
                        </div>
                    </div>

                    <div class="w-full px-6 border-t py-7">
                        <simple-pagination 
                            :numRows="filters.rows" 
                            :currentPage="items.current_page" 
                            :last-page="items.last_page" 
                            @update="(value: any) => paginate(value)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- DELETE MODAL -->
        <confirmation-modal :typeTwo="true" :show="showConfirmation" @cancel="showConfirmation = false"
            @confirm="deleteRecord()" cancel-text="Cancel" action-text="Confirm" title="Delete Record"
            content="Are you sure you wish to delete this Region?" />
            
        <success-modal :show="showSuccess" @confirm="closeDeleteModal()" action-text="Close"
            title="Record Deleted!" content="Selected region has been successfully deleted!" />

        <!-- RESTORE MODAL -->
        <confirmation-modal :typeTwo="true" :show="showRestoreConfirmation"
            @cancel="showRestoreConfirmation = false" @confirm="restoreRecord()" cancel-text="Cancel"
            action-text="Confirm" title="Restore Record"
            content="Are you sure you wish to restore this region?" />

        <success-modal :show="showRestoreSuccess" @confirm="closeRestoreModal()" action-text="Close"
            title="Record Restored!" content="Selected region has been successfully region!" />
        
         <page-loader :show="loading"/>
    </user-layout>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UserLayout from '@/layouts/UserLayout.vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import EditButton from "@/components/buttons/EditButton.vue";
import SideTab from "@/views/settings/Components/SideTab.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import RestoreButton from "@/components/buttons/RestoreButton.vue";
import DeleteButton from '@/components/buttons/DeleteButton.vue';
import DataTable from '@/components/partials/DataTable.vue';
import FilterPanel from '@/components/partials/FilterPanel.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import TabComponent from '@/components/partials/TabComponent.vue';
import DatePicker from "@/components/inputs/DatePicker.vue";
import { PlusIcon } from '@heroicons/vue/24/outline';
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

import { hasPermission } from "@/classes/AuthService";

import BarangayService from "@/classes/Settings/Locations/BarangayService";
import DropdownService from "@/classes/Dropdowns/DropdownService";

import { SelectInterface } from "@/interfaces/pages/offices/OfficeInterface"

import assignValues from "@/helpers/assignValues"
import PageLoader from "@/components/loaders/PageLoader.vue"

import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import { filters, items } from "@/interfaces/pages/FilterPageInterface";

import DateFormatter from "@/helpers/DateFormatter";

import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import debounce from "lodash/debounce";

const router = useRouter();
const route = useRoute();
const showFilter = ref<boolean>(false);
const loading = ref<boolean>(false);

const regionOptions = ref<SelectInterface[]>([]);
const provinceOptions = ref<SelectInterface[]>([]);
const cityOptions = ref<SelectInterface[]>([]);
const barangayOptions = ref<SelectInterface[]>([]);

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);

const selectedId = ref<number|null>(null);
const tabs = ref();

const headers: { text: string, haveSort?: boolean }[] = [
    { text: 'Code' },
    { text: 'City' },
    { text: 'Barangay' },
    { text: 'Date Created' },
];

/**
 * METHODS
 */
/**
 * METHODS
 */
const getData = (): void => {
    loading.value = true
    BarangayService.index(pickBy(filters.value))
        .then(({ data }) => {
            items.value = data.items;
            filters.value = assignValues(filters.value, data);

            loading.value = false;

            tabs.value = [
                {
                    name: "All",
                    count: filters.value.allItemsCount,
                    tab: null,
                    allowed: true,
                },
                {
                    name: "Archived",
                    count: filters.value.archiveItemsCount,
                    tab: "archived",
                    allowed: true,
                },
                {
                    name: "Action Logs",
                    count: filters.value.activityLogsCount,
                    tab: "activity_logs",
                    allowed: true,
                }, 
            ]
        })
        .catch((error: any) => {
            console.log(error);
        });
};

const applyFilters = (search: any = null) => {
    showFilter.value = false;
    filters.value.query = search;
    getData();
};

const applyReset = () => {
    filters.value.filterDate = null;
    filters.value.region_id = null;
    filters.value.province_id = null;
    filters.value.city_id = null;
    filters.value.barangay_id = null;

    showFilter.value = false;
    getData();

};

const paginate = ({ page, rows }): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })
    
    getData();
};

const updateTab = (currentTab: string) => {
    filters.value.tab = currentTab;
    filters.value.page = 1;
    applyFilters();
};

/**
 * MODALS
 */
const showDeleteModal = (item: any): void => {
    showConfirmation.value = true;
    selectedId.value = item.id;
};

const closeDeleteModal = (): void => {
    showSuccess.value = false;
};

const deleteRecord = (): void => {
    BarangayService.delete(selectedId.value)
        .then(() => {
            showSuccess.value = true;
            showConfirmation.value = false;
            applyFilters();
        })
        .catch((error) => console.log(error));
};

const showRestoreModal = (item: any): void => {
    showRestoreConfirmation.value = true;
    selectedId.value = item.id;
};

const closeRestoreModal = (): void => {
    showRestoreSuccess.value = false;
};

const restoreRecord = (): void => {
    BarangayService.restore(selectedId.value)
        .then(() => {
            showRestoreSuccess.value = true;
            showRestoreConfirmation.value = false;
            applyFilters();
        })
        .catch((error) => console.log(error));
};


const getRegions = ():void => {
    DropdownService
        .regions()
        .then(({data}) => {
            regionOptions.value = data;
        })
}

const getProvince = ():void => { 
    if(filters.value.region_id){
        DropdownService
            .provinces(filters.value.region_id)
            .then(({data}) => {
                provinceOptions.value = data;
            })
    }
}

const getCity = ():void => {
    if(filters.value.province_id){
        DropdownService
            .cities(filters.value.province_id)
            .then(({data}) => {
                cityOptions.value = data;
            })
    }
}

const getBrangay = ():void => {
    if(filters.value.city_id){
        DropdownService
            .barangays(filters.value.city_id)
            .then(({data}) => {
                barangayOptions.value = data;
            })
    }
}

const regionChange = ():void => { 
    filters.value.city_id = null
    filters.value.barangay_id = null
    filters.value.province_id = null
    getProvince()
}

const provinceChange = ():void => {
    filters.value.city_id = null
    filters.value.barangay_id = null
    getCity()
}

const cityChange = ():void => { 
    filters.value.barangay_id = null 
    getBrangay()
}

/**
 * WATCHERS
 */
watch(
    () => filters.value.query,
    debounce(() => {
        getData();
    }, 1000)
);


onMounted(() => {
    getData();
    getRegions();
});


</script>