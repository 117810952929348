import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Form from "@/classes/Cases/CaseGuardian/Offline/Formatters/CaseGuardianForm"
import useFormatFormStructure from "@/helpers/Offlinehelpers/useFormatFormStructure";
import Storage from "@/helpers/storage";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import Relationships from "@/classes/Forms/Relationships";
import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
const relationship = new Relationships();
const options  = new FormOptions();

const storage = new Storage();
const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const table = "case_guardians";

class OfflineService extends Syncable {
    /**
     * Return formatted data 
     * @param cases_id 
     * @returns 
     */
   async index(cases_id: number){
       await sqlite.connect();
       const query = `SELECT * FROM ${table} WHERE cases_id = ${cases_id} AND deleted_at IS NULL`; 
       const guardians = await sqlite.db.select(query);
       return {
           'data': {
               'items': {
                   data: guardians.length > 0 ? guardians : [],
                   total: guardians.length
               }
           },
       };
   }

   /**
    * Render form data
    * @returns data
    */
   async create(patient: number){
    await sqlite.connect();

    return {
        data : {  
            genders: Gender,
            guardianRelationships: await options.guardianRelationships(),
            patient: await await PatientOfflineProcessor.getFullName(patient)
        }
    }
   }

   /**
    * Update or Create data from storage
    * @param patient
    * @param patientCase
    * @param payload
    */
   async updateCreate(guardian:number, patient: any, patientCase: any, payload: any) {
        
        let structuredForm; 

       if(guardian){
           structuredForm  = useFormatFormStructure(payload, Form.updateForm(payload));
       }else{ 
           structuredForm  = useFormatFormStructure(payload, Form.createForm());
       }  

       const encoded_by = JSON.parse(storage.get('user'));
       structuredForm.cases_id = patientCase;
       structuredForm.encoded_by =  encoded_by.id;
       structuredForm.birthdate = payload.birthdate ? relationship.dateFormatFromIso(payload.birthdate) : null;
       
       //store or update 
       await sqlite.connect();
       this.table = table;
       const guardians = await this.sync(sqlite.db, [structuredForm]); 
   
       return {
           data: {
               data: {
                   item: guardians
               }
           }
       }
   }

   /**
    * Return needed data for edit page
    * @param patient 
    * @param patientCase 
    * @param guardian 
    * @returns 
    */
   async edit( patient: any, patientCase: any, guardian: any){
       await sqlite.connect();
       const query = `SELECT * FROM ${table} WHERE  cases_id = ${patientCase} AND id = ${guardian} LIMIT 1`;
       const item = await sqlite.db.select(query);
        
       return {
           data : {
               item: {
                   ...item,
               },
               patient: await PatientOfflineProcessor.getFullName(patient),
               genders: Gender,
               guardianRelationships: await options.guardianRelationships(),
           }
       }
   }

   /**
    * Removing data from storage
    * @param guardian 
    * @param patientCase 
    * @returns 
    */
   async delete(guardian:number, patientCase: number, forceDelete = false){
       await sqlite.connect();
       if (forceDelete) {
            await this.forceDelete(guardian, patientCase)
       }

       // check if record exist offline
       const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${guardian} AND cases_id=${patientCase}`);
       if (response.length > 0) {
            const item = response[0];

            if (item.is_created_offline) {
                await this.forceDelete(guardian, patientCase);
            } else {
                const form = {
                    id: guardian,
                    is_sync: false,
                    synching_remarks: null,
                    is_deleted_offline: true,
                    deleted_at: relationship.dateFormatFromIso(new Date),
                }
                await sqlite.db.updateWithFilters(table, form, { cases_id: patientCase, id: guardian });
            }
       }
       return this.index(patientCase);
   }

   async getCount(case_id: number) {
        const response = await sqlite.db.select(`SELECT count(id) as count FROM ${table} WHERE cases_id=${case_id} AND (deleted_at IS NULL OR deleted_at = 0)`);
        return response.length > 0 ? response[0].count : 0;
    }

    async syncToOffline(case_id: number, payload: any) {
        await sqlite.connect();
        const form = useFormatFormStructure(payload, Form.syncToOffline(payload));
        form.cases_id = case_id;
        syncable.table = table;
        const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, form.id);
        if (checkRecord) {
            return;
        }
        await syncable.sync(sqlite.db, [DefaultFormStructureBuild.resetStatus(form)]);
    }

    async forceDelete(guardian: number, case_id: number) {
        await sqlite.db.delete(table,{id: guardian, cases_id: case_id});
    }
}


export default new OfflineService();