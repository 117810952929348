import FormOptions from "@/classes/Forms/FormOptions";
import LegalStatus from "@/enums/LegalStatus";
import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Storage from "@/helpers/storage";
import Relationships from "@/classes/Forms/Relationships";
import { defaultCreateForm, defaultUpdateForm } from "@/classes/General/GeneralOfflineForms";
import useFormatFormStructure from "@/helpers/Offlinehelpers/useFormatFormStructure";
import PersonalInforForm from "../Formatters/PersonalInforForm";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";

const relationship = new Relationships();
const storage = new Storage();
const sqlite = new SqliteProcessor();
const options = new FormOptions();
const table = `intake_personal_information`;

class OfflineProcessor extends Syncable{

    /**
     * Render form data 
     * @param patient 
     * @param patientCase 
     * @returns 
     */
    async show(patient: number, patientCase: number){  
        await sqlite.connect();

        const intakeQuery = `SELECT * FROM ${table} WHERE cases_id=${patientCase} LIMIT 1`;
        const caseQuery = `SELECT * FROM cases WHERE id=${patientCase} LIMIT 1`;
        const patientQuery = `SELECT * FROM patients WHERE id=${patient} LIMIT 1`;
        const primaryDiagnosis = `SELECT * FROM case_diagnoses WHERE cases_id=${patientCase}`;
        const referrals = `SELECT * FROM case_referral_services WHERE cases_id=${patientCase}`;
        const companionsQuery = `SELECT * FROM case_companions WHERE cases_id=${patientCase}`;

        const response = await sqlite.db.select(intakeQuery);//intake_personnal_informations TABLE
        const primaryDiagnosisData = await sqlite.db.select(primaryDiagnosis);
        const referralData = await sqlite.db.select(referrals);

        const cases = await sqlite.db.select(caseQuery)
        const patients = await sqlite.db.select(patientQuery);
        const companions = await sqlite.db.select(companionsQuery);
      

        const gender: any = relationship.gender(patients[0]);
        const sex_at_birth: any  = relationship.sexAtBirth(patients[0]);
        const primary_diagnoses = primaryDiagnosisData.length > 0 ?  await relationship.primary_diagnoses(primaryDiagnosisData[0]) : "";
         
        const social_worker = referralData.length > 0 ? await relationship.social_worker(referralData[0].social_worker_id) : "";
        const sought_service = referralData.length > 0  ? await relationship.sought_service(referralData[0]) : "";
        const informant_relation_child = companions.length > 0 ? await relationship.companionRelationship(companions[0]) : "";
        const external_referral_from = referralData.length > 0 ? await relationship.referral_source(referralData[0].referral_source_id) : "";
        const intra_referral_from = referralData.length > 0 ? await relationship.referral_source(referralData[0].second_referral_source_id) : "";
        const hospital_areas =  await relationship.hospitalAreas(cases[0].hospital_area_id);
    
        const personal_information = {
            last_name: patients[0].last_name,
            first_name: patients[0].first_name,
            gender: gender.description,
            sex_at_birth: sex_at_birth.description,
            birthday_type: patients[0].birthday_type,
            date_initiated: cases[0].initiated_at,
            birthdate: patients[0].birthdate,  
            approximate_age_year: patients[0].approximate_age_year,
            case_number: cases[0].case_number,
            primary_diagnosis: primary_diagnoses?.label === null ? "" : primary_diagnoses?.label,
            social_worker: social_worker?.name,
            services_sought: sought_service?.name,
            informant_relation_child: informant_relation_child?.name,
            other_relationship: companions.length > 0 ? companions[0].other_relationship : "",
            external_referral_from:  external_referral_from.name,
            intra_referral_from:  intra_referral_from?.name === null ? "" : intra_referral_from?.name,
            if_ward_referred: hospital_areas?.name ?? "",
            patient_id: patient,
            case_id: patientCase,
        }
        

        let content = null;
        const id = response[0]?.id ?? null;
        if(response.length > 0){//means already has intake personnal information
            content =  {
                ...response[0],
                ...{
                    cases_information: {id,...personal_information},
                    id: response[0].id
                }
            }
        }else{
            content = {   
                cases_information: personal_information
            }
        }

        const patientName = await PatientOfflineProcessor.getFullName(patient);
        const patientWithBirthday = `${patientName} ${patients[0].birthdate}`;
       
        return {
            data: { 
                housingClassifications: await options.housingClassifications(),
                item: content,
                legalStatus: LegalStatus,
                socioEconomics: await options.socioEconomicStatuses(),
                patient: patientName,
                patient_with_birthday: patientWithBirthday
            }
        } 
    }

    /**
     * Update or create in storage
     * @param patient 
     * @param patientCase 
     * @param payload 
     * @returns 
     */
    async updateCreate(personalInforId: number | null, patient: number, patientCase:number, payload: any){
        this.table = table;
        
        const encoded_by = JSON.parse(storage.get('user'));
    
        let structuredForm;

        if(personalInforId){
            structuredForm = useFormatFormStructure(payload, PersonalInforForm.updateForm(payload));
            structuredForm.is_updated_offline = true;
        }else{
            structuredForm = useFormatFormStructure(payload, PersonalInforForm.createForm());
            structuredForm.cases_id = patientCase;
            structuredForm.encoded_by = encoded_by.id;
            structuredForm.is_created_offline = true;
        }
        
        structuredForm.is_sync = false;
        structuredForm.is_sync_failed = false;
        await sqlite.connect();
        await this.sync(sqlite.db, [structuredForm]);

        return await this.show(patient, patientCase);
    }

    async syncToOffline(case_id: number, payload: any) {
        await sqlite.connect();
        const encoded_by = JSON.parse(storage.get('user'));
        const structuredForm = useFormatFormStructure(payload, PersonalInforForm.updateForm(payload));
        structuredForm.cases_id = case_id;
        structuredForm.encoded_by = encoded_by.id;
        structuredForm.is_created_offline = false;
        structuredForm.is_updated_offline = false;
        structuredForm.is_deleted_offline = false;
        structuredForm.is_sync = false;
        structuredForm.is_sync_failed = false;
        structuredForm.synching_remarks = false;

        this.table = table;
        await this.sync(sqlite.db, [structuredForm]);
    }
}
export default new OfflineProcessor();