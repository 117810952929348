<template>
	<user-layout hasBack backLink="/data-dictionary/index">
		<div class="md:max-w-[500px] w-full mx-auto py-6">
			<form-section :form="form" :isReadOnly="true" />
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import UserLayout from "@/layouts/UserLayout.vue";
import FormSection from "./FormSection.vue";
import { computed, ref, watch } from "vue";
import DataDictionaryService from "@/classes/Settings/DataDictionaryService";
import { useRoute } from "vue-router"; 
import { useNetworkStore } from "@/store/network";
import { onIonViewWillEnter } from "@ionic/vue"; 
import DataDictionaryOfflineProcessor from "@/classes/DataDictionary/Offline/processor/DataDictionaryOfflineProcessor";
const route = useRoute(); 
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

/**
 * VARIABLES
 */
const form = ref<{ name: string; content: string }>({
	name: "",
	content: "",
});

const getData = async () => {
    const response = await DataDictionaryService.show({
		dataDictionary: route.params.dataDictionary,
	}, {});
    if (response?.status === 200 || response?.data != undefined) {
        const data = response.data; 
        if (isNetworkAvailable.value) {
            await DataDictionaryOfflineProcessor.syncToOffline(data.item);
        }
        form.value.name = data.item.name;
        form.value.content = data.item.content;
    } else {
        console.log(response);
    }
}

watch(
    () => isNetworkAvailable.value,
    async () => {
        await getData();
    }
);

onIonViewWillEnter(async () => {
    await getData();
});
</script>
@/classes/Databases/SqlLite@/classes/Databases/SqlLite