import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "./defaultMigration";

export const patients:SchemaTableOptions = {
    name:<string> 'patients',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'first_name', value: 'VARCHAR(255) NOT NULL'},
        { column: 'last_name', value: 'VARCHAR(255) NOT NULL'},
        { column: 'middle_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'birthday_classification', value: 'TINYINT UNSIGNED'},
        { column: 'birthdate', value: 'DATE DEFAULT NULL'},
        { column: 'approximate_age_year', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'approximate_age_month', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'registered_at', value: 'DATETIME NOT NULL'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
        { column: 'civil_status_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'nationality_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'religion_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'gender', value: 'TINYINT UNSIGNED DEFAULT NULL'},
        { column: 'sex_at_birth', value: 'TINYINT UNSIGNED'},
        { column: 'nickname', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'indigeneous_people_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'pwd', value: 'TINYINT UNSIGNED DEFAULT NULL'},
        { column: 'pwd_specify', value: 'TEXT DEFAULT NULL'},
        { column: 'office_id', value: 'INTEGER UNSIGNED'},
        { column: 'other_indigeneous_people', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'other_nationality', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'other_religion', value: 'VARCHAR(255) DEFAULT NULL'},
        ...defaultMigration
    ]
}