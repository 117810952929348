import http from "@/axios";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";
import CasePatientAddressForm from "../Cases/CasePatientAddress/Offline/Formatters/CasePatientAddressForm";
import Relationships from "../Forms/Relationships";

const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const table = 'case_patient_addresses';
const relationship = new Relationships();
export default class SyncPatientAddresses {
    async renderCasePatientAddress(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        await sqlite.connect();
        const addresses = await onlineSync.defaultQuery(table);
        if (addresses.length > 0) {
            for (const index in addresses) {
                const item = addresses[index];
                const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
                const setForm = CasePatientAddressForm.createForm();
                const form = onlineSync.setForm(CasePatientAddressForm.updateForm(item), setForm, item);
                // unique object value per table
                form.is_created_offline = item.is_created_offline;
                form.cases_id = item.cases_id;
                form.table = table;
                form.type = "Case Patient Address"
                if (cases.length > 0) {
                    form.patient_name = `${cases[0].first_name} ${cases[0].last_name}`;
                    form.birthdate_formatted = relationship.dateFormat(cases[0].birthdate);
                    form.url_view = `/patient/${cases[0].patient_id}/cases/${item.cases_id}/patient-addresses/${item.id}/edit`;
                }
                form.can_view = item.cases_id ? true : false;

                if (isRenderData) {
                    items.push(form);
                }
                
                // do something for online syching
                if (!isRenderData) {
                    hasError = await this.processForm(item, form);
                }
                hasError = false;
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async processForm(item: any, form: any) {
        const onlineSync = new OnlineSynching;
        let hasError = false;

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-patient-address-delete', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-patient-address-update', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline) {
            const response = await http.post('/attemp-synching-patient-address-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.data.item.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }
        return hasError;
    }
}