import http from "@/axios";
import FormOptions from "@/classes/Forms/FormOptions";
import DateFormatter from "@/helpers/DateFormatter";
import Storage from "@/helpers/storage";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import Relationships from "@/classes/Forms/Relationships";
import Legends from "@/enums/Legends";

const options  = new FormOptions();
const storage = new Storage();
const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const relationship = new Relationships();

const currentUser = JSON.parse(storage.get("user"));

class OfflineService {

	async getFormStructure(): Promise<any> {
        await sqlite.connect();
		const forms = await sqlite.db.select(`SELECT * FROM intervention_form_structure`)
		if(forms.length > 0){
			return forms;
		} else {
			return await http
				.get(`/get-form-structure`)
				.then(async ({data}) => {
					data = data.map((item:any) => {
						item.form = JSON.stringify(item.form);
						return item;
					});
                    await sqlite.db.set('intervention_form_structure', data);
				});
		}
	}

	async patientSearch(payload: any): Promise<any> {
        await sqlite.connect();
        syncable.table = 'patients';
        const patients = await syncable.paginate(sqlite.db, payload, (query: string) => {
            let sql = '';
            if (payload?.first_name || payload?.last_name) {
                sql += ` WHERE (first_name LIKE "%${payload?.first_name || null}%" OR last_name LIKE "%${payload?.last_name || null}%")`;
            }
            
            if (payload?.birthdate) {
                sql += ` AND birthdate="${payload?.birthdate}"`;
            }

            return sql;
        });

		patients.data = patients.data.map((item:any) => {
			item.patient_id = item.id;

			return item;
		});
        
		return {
            data: {
                items: patients
            }
        };
	}

	async storeOffline(items: object): Promise<void> {
		items = Object.values(items).map((item:any) => {
			item.data = JSON.stringify(item.data);
			return item;
		});
        await sqlite.connect();
        syncable.table = 'intervention_data';
        for (const index in items) {
            const item = items[index as keyof object];
            const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, item['id']);
            if (checkRecord) {
                return;
            }
            await syncable.sync(sqlite.db, [item]);
        }
	}

	async index(patient: number, case_id: number|null = null, payload: any|null = null): Promise<any> {
        await sqlite.connect();
		const interventionTypes = await options.interventionTypes();
		const patientData = await sqlite.db.select(`SELECT * FROM patients WHERE id = ${patient}`);
		syncable.table = 'intervention_data';
        const caseIds: any = [];
        if (payload.query) {
            const response = await sqlite.db.select(`SELECT * FROM cases WHERE patient_id=${patient} AND case_number LIKE '%${payload.query}%'`);
            for (const index in response) {
                caseIds.push(response[index].id);
            }
        }

        let caseNumbers = [];
        const interventionCases = await sqlite.db.select(`SELECT * FROM intervention_data WHERE patient_id=${patient}`)
        if (interventionCases.length > 0) {
            caseNumbers = interventionCases.map((item) => item.case_number);
        }

        const data = {
            items: await syncable.paginate(sqlite.db, payload, (query: string) => {
                let condition = ` WHERE patient_id = ${patient} AND deleted_at IS NULL`;
                
                if (payload.query) {
                    condition += ` AND cases_id IN (${caseIds})`
                } else {
                    if( case_id != null){
                        condition += ` AND cases_id = ${case_id}`;
                    }                
                }

                if (payload.scheduleDate?.length > 0) {
                    const date1 = new Date(payload.scheduleDate[0]);
                    date1.setHours(0, 0, 0, 0);
                    const date2 = new Date(payload.scheduleDate[1]);
                    date2.setHours(23, 59, 59, 999);
                    condition += ` AND schedule_date BETWEEN '${date1.toISOString()}' AND '${date2.toISOString()}'`;
                }

                if (payload.doneOnDate?.length > 0) {
                    const date1 = new Date(payload.doneOnDate[0]);
                    date1.setHours(0, 0, 0, 0);
                    const date2 = new Date(payload.doneOnDate[1]);
                    date2.setHours(23, 59, 59, 999);
                    condition += ` AND done_on BETWEEN '${date1.toISOString()}' AND '${date2.toISOString()}'`;
                }

                if (payload.intervention_type) {
                    condition += ` AND intervention_type=${payload.intervention_type}`;
                }

                if (payload.case_number) {
                    condition += ` AND case_number='${payload.case_number}'`;
                }

                if (payload.legend) {
                    if (payload.legend == 'Past Due') {
                        condition += ` AND date(schedule_date) < date('now', 'localtime') AND (done_on IS NULL OR done_on = '')`;
                    }

                    if (payload.legend == 'Scheduled') {
                        condition += ` AND date(schedule_date) >= date('now', 'localtime') AND (done_on IS NULL OR done_on = '')`;
                    }

                    if (payload.legend == 'Done') {
                        condition += ` AND (done_on IS NOT NULL OR done_on != '')`; 
                    }
                }

                return condition;
            })
        }

		return {
			data: {
				...data,
				interventionType: interventionTypes,
				patient: `${patientData[0].last_name}, ${patientData[0].first_name}` ,
                caseNumbers: caseNumbers,
                legends: Legends
			}
		}
	}

	async create(patient: number, form: number, case_id: number|null = null,  payload: object|null = null): Promise<string> {
        await sqlite.connect();
		return this.createFormatter(patient, form, case_id)
	}

	async store(patient: number, form: number, payload: any,): Promise<void> {
		await sqlite.connect();
		const data = {
			patient_id: patient,
			cases_id: payload.cases_id,
			intervention_type: payload.interventionType,
			data: JSON.stringify(payload.params),
			case_number: await this.getPatientCaseNumber(payload.cases_id),
			intervention_type_name: await this.getInterventionTypeName(payload.interventionType),
			is_created_offline: true, 
			created_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			...this.setFormParamsData(payload?.params),
		}
		
		data.status = this.getStatus(data);
        syncable.table = 'intervention_data';
        await syncable.sync(sqlite.db, [data])
	}

	async edit(interventionType:number, intervention:number, patient: number, payload: object|null = null): Promise<any> {
        await sqlite.connect();
        const result = await sqlite.db.searchTable('intervention_data', 'id', intervention);
        let items: any = [];
        if (result.length > 0) {
            items = {
                data: {
                    item: result[0]
                }
            }
        }

		return this.editFormatter(interventionType, items)
	}
	
	async update(intervention:number, patient: number, payload: any): Promise<any> {
        await sqlite.connect();
		const interventionDetails = await sqlite.db.select(`SELECT * FROM intervention_data where id = ${intervention}`);

		const data = Object.assign(
				interventionDetails[0],
				{
					patient_id: patient,
					cases_id: payload.cases_id,
					intervention_type: parseInt(payload.interventionType),
					data: JSON.stringify(payload.params),
					is_updated_offline: payload.is_created_offline ? false : true, 
					updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
					...this.setFormParamsData(payload?.params)
				}
			);
        data.status = this.getStatus(data);
        syncable.table = 'intervention_data';
        return await syncable.sync(sqlite.db, [data]);
	}

	private async createFormatter(patient:number, interventionType:number, case_id:number|null = null): Promise<any> 
	{
		return {
			data : {
				patient: await this.getPatientDetails(patient, case_id),
				form: await this.storedFormStructure(interventionType),
				cases: case_id ? [] : await this.getPatientCases(patient)
			}
		}
	}

	private async editFormatter(interventionType:number, result:any): Promise<any> 
	{
		const item = result.data.item;

		item.data = Object.values(JSON.parse(item.data));
		item.status = this.getStatus(item);

		return {
			data : {
				item: item,
				patient: await this.getPatientDetails(item.patient_id, item.cases_id),
				form: await this.storedFormStructure(interventionType),
				isCourtHearing: false,
			}
		}
	}

	private async getPatientDetails(patient:number, case_id:number|null = null): Promise<any>{
		let patient_age = '';
		let patient_birthday = '';

		let detials: any = await sqlite.db.select(`SELECT * FROM patients where id = ${patient}`);
		detials = detials[0];

		const content = detials;

		if(case_id){
			const cases = await sqlite.db.select(`SELECT * FROM cases where id = ${case_id}`)
			const casesContent = cases[0];
            if (casesContent) {
                patient_age = casesContent.computed_age 
                patient_birthday = casesContent.birthday
                content.educational_attainment_id = casesContent.educational_attainment_id;
            }
		}

		return {
			name: `${detials.first_name} ${detials.last_name}`,
			patient_name: `${detials.first_name} ${detials.last_name}`,
			patient_age: patient_age,
			patient_birthday: patient_birthday,
			patient_education: content?.educational_attainment_id || '',
			patient_guardian: "",
			office: currentUser.office_id
		};
	}

	private async storedFormStructure(interventionType:number): Promise<JSON>{
		const form = await sqlite.db.select(`SELECT * FROM intervention_form_structure where intervention_type = ${interventionType}`)

		return JSON.parse(form[0].form);
	}

	private async getPatientCases(patient:number): Promise<any>{
		const cases = await sqlite.db.select(`SELECT * FROM cases where patient_id = ${patient}`)

		return cases.map((item:any) => { 
			const content = cases[0];

			return {
				id:item.id, 
				value: item.id, 
				label: item.case_number, 
				computed_age: content.computed_age,
				birthday: content.birthday, 
			}
		});
	}

	private async getPatientCaseNumber(cases_id:number): Promise<string>{
		const cases = await sqlite.db.select(`SELECT * FROM cases where id = ${cases_id}`)

		return cases[0].case_number;
	}
	private async getInterventionTypeName(intervention_type:string): Promise<string>{
		const interventionTypes = await options.interventionTypes();

		const index = interventionTypes.map((item:any) => item.id).indexOf(parseInt(intervention_type));

		return interventionTypes[index].label;
	}

	private setFormParamsData(data: any[]): any
	{
		const params:any = [];

		Object.values(data).forEach((value:any) => {
			switch (value.form_tag) {
				case 'type':
					params['type_type'] = value.source_type;
					params['type_id'] = value.value;
					params['type'] = value.value_label;
					break;
				case 'sub_type':
					params['subtype_type'] = value.source_type;
					params['subtype_id'] = value.value;
					params['subtype'] = value.value_label;
					break;
				case 'schedule_date_time':
					params['schedule_date'] = DateFormatter.customDateFormat(value.value, 'yyyy-MM-dd hh:mm:ss');
					break;
				case 'done_date_time':
					if(value.value){
						params['done_on'] = DateFormatter.customDateFormat(value.value, 'yyyy-MM-dd hh:mm:ss');
					} else {
						params['done_on'] = null;
					}
					break;
				case 'assigned_group':
					if(value.value != null){
						params['position_id'] = value.value;
					} else {
						params['position_id'] = currentUser.position_id;
					}
					break;
				case 'done_by':
					if(value.value != null){
						params['done_by_id'] = value.value;
						
					} else {
						params['done_by_id'] = currentUser.id;
						params['done_by'] = currentUser.name;

					}
					break;
			}

			if(value.form_tag == 'assigned_group' && value.value != null){
                params['position_id'] = value.value;
            } else {
                params['position_id'] = currentUser.position_id;
            }
			
			if(value.form_tag == 'done_by' && value.value != null){
                params['done_by_id'] = value.value;
            } else {
                params['done_by_id'] = currentUser.id;
                params['done_by'] = currentUser.name;
            }
		})

		return params;
	}

	public getStatus(intervention:any): string{
		let status = "Past Due";

		if(intervention?.done_on){
			status = "Done";
		} else if((intervention?.done_on == undefined || intervention?.done_on == null) && this.isDateGreaterOrEqual(intervention.schedule_date) ){ 
			status = 'Scheduled';
		} 

		return status;
	}

	public isDateGreaterOrEqual(dateString: string): boolean {
		const date = new Date(dateString);
		const now = new Date();
		return date >= now;
	}

    async delete(intervention: number) {
        await sqlite.connect();
        const result = await sqlite.db.select(`SELECT * FROM intervention_data WHERE id=${intervention} AND is_created_offline=true`);
        if (result.length > 0) {
            return await sqlite.db.delete('intervention_data', { 
                id: intervention,
            });
        } 

        return await sqlite.db.update('intervention_data', {
            id: intervention,
            is_deleted_offline: true,
            deleted_at: relationship.dateFormatFromIso(new Date),
        })
    }

    async getCount(case_id: number) {
        const response = await sqlite.db.select(`SELECT count(id) as count FROM intervention_data WHERE cases_id=${case_id} AND deleted_at IS NULL`);
        return response.length > 0 ? response[0].count : 0;
    }

}
export default new OfflineService();
