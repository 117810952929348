import SqliteProcessor from "@/processors/SqliteProcessor";
import Relationships from "@/classes/Forms/Relationships";
import { civil_statuses } from "@/migrations/tables/Options/civil_statuses";
import { primary_diagnoses } from "@/migrations/tables/Options/primary_diagnoses";
import { socio_economic_statuses } from "@/migrations/tables/Options/socio_economic_statuses";
import { current } from "tailwindcss/colors";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import parseArray from "@/helpers/Offlinehelpers/parseArray";
const sqlite = new SqliteProcessor();
const relationship = new Relationships();


const tbl_cases = 'cases';
const tbl_patients = 'patients';
const tbl_diagnoses = 'case_diagnoses';
const tbl_companions = 'case_guardians';
const tbl_case_intake = 'intake_personal_information';
const tbl_patient_addresses = 'case_patient_addresses';
const tbl_referral_service = 'case_referral_services';
const tbl_contact_number = 'intake_contact_numbers';
const tbl_email_address = 'intake_email_addresses';
const tbl_incest = 'intake_incest_cases';
class OfflineProcessor{
    async index(patientId: number | null, caseId: number | null){
        await sqlite.connect();


        const casesData = await sqlite.db.select(`SELECT * FROM ${tbl_cases} WHERE id=${caseId}`);
        const patientData = await sqlite.db.select(`SELECT * FROM ${tbl_patients} WHERE id=${patientId}`);
        const diagnosesData = await sqlite.db.select(`SELECT * FROM ${tbl_diagnoses} WHERE cases_id=${caseId}`);
        const companionData = await sqlite.db.select(`SELECT * FROM ${tbl_companions} WHERE cases_id=${caseId}`);
        const intakeData = await sqlite.db.select(`SELECT * FROM ${tbl_case_intake} WHERE cases_id=${caseId}`);
        const addressData = await sqlite.db.select(`SELECT * FROM ${tbl_patient_addresses} WHERE cases_id=${caseId} ORDER BY id DESC`);
        const referralServiceData = await sqlite.db.select(`SELECT * FROM ${tbl_referral_service} WHERE cases_id=${caseId}`);
        const contactNumbers = await sqlite.db.select(`SELECT * FROM ${tbl_contact_number} WHERE cases_id=${caseId}`);
        const emailAdresses = await sqlite.db.select(`SELECT * FROM ${tbl_email_address} WHERE cases_id=${caseId}`);
        const incestData = await sqlite.db.select(`SELECT * FROM ${tbl_incest} WHERE cases_id=${caseId}`);


        //Intake Personal Section
        const personalInformation = await  this.renderIntake(
            casesData[0], patientData[0], diagnosesData[0],
            companionData[0], intakeData[0], referralServiceData[0],
            contactNumbers[0]
         );


        //Render Address Section
        const addresses = await this.renderAddress(addressData, caseId);
       
        const familyComposition = await this.renderFamilyComposition(companionData);
       
        //Render Incest
        const incest = await this.renderIncest(incestData[0]);


        //Return data sets
        return {
            ...personalInformation,//main items
            addresses,
            emailAdresses,
            contactNumbers,
            incest,
            familyComposition,
            hasCurrentAddress: addressData.length > 0 ? true : false,
        }
    }




    //Render Intake Section data
    async renderIntake(
        casesData: any, patientData:any, diagnosesData:any,
        companionData:any, intakeData:any, referralServiceData:any,
        contactNumberData: any
    ){
        const sex_form:any = relationship.sexAtBirth(patientData);
        const gender:any = relationship.gender(patientData);
        const services_sought =referralServiceData   ? await relationship.sought_service(referralServiceData) : "";
        const social_worker  = referralServiceData   ? await relationship.social_worker(referralServiceData.social_worker_id) :"";
        const civil_status = casesData ? await relationship.civil_status(casesData) : "";
        const external_referral = referralServiceData  ? await relationship.referral_source(referralServiceData.referral_source_id) : "";
        const intra_referral = referralServiceData  ? await relationship.referral_source(referralServiceData.second_referral_source_id) : "";
        const legal_status:any = intakeData  ? relationship.legalStatus(intakeData) : "";
        const housing_classification = intakeData  ?  await relationship.housingClassification(intakeData) : "";
        const primary_diagnoses =  diagnosesData ? await relationship.primary_diagnoses(diagnosesData) : "";
        const socio_economic_status =  intakeData   ? await relationship.socioEconomic(intakeData) : "";
        const inpatient_referred = casesData.length > 0 ? await relationship.hospitalAreas(casesData.hospital_area_id) : "";
        const informant_relation_child =  companionData ? await relationship.companionRelationship(companionData) : "";
        const lives_w_child = companionData ? relationship.livesWithChild(parseArray(companionData?.person_related_types)): [];
 
        return {
            case_id: casesData.id,
            patient_id: patientData.id,
            first_name: patientData.first_name,
            last_name: patientData.last_name,
            name:  await PatientOfflineProcessor.getFullName(patientData.id),
            gender: gender.description,
            sex: patientData.gender === 0 ? 'M' : 'F',
            sex_form: sex_form.description,
            birthdate: relationship.dateFormat(patientData?.birthdate),
            birthday_classification: patientData.birthday_classification,
            age: patientData.birthdate ? relationship.computeAge(patientData?.birthdate, patientData?.age) : relationship.birthdateDetails(patientData),
            case_number: casesData.case_number,
            civil_status: civil_status?.name ?? "",
            primary_diagnosis: primary_diagnoses.name ,
            social_worker: social_worker?.name ?? "" ,
            services_sought: services_sought?.name ?? "",
            external_referral: external_referral?.name ?? "",
            intra_referral: intra_referral?.name ?? "",
            is_inpatient: casesData.hospital_area ? "Yes" : "No",
            inpatient_referred: inpatient_referred.name ?? "",
            contacts: contactNumberData?.number ?? "",


            legal_status: legal_status?.description ?? "",
            socio_economic_status: socio_economic_status?.name ?? "" ,
            no_children_in_family: intakeData?.no_children_in_family ?? "",
            no_household_members: intakeData?.no_household_members ?? "",
            no_family_in_household: intakeData?.no_family_in_household ?? "",
            housing_classification: housing_classification?.name,
            lives_w_child:  lives_w_child.join(",") ??  "", 
            family_background: "",//TODO
            is_enrolled: "",//TODO
            company_name:"", //TODO
            informant_relation_child: informant_relation_child?.name ?? "-",
            other_relationship: companionData?.other_relationship ?? "-",
            // "informant_relation_child" => $companions?->relationship?->name ?? "-",
            // "other_relationship" => $companions?->other_relationship ?? "-",
        }
    }


     //Render Addresses
     async renderAddress(addressData:any, caseId: any){
        const items = [];
        for (const index in addressData) {
            const address = addressData[index];
            const currentRegion = await relationship.regions(address);
            const currentBrgy = await relationship.barangays(address);
            const currentProvince = await relationship.provinces(address);
            const currentCity = await relationship.cities(address);
            const renderAddress = `${address.address} ${currentBrgy?.name} ${currentCity?.name} ${currentRegion?.name}`;
            const residenceType:any = relationship.residenceTypes(address);

            items.push({
                id: address.id,
                address_type: renderAddress || "NA",
                current: residenceType?.description ?? "",
                current_directions: address.direction,
                cur_barangay: currentBrgy?.name,
                cur_province: currentProvince?.name,
                cur_region: currentRegion?.name || "",
                cur_city: currentCity?.name,
            });
        }
        return items;
        // const currentArr = [];
        // const permanentArr = [];


        // if (addressData.length > 0) {
        //     for (const address of addressData) {
        //         const item = { ...address };  // Create a copy of the current address object


        //         console.log("address id here", item.id);


        //         // permanent address
        //         if (item.residence_type === 2) {
        //             permanentArr.push(item);
        //         }
        //         // current address
        //         if (item.residence_type === 0) {
        //             currentArr.push(item);
        //         }
        //     }
        // }




        // let permanentItem = null;
        // let currentItem = null
        // if (permanentArr.length > 0) {
        //     const latestPermanentId = permanentArr.map((item) => item.id).reduce((a, b) => Math.max(a, b), -Infinity);
        //     permanentItem = await this.setAddressOtherDetails(
        //         permanentArr.find((item) => item.id === latestPermanentId)
        //     );
        // }


        // if (currentArr.length > 0) {
        //     const lastestCurrentId = currentArr.map((item) => item.id).reduce((a, b) => Math.max(a, b), -Infinity);
        //     currentItem = await this.setAddressOtherDetails(
        //         currentArr.find((item) => item.id === lastestCurrentId)
        //     );
        // }

        
        // return await this.generateAddres(permanentItem, currentItem, caseId);
    }


    //Array of address depends on address Type
    async generateAddres(permanentItem: any, currentItem: any, caseId: any) {


        // let fullCurrent = null;
        // let fullPermanent = null;
        // if (currentItem) {
        //     fullCurrent = `${currentItem.address} ${currentItem.barangay} ${currentItem.city} ${currentItem.region?.name}`;
        // }


        // if (permanentItem) {
        //     fullPermanent = `${permanentItem.address} ${permanentItem.barangay} ${permanentItem.city} ${permanentItem.region?.name}`;
        // } else {
        //     fullPermanent = fullCurrent;
        // }


        // const permanentRegion = permanentItem ? await relationship.regions(permanentItem) : null;
        // const currentRegion = currentItem ? await relationship.regions(currentItem) : null;
       
        // const residenceType:any = currentItem ? relationship.residenceTypes(currentItem) : "";
 
        // return {
        //     permanent: fullPermanent || 'NA',
        //     address_type: residenceType?.description ?? "",
        //     permanent_directions: permanentItem?.direction,
        //     current: fullCurrent || 'NA',
        //     current_directions: currentItem?.direction,
        //     cur_barangay: currentItem?.barangay,
        //     cur_province: currentItem?.province,
        //     cur_region: currentRegion?.name ?? "",
        //     cur_city: currentItem?.city,
        //     perm_barangay: permanentItem?.barangay,
        //     perm_province: permanentItem?.province,
        //     perm_region: permanentItem?.region?.name,
        //     cur_ncr_municipality: currentRegion?.code === 'car-cordillera-autonomous-region' ? currentItem?.city : '-',
        //     perm_ncr_municipality: permanentRegion?.code === 'car-cordillera-autonomous-region' ? permanentItem?.city : '-'
        // };
    }


    //Address Detials
    async setAddressOtherDetails(content: any) {
        if (content) {
            const brgy = await relationship.barangays(content)
            const city = await relationship.cities(content)
            const province = await relationship.provinces(content);
            return {...content, ...{
                id: content.id,
                cases_id: content.cases_id,
                barangay: brgy?.name,
                city: city?.name,
                province: province?.name,
            }}
        }
    }


    //Rendering Family Composition
    async renderFamilyComposition(companion:any){
        console.log("companions",companion)


        const data = await Promise.all(companion.map(async(item:any) => {
            const gender:any = relationship.gender(item);
            const relation = await relationship.companionRelationship(item)
            return {
              name: `${item.first_name} ${item.last_name}`,
              gender: gender?.description || "",
              relationship: relation?.name ?? "",
              birthdate: item.birthdate ?  relationship.birthdateDetails(item) : "Unknown",
              age: relationship.birthdateDetails(item),
              contact: item.contacts ?? ""
            };
          }));


          return data;
    }




    //Rendering Incest
    async renderIncest(incestData:any){
     
        const sleeping_arrangement:any = incestData ? relationship.sleepingArrangement(incestData.sleeping_arrangement) : "";
         
        const abusedArray:any = [];
        const presentArray:any = [];
        const same_bed_with:any = [];
        const same_room_with:any = [];


        if(incestData){
            parseArray(incestData?.same_room_with).forEach(index=>{
                const item:any = relationship.sleepingArrangementWith(index);
                same_room_with.push(item.description);
            })
           
            parseArray(incestData?.same_bed_with).forEach(index=>{
                const item:any = relationship.sleepingArrangementWith(index);
                same_bed_with.push(item.description);
            })
           
            parseArray(incestData?.abused_living_arrangement).forEach(element => {
                const item:any = relationship.livingArrangement(element)
                abusedArray.push(item.description);
            });
   
            parseArray(incestData.present_living_arrangement).forEach(index=>{
                const item:any = relationship.livingArrangement(index)
                presentArray.push(item.description);
            })
        }
       
        return {
            sleeping_arrangement: sleeping_arrangement.description ?? "",
            others: incestData?.others ?? "-",
            same_bed_with: same_bed_with.join(",") ?? "",
            same_room_with: same_room_with.join(",") ?? "",
            abused: {
                form: abusedArray
            },
            present: {
                form: presentArray
            }
        }
    }
}


export default new OfflineProcessor();