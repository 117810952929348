import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../../defaultMigration";

export const case_conference_recommendations:SchemaTableOptions = {
    name:<string> 'case_conference_recommendations',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
		{ column: "case_conference_id", value: "INTEGER UNSIGNED" },
		{ column: "recommendation_option_id", value: "INTEGER UNSIGNED" },
        { column: "remarks", value: "LONGTEXT NULL" },
		{ column: "action", value: "INT NULL" },
        ...defaultMigration
    ]
}