import { defaultCreateForm, defaultDeleteForm, defaultUpdateForm } from "../../../../General/GeneralOfflineForms" 
import Relationships from "@/classes/Forms/Relationships"

const relationship = new Relationships();
class ContactNumberForm{
    viewForm(item:any){  
        const data = item?.contactNumber?.map((item:any) => {  
            return {
                id: item?.id,
                label: item?.label,
                number: item?.number,
                updated_at_formatted: relationship.dateFormat(item?.updated_at || item?.created_at,"LLLL dd, yyyy HH:mm" )
            };
        });
        
        return data || [];
    }
    createForm = () => {
        return {
            label: null,
            number: null,
            ...defaultCreateForm()
        }
    }
    updateForm = (item: any) => {
        return {
            id: item.id,
            label: item.label,
            number: item.number, 
            ...defaultUpdateForm()
        }
    }
    deleteForm = (item: any) => { 
        return {
            id: item.id,
            label: item.label,
            number: item.number,
            ...defaultDeleteForm()
        }
    }
    
}

export default new ContactNumberForm(); 