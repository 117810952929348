export function useSessionCookie(cookieName: string) {
    const setSessionCookie = (value: any) => {
        document.cookie = `${cookieName}=${value}; path=/; SameSite=Lax;`;
    }

    const getSessionCookie = (name: string) => {
        const cookieArr = document.cookie.split(";");
        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].trim();
            if (cookiePair.indexOf(name + "=") === 0) {
                return cookiePair.split("=")[1];
            }
        }
        return null;
    }

    const deleteSessionCookie = () =>  {
        document.cookie = `${cookieName}=; Max-Age=0; path=/;`;
    }

    return {
        setSessionCookie,
        getSessionCookie,
        deleteSessionCookie
    };
}