import PrxSqlite from "@/vendors/prx-sqlite/PrxSqlite";
import Storeable from "./Storeable";
import SyncedModelInterface from "@/interfaces/models/SyncedModelInterface";
import Storage from '@/helpers/storage';
import { useNetworkStore } from '@/store/network';

export default class Syncable extends Storeable implements SyncedModelInterface {

    /*
    |--------------------------------------------------------------------------
    | @Contracts
    |--------------------------------------------------------------------------
    */
    
    /**
     * Sync items from server with sqlite.
     * 
     * @param   PrxSqlite db
     * @param   Array<object> items
     * 
     * @return  Promise<void>
     */
    async sync(db:PrxSqlite, items:Array<object>):Promise<void> {
        if (items.length === 0) return
        for(let i = 0; i < items.length; i++) {
            const item = items[i];
            const values = await db.select(`SELECT * FROM ${this.table} WHERE ${this.key} = "${item[this.key as keyof object]}"`)
            console.log(item);
            if(values.length != 0) {
                await db.update(this.table, item, this.key)
            }
            else {
                await db.insert(this.table, item)
            }
        }        
    }

    /**
     * Render collection with paginate
     */
    async paginate(db: PrxSqlite, params: any, callback: any): Promise<any> {
        const rows = params.rows ?? 5;
        params.page = params.page == undefined ? 1 : params.page;
        let query = `SELECT * FROM ${this.table}`;

        if (callback) {
            query += callback(query);
        }

        const total = await db.select(query);

        query += ` ORDER BY id DESC`;
        if (rows) {
            query += ` LIMIT ${rows}`;
        }

        if (params.page && params.page > 1) {
            query += ` OFFSET ${params.page - 1}`;
        }

        const results = await db.select(query);
        return {
            current_page: parseInt(params.page),
            data: results,
            from: parseInt(params.page),
            last_page: Math.ceil(total.length / rows),
            per_page: parseInt(rows),
            to: parseInt(params.page),
            total: total.length
        };

    }

    async selectIndex(db: PrxSqlite, table: string, condition: string) {
        let deletedQuery = "";
        if(condition){
             deletedQuery = ` WHERE ${condition} AND deleted_at IS NULL`;
        }else{
            deletedQuery = ` WHERE deleted_at IS NULL`;
        }
        
        const result = await db.select(`SELECT * FROM ${table} ${deletedQuery} ORDER BY id DESC`);

        return {
            data: {
                items: { data: result, total: result.length },
            }
        }
    }

    /**
     * Sync items from sqlite to pinia.
     * 
     * @param   PrxSqlite db
     * @return  Promise<void>
     */
    async globalize(db:PrxSqlite):Promise<void> {
        await db.get(this.table, this.key).then(items => this.set(items))
    }

    /**
     * Update item.
     * 
     * @param   PrxSqlite db
     * @param   object params
     * @param   object filters
     * 
     * @return  Promise<void>
     */
    async update(db:PrxSqlite, params:object, filters:object):Promise<void> {
        this.updateStore(params, filters)
        await db.updateWithFilters(this.table, params, filters)
    }

    async insert(db:PrxSqlite, item: object): Promise<any> {
        await db.insert(this.table, item);
        const data =  await db.select(`SELECT * FROM ${this.table} ORDER BY id DESC LIMIT 1`);
        return data;
    }

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Update item in pinia.
     * 
     * @param   object params
     * @param   object filters
     * 
     * @return  void
     */
    updateStore(params:object, filters:object):void {
        throw new Error('Method updateStore() must be implemented')
    }

    async removeCreate(db: PrxSqlite, removeParams: any, payload: any) {
        await db.delete(this.table, removeParams);
        await db.insert(this.table, payload);
    }

    async removeCreateMany(db: PrxSqlite, removeParams: any, payload: any) {
        await db.delete(this.table, removeParams);
        await db.set(this.table, payload);
    }

    /**
     * Used for online synching updating the old patient id to new patient id
     */
    async updateSynchingPatientId(db: PrxSqlite, oldId: number, newId: number) {
        const response = await db.select(`SELECT * FROM ${this.table} WHERE patient_id=${oldId}`);

        if (response.length > 0) {
            await db.updateWithFilters(this.table, {
                patient_id: newId,
            }, {
                patient_id: oldId
            })
        }
    }

    /**
     * Used for online synching updating the old cases id to new cases id
     */
    async updateSynchingCaseId(db: PrxSqlite, oldId: number, newId: number) {
        const response = await db.select(`SELECT * FROM ${this.table} WHERE cases_id=${oldId}`);

        if (response.length > 0) {
            await db.updateWithFilters(this.table, {
                cases_id: newId,
            }, {
                cases_id: oldId
            })
        }
    }

    /**
     * Check if record in a selected table is not yet synced
     */
    async checkUnsyncRecord(db: PrxSqlite, id: number, key = 'id') {
        if (id) {
            const response = await db.select(`SELECT * FROM ${this.table} WHERE ${key}=${id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
            return response.length > 0 ? true : false;
        }

        return false;
    }

    async hasDraft(db: PrxSqlite) {
        const tables = [
            'patients',
            'cases',
            'intervention_data',
            'case_guardians',
            'case_companions',
            'case_patient_addresses',
            'case_perpetrators',
            'perpetrators',
            'case_conferences',
            'case_conference_recommendations',
            'case_conference_aggravating_factors',
            'intake_personal_information',
            'intake_email_addresses',
            'intake_contact_numbers',
            'intake_incest_cases',
            'intake_person_related_to_cases',
            'case_safety_assessments',
        ];

        const storage = new Storage();
        const networkStore = useNetworkStore();
        let status = false;
        for (const index in tables) {
            const table = tables[index];
            const response = await db.select(`SELECT id FROM ${table} WHERE is_created_offline = true OR is_updated_offline = true OR is_deleted_offline = true`);
            if (response.length > 0) {
                status = true;
                storage.set('hasDraft', status);
                networkStore.isNetworkAvailable = false;
                return;
            }
        }
        
        storage.set('hasDraft', status);
        if (!status) {
            networkStore.isNetworkAvailable = eval(storage.get('actualNetworkStatus'));
        }
    }
}