import { createPatient } from "../Interfaces/PatientInterface";
import Relationships from "@/classes/Forms/Relationships";
import PatientOfflineProcessor from "../processor/PatientOfflineProcessor";
import CaseOfflineForm from "@/classes/Cases/CaseHistory/Offline/Formatters/CaseOfflineForm";
import CaseReferralOfflineForm from "@/classes/Cases/CaseHistory/Offline/Formatters/CaseReferralOfflineForm";
import CaseDiagnosesOfflineForm from "@/classes/Cases/CaseHistory/Offline/Formatters/CaseDiagnosesOfflineForm";
import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";

const relationship = new Relationships();
class PatientOfflineForm {
    /**
     * Template form structure of patient table
     */
    formStructure () {
        return {
            id: null,
            first_name: null,
            last_name: null,
            middle_name: null,
            birthday_classification: null,
            birthdate: null,
            approximate_age_year: null,
            approximate_age_month: null,
            registered_at: null,
            encoded_by: null,
            civil_status_id: null,
            nationality_id: null,
            religion_id: null,
            gender: null,
            sex_at_birth: null,
            nickname: null,
            indigeneous_people_id: null,
            pwd: null,
            pwd_specify: null,
            office_id: null,
            other_indigeneous_people: null,
            other_nationality: null,
            other_religion: null,
        }
    }
 
    createPatientCaseForm () {
        return {
            ...this.formStructure(),
            ...CaseOfflineForm.formStructure(),
            ...CaseReferralOfflineForm.formStructure(),
            ...CaseDiagnosesOfflineForm.formStructure(),
            mental_health: [],
            comorbidities: [],
            remarks: [],
            other_indigeneous_people: null,
            approximate_age: null,
            other_nationality: null,
        }
    }

    fillPatientForm(item: any) {
        const builder: any = {};
        if (item === undefined) {
            return;
        }
        
        const items = this.formStructure()
        // get default form structure template
        for (const index in items) {
            // match all existing indexes based on default form structure
            builder[index] = DefaultFormStructureBuild.fill(index, item);
        }
        builder.patient_id = item.id;
        return builder;
    }

    fillSyncPatientForm(item: any) {
        const builder: any = this.fillPatientForm(item);
        builder.created_at = item.created_at;
        builder.updated_at = item.updated_at;
        builder.deleted_at = item.deleted_at;
        builder.is_created_offline = item.is_created_offline;
        builder.is_updated_offline = item.is_updated_offline;
        builder.is_deleted_offline = item.is_deleted_offline;
        builder.synching_remarks = item.synching_remarks;
        return builder;
    }

    renderSyncToOffline (item: Array<createPatient>, isUpdate = false) {
        const builder: any = {};
        const items = this.formStructure()
        // get default form structure template
        for (const index in items) {
            // match all existing indexes based on default form structure
            builder[index] = DefaultFormStructureBuild.fill(index, item);

            // filter all dates fields and convert value into ISO
            const check = this.checkFormStrucuteDates().find((item) => item === index);
            if (check) {
                builder[index] = relationship.dateFormatFromIso(builder[index]);
            }
        }
        // usable only when updating record
        if (isUpdate) {
            return this.updatePatientForm(builder, item);
        }
        
        return builder;
    }

    updatePatientForm(builder: any, item: any) {
        builder.id = item.patient_id || item.id;
        builder.is_sync = false;
        builder.synching_remarks = null;
        builder.is_updated_offline = true;
        builder.updated_at = relationship.dateFormatFromIso(new Date);
        return builder;
    }

    async renderPatientProfile(item: Array<any>) {
        if (item.length > 0) {
            const data = item[0];            
            
            data.sex_at_birth_meta = relationship.sexAtBirth(data);
            data.gender_meta = relationship.gender(data);
            data.registered_at_formatted = relationship.dateFormat(data.registered_at, 'LL/dd/yyyy');
            return data;
        }

        return null;
    }

    async renderPatientSearch(item: any) {
        const getPatient = await PatientOfflineProcessor.getPatient(item.patient_id || item.id);

        const gender: any = getPatient ? relationship.gender(getPatient) : '';
        return {
            id: item.id,
            patient: {
                abbr: getPatient ? relationship.fullnameAbbr(getPatient) : '',
            },
            first_name: getPatient?.first_name,
            last_name: getPatient?.last_name,
            gender: gender ? gender?.description : '',
            birthdate: getPatient?.birthdate,
            birthday_classification: getPatient?.birthday_classification,
            computed_age: this.renderComputedAge(getPatient),
            latest_case_number: '',
            registered_at: getPatient?.registered_at,
            primary_diagnosis: item.primary_diagnosis,
            profile_photo_url: '',
            patient_id: getPatient?.id,
        };
    }

    renderComputedAge(item: any) {
        if (!item) {
            return '';
        }

        const birthdate = item.birthdate;
        return birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
    }

    private checkFormStrucuteDates () {
        return [
            'birthdate',
            'registered_at'
        ]
    }

    store(payload:any) {
        const form = this.renderSyncToOffline(payload);
        form.is_sync = false;
        form.synching_remarks = null;
        form.is_created_offline = true;
        form.created_at = relationship.dateFormatFromIso(new Date);
        return form;
    }
}

export default new PatientOfflineForm();