import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";

export const case_referral_services:SchemaTableOptions = {
    name:<string> 'case_referral_services',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'cases_id', value: 'INTEGER UNSIGNED'},
        { column: 'referral_source_id', value: 'INTEGER UNSIGNED'},
        { column: 'second_referral_source_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'sought_service_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'intake_social_worker_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'social_worker_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'physician_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'mental_health_personnel_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'mms_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'trainee_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
        { column: 'referral_specify', value: 'VARCHAR(255) DEFAULT NULL'},
    ]
}