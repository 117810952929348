import OfflineProcessor from "./Processor/OfflineProcessor";
import http from "@/axios";   

class EmailAddressService {
 /**
     * Render index page
     * @param patient 
     * @param cases_id 
     * @param payload 
     * @param networkAvailable 
     * @returns 
     */
 async index(patient: number, patientCase: number,networkAvailable:boolean, payload: any ): Promise<any> {
    if(networkAvailable){
        return await http.get(`patients/${patient}/cases/${patientCase}/intakeForm/emailAddress`, { params: payload });
    }else{ 
        const data = await OfflineProcessor.index(patient, patientCase); 
        return {
            data: {
                ...data.data,
            }
        };
    }
} 

/**
 * Store data to storage
 * @param patient
 * @param patientCase
 * @param payload
 * @param networkAvailable
 * @returns
 */
async store(patient: any, patientCase: any,  networkAvailable:boolean, payload: any,): Promise<any> {
    if(networkAvailable){
        return await http.post(`patients/${patient}/cases/${patientCase}/intakeForm/emailAddress`, payload);
    }else{    
        return await OfflineProcessor.updateCreate(patient, patientCase, payload);
    }
}

/**
 * Store data to storage
 * @param patient
 * @param patientCase
 * @param payload
 * @param networkAvailable
 * @returns
 */
async update(patient: any, patientCase: any,patientEmail: number, networkAvailable:boolean, payload: any,): Promise<any> {
    if(networkAvailable){
        return await http.put(
			`patients/${patient}/cases/${patientCase}/intakeForm/emailAddress/${patientEmail}/update`,
			payload
		); 
    }else{    
        return await OfflineProcessor.updateCreate(patient, patientCase, payload);
    }
}



/**
 * Remove data from storage
 * @param patient 
 * @param patientCase 
 * @param guardian 
 * @param payload 
 * @param networkAvailable 
 * @returns 
 */
async delete(
    patient: number,
    patientCase: number,
    patientEmailAddress: number,
    networkAvailable:boolean,
    payload: any,
): Promise<any> {
    if(networkAvailable){
        return await http.delete(
            `patients/${patient}/cases/${patientCase}/intakeForm/emailAddress/${patientEmailAddress}/delete`,
            payload
        );
    }else{ 
        //just updating the status of deleted at for syncing
        return await OfflineProcessor.delete(patientEmailAddress, patientCase);
    }
}

}
export default new EmailAddressService();