import http from "@/axios";
import { SyncStatus } from "@/views/enums/SyncStatus";
import Relationships from "../Forms/Relationships";
import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";
import { intervention_data } from "@/migrations/tables/intervention_data";
import OnlineSynching from "./OnlineSynching";

const relationship = new Relationships();
const syncable = new Syncable;
const sqlite = new SqliteProcessor;
const table = 'intervention_data';

export default class SyncInterventions {
    
    resetItemData(item: any) {
        const data:any = [];

        intervention_data.schema.forEach((schema:any) => data[schema.column] = item[schema.column]);

        data.synching_remarks = null;
        data.is_sync_failed = false;
        data.is_sync = true;
        data.is_created_offline = false;
        data.is_updated_offline = false;
        data.is_deleted_offline = false;
        data.data = JSON.stringify(data.data);

        return data;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error?.message || 'System Error';
        item.is_sync_failed = true;
        await sqlite.db.insert(table, [item]);

        return true;
    }


    async renderInterventions(isRenderData:boolean): Promise<any> {
        const items:any = [];
        let hasError = false;

        await sqlite.connect();
        const interventions = await sqlite.db.select(`SELECT * FROM ${table} WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC`);
        
        if (interventions.length > 0) {
            for (const index in interventions) {

                const intervention = interventions[index];

                const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${intervention.cases_id}`);
                const casesData = cases.length > 0 ? cases[0] : {};
                const form:any = intervention;

                form.data =  JSON.parse(form.data)
                form.table = table;
                form.type = "Interventions"
                form.patient_name = `${casesData.first_name} ${casesData.last_name}`;
                form.url_view = `/patient/${intervention.patient_id}/cases/${intervention.cases_id}/intervention/${intervention.intervention_type}/edit/${intervention.id}`;
                form.can_view = true;
                form.birthdate_formatted = relationship.dateFormat(casesData.birthdate);
                form.created_at_formatted = relationship.dateFormat(intervention.updated_at);

                form.status = intervention.is_sync_failed == false || intervention.is_sync_failed == undefined 
                    ? (intervention.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;

                if (isRenderData) {
                    items.push(form);
                }
                // do something for online syncing
                if (!isRenderData) {
                    hasError = await this.processForm(intervention, form);
                }

            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async processForm(item: any, form: any): Promise<any> {

        if (item.is_deleted_offline) {
            return await this.processDelete(item, form);
        }

        if (item.is_created_offline) {
            return await this.processCreate(item, form);
        }

        if (item.is_updated_offline) {
            return await this.processUpdate(item, form);
        }

        return false;
    }

    async processUpdate(item: any, form:any): Promise<any>
    {
        const onlineSync = new OnlineSynching;
        const response = await http.post(`/sync/interventions/update/${form.patient_id}/${item.id}`, {
            cases_id: form.cases_id,
            interventionType: form.intervention_type,
            params: form.data,
        }).catch(error => error);

        if (response?.status === 200) {
            await onlineSync.resetItemData(table, item.id);
            return false;
        } else {
            return await this.rollback(item, response);
        }
    }

    async processCreate(item: any, form:any): Promise<any>
    {
        const onlineSync = new OnlineSynching;
        try {     
            const response = await http.post(`/sync/interventions/create/${form.patient_id}`, {
                cases_id: form.cases_id,
                interventionType: form.intervention_type,
                params: form.data,
            }).catch(error => error);
    
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.data.item.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                return false;
            } else {
                console.log('power');
                return await this.rollback(item, response);
            }
        } catch (error) {
            console.log(error);
        }

    }

    async processDelete(item: any, form:any): Promise<any>
    {
        const onlineSync = new OnlineSynching;
        const response = await http.post(`/sync/interventions/delete/${item.id}`, form).catch(error => error);
        if (response?.status === 200) {
            await onlineSync.removeRecord(table, item.id);
            return false;
        } else {
            return await this.rollback(item, response);
        }
    }
}