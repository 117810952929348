<template>
    <user-layout hasBack backTitle="Primary Diagnosis Related Reports" backLink="/reports">

        <template #lowerRight>
            <export-button @click="exporting()" />
        </template>

        <div class="-mx-6 w-[calc(100%+32px)] pb-6" v-if="!loading"> 
            <div class="mx-6 mt-8 border rounded-lg border-neutral-100">
                <div class="">
                    <detail-table 
                        :hasSearch="true"
                        searchPlaceholder="Search Report type name"
                        :headers="
                        filters.report_type !== PrimaryDiagnosisReportTypes.COMORBID ? defaultHeader
                        : (filters.group_category == 1 ? vacHeader : vawHeader)
                        "
                        :no-action="true"
                        :count="items?.data?.length"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                        :search="filters.query"
                        @update:searchText="(value:string) => (filters.query = value)"
                    >
                        <template #topContent>
                            <div class="flex space-x-2">
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <AdjustmentsVerticalIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Report Type</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">CASE RELATED</p>
                                                <radio-group
                                                    :options="reportTypeCaseOptions"
                                                    name="reportTypeCaseOptions"
                                                    customLabelClass="whitespace-nowrap"
                                                    v-model="filters.report_type"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                            <!-- <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">COMORBID</p>
                                                <radio-group
                                                    :options="reportTypePatientOptions"
                                                    name="reportTypePatientOptions"
                                                    customLabelClass="whitespace-nowrap"
                                                    v-model="filters.report_type"
                                                    @update:modelValue="getData()"
                                                />
                                            </div> -->
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">LOCATION RELATED</p>
                                                <radio-group
                                                    :options="reportTypeOptions"
                                                    name="reportTypeOptions"
                                                    customLabelClass="whitespace-nowrap"
                                                    v-model="filters.report_type"
                                                    @update:modelValue="paginate(1, filters.rows)"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>
                                <Popover class="relative" v-if="filters.report_type ===  PrimaryDiagnosisReportTypes.COMORBID">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <UserGroupIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Group</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">CATEGORY</p>
                                                <radio-group
                                                    :options="defaultCategoryOptions"
                                                    name="categoryOptions"
                                                    v-model="filters.group_category"
                                                    @update:modelValue="paginate( 1, 10);"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>
                                <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100"
                                @click="showFilter = true">
                                    <FunnelIcon class="inline-block w-5 h-5 text-neutral-900" />
                                    <p class="text-sm font-semibold text-neutral-900">Filter</p>
                                </div>
                                
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <ArrowsUpDownIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Sort</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="sampleOptions"
                                                    name="sampleoptions"
                                                    v-model="filters.column"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="defaultOptions"
                                                    name="defaultoptions"
                                                    v-model="filters.order"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <FilterPanel
                                    :show="showFilter"
                                    @close="showFilter = false"
                                    @apply:reset="applyReset()"
                                    @apply:filters="applyFilters()"
                                    customClass="!overflow-visible overflow-x-visible"
                                    >
                                    <template #fields>
                                        <div class="space-y-5">
                                            <date-picker
                                                id="date_created"
                                                name="date_created"
                                                label="Date Created"
                                                v-model="filters.created_at"
                                                range
                                                multiCalendars
                                                class="filter-range"
                                            />
                                            <multi-select   
                                                v-if="filters.report_type !== PrimaryDiagnosisReportTypes.COMORBID"
                                                :options="diagnosis"
                                                id="diagnosis_id"
                                                name="diagnosis_id"
                                                label="Primary Diagnosis"
                                                placeholder="Select.."
                                                v-model="filters.diagnosis_id"   
                                            />

                                            <div class="space-y-5">
                                                <multi-select  
                                                    v-if="
                                                    filters.report_type === PrimaryDiagnosisReportTypes.PROVINCE
                                                    || filters.report_type === PrimaryDiagnosisReportTypes.CITY
                                                    || filters.report_type === PrimaryDiagnosisReportTypes.BARANGAY
                                                    " 
                                                    :options="regions"
                                                    id="region_id"
                                                    name="region_id"
                                                    label="Regions"
                                                    placeholder="Select.."
                                                    v-model="filters.region_id"  
                                                    @update:modelValue="regionChange()"
                                                />
                                                <multi-select   
                                                    v-if=" 
                                                    filters.report_type === PrimaryDiagnosisReportTypes.CITY
                                                    || filters.report_type === PrimaryDiagnosisReportTypes.BARANGAY
                                                    "
                                                    :options="provinces"
                                                    id="province_id"
                                                    name="province_id"
                                                    label="Provinces"
                                                    placeholder="Select.."
                                                    v-model="filters.province_id"  
                                                    @update:modelValue="provinceChange()" 
                                                />
                                                <multi-select  
                                                    v-if=" 
                                                    filters.report_type === PrimaryDiagnosisReportTypes.BARANGAY 
                                                    "  
                                                    :options="cities"
                                                    id="city_id"
                                                    name="city_id"
                                                    label="Cities"
                                                    placeholder="Select.."
                                                    v-model="filters.city_id"  
                                                    @update:modelValue="cityChange()"
                                                />
                                                <!-- TODO confirm PM  -->
                                                <!-- <multi-select  
                                                    v-if=" 
                                                    filters.report_type === PrimaryDiagnosisReportTypes.BARANGAY 
                                                    "   
                                                    :options="barangays"
                                                    id="barangay_id"
                                                    name="barangay_id"
                                                    label="Barangay"
                                                    placeholder="Select.."
                                                    v-model="filters.barangay_id"  
                                                /> --> 
                                            </div>
                                        </div>
                                    </template>
                                </FilterPanel>
                            </div>
                        </template>
                        <template #body> 
                                <template v-if="filters.report_type != PrimaryDiagnosisReportTypes.COMORBID">
                                    <template v-for="item in items?.data" :key="item">     
                                        <tr>
                                            <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.name }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set1 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set1 }}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set2 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set2 }}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set3 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set3 }}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set4 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set4 }}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set5 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set5 }}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set6 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set6 }}
                                            </td> 
                                            <!-- Total Male and Female Children -->
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item?.children_male}}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.children_female}}
                                            </td> 
                                            <!-- Total Children -->
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item.total_children}}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set7 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set7 }}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set8 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set8 }}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set9 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set9 }}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.male_set10 }}
                                            </td>
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.female_set10 }}
                                            </td> 
                                            <!-- Total Male and Female-->
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.adult_male}}
                                            </td> 
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.adult_female}}
                                            </td> 
                                            <!-- Total Male and Female Adult Female -->
                                            <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{  item?.total_adultWomen }}
                                            </td> 
                                            <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.total }}
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-if="filters.report_type === PrimaryDiagnosisReportTypes.COMORBID">
                                    <template v-if="filters.group_category === 1">
                                        <template v-for="item in items?.data" :key="item">     
                                            <tr>
                                                <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.name }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set1 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set1 }}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set2 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set2 }}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set3 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set3 }}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set4 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set4 }}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set5 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set5 }}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set6 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set6 }}
                                                </td> 
                                                <!-- Total Male and Female Children -->
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                {{ item?.children_male}}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.children_female}}
                                                </td> 
                                                <!-- Total Children -->
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item.total_children}}
                                                </td>  
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-if="filters.group_category === 3">
                                        <template v-for="item in items?.data" :key="item">     
                                            <tr>
                                                <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.name }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set7 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set7 }}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set8 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set8 }}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set9 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set9 }}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.male_set10 }}
                                                </td>
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.female_set10 }}
                                                </td> 
                                                <!-- Total Male and Female-->
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.adult_male}}
                                                </td> 
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item?.adult_female}}
                                                </td>  
                                                <!-- Total Male and Female Adult Female -->
                                                <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                                    {{  item?.total_adultWomen }}
                                                </td>  
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                                <tr class="bg-gray-50">
                                    <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                        Total
                                    </td>
                                    <template v-for="(total, index) in totals" :key="index">
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            {{ total }}
                                        </td>
                                    </template>
                                </tr>
                            </template> 
                            
                    </detail-table>
                    <div class="w-full px-6 border-t py-7">
						<simple-pagination 
							:numRows="filters.rows" 
                            :currentPage="items?.current_page" 
                            :last-page="items?.last_page"
                            @update="(value: any) => paginate(value.page, value.rows)" 
						/>
					</div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="loading"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
    </user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DetailTable from '@/components/partials/DetailTable.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { AdjustmentsVerticalIcon, UserGroupIcon, FunnelIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import ReportService from "@/classes/Reports/ReportService";
import MultiSelect from '@/components/inputs/MultiSelect.vue';
import DateFormatter from '@/helpers/DateFormatter';
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { ReportTypes } from '@/views/enums/ReportTypes';
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import DropdownService from "@/classes/Dropdowns/DropdownService";
import { PrimaryDiagnosisReportTypes } from '@/views/enums/PrimaryDiagnosisReportTypes';
import ReportTypeDiagnosis from '@/enums/Reports/ReportTypeDiagnosis';
const regions = ref<renderSelect[]>([]);
const cities = ref<renderSelect[]>([]);
const provinces = ref<renderSelect[]>([]);
const barangays = ref<renderSelect[]>([]);

const diagnosis = ref<renderSelect[]>([]);
const showFilter = ref<boolean>(false); 
const loading = ref<boolean>(false);

const filters = ref({
    rows: 10,
    query: null,
    report_type: PrimaryDiagnosisReportTypes.SUICIDAL_RISK,  
    created_at: null,
    column: 'name',
    order: 'ASC',
    page: 1,
    group_category: 1,

    //filters for report
    region_id: null,
    province_id: null,
    city_id: null,
    barangay_id: null,

    //filter the primary diagnosis
    diagnosis_id:  null,
}) 
const sampleOptions = [
    { id: '1', label: 'Label', value: 'name'},
    { id: '2', label: 'Count', value: 'total'},
]

const defaultOptions = [
    { id: '3', label: 'Ascending', value: 'ASC'},
    { id: '4', label: 'Descending', value: 'DESC'},
]
   
const reportTypeOptions = [
    { id: "9", label: "Region", value: PrimaryDiagnosisReportTypes.REGION },
    { id: "8", label: "Province", value: PrimaryDiagnosisReportTypes.PROVINCE },
    { id: "7", label: "City", value: PrimaryDiagnosisReportTypes.CITY },
    { id: "6", label: "Barangay", value: PrimaryDiagnosisReportTypes.BARANGAY },
    { id: "10", label: "Office", value: PrimaryDiagnosisReportTypes.OFFICE },
     
];
    
const reportTypePatientOptions = [
    // { id: "5", label: "COMORBID", value: PrimaryDiagnosisReportTypes.COMORBID }, 
];

const reportTypeCaseOptions = [
    { id: "1", label: "Suicidal Risk", value: PrimaryDiagnosisReportTypes.SUICIDAL_RISK },
    { id: "2", label: "Perpetrator Relation", value: PrimaryDiagnosisReportTypes.PERPETRATOR_RELATION },
    { id: "3", label: "Referral Source", value: PrimaryDiagnosisReportTypes.REFERRAL_SOURCE },
    { id: "4", label: "PWD", value: PrimaryDiagnosisReportTypes.PWD },
];
 
const vacHeader = ref([
    { text: 'Report Type', colspan: 1, rowspan: 2, }, 
    { text: '0-3', colspan: 2, rowspan: 1,  subheaders: ['M', 'F']  },
    { text: '4-6', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '7-9', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '10-12', colspan: 2, rowspan: 1,subheaders: ['M', 'F'] },
    { text: '13-15', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '16-18', colspan: 2, rowspan: 1,subheaders: ['M', 'F'] },
    { text: 'Total', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: 'Children', colspan: 1, rowspan: 2,},//Total Children 
])


const vawHeader = ref([
    { text: 'Report Type', colspan: 1, rowspan: 2, }, 
    { text: '19-24', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '25-44', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '45-59', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '60-Above', colspan: 2, rowspan: 1, subheaders: ['M', 'F']}, 
    { text: 'Total', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: 'Women', colspan: 1, rowspan: 2},//Total Women 
])

const defaultHeader = ref([
    { text: 'Report Type', colspan: 1, rowspan: 1, subheaders: ['primary diagnosis']}, 
    { text: '0-3', colspan: 2, rowspan: 1,  subheaders: ['M', 'F']  },
    { text: '4-6', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '7-9', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '10-12', colspan: 2, rowspan: 1,subheaders: ['M', 'F'] },
    { text: '13-15', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '16-18', colspan: 2, rowspan: 1,subheaders: ['M', 'F'] },
    { text: 'Total', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: 'Children', colspan: 1, rowspan: 2,},//Total Children
     
    { text: '19-24', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '25-44', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '45-59', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: '60-Above', colspan: 2, rowspan: 1, subheaders: ['M', 'F']}, 
    { text: 'Total', colspan: 2, rowspan: 1, subheaders: ['M', 'F']},
    { text: 'Women', colspan: 1, rowspan: 2},//Total Women
    { text: 'Grand Total', colspan: 1, rowspan: 2 },//Grand Total
]);

const items = ref()
const totals = ref()

const defaultCategoryOptions = [
    { id: "1", label: "CPN/Children", value: 1 }, 
    { id: "3", label: "Adult Patient", value: 3 },
];

 
const paginate = ( page:number, rows:number )=>{
    ReportService.paginate(page, rows,filters.value, getData)
}

const applyFilters = (search: any = null) => {
	showFilter.value = false; 
    paginate( 1, filters.value.rows);   
};

const applyReset = () => {
    showFilter.value = false;
    filters.value.created_at = null; 
    filters.value.region_id = null;
    filters.value.province_id  = null;
    filters.value.city_id = null;   
    filters.value.barangay_id = null;
    filters.value.diagnosis_id =null;
    paginate( 1, filters.value.rows);
};
 
const getData = () => {  
    loading.value = true;
    ReportService.index('primary-diagnosis-related', omitBy(filters.value, isNil))
        .then(({ data }) => {  
            items.value = data.items.data; 
            totals.value = data.items.totals;  
            diagnosis.value = data.diagnosis; 
        })
        .catch(errors => { 
            console.log(errors);
        })
        .finally(() => loading.value = false)
}

const exporting = (): void => { 
    loading.value = true;   
    ReportService.export('primary-diagnosis-related',omitBy(filters.value, isNil))
        .then(({data})=>{
             ReportService.downloadExport("primary-diagnosis-export.csv",data);  
        })
        .finally(() => loading.value = false)
}

const getRegions = async (): Promise<any> => { 
    DropdownService
    .regions()
    .then(({data}) => {
        regions.value = data;
    }) 
}

 
const getProvince = async (): Promise<any> => {
    if(filters.value.region_id){ 
        DropdownService
        .provinces(filters.value.region_id)
        .then(({data}) => {
            provinces.value = data;
        }) 
    }
}

const getCity = async (): Promise<any> => {
    if(filters.value.province_id){ 
        DropdownService
        .cities(filters.value.province_id)
        .then(({data}) => {
            cities.value = data;
        }) 
    }
}

const getBrangay = async (): Promise<any> => { 
    if(filters.value.city_id){ 
        DropdownService
        .barangays(filters.value.city_id)
        .then(({data}) => {
            barangays.value = data;
        }) 
    }
}
const regionChange = ():void => {
    filters.value.city_id = null
    filters.value.barangay_id = null
    filters.value.province_id = null 
    getProvince()
}

const provinceChange = ():void => {
    filters.value.city_id = null
    filters.value.barangay_id = null
    getCity()
}

const cityChange = ():void => { 
    filters.value.barangay_id = null 
    getBrangay()
}
//----------------------------------------
 
const getDiagnosisHeader = (type: any) =>{  
    defaultHeader.value[0].subheaders[0] = 'All'; 
    diagnosis.value.filter(function(item:any){ 
        if(item.value === type){ 
            defaultHeader.value[0].subheaders[0] = item.label 
        } 
    });     
}

const getReportTypeHeader = (type: any)=>{ 
    defaultHeader.value[0].text = ReportTypeDiagnosis[0].label;
    ReportTypeDiagnosis.filter(function(item):any{
        if(item.value === type){
            defaultHeader.value[0].text = item.label;
        }
    })
} 

watch(
    () => filters.value.report_type,
    debounce(() => {   
        applyReset();
    }, 500)
) 
watch(
    () => filters.value.diagnosis_id,
    debounce(() => {    
        getDiagnosisHeader(filters.value.diagnosis_id);   
    }, 500)
);

watch(
    () => filters.value.report_type,
    debounce(() => { 
        getReportTypeHeader(filters.value.report_type);   
    }, 500)
);

watch(
    () => filters.value.query,
    debounce(() => {
        paginate( 1, filters.value.rows);
        applyReset();
    }, 500)
);
 
onMounted(() => { 
    getRegions(),
    getData(), 
    getReportTypeHeader(1); 
    getDiagnosisHeader(1);
})

</script>