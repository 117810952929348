import {required, helpers } from "@vuelidate/validators"
const createRules = (form: any): any => { 
   return {
    sleeping_arrangement: {
           required:  helpers.withMessage(errorMessage('sleeping arrangement'), required)
       },
    //    same_bed_with: {
    //        required: helpers.withMessage('This field is required', required)
    //    }, 
    //    same_room_with: {
    //     required: helpers.withMessage('This field is required', required)
    // }, 
   }
}

const errorMessage = (field: any) => {
   return `The ${field} field is required`;
}
export default {
   createRules,
}
