import { PerpetratorIdentity } from "@/views/enums/PerpetratorIdentity";
import { requiredIf, helpers, required, numeric, minValue, maxValue, minLength, maxLength } from "@vuelidate/validators";
import { number } from "zod";

const searchRules = (form: any) => {
    return {
        first_name: {
            required: helpers.withMessage('The first name field is required when last name is empty.', requiredIf(!form.last_name)),
        },
        last_name: {
            required: helpers.withMessage('The last name field is required when first name is empty.', requiredIf(!form.first_name)),
        },
    }
}

const createRules = (form: any) => {
    return {
        first_name: {
            required: helpers.withMessage(errorMessage('first name'), requiredIf(form.identity === PerpetratorIdentity.KNOWN)),
        },
        last_name: {
            required: helpers.withMessage(errorMessage('last_name'), requiredIf(form.identity === PerpetratorIdentity.KNOWN)),
        },
        gender: {
            required: helpers.withMessage(errorMessage('gender'), required),
        },
        identity: {
            required: helpers.withMessage(errorMessage('identity'), required),
        },
        perpetrator_relationship_id: {
            required: helpers.withMessage(errorMessage('relationship'), required),
        },
        approximate_age_month: {
            numeric: number,
            minValue: minValue(form.approximate_age_year > 0 ? 0 : 1),
            maxValue: maxValue(12),
            required: helpers.withMessage('The approximate age month field is required when birthdate is not present.', requiredIf(!form.birthdate)),
        },
        approximate_age_year: {
            numeric: numeric,
            minValue: minValue(form.approximate_age_month > 0 ? 0 : 1),
            required: helpers.withMessage('The approximate age year field is required when birthdate is not present.', requiredIf(!form.birthdate)),
        },
        birthdate: {
            required: helpers.withMessage('The birthdate field is required when approximate age month & year is not present.', requiredIf(!form.approximate_age_month && !form.approximate_age_year)),
        },
        tag: {
            required: helpers.withMessage('The tag is required when identity is unknown', requiredIf(form.identity === PerpetratorIdentity.UNKNOWN)),
        },
        contacts: {
            numeric: helpers.withMessage('The contact number must be numeric', numeric),
            minLength: helpers.withMessage('The contact number must be 10 numbers', minLength(10)),
            maxLength: helpers.withMessage('The contact number must be 10 numbers', maxLength(10)),
        },
        other_nationality: {
            required: helpers.withMessage(errorMessage('other nationality'), requiredIf(form.nationality_id == 'others')),
        },
        other_religion: {
            required: helpers.withMessage(errorMessage('other religion'), requiredIf(form.religion_id == 'others')),
        },
    }
}

const errorMessage = (field: any) => {
    return `The ${field} field is required`; 
}

export default {
    searchRules,
    createRules,
}