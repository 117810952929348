<template>
    <user-layout hasBack backTitle="Patient Related Report" backLink="/reports">

        <template #lowerRight>
            <export-button @click="exporting()" />
        </template>

        <div class="-mx-6 w-[calc(100%+32px)] pb-6" v-if="!loading">
            <div class="mx-6 mt-8 border rounded-lg border-neutral-100">
                <div class="">
                    <detail-table 
                        :hasSearch="true"
                        searchPlaceholder="Search Report type name"
                        :headers="
                        filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS 
                        ? (filters.group_category == 1 ? Primaryheaders : (filters.group_category == 2 ? PrimaryunicefHeaders : PrimaryadultHeaders))
                        : filters.group_category == 1 ? headers : (filters.group_category == 2 ? unicefHeaders : adultHeaders)
                        " 
                        :no-action="true"
                        :count="items?.data?.length"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                        :search="filters.query"
                        @update:searchText="(value:string) => (filters.query = value)"
                    >
                        <template #topContent>
                            <div class="flex space-x-2">
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <AdjustmentsVerticalIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Report Type</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">CASE INFORMATION</p>
                                                <radio-group
                                                    :options="reportTypeCaseOptions"
                                                    name="reportTypeCaseOptions"
                                                    customLabelClass="whitespace-nowrap"
                                                    v-model="filters.report_type"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">LOCATION</p>
                                                <radio-group
                                                    :options="reportTypeOptions"
                                                    name="reportTypeOptions"
                                                    customLabelClass="whitespace-nowrap"
                                                    v-model="filters.report_type"
                                                    @update:modelValue="paginate(1, filters.rows)"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">PATIENT INFORMATION</p>
                                                <radio-group
                                                    :options="reportTypePatientOptions"
                                                    name="reportTypePatientOptions"
                                                    customLabelClass="whitespace-nowrap"
                                                    v-model="filters.report_type"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>
                                
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <UserGroupIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Group</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">CATEGORY</p>
                                                <radio-group
                                                    :options="groupOptions"
                                                    name="categoryOptions"
                                                    v-model="filters.group_category"
                                                    @update:modelValue="paginate( 1, 10);"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">SEX</p>
                                                <radio-group
                                                    :options="sexOptions"
                                                    name="sexOptions"
                                                    v-model="filters.sex_at_birth"
                                                    @update:modelValue="paginate( 1, 10);"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100"
                                @click="showFilter = true">
                                    <FunnelIcon class="inline-block w-5 h-5 text-neutral-900" />
                                    <p class="text-sm font-semibold text-neutral-900">Filter</p>
                                </div>
                                
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <ArrowsUpDownIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Sort</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="sampleOptions"
                                                    name="sampleoptions"
                                                    v-model="filters.column"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="defaultOptions"
                                                    name="defaultoptions"
                                                    v-model="filters.order"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <FilterPanel
                                    :show="showFilter"
                                    @close="showFilter = false"
                                    @apply:reset="applyReset()"
                                    @apply:filters="applyFilters()"
                                    customClass="!overflow-visible overflow-x-visible"
                                    >
                                    <template #fields>
                                        <div class="space-y-5">
                                            <date-picker
                                                id="date_created"
                                                name="date_created"
                                                label="Date Created"
                                                v-model="filters.created_at"
                                                range
                                                multiCalendars
                                                class="filter-range"
                                            />
                                            <div class="space-y-5">
                                                <multi-select  
                                                    v-if="
                                                    filters.report_type === ReportTypes.PROVINCE
                                                    || filters.report_type === ReportTypes.CITY
                                                    || filters.report_type === ReportTypes.BARANGAY
                                                    " 
                                                    :options="regions"
                                                    id="region_id"
                                                    name="region_id"
                                                    label="Regions"
                                                    placeholder="Select.."
                                                    v-model="filters.region_id"  
                                                    @update:modelValue="regionChange()"
                                                />
                                                <multi-select   
                                                    v-if=" 
                                                    filters.report_type === ReportTypes.CITY
                                                    || filters.report_type === ReportTypes.BARANGAY
                                                    "
                                                    :options="provinces"
                                                    id="province_id"
                                                    name="province_id"
                                                    label="Provinces"
                                                    placeholder="Select.."
                                                    v-model="filters.province_id"  
                                                    @update:modelValue="provinceChange()" 
                                                />
                                                <multi-select  
                                                    v-if=" 
                                                    filters.report_type === ReportTypes.BARANGAY 
                                                    "  
                                                    :options="cities"
                                                    id="city_id"
                                                    name="city_id"
                                                    label="Cities"
                                                    placeholder="Select.."
                                                    v-model="filters.city_id"  
                                                    @update:modelValue="cityChange()"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </FilterPanel>
                            </div>
                        </template>
                        <template #body>
                            <template v-if="filters.group_category !== 1">
                                <template 
                                    v-for="item in items?.data" 
                                    :key="item"
                                >
                                    <tr>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.name }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set1 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set1 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set1 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set2 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set2 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set2 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set3 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set3 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set3 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set4 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set4 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set4 }}
                                        </td> 
                                        <!-- TODO hide when filtered in Group By Age -->
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                           {{ item.total_male}}
                                        </td> 
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.total_female}}
                                        </td> 
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.total }}
                                        </td>
                                    </tr>
                                </template>
                                <tr class="bg-gray-50">
                                    <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                        Total
                                    </td>
                                    <template v-for="(total, index) in totals" :key="index">
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            {{ total }}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                            <template v-else>
                                <template 
                                    v-for="item in items?.data" 
                                    :key="item"
                                >
                                    <tr>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.name }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set1 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set1 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set1 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set2 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set2 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set2 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set3 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set3 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set3 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set4 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set4 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set4 }}
                                        </td> 
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set5 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set5 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set5 }}
                                        </td> 
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.male_set6 }}
                                        </td>
                                        <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.female_set6 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap" >
                                            {{ item.set6 }}
                                        </td> 
                                        <!-- TODO hide when filtered in Group By Age -->
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.total_male}}
                                        </td> 
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                             {{ item.total_female}}
                                        </td> 
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.total }}
                                        </td>
                                    </tr>
                                </template>
                                <tr class="bg-gray-50">
                                    <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                        Total
                                    </td>
                                    <template v-for="(total, index) in totals" :key="index">
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            {{ total }}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </template>
                    </detail-table>
                    <div class="w-full px-6 border-t py-7">
						<simple-pagination 
							:numRows="filters.rows" 
                            :currentPage="items?.current_page" 
                            :last-page="items?.last_page"
                            @update="(value: any) => paginate(value.page, value.rows)" 
						/>
					</div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="loading"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
    </user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DetailTable from '@/components/partials/DetailTable.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { AdjustmentsVerticalIcon, UserGroupIcon, FunnelIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import ReportService from "@/classes/Reports/ReportService";
import MultiSelect from '@/components/inputs/MultiSelect.vue';
import DateFormatter from '@/helpers/DateFormatter';
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { ReportTypes } from '@/views/enums/ReportTypes';
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import DropdownService from "@/classes/Dropdowns/DropdownService";
import { PrimaryDiagnosisReportTypes } from '@/views/enums/PrimaryDiagnosisReportTypes';
const regions = ref<renderSelect[]>([]);
const cities = ref<renderSelect[]>([]);
const provinces = ref<renderSelect[]>([]);
const barangays = ref<renderSelect[]>([]);
const showFilter = ref<boolean>(false); 
const loading = ref<boolean>(false);

const filters = ref({
    rows: 10,
    query: null,
    report_type: ReportTypes.COMORBIDITY_VAC_VAW,
    group_category: 1,
    sex_at_birth: 2,
    created_at: null,
    column: 'name',
    order: 'ASC',
    page: 1,
    //filters for report
    region_id: null,
    province_id: null,
    city_id: null,
    barangay_id: null,
})

const sampleOptions = [
    { id: '1', label: 'Name', value: 'name'},
    { id: '2', label: 'Count', value: 'total'},
]

const defaultOptions = [
    { id: '3', label: 'Ascending', value: 'ASC'},
    { id: '4', label: 'Descending', value: 'DESC'},
]

const categoryOptionsVac = [
    { id: "1", label: "CPN/Children", value: 1 },
]
const categoryOptionsVaw = [
    { id: "3", label: "Adult Patient", value: 3 },
]
const defaultCategoryOptions = [
    { id: "1", label: "CPN/Children", value: 1 },
    // { id: "2", label: "UNICEF/Children", value: 2}, //Hide as per client
    { id: "3", label: "Adult Patient", value: 3 },
];

const sexOptions = ref({});

const defaultSexOptions = [
    { id: "1", label: "All", value: 2 },
    { id: "2", label: "Male", value: 0 },
    { id: "3", label: "Female", value: 1 },
];
const sexOptionVaw = [ 
    { id: "3", label: "Female", value: 1 },
];
const groupOptions = ref({}); 

const reportTypeOptions = [
    { id: "9", label: "Barangay", value: ReportTypes.BARANGAY },
    { id: "10", label: "City", value: ReportTypes.CITY },
    { id: "11", label: "Province", value: ReportTypes.PROVINCE },
    { id: "12", label: "Region", value: ReportTypes.REGION },
    { id: "13", label: "Office", value: ReportTypes.OFFICE },
];
    
const reportTypePatientOptions = [
    // { id: "14", label: "Nationality", value: ReportTypes.NATIONALITY },
    // { id: "15", label: "Religion", value: ReportTypes.REGION },
    { id: "16", label: "Indigenous Group", value: ReportTypes.INDIGENOUS_GROUP },
];

const reportTypeCaseOptions = [
    // { id: "1", label: "Primary Diagnosis", value: ReportTypes.PRIMARY_DIAGNOSIS }, //Remove 082124
    { id: "2", label: "Comorbidity", value: ReportTypes.COMORBIDITY_VAC_VAW }, //confirm what type of report if by disaggregated age or not
    
    //hide but might needed in the future
    // { id: "3", label: "VAC", value: ReportTypes.VAC }, 
    // { id: "4", label: "VAW", value: ReportTypes.VAW }, 

    { id: "5", label: "PWD", value: ReportTypes.PWD },
    { id: "6", label: "Mental Health Diagnosis", value: ReportTypes.MENTAL_HEALTH },
    { id: "7", label: "Suicidal Risk", value: ReportTypes.SUICIDAL_RISK },
    { id: "8", label: "Perpetrator Relation", value: ReportTypes.PERPETRATOR_RELATION },

    { id: "17", label: "Suicidal Behavior", value: ReportTypes.SUICIDAL_BEHAVIOR },
    { id: "18", label: "Non Suicidal Behavior", value: ReportTypes.NON_SUICIDAL_BEHAVIOR },
];

const headers = ref([
    { text: 'Comorbidity' },
    { text: '0-3' },
    { text: '4-6' },
    { text: '7-9' },
    { text: '10-12' },
    { text: '13-15' },
    { text: '16-18' },
    { text: 'M' },
    { text: 'F' },
    { text: 'Total' },
]);
const unicefHeaders = ref([
    { text: 'Comorbidity' },
    { text: '0-9' },
    { text: '10-14' },
    { text: '15-17' },
    { text: '18+' },
    { text: 'M' },
    { text: 'F' },
    { text: 'Total' },
]);

const adultHeaders = ref([
{ text: 'Comorbidity' },
    { text: '19-24' },
    { text: '25-44' },
    { text: '45-59' },
    { text: '60-100+' },
    { text: 'M' },
    { text: 'F' },
    { text: 'Total' },
]);

const Primaryheaders = ref([
    { text: 'Primary Diagnosis', colspan: 1, rowspan: 2 },
    { text: '0-3', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '4-6', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '7-9', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '10-12', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '13-15', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '16-18', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: 'Total', colspan: 1, rowspan: 2 }
]);

const PrimaryunicefHeaders = ref([
    { text: 'Primary Diagnosis', colspan: 1, rowspan: 2 },
    { text: '0-9', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '10-14', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '15-17', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '18+', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] }, 
    { text: 'Total', colspan: 1, rowspan: 2 } 
]);

const PrimaryadultHeaders = ref([
    { text: 'Primary Diagnosis', colspan: 1, rowspan: 2 },
    { text: '19-24', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '25-44', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '45-59', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] },
    { text: '60-100+', colspan: 3, rowspan: 1, subheaders: ['M', 'F', 'Total'] }, 
    { text: 'Total', colspan: 1, rowspan: 2 }  
]);

const items = ref()
const totals = ref()

const paginate = ( page:number, rows:number )=>{
    ReportService.paginate(page, rows,filters.value, getData)
}

const applyFilters = (search: any = null) => {
	showFilter.value = false;
    console.log(filters.value);
    paginate( 1, filters.value.rows);   
};

const applyReset = () => {
    showFilter.value = false;
    filters.value.created_at = null; 
    filters.value.region_id = null;
    filters.value.province_id = null;
    filters.value.city_id = null;
    paginate( 1, filters.value.rows);
};

const getData = () => { 
    loading.value = true;
    ReportService.index('patient-related', omitBy(filters.value, isNil))
        .then(({ data }) => {  
            items.value = data.items.data;
            totals.value = data.items.totals;  
        })
        .catch(errors => { 
            console.log(errors);
        })
        .finally(() => loading.value = false)
}

const exporting = (): void => { 
    loading.value = true;
    ReportService.export('patient-related',omitBy(filters.value, isNil))
        .then(({data})=>{
             ReportService.downloadExport("patient-related-export.xlsx",data);  
        })
        .finally(() => loading.value = false)
}


const getReportTypeHeader = (type: any)=>{
    reportTypeOptions.filter(function(item:any){
        if(item.value === type){
            headers.value[0].text = item.label;
            unicefHeaders.value[0].text = item.label
            adultHeaders.value[0].text = item.label
        } 
    });
    reportTypePatientOptions.filter(function(item:any){
        if(item.value === type){
            headers.value[0].text = item.label;
            unicefHeaders.value[0].text = item.label
            adultHeaders.value[0].text = item.label
        } 
    });
    reportTypeCaseOptions.filter(function(item:any){
        if(item.value === type){
            headers.value[0].text = item.label;
            unicefHeaders.value[0].text = item.label
            adultHeaders.value[0].text = item.label
        } 
    });
} 


const setOptionsVac = () => {
    filters.value.group_category = 1;
    groupOptions.value = categoryOptionsVac; 
}
const setOptionsVaw = () => {
    filters.value.group_category = 3;
    filters.value.sex_at_birth = 1;
    sexOptions.value = sexOptionVaw; 
    groupOptions.value = categoryOptionsVaw; 
}

const setCategoryOptions = (reportType = filters.value.report_type) => { 
    if(reportType === ReportTypes.VAC){
        setOptionsVac();
        console.log(groupOptions.value);
    }else if (reportType === ReportTypes.VAW){
        setOptionsVaw();
        console.log(groupOptions.value);
    }else{
        filters.value.sex_at_birth = 2;
        filters.value.group_category = 1; 
        groupOptions.value = defaultCategoryOptions;
        sexOptions.value = defaultSexOptions; 
    }
    getData();
}



const getRegions = async (): Promise<any> => { 
    DropdownService
    .regions()
    .then(({data}) => {
        regions.value = data;
    }) 
}

const getProvince = async (): Promise<any> => {
    if(filters.value.region_id){ 
        DropdownService
        .provinces(filters.value.region_id)
        .then(({data}) => {
            provinces.value = data;
        }) 
    }
}

const getCity = async (): Promise<any> => {
    if(filters.value.province_id){ 
        DropdownService
        .cities(filters.value.province_id)
        .then(({data}) => {
            cities.value = data;
        }) 
    }
}

const getBrangay = async (): Promise<any> => { 
    if(filters.value.city_id){ 
        DropdownService
        .barangays(filters.value.city_id)
        .then(({data}) => {
            barangays.value = data;
        }) 
    }
}
const regionChange = ():void => {
    filters.value.city_id = null
    filters.value.barangay_id = null
    filters.value.province_id = null
    getProvince()
}

const provinceChange = ():void => {
    filters.value.city_id = null
    filters.value.barangay_id = null
    getCity()
}

const cityChange = ():void => { 
    filters.value.barangay_id = null 
    getBrangay()
}

watch(
    () => filters.value.query,
    debounce(() => {
        paginate( 1, filters.value.rows);
        applyReset();
    }, 500)
);

watch(
    () => filters.value.report_type,
    debounce(() => {
        getReportTypeHeader(filters.value.report_type); 
        setCategoryOptions(filters.value.report_type);
    }, 500)
);

onMounted(() => {
    getRegions()
    setCategoryOptions(ReportTypes.PRIMARY_DIAGNOSIS);
    getData()
})

</script>