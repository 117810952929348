import { defineStore } from 'pinia'
import Storage from '@/helpers/storage';

interface INetworkState {
    isNetworkAvailable: boolean,
    isActualNetworkAvailable: boolean,
}

const storage = new Storage();

export const useNetworkStore = defineStore({
    id: 'network',
    state: (): INetworkState => {
        let status = eval(storage.get('isNetworkAvailable')) ? true : false

        // override network status if has pending offline drafts
        if (eval(storage.get('hasDraft'))) {
            status = false;
        }

        return {
            isNetworkAvailable: status,
            isActualNetworkAvailable: eval(storage.get('actualNetworkStatus'))
        }
    },
    getters: {},
    actions: {},
})