import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";

export const case_remarks:SchemaTableOptions = {
    name:<string> 'case_remarks',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'cases_id', value: 'INTEGER UNSIGNED'},
        { column: 'remarks', value: 'TEXT'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
    ]
}