<template>
    <div class="w-full">
        <!-- View Mode -->
        <div v-if="isView" class="grid items-start grid-cols-12 gap-6">
            <div class="col-span-12 p-4">
                <p class="mb-3 font-medium text-neutral-300">
                    {{ form.date_time_formatted }}
                </p>
                <p v-if="!form.meeting_minutes" class="text-neutral-600">No minutes of the meeting yet.</p>
                <div v-else v-html="form.meeting_minutes" class="frm-description"></div>
            </div>
        </div>

        <!-- Create and Edit Mode -->
        <div v-if="!isView" class="grid grid-cols-12 gap-6">
            <div class="col-span-12">
                <ck-editor
                    v-model="form.meeting_minutes"
                ></ck-editor>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import CkEditor from '@/components/inputs/CKEditor.vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    isView: {
        type: Boolean,
        default: false
    }
});
const emit = defineEmits(['update:form']);
const form = ref(props.form)
watch(
    form.value,
    () => {
        emit('update:form', form.value);
    }
);
</script>