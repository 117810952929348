import http from "@/axios";
import Relationships from "../Forms/Relationships";
import { SyncStatus } from "@/views/enums/SyncStatus";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";

const relationship = new Relationships();

const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const table = 'case_conference_recommendations';

export default class SyncCaseConferenceRecommendations {
    resetItemData(item: any) {
        item.synching_remarks = null;
        item.is_sync_failed = false;
        item.is_sync = true;
        item.is_created_offline = false;
        item.is_updated_offline = false;
        item.is_deleted_offline = false;

        return item;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error.message || 'System Error';
        item.is_sync_failed = true;

        return item;
    }

    async render(isRenderData = false): Promise<any> {
        const items = [];

        let hasError = false;

        const recommendations = await sqlite.db.select(`SELECT * FROM ${table} WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC`);
        
        if (recommendations.length > 0) {
            for (const index in recommendations) {

                const recommendation = recommendations[index];

                const conferenceResponse = await sqlite.db.select(`SELECT * FROM case_conferences WHERE id=${recommendation.case_conference_id}`);
                
                if(conferenceResponse.length > 0){
                    const conference = conferenceResponse[0];

                    const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${conference.cases_id}`);
                    const casesData = cases.length > 0 ? cases[0] : {};

                    const form:any = {...conference};

                    form.type = "Case Conference Information Recommendation"
                    form.table = "case_conference_recommendations";

                    form.cases_id = conference.cases_id;
                    form.patient_id = conference.patient_id;
                    form.patient_name = `${casesData.first_name} ${casesData.last_name}`;
                    form.birthdate_formatted = relationship.dateFormat(casesData.birthdate);
                    form.created_at_formatted = relationship.dateFormat(conference.updated_at);

                    form.url_view = `/patient/${conference.patient_id}/cases/${casesData.id}/case-conference/information/${conference.id}/edit`;
                    form.can_view =  true;
                    
                    form.status = recommendation.is_sync_failed == false || recommendation.is_sync_failed == undefined 
                        ? (recommendation.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                        : SyncStatus.RECORD_CONFLICT;

                    if (isRenderData) {
                        items.push(form);
                    }

                    // do something for online synching
                    if (!isRenderData) {
                        hasError = await this.processSync(recommendation, form);
                    }
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async processSync(item: any, form: any): Promise<any> {

        if (item.is_deleted_offline) {
            return await this.processDelete(item, form);
        }

        if (item.is_created_offline) {
            return await this.processCreate(item, form);
        }

        if (item.is_updated_offline) {
            return await this.processUpdate(item, form);
        }

        return false;
    }

    async processUpdate(item: any, form:any): Promise<any>
    {
        const onlineSync = new OnlineSynching;
        const response = await http.post(`/sync/case-conference/updateRecommendations/${item.id}`, item).catch(error => error);

        if (response?.status === 200) {
            await onlineSync.resetItemData(table, item.id);
            return false;
        } else {
            return await this.rollback(item, response);
        }
    }

    async processCreate(item: any, form:any): Promise<any>
    {
        const onlineSync = new OnlineSynching;
        try {     
            const response = await http.post(`/sync/case-conference/storeRecommendations/${item.case_conference_id}`, item).catch(error => error);
    
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.data.item.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                return false;
            } else {
                return await this.rollback(item, response);
            }
        } catch (error) {
            console.log(error);
        }

    }

    async processDelete(item: any, form:any): Promise<any>
    {
        const onlineSync = new OnlineSynching;
        const response = await http.post(`/sync/case-conference/delete/${item.id}`, form).catch(error => error);
        if (response?.status === 200) {
            await onlineSync.removeRecord(table, item.id);
            return false;
        } else {
            return await this.rollback(item, response);
        }
    }
}