import http from "@/axios"; 
import OfflineProcessor from "./Processor/OfflineProcessor";
import FormOptions  from "@/classes/Forms/FormOptions" 
class ContactNumberService{
    /**
     * Render index page
     * @param patient 
     * @param cases_id 
     * @param payload 
     * @param networkAvailable 
     * @returns 
     */
   async index(patient: number, patientCase: number,networkAvailable:boolean, payload: any ): Promise<any> {
       if(networkAvailable){
            return await http.get(`patients/${patient}/cases/${patientCase}/intakeForm/contactNumber`, { params: payload });
       }else{ 
           const data = await OfflineProcessor.index(patient, patientCase); 
           return {
               data: {
                   ...data.data,
               }
           };
       }
   } 

   /**
    * Store data to storage
    * @param patient
    * @param patientCase
    * @param payload
    * @param networkAvailable
    * @returns
    */
   async store(patient: any, patientCase: any,  networkAvailable:boolean, payload: any,): Promise<any> {
       if(networkAvailable){
            return await http.post(`patients/${patient}/cases/${patientCase}/intakeForm/contactNumber`, payload);
       }else{    
           return await OfflineProcessor.updateCreate(patient, patientCase, payload);
       }
   }

   /**
    * Update item from storage
    * @param patient 
    * @param patientCase 
    * @param patientContactNumber 
    * @param networkAvailable 
    * @param payload 
    * @returns 
    */
   async update(patient: number, patientCase: number,patientContactNumber: number,  networkAvailable:boolean, payload: any ): Promise<any> {
        if(networkAvailable){
            return await http.put(
                `patients/${patient}/cases/${patientCase}/intakeForm/contactNumber/${patientContactNumber}/update`,
                payload
            ); 
        }else{    
            return await OfflineProcessor.updateCreate(patient, patientCase, payload);
        }
    }

   /**
    * Remove data from storage
    * @param patient 
    * @param patientCase 
    * @param guardian 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async delete(
       patient: number,
       patientCase: number,
       patientContactNumber: number,
       networkAvailable:boolean,
       payload: any,
   ): Promise<any> {
       if(networkAvailable){
            return await http.delete(
                `patients/${patient}/cases/${patientCase}/intakeForm/contactNumber/${patientContactNumber}/delete`,
                payload
            );
       }else{ 
          //just updating the status of deleted at for syncing
        return await OfflineProcessor.delete(patientContactNumber, patientCase);

       }
   }
}


export default new ContactNumberService();