<template>
    <user-layout hasBack backTitle="Case Related Reports" backLink="/reports">

        <template #lowerRight>
            <export-button @click="exporting()" />
        </template>

        <div class="-mx-6 w-[calc(100%+32px)] pb-6" v-if="!loading">
            <div class="mx-6 mt-8 border rounded-lg border-neutral-100">
                <div class="">
                    <data-table 
                        :hasSearch="true"
                        searchPlaceholder="Search Report type name, Case No., Patient"
                        :headers="headers" 
                        :no-action="true"
                        :count="items?.data?.length ?? 0"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                        :search="filters.query"
                        @update:searchText="(value:string) => (filters.query = value)"
                    >
                        <template #topContent>
                            <div class="flex space-x-2"> 
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <AdjustmentsVerticalIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Report Type</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="reportTypeOptions"
                                                    name="reportTypeOptions"
                                                    v-model="filters.report_type"
                                                    customLabelClass="whitespace-nowrap"
                                                    @update:modelValue="paginate(1, filters.rows)"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>
                                
                                <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100"
                                @click="showFilter = true">
                                    <FunnelIcon class="inline-block w-5 h-5 text-neutral-900" />
                                    <p class="text-sm font-semibold text-neutral-900">Filter</p>
                                </div>
                                
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <ArrowsUpDownIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Sort</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="sortOptions"
                                                    v-model="filters.column"
                                                    name="sortOptions"
                                                    @update:modelValue="fetch()"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="orderOptions"
                                                    v-model="filters.order"
                                                    name="orderOptions"
                                                    @update:modelValue="fetch()"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <FilterPanel
                                    :show="showFilter"
                                    @close="showFilter = false"
                                    @apply:reset="applyReset()"
                                    @apply:filters="applyFilters()"
                                    customClass="!overflow-visible overflow-x-visible"
                                    >
                                    <template #fields>
                                        <div class="space-y-5">
                                             <date-picker
                                                id="date_created"
                                                name="date_created"
                                                label="Date Created"
                                                v-model="filters.created_at"
                                                range
                                                multiCalendars
                                                class="filter-range"
                                            />
                                            <!-- <date-picker
                                                id="date_created"
                                                name="date_created"
                                                label="Date Created"
                                                v-model="filters.created_at"
                                                range
                                                multiCalendars
                                                @update:modelValue="(value:any) => (
                                                    filters.created_at[0] =  DateFormatter.customDateFormat(value[0], `yyyy-MM-dd HH:mm:ss`) ,
                                                    filters.created_at[1] =  DateFormatter.customDateFormat(value[1], `yyyy-MM-dd HH:mm:ss`) 
                                                )"
                                                class="custom-range"
                                            /> -->
                                        </div>
                                    </template>
                                </FilterPanel>
                            </div>
                        </template>
                        <template #body> 
                            <template 
                                v-for="item in items.data" 
                                :key="item"
                            >
                                <tr>
                                    <td v-if="filters.report_type == ReportTypes.INDIGENOUS_GROUP" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.indigenous_group }}
                                    </td>
                                    <td v-if="filters.report_type == ReportTypes.PRIMARY_DIAGNOSIS" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.primary_diagnosis }}
                                    </td>
                                    <td v-if="filters.report_type == ReportTypes.COMORBIDITY_VAC_VAW" class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        <p class="relative group cursor-help">
                                            {{ truncateItem(item.comorbid_vac_vaw,15) }}

                                            <span class="absolute z-[1] top-5 left-0 w-[800px] rounded group-hover:block hidden whitespace-normal bg-slate-200 p-3">
                                                {{ item.comorbid_vac_vaw }}
                                            </span>
                                        </p>
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.case_no }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.patient }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.gender }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.birthdate }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.age }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ DateFormatter.customDateFormat(item.created_at, "MMM dd, yyy") }}
                                    </td> 
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.assigned_social_worker }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.assigned_physician }}
                                    </td>
                                </tr>
                            </template>  
                        </template>
                    </data-table>
                    <div class="w-full px-6 border-t py-7">
                        <simple-pagination 
                            :numRows="filters.rows ?? 10" 
                            :currentPage="items?.current_page" 
                            :last-page="items?.last_page"
                            @update="(value: any) => paginate(value.page, value.rows)" 
                        />
                    </div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="loading"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
    </user-layout>
</template>
<script setup lang="ts">
import { ref, watch, onMounted,computed } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DataTable from '@/components/partials/DataTable.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { AdjustmentsVerticalIcon, UserGroupIcon, FunnelIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import { Popover, PopoverButton, PopoverPanel, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import ReportService from '@/classes/Reports/ReportService'; 

import DateFormatter from '@/helpers/DateFormatter';
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import debounce from "lodash/debounce";
import { ReportTypes } from '@/views/enums/ReportTypes';

const reportType = ref<string>("Primary Diagnoses");
const showFilter = ref<boolean>(false);
const showSync = ref<boolean>(false);
const loading = ref<boolean>(false);

const enabled = ref<boolean>(false); 
const filters = ref({
    query: null,
    rows: 10,
    created_at: null,
    order: 'asc',
    column: '',
    report_type: ReportTypes.PRIMARY_DIAGNOSIS, 
    page: 1
})
 
const sortOptions = [
    { id: '1', label: 'Name', value: 'patient'},  
]
const orderOptions = [
    { id: '3', label: 'Ascending', value: 'asc'},
    { id: '4', label: 'Descending', value: 'desc'},
]

const reportTypeOptions = [
    { id: "1", label: "Primary Diagnosis", value: ReportTypes.PRIMARY_DIAGNOSIS },
    // { id: "2", label: "Indigenous Group", value: ReportTypes.INDIGENOUS_GROUP },
    { id: "3", label: "Comorbid VAC/VAW", value: ReportTypes.COMORBIDITY_VAC_VAW },
];
 
const headers  = ref([
    { text:  "Primary Diagnosis"},
    { text: 'Case Number' },
    { text: 'Patient' },
    { text: 'Sex' },
    { text: 'Birthday' },
    { text: 'Age' },
    { text: 'Date Registered' }, 
    { text: 'Assigned Social Worker' }, 
    { text: 'Assigned Physician' }, 
]); 

const paginate = ( page:number, rows:number )=>{
    ReportService.paginate(page, rows,filters.value, fetch)
}

const applyFilters = (search: any = null) => {
    showFilter.value = false;
    paginate( 1, filters.value.rows);
};

const applyReset = () => {
    showFilter.value = false;
    filters.value.created_at = null; 
    paginate( 1, filters.value.rows);
};

const items = ref() 

const fetch = () => { 
    loading.value = true;
    ReportService.index('case-related-specific', omitBy(filters.value, isNil))
        .then(({ data }) => { 
            items.value = data   
        })
        .catch(errors => { 
            console.log(errors)
        })
        .finally(() => loading.value = false)
}

const exporting = (): void => {
    loading.value = true;
    ReportService.export('case-related-specific',omitBy(filters.value, isNil))
        .then(({data})=>{ 
             ReportService.downloadExport("case-related-specific-export.csv",data);  
        })
        .finally(() => loading.value = false)
}

const truncateItem = (item:any, strLen:number)=>{
    if(item && item.length > strLen){
        return item.substring(0,strLen) + "..."
    }else{
        return item;
    }
}
const getReportTypeHeader = (type: any)=>{
    reportTypeOptions.filter(function(item:any){
        if(item.value === type){
            headers.value[0].text = item.label; 
        } 
    });
} 

watch(
    () => filters.value.query,
    debounce(() => {
        paginate( 1, filters.value.rows);
        applyReset();
    }, 500)
);
 
watch (
    ()=> filters.value.report_type,
    ()=>{ 
        getReportTypeHeader(filters.value.report_type); 
    }
)

onMounted(()=> { 
    fetch(); 
})

</script>