import { EnumsInterface } from "@/interfaces/EnumsInterface";

const ReportTypeDiagnosis:EnumsInterface[] = [
    { id: 1, label: "Suicidal Risk", value: 1 },
    { id: 2, label: "Perpetrator Relation", value: 2 },
    { id: 3, label: "Referral Source", value: 3 },
    { id: 4, label: "Pwd", value: 4 },
    { id: 5, label: "Comorbid", value: 5 },
    { id: 6, label: "Barangay", value: 6 },
    { id: 7, label: "City", value: 7 },
    { id: 8, label: "Province", value: 8 },
    { id: 9, label: "Region", value: 9 },
    { id: 10, label: "Office", value: 10 },
]

export default ReportTypeDiagnosis