import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../../defaultMigration";


export const intake_incest_cases: SchemaTableOptions = {
   name:<string> 'intake_incest_cases',
   schema:<Array<SchemaColumnOptions>> [
       { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
       { column: 'cases_id', value: 'INTEGER UNSIGNED'},
       { column: 'sleeping_arrangement', value: 'INTEGER UNSIGNED'},
       { column: 'others', value: 'VARCHAR(255) DEFAULT NULL'}, 
       { column: 'same_bed_with', value: 'TEXT DEFAULT NULL'}, 
       { column: 'same_room_with', value: 'TEXT DEFAULT NULL'}, 
       { column: 'abused_living_arrangement', value: 'TEXT DEFAULT NULL'}, 
       { column: 'present_living_arrangement', value: 'TEXT DEFAULT NULL'}, 
       { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
       ...defaultMigration
   ]
}
