import Syncable from "@/extenders/models/Syncable";
import CaseOfflineForm from "../Formatters/CaseOfflineForm";
import SqliteProcessor from "@/processors/SqliteProcessor";
import CaseComorbitiesProcessor from "./CaseComorbitiesProcessor";
import CaseMentalHealthProcessor from "./CaseMentalHealthProcessor";
import CaseDiagnosesProcessor from "./CaseDiagnosesProcessor";
import CaseReferralServicesProcessor from "./CaseReferralServicesProcessor";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import PatientOfflineForm from "@/classes/Patients/Offline/Formatters/PatientOfflineForm";
import CaseRemarksProcessor from "./CaseRemarksProcessor";
import CaseComorbiditiesOfflineForm from "../Formatters/CaseComorbiditiesOfflineForm";
import Relationships from "@/classes/Forms/Relationships";
import CaseConferenceProcessor from "./OtherInformation/CaseConferenceProcessor";
import PerpetratorProcessor from "@/classes/Perpetrators/Offline/Processor/PerpetratorProcessor";
import CaseMentalHealthOfflineForm from "../Formatters/CaseMentalHealthOfflineForm";
import CaseInformantOfflineProcessor from "@/classes/Cases/CaseInformant/Offline/Processor/OfflineProcessor";
import CaseGuardianOfflineProcessor from "@/classes/Cases/CaseGuardian/Offline/Processor/OfflineProcessor";
import InterventionOfflineService from "@/classes/Interventions/Classes/OfflineService";
import CasePatientAddressOfflineProcessor from "@/classes/Cases/CasePatientAddress/Offline/Processor/OfflineProcessor";
import DefaultFormStructureBuild from "../Formatters/DefaultFormStructureBuild";
import SafetyAssessmentOfflineProcessor from "@/classes/Cases/CaseImmediateAssessment/Processor/OfflineProcessor";

const sqlite = new SqliteProcessor;
const relationship = new Relationships();
const table = 'cases';

class CaseOfflineProcesssor extends Syncable{
    async syncToOffline(payload: any, isPayloadObject = false) {
        this.table = 'cases';
        if (!isPayloadObject) {
            // Process collection cases
            const items = payload.cases.data;

            for(const index in items) {
                const casesIsNotSync = await this.checkUnsyncCases(items[index].id);
                if (!casesIsNotSync) {
                    const form = CaseOfflineForm.renderSyncToOffline(items[index]);
                    await this.sync(sqlite.db, [DefaultFormStructureBuild.resetStatus(form)]);
                    await this.processCaseRelationships(items[index].id, items[index], true)
                }
            }
        } else {
            // Process single cases
            const form = CaseOfflineForm.renderSyncToOffline(payload);
            await this.sync(sqlite.db, [DefaultFormStructureBuild.resetStatus(form)]);
            await this.processCaseRelationships(payload.id, payload);
        }
    }

    async processCaseRelationships(case_id: number, payload: any, fromCollection = false) {
        const comorbidities =  payload.comorbidities.map((item: any) => { 
            return { comorbidity_id: item.id };
        });
        await CaseComorbitiesProcessor.store(case_id, comorbidities);
        await CaseMentalHealthProcessor.store(
            case_id,
            CaseMentalHealthOfflineForm.getMentalHealthPayload(fromCollection, payload)            
        );
        await CaseDiagnosesProcessor.store(
            case_id, 
            payload.diagnoses || payload
        );
        await CaseReferralServicesProcessor.store(
            case_id, 
            payload.referral_service || payload
        );
    }

    async viewCaseCardHistory(patient: number, payload: any) {
        this.table = 'cases';
        const result = await this.paginate(sqlite.db, payload, (query: string) => {
            let sql = ` WHERE patient_id=${patient}`;
            if (payload.query) {
                sql += ` AND case_number LIKE '%${payload.query}%'`;
            }

            return sql;
        });

        const arr = [];
        for (const index in result.data) {
            arr.push(await CaseOfflineForm.renderCaseCardHistory(result.data[index]))
        }

        result.data = arr;
        return result;
    }

    async store(payload: any) {
        const casesForm = CaseOfflineForm.store(
            this.setOfflineCaseNumber(payload)
        );
        this.table = 'cases';
        // store patient cases
        await this.sync(sqlite.db, [casesForm]);
        const case_id = await this.getLatestStoredCase(payload.patient_id);
        if (case_id) {
            await this.storeCaseRelationships(case_id.id, payload);
        }
    }

    async storeCaseRelationships(case_id: number, payload: any) {
        await CaseComorbitiesProcessor.store(case_id, payload.comorbidities);
        await CaseMentalHealthProcessor.store(case_id, payload.mental_health);
        await CaseDiagnosesProcessor.store(case_id, payload);
        await CaseReferralServicesProcessor.store(case_id, payload);
        await CaseRemarksProcessor.store(case_id, payload.remarks);
    }

    async storePatientCase(patient: number, payload: any) {
        await sqlite.connect();
        // update patient information
        const form = PatientOfflineForm.renderSyncToOffline(payload);
        this.table = 'patients';
        await this.sync(sqlite.db, [form]);

        payload.id = null;
        payload.patient_id = patient;
        await this.store(payload);

        return {
            patient: await PatientOfflineProcessor.getLatestStoredPatient(patient),
            case_details: await this.getLatestStoredCase(patient)
        };
    }

    async getLatestStoredCase(patient: number) {
        const response = await sqlite.db.select(`SELECT * FROM cases WHERE patient_id=${patient} ORDER BY id DESC LIMIT 1`);
        if (response.length > 0) {
            return response[0];
        }
        return null;
    }

    async editCase(case_id: number) {
        const response = await sqlite.db.select(`SELECT * FROM cases WHERE id=${case_id} ORDER BY id DESC LIMIT 1`);
        if (response.length > 0) {
            const item = response[0];
            item.comorbidities = CaseComorbiditiesOfflineForm.renderCaseHistoryCard(
                await CaseComorbitiesProcessor.getCardCaseHistory(item.id)
            );
            item.diagnoses = await CaseDiagnosesProcessor.view(item);
            item.mental_health_diagnoses = await CaseMentalHealthProcessor.view(item) || [];
            item.referral_service = await CaseReferralServicesProcessor.view(item);
            item.remarks = await CaseRemarksProcessor.view(item) || [];
            return await this.transformEditCase(item);
        }
        return null;
    }

    async updateCase(patient: number, case_id: number, payload: any) {
        await sqlite.connect();
        // update patient information
        await PatientOfflineProcessor.syncToOffline(payload, true);
        const casesForm = CaseOfflineForm.update(payload);
        this.table = 'cases';
        // store patient cases
        await this.sync(sqlite.db, [casesForm]);
        await this.storeCaseRelationships(case_id, payload);
    }

    private setOfflineCaseNumber(payload: any) {
        payload.case_number = 'Offline(No Case Number)';
        return payload;
    }

    private async transformEditCase(item: any) {
        item.name = relationship.fullname(item);
        item.computed_age = PatientOfflineForm.renderComputedAge(item);
        item.sex_at_birth_meta = relationship.sexAtBirth(item);
        item.gender_meta = relationship.gender(item);
        item.indigeneous = await relationship.indigeneous(item);
        item.pwd_meta = relationship.pwd(item);
        item.initiated_at_formatted = relationship.dateFormat(item.initiated_at, 'MMMM dd, yyyy') || "";
        item.incident_time_meta = relationship.incident_time(item.incident_time);
        item.diagnoses = await CaseDiagnosesProcessor.renderDiagnoses(item.diagnoses);
        item.mental_health_diagnoses = await this.transformMentalHealth(item.mental_health_diagnoses);
        item.referral_service = await CaseReferralServicesProcessor.renderReferralService(item.referral_service);
        item.hospital_area = await relationship.hospitalAreas(item.hospital_area_id);
        item.child_type_meta = relationship.child_type(item.child_type);
        item.educational_attainment = await relationship.educational_attainment(item);
        item.nationality = await relationship.nationality(item.nationality_id);
        item.civil_status = await relationship.civil_status(item);
        item.religion = await relationship.religion(item);
        item.employment_status = await relationship.employment_status(item);
        return await this.getCaseOtherInformationCounts(item);
    }

    private async getCaseOtherInformationCounts(item: any) {
        item.case_conferences_count = await CaseConferenceProcessor.getCount(item.id);
        item.companions_count = await CaseInformantOfflineProcessor.getCount(item.id);
        item.guardians_count = await CaseGuardianOfflineProcessor.getCount(item.id)
        item.interventions_count = await InterventionOfflineService.getCount(item.id);
        item.patient_addresses_count = await CasePatientAddressOfflineProcessor.getCount(item.id);
        item.perpetrators_count = await PerpetratorProcessor.getCount(item.id);
        item.assessments = await SafetyAssessmentOfflineProcessor.getItem(item.id);
        return item;
    }

    private async transformMentalHealth(items: any) {
        const arr = [];
        for (const index in items) {
            const item = items[index];
            arr.push({
                ...item,
                mental_health: await relationship.mental_health_diagnoses(item.mental_health_diagnoses_id),
                type_meta: item.type ? relationship.behavior(item.type) : '',
                behavior_type: await relationship.behavior_types(item),
            });
        }

        return arr;
    }

    async getCase(case_id: number) {
        const response = await sqlite.db.select(`SELECT * FROM cases WHERE id=${case_id}`);
        if (response.length > 0) {
            return response[0];
        }
        return null;
    }

    async viewPerpetratorCaseCardHistory(cases_id: Array<[]> = [], payload: any) {
        cases_id = cases_id.filter((case_id: any) => case_id != null);
        if (cases_id.length === 0) {
            return [];
        }   

        this.table = 'cases';
        const result = await this.paginate(sqlite.db, payload, (query: string) => {
            let sql = ` WHERE id IN(${cases_id.join(',')})`;
            if (payload.query || payload.case_number) {
                sql += ` AND case_number LIKE '%${payload.query}%'`;
            }
            return sql;
        });

        const arr = [];
        for (const index in result.data) {
            arr.push(await CaseOfflineForm.renderCaseCardHistory(result.data[index]))
        }

        result.data = arr;
        return result;
    }

    async checkUnsyncCases(cases: number) {
        if (cases) {
            const response = await sqlite.db.select(`SELECT * FROM cases WHERE id=${cases} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);

            return response.length > 0 ? true : false;
        }

        return false;
    }
}

export default new CaseOfflineProcesssor();