import http from "@/axios";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";
import PerpetratorOfflineForm from "../Perpetrators/Offline/Formatters/PerpetratorOfflineForm";
import { PerpetratorIdentity } from "@/views/enums/PerpetratorIdentity";

const sqlite = new SqliteProcessor;
const table = 'perpetrators';
const syncable = new Syncable;
export default class SyncPerpetrators {
    
    async renderPerpetrators(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'perpetrators';
        await sqlite.connect();
        const perpetrators = await onlineSync.defaultQuery(table);
        if (perpetrators.length > 0) {
            for (const index in perpetrators) {
                const item = perpetrators[index];
                const setForm = PerpetratorOfflineForm.formStructure();
                const form = onlineSync.setForm(PerpetratorOfflineForm.renderSyncToOffline(item), setForm, item);

                // unique object value per table
                form.table = table;
                form.type = "Perpetrators"
                if (item.identity == PerpetratorIdentity.UNKNOWN) {
                    form.patient_name = item.tag;
                }
                form.url_view = `/perpetrator/${item.id}/view`;
                form.can_view = true; 
                if (isRenderData) {
                    items.push(form);
                }

                // process online synching
                if (!isRenderData) {
                    hasError = await this.processForm(form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async processForm(item: any) {
        const onlineSync = new OnlineSynching;
        let hasError = false; 
        if (item.is_deleted_offline) { 
            const response = await http.post('/attemp-synching-perpetrator-delete', item).catch(error => error);
            if (response?.status === 200) { 
                await onlineSync.removeRecord(table, item.id);
                // delete related tables
                await sqlite.db.delete('case_perpetrators', { perpetrator_id: item.id })
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) { 
            const response = await http.post('/attemp-synching-perpetrator-update', item).catch(error => error);
            if (response?.status === 200) {
                await this.updateOthers(response.data.data.item, item)
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }
        }

        if (item.is_created_offline) { 
            const response = await http.post('/attemp-synching-perpetrator-create', item).catch(error => error);
            if (response?.status === 200) {
                const oldID = item.id;
                const newId = response.data.id;
                await this.updateOthers(response.data, item)
                await onlineSync.resetItemData(table, oldID);
                await onlineSync.updateNewItemId(table, oldID, newId);
                // update related tables
                await sqlite.db.updateWithFilters('case_perpetrators', {
                    perpetrator_id: newId
                }, {
                    perpetrator_id: oldID
                })
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }
        }        

        return hasError;
    }

    async updateOthers(data: any, item: any) {
        const onlineSync = new OnlineSynching;
        // if (item.nationality_id === 'others') {
        //     await onlineSync.updateOthersDropdown('nationalities', data.nationality_id, item.other_nationality);
        // }
        // if (item.religion_id === 'others') {
        //     await onlineSync.updateOthersDropdown('religions', data.religion_id, item.other_religion);
        // }
    }
}