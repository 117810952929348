<template>
    <user-layout>
        <div class="w-full py-6 mx-auto">
            <div class="flex items-center justify-between">
                <section-title title="Personnel" description="View the relevant in the system." />

                <div class="flex items-center justify-end space-x-3">
                    <export-button 
                        v-if="hasPermission('can-view-admin')"
                        @click="exportPerosnnel" 
                    />
                    <a 
                        v-if="hasPermission('can-create-admin')"
                        @click="router.replace('/personnel/create')"
                    >
                        <button-component>
                            <PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
                            <span>Create</span>
                        </button-component>
                    </a>
                </div>
            </div>
            <div class="mt-6 border rounded-lg border-neutral-100">
                <div class="tabs flex items-center justify-between pl-6 py-6 pr-3.5 border-b border-neutral-100">
                    <div class="flex items-center space-x-4">
                        <tab-component :tabs="tabs" @update:tab="(value: string) => updateTab(value)" />
                    </div>
                </div>

                <div v-if="filters.tab != 'activity_logs'">
                    <data-table 
                        :headers="headers"
                        :no-action="false" 
                        :count="items.total"
                        :hasSearch="true"
                        searchPlaceholder="Search personnel" 
                        tableClass="header-frm-table" 
                        dataTableClass="border-0"
                        @update:searchText="(value: string) => (filters.query = value)"
                    >
                        <template #topContent>
                            <div>
                                <button type="button"
                                    class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 hover:bg-primary-100 w-max"
                                    @click="showFilter = true">
                                    <span class="text-xs font-medium text-neutral-600">Showing:</span>
                                    <span class="text-xs font-medium">All Records</span>
                                </button>
                                <FilterPanel :show="showFilter" @close="showFilter = false" @apply:reset="applyReset()"
                                    @apply:filters="applyFilters()">
                                    <template #fields>
                                        <div class="space-y-5">
                                            <date-picker id="date_created" name="date_created" label="Date Created"
                                                :range="true" v-model="filters.filterDate" />
                                            <select-options :options="officeOptions" label="Office" id="office"
                                                name="office" placeholder="Select.." v-model="filters.filterOffice" />

                                            <select-options :options="userGroupOptions" label="User Group" id="user_group" 
                                               name="user_group" placeholder="Select.." v-model="filters.filterUserGroup" />
 
                                            <select-options :options="positionOptions" label="Position" id="position"
                                                name="position" placeholder="Select.." v-model="filters.filterPosition" />
                                        </div>
                                    </template>
                                </FilterPanel>
                            </div>
                        </template>
                        <template v-slot:body>
                            <template v-for="item in items.data" :key="item">
                                <tr>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.id }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.name }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.office_name }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.user_group_name }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.position_name }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        <span v-if="item.status == 'Unverified'"
                                            class="text-xs px-1.5 py-[5px] rounded-3xl leading-3 font-semibold text-gray-600 bg-[#f0f1f2]">
                                            Unverified
                                        </span>
                                        <span v-if="item.status == 'Verified'"
                                            class="text-xs px-1.5 py-[5px] rounded-3xl leading-3 font-semibold text-green-600 bg-green-100">
                                            Verified
                                        </span>
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.created_at_formatted }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        <div class="flex items-center justify-center mx-auto">
                                            <template v-if="!filters.tab">
                                                <edit-button 
                                                    class="shrink-0"
                                                    v-if="hasPermission('can-update-admin')"      
                                                    @click="router.replace(`/personnel/edit/${item.id}`)"/>
                                                <delete-button 
                                                    class="shrink-0"
                                                    v-if="hasPermission('can-archive-admin')"
                                                    @click="showDeleteModal(item)" 
                                                />
                                            </template>

    
                                            <template v-if="filters.tab == 'archived'">
                                                <restore-button
                                                    class="shrink-0"
                                                    v-if="hasPermission('can-archive-admin')"
                                                    @click="showRestoreModal(item)" 
                                                />
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </data-table>
                </div>

                <ActivityLogsTable
                    v-if="filters.tab == 'activity_logs'"
                    :items="items"
                />

                <div class="w-full px-6 border-t py-7">
                    <simple-pagination 
                        :numRows="filters.rows" 
                        :currentPage="items.current_page" 
                        :last-page="items.last_page" 
                        @update="(value: any) => paginate(value)"
                    />
                </div>
            </div>
        </div>
        
        <!-- DELETE MODAL -->
        <confirmation-modal :typeTwo="true" :show="showConfirmation" @cancel="showConfirmation = false"
            @confirm="deleteRecord()" cancel-text="Cancel" action-text="Confirm" title="Delete Record"
            content="Are you sure you wish to delete this record?" />
        <success-modal :show="showSuccess" @confirm="closeDeleteModal()" action-text="Close"
            title="Record Deleted!" content="Selected record has been successfully deleted!" />
        <!-- RESTORE MODAL -->
        <confirmation-modal :typeTwo="true" :show="showRestoreConfirmation"
            @cancel="showRestoreConfirmation = false" @confirm="restoreRecord()" cancel-text="Cancel"
            action-text="Confirm" title="Restore Record"
            content="Are you sure you wish to restore this record?" />
        <success-modal :show="showRestoreSuccess" @confirm="closeRestoreModal()" action-text="Close"
            title="Record Restored!" content="Selected record has been successfully restored!" />
            
        <success-modal :show="showExportSuccess" @confirm="closeExportPerosnnel()" action-text="Close"
            title="Personnels Exported!" content="Please check your email for the exported personnels" />

        <page-loader :show="loading"/>
    </user-layout>
</template>
<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";

import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ExportButton from "@/components/buttons/ExportButton.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import EditButton from "@/components/buttons/EditButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import RestoreButton from "@/components/buttons/RestoreButton.vue";
import DataTable from "@/components/partials/DataTable.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
// import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import SimplePagination from "@/components/partials/SimplePagination.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import SelectOptions from "@/components/inputs/SelectOptions.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import Personnels from "@/classes/Personnels";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

import { SelectInterface } from "@/interfaces/pages/BasePageInterface"

import { filters, items } from "@/interfaces/pages/FilterPageInterface";

import assignValues from "@/helpers/assignValues"
import PageLoader from "@/components/loaders/PageLoader.vue"

import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import { hasPermission } from "@/classes/AuthService";

const router = useRouter();
const route = useRoute();

/**
 * VARIABLES
 */
const loading = ref<boolean>(false);
const showFilter = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const showExportSuccess = ref<boolean>(false);
const personnelId = ref<number>(0);

const officeOptions = ref<SelectInterface[]>([]);
const userGroupOptions = ref<SelectInterface[]>([]);
const positionOptions = ref<SelectInterface[]>([]);

const tabs = ref<{ name: string; count: number; tab: string|null; allowed: boolean }[]>([
    {
        name: "All",
        count: filters.value.allItemsCount,
        tab: null,
        allowed: true,
    },
    {
        name: "Archived",
        count: 0,
        tab: "archived",
        allowed: true,
    },
    {
        name: "Activity logs",
        count: filters.value.activityLogsCount,
        tab: "activity_logs",
        allowed: true,
    },
]);

const headers: { text: string; haveSort?: boolean }[] = [
    { text: "" },
    { text: "Name", haveSort: true },
    { text: "Office" },
    { text: "User Group" },
    { text: "Position" },
    { text: "Status" },
    { text: "Date Created" },
];

/**
 * METHODS
 */
const getData = (): void => {
    loading.value = true
    Personnels.index(pickBy(filters.value))
        .then(({ data }) => {
            filters.value = assignValues(filters.value, data);
          
            items.value = data.items;

            officeOptions.value = data.offices;
            positionOptions.value = data.positions;
            userGroupOptions.value = data.userGroups;

            loading.value = false
        })
        .catch((error: any) => {
            console.log(error);
        });
};

const applyFilters = (search: any = null) => {
    showFilter.value = false;
    filters.value.query = search;
    getData();
};

const applyReset = () => {
    filters.value.filterDate = null;
    filters.value.filterOffice = null;
    filters.value.filterPosition = null;
    filters.value.filterUserGroup = null;
   
    showFilter.value = false;
    getData();

};

const paginate = ({ page, rows }): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })
    getData();
};

const updateTab = (currentTab: string) => {
    filters.value.tab = currentTab;
    filters.value.page = 1;
    applyFilters();
};

/**
 * MODALS
 */
const showDeleteModal = (item: any): void => {
    showConfirmation.value = true;
    personnelId.value = item.id;
};

const closeDeleteModal = (): void => {
    showSuccess.value = false;
};

const deleteRecord = (): void => {
    Personnels.delete(personnelId.value)
        .then(() => {
            showSuccess.value = true;
            showConfirmation.value = false;
            applyFilters();
        })
        .catch((error) => console.log(error));
};

const showRestoreModal = (item: any): void => {
    showRestoreConfirmation.value = true;
    personnelId.value = item.id;
};

const closeRestoreModal = (): void => {
    showRestoreSuccess.value = false;
};

const restoreRecord = (): void => {
    Personnels.restore(personnelId.value)
        .then(() => {
            showRestoreSuccess.value = true;
            showRestoreConfirmation.value = false;
            applyFilters();
        })
        .catch((error) => console.log(error));
};

const exportPerosnnel = (): void => {
    loading.value = true
    Personnels.export(filters.value)
        .then(() => {
            loading.value = false
            showExportSuccess.value = true;
        })
        .catch((error) => console.log(error));
};


const closeExportPerosnnel = (): void => {
    showExportSuccess.value = false;
};

/**
 * WATCHERS
 */
watch(
    () => filters.value.query,
    throttle(() => {
        getData();
    }, 1000)
);

// onMounted(() => {
//     filters.value.filterOffice = route.query.office;

//     getData();
// });

onIonViewWillEnter(() => {
    filters.value.filterOffice = route.query.office;

    getData();
});
</script>
