import {required, helpers, numeric } from "@vuelidate/validators"
const createRules = (form: any): any => { 
   return {
    last_name: {
        required:  helpers.withMessage(errorMessage('last name'), required)
    },
    first_name: {
        required:  helpers.withMessage(errorMessage('first name'), required)
    },
    assessment_date: {
        required:  helpers.withMessage(errorMessage('assessment date'), required)
    },
    //From backend this is required
    // is_assessment_patient_sibling: {
    //     required: helpers.withMessage(errorMessage('assessment patient sibling'), required)
    // }, 
    yes_count: {
        numeric: numeric, 
    },
    no_count: {
        numeric: numeric, 
    },
    unknown_count: {
        numeric: numeric, 
    },
    //From backend this is required
    risk_code_id: {
        required:  helpers.withMessage(errorMessage('risk code'), required)
    },
        
   }
}


const errorMessage = (field: any) => {
   return `The ${field} field is required`;
}
export default {
   createRules,
}