import Syncable from "@/extenders/models/Syncable";
import PatientOfflineForm from "../Formatters/PatientOfflineForm";
import SqliteProcessor from "@/processors/SqliteProcessor";
import CaseOfflineForm from "@/classes/Cases/CaseHistory/Offline/Formatters/CaseOfflineForm";
import PatientTemplateForm from "../Formatters/PatientTemplateForm";
import CaseOfflineProcessor from "@/classes/Cases/CaseHistory/Offline/processor/CaseOfflineProcessor";
import Relationships from "@/classes/Forms/Relationships";
import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";
import OnlineSynching from "@/classes/Synching/OnlineSynching";

const sqlite = new SqliteProcessor;
const relationship = new Relationships();
class PatientOfflineProcessor extends Syncable {
    async syncToOffline(payload: any, isUpdate = false) {
        const form = PatientOfflineForm.renderSyncToOffline(payload.item || payload, isUpdate);
        this.table = 'patients';
        const newForm = isUpdate ? form : DefaultFormStructureBuild.resetStatus(form);
        await this.sync(sqlite.db, [newForm]);
    }

    async viewPatientProfile(patient: number, payload: any) {
        this.table = 'patients';
        const result = await sqlite.db.select(`SELECT * FROM ${this.table} WHERE id = ${patient}`);
        return await PatientOfflineForm.renderPatientProfile(result);
    }

    async searchPatient(payload: any) {
        await sqlite.connect();
        this.table = payload.mode === 'patient' ? 'patients' : 'cases';
        const result = await this.paginate(sqlite.db, payload, (query: string) => {
            let sql = '';
            if (payload?.first_name || payload?.last_name) {
                sql += ` WHERE (first_name LIKE "%${payload?.first_name || null}%" OR last_name LIKE "%${payload?.last_name || null}%")`;
            }
            
            if (payload?.birthdate) {
                sql += ` AND birthdate="${payload?.birthdate}"`;
            }

            return sql;
        });
        
        const arr = [];
        for (const index in result.data) {
            const data = await CaseOfflineForm.renderSearchPatientCase(result.data[index], payload);
            if (data.id && data.patient_id) {
                arr.push(data);
            }
        }

        result.data = arr;
        return result;
    }

    async getPatient(patient: number) {
        this.table = 'patients';
        const result =  await sqlite.db.select(`SELECT * FROM ${this.table} WHERE id = ${patient}`);

        return result.length > 0 ? result[0] : null;
    }

    async create() {
        await sqlite.connect();
        return await PatientTemplateForm.createTemplate();
    }

    async createPatientCase() {
        await sqlite.connect();
        return await PatientTemplateForm.createPatientCaseTemplate();
    }

    async store(payload: any) {
        await sqlite.connect();
        this.table = 'patients';
        const patientForm = PatientOfflineForm.store(payload);
        // store patient table
        await this.sync(sqlite.db, [patientForm]);
        const patient = await this.getLatestStoredPatient();
        if (patient) {
            payload.patient_id = patient.id;
            await CaseOfflineProcessor.store(payload);
        }

        return {
            patient: await this.getLatestStoredPatient(),
            case_details: await CaseOfflineProcessor.getLatestStoredCase(patient.id)
        };
    }

    async getLatestStoredPatient(id: any = null) {
        let where = '';
        if (id) {
            where += ` WHERE id=${id}`;
        }
        const response = await sqlite.db.select(`SELECT * FROM patients ${where} ORDER BY id DESC LIMIT 1`);
        if (response.length > 0) {
            return response[0];
        }
        return null;
    }

    async transformEditPatient(item: any) {
        item.registered_at_formatted = relationship.dateFormat(item?.registered_at, 'MM/dd/yyyy') || "";
        item.birthdate_formatted = relationship.dateFormat(item.birthdate, 'MM/dd/yyyy') || "Unknown";
        return item;
    }

    async getFullName(patient: number) {
        const result = await this.getLatestStoredPatient(patient);
        if (result) {
            return `${result.last_name}, ${result.first_name} ${result.middle_name || ''}`;
        }

        return null;
    }

    async checkUnsyncPatient(patient: number) {
        if (patient) {
            const response = await sqlite.db.select(`SELECT * FROM patients WHERE id=${patient} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);

            return response.length > 0 ? true : false;
        }

        return false;
    }

    async test(table: string, id: number) {
        if (id && table) {
            const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);

            return response.length > 0 ? true : false;
        }

        return false;
    }

    async deleteExistingPatientRecord(patient: number) {
        const onlineSync = new OnlineSynching();

        if (patient) {
            const cases = await sqlite.db.select(`SELECT * FROM cases WHERE patient_id=${patient}`);
            if (cases.length > 0) {
                for (const index in cases) {
                    const item = cases[index];
                    await onlineSync.deleteOtherCaseRelationships(item.id);
                }                
            }
            await onlineSync.deleteCases(patient);
        }
    }
}

export default new PatientOfflineProcessor();