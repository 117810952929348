<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		backTitle="Search Perpetrator">
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Search Perpetrator" />
			</div>
			<form class="grid grid-cols-6 gap-6 mt-6">
				<div class="col-span-6 border-t border-gray-200"></div>

				<div class="col-span-6">
					<div class="flex flex-1 md:space-x-6 md:space-y-0 space-y-3 md:flex-row flex-col">
						<div class="md:w-1/3 w-full">
							<text-input
								type="text"
								label="First Name"
								placeholder="First name"
								name="first_name"
								id="first_name"
								v-model="form.first_name"
								:error="errors?.first_name" />
						</div>
						<div class="md:w-1/3 w-full">
							<text-input
								type="text"
								label="Last Name"
								placeholder="Last name"
								name="last_name"
								id="last_name"
								v-model="form.last_name"
								:error="errors?.last_name" />
						</div>
						<div class="md:w-1/3 w-full">
							<date-picker
								id="birthdate"
								name="birthdate"
								label="Birthdate"
								v-model="form.birthdate"
								:error="errors?.birthdate" />
						</div>
						<div class="shrink-0 md:pt-[26px] pt-3">
							<button-component 
								customClass="quaternary md:w-auto w-full"
								size="large"
								@click="search()"
							>
								<!-- <img
									src="/assets/icon/ic-search-2.svg"
									alt="search_icon"
									class="object-contain w-5 h-5 mr-1 icon" /> -->
									<search-two-icon class="object-contain w-5 h-5 mr-1 icon" />
								<span>Search</span>
							</button-component>
						</div>
					</div>
				</div>
			</form>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { useRouter, useRoute } from "vue-router";
import { PerpetratorSearch } from "@/interfaces/Cases/Perpetrator";
import { errorResponse } from "@/helpers/errorMessage"; 
import pickBy from "lodash/pickBy"; 
import { onIonViewWillEnter } from "@ionic/vue";
import SearchTwoIcon from "@/components/icons/searchTwoIcon.vue";
import Rules from "@/classes/Patients/Offline/Validation/Rules";
import useVuelidate from "@vuelidate/core";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import { useNetworkStore } from "@/store/network";
import PerpetratorService from "@/classes/Perpetrators/PerpetratorService";

const router = useRouter();
const route = useRoute();
const errors = ref<object>({});
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const form = ref<PerpetratorSearch>({
	last_name: null,
	first_name: null,
	birthdate: null,
});
const patient = ref<string>('');
const { searchRules } = Rules;
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const getData = async (): Promise<any> =>{
	await PerpetratorService.getPatient(
		patientId.value,
		caseId.value,
        isNetworkAvailable.value,
	)
		.then(({data}) => {
            console.log(data, 'dadasd')
			patient.value = data.patient
		})
		.catch((error: object) => {
			errors.value = errorResponse(error.response?.data?.errors);
		});
}

/**
 * Validate the form using Vuelidator
 */
 const validateForm = async () =>{
	const vuelidate = useVuelidate(searchRules({...form.value}), form.value)
	const result = await vuelidate.value.$validate();

    if (result) {
        return null;
    } else {
        errors.value = ErrorValidation.getErrors(vuelidate.value);
        return errors.value;
    }
}

const search = async (): void => {
    errors.value = {};

    // Set validation rule for Online and Offline
    const validate = await validateForm();
    if (validate) {
        return;
    }

    const response = await PerpetratorService.index(
		patientId.value,
		caseId.value,
		{ ...form.value },
        isNetworkAvailable.value
	)

    if (response?.status === 200 || response?.data != undefined) {
        router.push({
				path: `/patient/${patientId.value}/cases/${caseId.value}/perpetrators/index`,
				query: form.value,
			});
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};

const resetForm = () => {
    form.value.last_name = null;
    form.value.first_name = null;
    form.value.birthdate = null;
}
 
onIonViewWillEnter(async () => {
    resetForm(); 
    await getData();
}); 
</script>
