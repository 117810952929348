<template>
    <security-feature-form
        v-if="useSecurityFeature().isActivated"
        :show="useSecurityFeature().isActivated"
        @confirm="(value) => submit(value)"
        :title="title"
        content="Please input your password to continue access"
        action-text="Unlock"
        :validate="false"
        hasPinCode
        whiteBg
        :password="password"
        :error="error"
    />

    <page-loader :show="loading"/>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import SecurityFeatureForm from '@/components/modals/SecurityFeatureForm.vue';
import PageLoader from "@/components/loaders/PageLoader.vue"
import { useNetworkStore } from "@/store/network";
import Storage from "@/helpers/storage";
import { securityFeature } from "@/classes/AuthService";
import { useSecurityFeature } from "@/store/securityFeature";

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: "Device is back online!"
    }
});

const storage = new Storage();
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isActualNetworkAvailable);
// const show = ref<boolean>(props.show);
const loading = ref<boolean>(false);
const password = ref('');
const error = ref('');

const submit = async (value: string) => {
    let user = storage.get('user');
    if (user) {
        user = JSON.parse(user);
        const result = await securityFeature(user.email, value).catch(error => error);

        if (result.status === 200) {
            // show.value = false;
            storage.set('enable-security-feature', false);
            useSecurityFeature().isActivated = false;
            useSecurityFeature().isPasswordBlocker = false;
            sessionStorage.setItem('hasPasswordBlocker', false);
            // window.location.reload();
        } else {
            error.value = result?.response?.data?.message || "Password does not match"
        }
    }
}

const removeRedundantModals = () => {
    setTimeout(() => {
        const modals = document.querySelectorAll('#headlessui-portal-root > div');
        // Remove all child divs except the last one
        Array.from(modals).slice(0, -1).forEach(div => div.remove());
    }, 300);
}

watch(
    () => isNetworkAvailable.value,
    () => {
        removeRedundantModals();
    }
);

onMounted(() => {
    const status = eval(storage.get('enable-security-feature'));
    if (status && isNetworkAvailable.value) {
        useSecurityFeature().isActivated = true;
    }
    removeRedundantModals();
});
</script>