<template>
	<user-layout>
		<div class="max-w-[500px] w-full mx-auto py-16">
			<div class="px-6 py-6 rounded-lg bg-primary-400">
				<h1 class="mb-6 text-xl font-bold">Search Patient</h1>
				<h2 class="mb-6 text-sm font-bold">Patient Name</h2>
				<form class="flex flex-col md:space-y-6 space-y-3" @submit.prevent="submit">
					<text-input
						type="text"
						label="First Name"
						placeholder="First name"
						name="first_name"
						id="first_name"
						v-model="form.first_name"
						:error="errors?.first_name" />

					<text-input
						type="text"
						label="Last Name"
						placeholder="Last name"
						name="last_name"
						id="last_name"
						v-model="form.last_name"
						:error="errors?.last_name" />

					<Disclosure v-slot="{ open }">
						<DisclosureButton class="text-left cursor-default md:w-max md:pt-0 pt-3">
							<button-component
								customClass="secondary md:w-auto w-full"
								@click="addFilter = true"
							>
								<PlusIcon v-if="!open" class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
								<MinusIcon v-if="open" class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
								<span>Add Filter Search</span>
							</button-component>
						</DisclosureButton>
						<DisclosurePanel>
							<date-picker
								id="birthdate"
								name="birthdate"
								label="Birthdate"
								v-model="form.birthdate"
								:error="errors?.birthdate" />
						</DisclosurePanel>
					</Disclosure>

					<div>
						<button-component type="submit" size="large" btnWidth="w-full"> Search </button-component>
					</div>
				</form>
			</div>
		</div>
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { PlusIcon, MinusIcon } from "@heroicons/vue/24/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { errorResponse } from "@/helpers/errorMessage";
import PatientService from "@/classes/PatientService";
import { PatientSearch } from "@/interfaces/PatientInterface";
import { useRouter } from "vue-router";
import { onIonViewWillLeave } from "@ionic/vue";
import Storage from "@/helpers/storage";
import PageLoader from "@/components/loaders/PageLoader.vue";
import { useNetworkStore } from "@/store/network";
import Rules from "@/classes/Patients/Offline/Validation/Rules";
import useVuelidate from "@vuelidate/core";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import PatientRecordService from "@/classes/Patients/PatientRecordService";

const storage = new Storage();
const router = useRouter();
const form = ref<PatientSearch>({
	last_name: "",
	first_name: "",
	birthdate: "",
});
const addFilter = ref<boolean>(false);
const errors = ref<object>({});
const loading = ref<boolean>(false);
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const { searchRules } = Rules;

/**
 * Validate the form using Vuelidator
 */
 const validateForm = async () =>{
	const vuelidate = useVuelidate(searchRules({...form.value}), form.value)
	const result = await vuelidate.value.$validate();

    if (result) {
        return null;
    } else {
        errors.value = ErrorValidation.getErrors(vuelidate.value);
        return errors.value;
    }
}


const submit = async (): void => {
	loading.value = true;

    // Set validation rule for Online and Offline
    const validate = await validateForm();
    if (validate) {
        loading.value = false;
        return;
    }

	PatientRecordService.search(
		{
			last_name: form.value.last_name,
			first_name: form.value.first_name,
			birthdate: form.value.birthdate,
			mode: 'patient',
		},
		isNetworkAvailable.value
	)
		.then(() => {
			loading.value = false;
			errors.value = null;
			storage.set(
				"search",
				JSON.stringify({
					last_name: form.value.last_name,
					first_name: form.value.first_name,
					birthdate: form.value.birthdate,
				})
			);
			router.push({ path: "/intervention/index", query: form.value });
		})
		.catch((error: object) => {
			loading.value = false;
			errors.value = errorResponse(error.response?.data?.errors);
		});
};

onIonViewWillLeave(() => {
	form.value.first_name = "";
	form.value.last_name = "";
	form.value.birthdate = "";
});
</script>
