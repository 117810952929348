import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";

const sqlite = new SqliteProcessor;

class DownloadResourcesService extends Syncable{
    async storeOptions (items: any) {
        await sqlite.connect();
        for (const index in items) {
            const table: string = this.findTable(index);
            const isTableExists = await sqlite.db.keyExists(table)
            if (isTableExists) {
                if (items[index as keyof object].length > 0) {
                    this.table = table;
                    // refresh tables
                    await sqlite.db.select(`DELETE FROM ${this.table}`);
                    await sqlite.db.set(this.table, items[index as keyof object]);
                }
            } else {
                console.log(`${table} does not exists.`);
            }
        }
    }


    private findTable (key: string) {
        const result = this.setTable().find((item: any) => item.params === key);

        return result != undefined ? result.table : '';
    }

    setTable () {
        return [
            {
                params: 'agencies',
                table: 'agencies',
            },
            {
                params: 'aggravating_factors',
                table: 'aggravating_factors'
            },
            {
                params: 'barangays',
                table: 'barangays'
            },
            {
                params: 'behaviorTypes',
                table: 'behavior_types'
            },
            {
                params: 'cities',
                table: 'cities'
            },
            {
                params: 'civilStatuses',
                table: 'civil_statuses'
            },
            {
                params: 'comorbidities',
                table: 'comorbidities'
            },
            {
                params: 'companionRelationships',
                table: 'companion_relationships'
            },
            {
                params: 'departments',
                table: 'departments'
            },
            {
                params: 'disabilities',
                table: 'disabilities'
            },
            {
                params: 'educationalAttainments',
                table: 'educational_attainments'
            },
            {
                params: 'employmentStatus',
                table: 'employment_statuses'
            },
            {
                params: 'guardianRelationships',
                table: 'guardian_relationships'
            },
            {
                params: 'hospitalAreas',
                table: 'hospital_areas'
            },
            {
                params: 'housingClassifications',
                table: 'housing_classifications'
            },
            {
                params: 'indigeneousPeoples',
                table: 'indigeneous_peoples'
            },
            {
                params: 'interventionTypeLists',
                table: 'intervention_type_lists'
            },
            {
                params: 'interventionTypes',
                table: 'intervention_types'
            },
            {
                params: 'mentalHealthDiagnoses',
                table: 'mental_health_diagnoses'
            },
            {
                params: 'mitigating_factors',
                table: 'mitigating_factors'
            },
            {
                params: 'nationalities',
                table: 'nationalities'
            },
            {
                params: 'offices',
                table: 'offices'
            },
            {
                params: 'perpetratorRelationships',
                table: 'perpetrator_relationships'
            },
            {
                params: 'personnels',
                table: 'personnels'
            },
            {
                params: 'assignedGroups',
                table: 'positions'
            },
            {
                params: 'primaryDiagnoses',
                table: 'primary_diagnoses'
            },
            {
                params: 'provinces',
                table: 'provinces'
            },
            {
                params: 'recommendationOptions',
                table: 'recommendation_options'
            },
            {
                params: 'referralSources',
                table: 'referral_sources'
            },
            {
                params: 'regions',
                table: 'regions'
            },
            {
                params: 'relationships',
                table: 'relationships'
            },
            {
                params: 'religions',
                table: 'religions'
            },
            {
                params: 'riskCodes',
                table: 'risk_codes'
            },
            {
                params: 'safetyAssessmentQuestions',
                table: 'safety_assessment_questions'
            },
            {
                params: 'socioEconomicStatuses',
                table: 'socio_economic_statuses'
            },
            {
                params: 'soughtServices',
                table: 'sought_services'
            },
            {
                params: 'telCpuOffices',
                table: 'telcpus'
            },
            {
                params: 'teleCpuLocations',
                table: 'telcpu_locations'
            },
        ]
    }
}

export default new DownloadResourcesService();