import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";

export const case_mental_health_diagnoses:SchemaTableOptions = {
    name:<string> 'case_mental_health_diagnoses',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'cases_id', value: 'INTEGER UNSIGNED'},
        { column: 'mental_health_diagnoses_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'type', value: 'TINYINT UNSIGNED DEFAULT NULL'},
        { column: 'specify', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'behavior_type_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
    ]
}