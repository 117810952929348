import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";

export const case_diagnoses:SchemaTableOptions = {
    name:<string> 'case_diagnoses',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'cases_id', value: 'INTEGER UNSIGNED'},
        { column: 'primary_diagnoses_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'other_diagnoses', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'high_suicidal_risk', value: 'TINYINT UNSIGNED DEFAULT NULL'},
        { column: 'is_hama', value: 'BOOLEAN DEFAULT FALSE'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
    ]
}