<template>
    <user-layout mainCustomClass="!pb-0">
        <div class="-mx-6 w-[calc(100%+48px)] h-full flex flex-col">
            <div class="py-6 mx-6">
                <section-title title="Report Management" description="View the report and data analytics in the system." />
            </div>
            <div class="flex-1 px-6 pt-6 bg-primary-100">
                <div class="grid w-full grid-cols-5 gap-6">
                    <div v-for="item in sampleData" :key="item" >
                        <div v-if="item.id == 11" >
                            <div v-if="userRole?.role_id == RoleTypes.SUPERADMIN || userRole?.role_id == RoleTypes.SUPERADMIN_IT" class="flex flex-col justify-between col-span-1 p-6 bg-white border rounded-lg"> 
                                <p class="mb-1 text-sm font-semibold text-neutral-900">{{item.label}}
                                    <svg class="inline-block shrink-0 text-primary-600" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.16667 2.54492C5.41671 2.95946 2.5 6.13869 2.5 9.99915C2.5 14.1413 5.85786 17.4991 10 17.4991C13.8605 17.4991 17.0397 14.5824 17.4542 10.8325H9.16667V2.54492Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.0732 7.49915H12.5V2.92606C14.631 3.67926 16.3199 5.36819 17.0732 7.49915Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </p>
                                <div class="mb-4">
                                    <p class="text-sm text-gray-500">{{item.description}}</p>
                                </div>
                                <div>
                                    <a :href="item.link" class="text-sm font-semibold transition text-primary-600 hover:text-primary-800">See Report</a>
                                </div>
                            </div>
                        </div>
                        <div v-else class="flex flex-col justify-between col-span-1 p-6 bg-white border rounded-lg">
                            <div> 
                                <p class="mb-1 text-sm font-semibold text-neutral-900">{{item.label}}
                                    <svg class="inline-block shrink-0 text-primary-600" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.16667 2.54492C5.41671 2.95946 2.5 6.13869 2.5 9.99915C2.5 14.1413 5.85786 17.4991 10 17.4991C13.8605 17.4991 17.0397 14.5824 17.4542 10.8325H9.16667V2.54492Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.0732 7.49915H12.5V2.92606C14.631 3.67926 16.3199 5.36819 17.0732 7.49915Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </p>
                                <div class="mb-4">
                                    <p class="text-sm text-gray-500">{{item.description}}</p>
                                </div>
                            </div>
                            <div>
                                <a :href="item.link" class="text-sm font-semibold transition text-primary-600 hover:text-primary-800">See Report</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue"; 
import { RoleTypes } from '../enums/RoleTypes';

interface sampleDataInfo {
    id: number
    label: string,
    description: string,
    link: string, 
}

const userRole = ref();
onMounted(() => {
    userRole.value =  JSON.parse(localStorage.getItem('user'));  
})
 
const sampleData = ref<sampleDataInfo[]> ([
    {
        id: 10,
        label: 'Patient related report',
        description: 'View patient related reports',
        link: '/reports/patient-related-report', 
    },
    // {
    //     label: 'Comorbid Report',
    //     description: 'View VAC/VAW Report',
    //     link: '/reports/comorbid-report'
    // },
    // {
    //     label: 'Case Related Age Group Report',
    //     description: 'View case related age group reports',
    //     link: '/reports/case-related-age-group-report'
    // },
    {
        id: 20,
        label: 'Case related reports',
        description: 'View case related reports',
        link: '/reports/case-related-specific-age', 
    },
    {
        id: 30,
        label: 'Primary Diagnosis Related Reports',
        description: 'View primary diagnosis related reports',
        link: '/reports/primary-diagnosis-related', 
    },
    
    {
        id: 40,
        label: 'Intake form',
        description: 'View intake form reports',
        link: '/reports/intake-report', 
    },
    {
        id: 50,
        label: 'Safety assessment',
        description: 'View safety assessment reports',
        link: '/reports/safety-assessment-report', 
    },
    {
        id: 60,
        label: 'Intervention report by patient',
        description: 'View intervention records reports',
        link: '/reports/intervention-records-report', 
    },
    {
        id: 70,
        label: 'Intervention report by type',
        description: 'View intervention age group reports',
        link: '/reports/intervention-report', 
    },
    {
        id: 80,
        label: 'Court schedule report',
        description: 'View court schedule reports',
        link: '/reports/court-schedule-report', 
    },
    // {
    //     label: 'Caseload Report',
    //     description: 'View caseload reports',
    //     link: '/reports/caseload-report'
    // },
    {
        id: 90,
        label: 'Patient by personnel',
        description: 'View patient by personnel reports',
        link: '/reports/patient-personnel-report', 
    },
    {
        id: 100,
        label: 'Workload report by personnel',
        description: 'View workload reports',
        link: '/reports/workload-report', 
    },
    {
        id: 110,
        label: 'System logs',
        description: 'View system logs reports',
        link: '/reports/system-logs-report', 
    },
    {
        id: 120,
        label: 'Office report',
        description: 'View office reports',
        link: '/reports/office-report', 
    },
]);


</script>