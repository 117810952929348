<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`">
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex md:justify-between md:items-center md:flex-row flex-col">
				<section-title title="Search Perpetrator" />
				<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3 md:mt-0 mt-6">
					<button-component
						customClass="quaternary md:w-auto w-full"
						size="large"
						@click="router.replace(`/patient/${patientId}/cases/${caseId}/perpetrators/search`)">
						<!-- <img
							src="/assets/icon/ic-search-2.svg"
							alt="search_icon"
							class="object-contain w-5 h-5 mr-1 icon" /> -->
						<search-two-icon class="object-contain w-5 h-5 mr-1 icon" />
						<span>Search</span>
					</button-component>
					<button-component
						@click="
							router.push(
								`/patient/${patientId}/cases/${caseId}/perpetrators/create`
							)
						"
						customClass="primary md:w-auto w-full md:mt-0 mt-6"
					>
						<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
						<span>Add Perpetrator</span>
					</button-component>
				</div>
			</div>
			<div class="mt-6">
				<data-table
					v-if="loaded"
					:headers="headers"
					:no-action="false"
					:count="items.data?.length"
					tableClass="header-frm-table">
					<template v-slot:body>
						<template v-for="item in items.data" :key="item">
							<tr>
								<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
									{{ item.name }}
								</td>
								<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
									{{ item?.birthdate ? DateFormatter.customDateFormat(item?.birthdate, 'MM/dd/yyy') : 'Unknown' }}
								</td>
								<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
									{{ item?.computed_age }}
								</td>
								<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
									{{ item.identity_meta?.description }}
								</td>
								<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
									{{ item?.latest_case_number || 'NA' }}
								</td>
								<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
									<div class="flex items-center justify-center mx-auto">
										<link-button 
											@click="linkPerpetrator(item)"
											class="shrink-0"
										>
											<span class="ml-1 font-semibold text-white">Link</span>
										</link-button>
                                                          
										<view-button
                                            v-if="isNetworkAvailable"
											:routeLink="`/perpetrator/${item.id || item.offlineData?.offline_id}/view`"
											target="_blank"
											custom-class="flex items-center justify-center w-full"
											><span class="ml-1 font-semibold text-white">View</span></view-button
										>
                                        <view-button
                                            v-if="!isNetworkAvailable"
											@click="router.push(`/perpetrator/${item.id || item.offlineData?.offline_id}/view`)"
                                            custom-class="flex items-center justify-center w-full"
											><span class="ml-1 font-semibold text-white">View</span></view-button
										>
									</div>
								</td>
							</tr>
						</template>
					</template>
				</data-table>
			</div>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Perpetraror Linked"
			content="Perpetraror successfully linked!" />

		<page-loader :show="loading"/>
	
	</user-layout>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import DataTable from "@/components/partials/DataTable.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import LinkButton from "@/components/buttons/LinkButton.vue";
import { useRouter, useRoute } from "vue-router";
import PerpetratorService from "@/classes/Perpetrators/PerpetratorService";
import { PerpetratorSearch } from "@/interfaces/Cases/Perpetrator";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { errorResponse } from "@/helpers/errorMessage"; 
import pickBy from "lodash/pickBy";
import PageLoader from "@/components/loaders/PageLoader.vue"
import DateFormatter from "@/helpers/DateFormatter";
import { onIonViewWillEnter } from "@ionic/vue";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import SearchTwoIcon from "@/components/icons/searchTwoIcon.vue";
import { useNetworkStore } from "@/store/network";
import PerpetratorProcessor from "@/classes/Perpetrators/Offline/Processor/PerpetratorProcessor";

const loading = ref<boolean>(false);
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const items = ref<PerpetratorSearchIndex[]>([]);
const form = ref<PerpetratorSearch>({
	last_name: route.query?.last_name,
	first_name: route.query?.first_name,
	birthdate: router.query?.birthdate,
});
const errors = ref<object>({});
const offlineParams = ref<string>(route.query.offline);
const loaded = ref<boolean>(false);
const patient = ref<string>('');
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "Perpetrator Name" },
	{ text: "Birthdays" },
	{ text: "Age" },
	{ text: "Type" },
	{ text: "Latest Case No." },
];
const recordStatusChecker = useRecordStatusChecker();

const linkPerpetrator = async (item: object) => {
    const payload = isNetworkAvailable.value ? { perpetrator: item.id } : item;
    const response = await PerpetratorService.link(
		patientId.value,
		caseId.value,
		pickBy(payload),
        isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        recordStatusChecker.isCreatedPerpetrator = true;
        showSuccess.value = true;
        if (isNetworkAvailable.value) {
            item.case_perpetrator_id = response.data.data.id;
            await PerpetratorProcessor.syncToOffline(patientId.value, caseId.value, item);
        }
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace(
		`/patient/${patientId.value}/cases/${caseId.value}/edit${offlineParams.value ? "?offline=true" : ""}`
	);
};

const getData = async (): Promise<any> => {
    form.value.is_searching = true;
    const response = await PerpetratorService.index(
		patientId.value,
		caseId.value,
		{ ...form.value },
        isNetworkAvailable.value,
	);
    
    if (response?.status === 200 || response?.data != undefined) {
     
		items.value = response.data.items;
		patient.value = response.data.patient;
       
        setTimeout(() => {
            loaded.value = true;
        }, 200);
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
        loaded.value = true;
    }
};

const setFormValue = () => {
    form.value.last_name = route.query?.last_name;
    form.value.first_name = route.query?.first_name;
    form.value.birthdate = router.query?.birthdate;
}

watch(
    () => isNetworkAvailable.value,
    () => {
        loading.value = false;
        getData();
    }
);

onIonViewWillEnter(async () => {
    recordStatusChecker.isCreatedPerpetrator = false;
    setFormValue();
    await getData();
});
</script>
