/* eslint-disable */

import StoredModelInterface from "@/interfaces/models/StoredModelInterface";
import BaseModel from "./BaseModel";

export default class Storeable extends BaseModel implements StoredModelInterface {

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */
    
    /**
     * Get items from pinia.
     * 
     * @param   object params
     * @return  Array<object>
     */
    get(params?:object):Array<object> {
        throw new Error("Method 'get()' must be implemented.")
    }

    /**
     * Find item from pinia.
     * 
     * @param   number|string id
     * @return  object|undefined
     */
    find(id:number|string):object|undefined {
        throw new Error("Method 'find()' must be implemented.")
    }

    /**
     * Set items into pinia.
     * 
     * @param   Array<object>
     * @return  void
     */
    set(items:Array<object>):void {
        throw new Error("Method 'set()' must be implemented.")
    }

    /**
     * Filter checker.
     * 
     * @param   object item
     * @param   object params
     * 
     * @return  boolean
     */
    filter(item:object, params?:object):boolean {
        return true;
    }
}