import http from "@/axios";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";
import Relationships from "../Forms/Relationships";
import { SyncStatus } from "@/views/enums/SyncStatus";
import OfflineProcessor from "../Cases/CaseImmediateAssessment/Processor/OfflineProcessor";

const sqlite = new SqliteProcessor;
const table = 'intake_personal_information';
const syncable = new Syncable;
const relationship = new Relationships();
export default class SyncIntakes {
    async defaultForm(item: any, table: string, title: string, url: string | null) {
        const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
        const form = item;
        form.table = table;
        form.type = title;
        if (cases.length) {
            form.cases_id = cases[0].id;
            form.patient_name = `${cases[0].first_name} ${cases[0].last_name}`;
            form.birthdate_formatted = relationship.dateFormat(cases[0].birthdate);
            if (url) {
                form.url_view = `/patient/${cases[0].patient_id}/cases/${cases[0].id}/intake/${url}`;
            }
        }
        form.can_view = item.cases_id ? true : false;
        form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
            ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
            : SyncStatus.RECORD_CONFLICT;

        return form;
    }

    async renderIntakePersonnalInformation(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        await sqlite.connect();
        const patients = await onlineSync.defaultQuery(table);
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const form = await this.defaultForm(item, table, 'Case Intake - Personnal Information', 'personal-information');

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {
                    hasError = await this.processPersonnalInformation(item, form);
                }
            }
        }

        hasError = false;
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderIntakePersonRelatedToCase(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'intake_person_related_to_cases';
        await sqlite.connect();
        const patients = await onlineSync.defaultQuery(table);
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const form = await this.defaultForm(item, table, 'Case Intake - Person Related To Case', 'persons-related-case');

                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = false;
                    hasError = await this.processPersonRelatedToCase(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderIntakeContactNumber(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'intake_contact_numbers';
        await sqlite.connect();
        const patients = await onlineSync.defaultQuery(table);
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const form = await this.defaultForm(item, table, 'Case Intake - Contact Number', 'contact-numbers');

                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = await this.processContactNumber(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderIntakeEmail(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'intake_email_addresses';
        await sqlite.connect();
        const patients = await onlineSync.defaultQuery(table);
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const form = await this.defaultForm(item, table, 'Case Intake - Email Address', 'email-addresses');

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {
                    hasError = false;
                    hasError = await this.processEmail(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderIntakeIncestCase(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'intake_incest_cases';
        await sqlite.connect();
        const patients = await onlineSync.defaultQuery(table);
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const form = await this.defaultForm(item, table, 'Case Intake Incest', 'incest-cases');

                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = await this.processIncest(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderCaseSafeAssessment(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'case_safety_assessments';
        await sqlite.connect();
        const patients = await onlineSync.defaultQuery(table);
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const form = await this.defaultForm(item, table, 'Case Immediate Safety Assessment', null);
                const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
                const questions = await sqlite.db.select(`SELECT * FROM case_safety_assessment_questions WHERE case_safety_assessment_id=${item.id}`);
                const builder = await OfflineProcessor.questionsFormBuilder({questions: questions}, item.id);
                form.questions = builder;
                form.is_assessment_patient_sibling = form.is_assessment_patient_sibling || false;
                form.is_court_hearing_included = form.is_court_hearing_included || false;
                if (cases.length > 0) {
                    form.url_view = `/patient/${cases[0].patient_id}/cases/${cases[0].id}/safety-assessment/${item.id}/view`;
                }

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {
                    hasError = false;
                    hasError = await this.processSafetyAssessment(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }


    async processPersonnalInformation(item: any, form: any) {
        const onlineSync = new OnlineSynching;
        let hasError = false;
        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-personnal-info-delete', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-personnal-info-update', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-personnal-info-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }        

        return hasError;
    }

    async processPersonRelatedToCase(item: any, form: any) {
        const onlineSync = new OnlineSynching;
        const table = 'intake_person_related_to_cases';
        let hasError = false;

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-person-to-case-delete', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-person-to-case-update', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-person-to-case-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                await sqlite.db.updateWithFilters('case_safety_assessment_questions', { case_safety_assessment_id: newId }, { case_safety_assessment_id: oldId });
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        return hasError;
    }
    
    async processContactNumber(item: any, form: any) {
        const onlineSync = new OnlineSynching;
        const table = 'intake_contact_numbers';
        let hasError = false;

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-contact-number-delete', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-contact-number-update', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-contact-number-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }
        return hasError;
    }

    async processEmail(item: any, form: any) {
        const onlineSync = new OnlineSynching;
        const table = 'intake_email_addresses';
        let hasError = false;

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-email-delete', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-email-update', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-email-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }        

        return hasError;
    }

    async processIncest(item: any, form: any) {
        const onlineSync = new OnlineSynching;
        const table = 'intake_incest_cases';
        let hasError = false;

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-incest-delete', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-intake-incest-update', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-incest-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        return hasError;
    }

    async processSafetyAssessment(item: any, form: any) {
        const onlineSync = new OnlineSynching;
        const table = 'case_safety_assessments';
        let hasError = false;
        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-safety-assessment-update', form).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline) {
            const response = await http.post('/attemp-synching-safety-assessment-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }
        
        return hasError;
    }
}