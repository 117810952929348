import CaseDiagnosesOfflineForm from "../Formatters/CaseDiagnosesOfflineForm";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Relationships from "@/classes/Forms/Relationships";
import Syncable from "@/extenders/models/Syncable";

const sqlite = new SqliteProcessor;
const table = 'case_diagnoses';
const relationship = new Relationships();
const syncable = new Syncable;

class CaseDiagnosesProcessor {
    async store(case_id: number, payload: any) {
        const form = CaseDiagnosesOfflineForm.renderStoreForm(case_id, payload);
        syncable.table = table;
        await syncable.removeCreate(
            sqlite.db,
            { cases_id: case_id},
            form
        );
    }

    async getDiagnoses(case_id: number) {
        return await sqlite.db.select(`SELECT * FROM ${table} WHERE cases_id=${case_id}`);
    }

    async getCardCaseHistory(case_id: number) {
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE cases_id=${case_id}`);
        if (response.length > 0) {
            return await sqlite.db.select(`SELECT * FROM primary_diagnoses WHERE id=${response[0].primary_diagnoses_id}`);
        }
        return [];
    }

    async getPrimaryDiagnoses(case_id: number) {
        const response = await this.getCardCaseHistory(case_id);
        if (response.length > 0) {
            return response[0].name;
        }

        return null;
    }

    async view(item: any) {
        const response = await this.getDiagnoses(item.id);
        if (response.length > 0) {
            return response[0];
        }

        return null;
    }

    async renderDiagnoses(item: any) {
        if (!item) {
            return null;
        }
        
        return {
            ...item,
            primary_diagnoses: await relationship.primary_diagnoses(item),
            high_suicidal_risk_meta: relationship.high_suicidal_risk(item.high_suicidal_risk)
        };
    }
}

export default new CaseDiagnosesProcessor();