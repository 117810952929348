class DefaultFormStructureBuild {

    fill(index: string, item: any) {
        if (item == undefined) {
            return null;
        }

        if (item[index] !== undefined) {
            const type = this.identifyTypeOf(item[index]);

            // validate if type is object and has an object id
            if (type === 'object' && item[index]?.id) {
                return item[index]?.id;
            }
            // else return default
            return item[index];
        }

        return null;
    }

    identifyTypeOf(item: any) {
        return typeof item;
    }

    resetStatus(form: any) {
        form.is_created_offline = false;
        form.is_updated_offline = false;
        form.is_deleted_offline = false;
        form.synching_remarks = null;
        form.is_sync = false;
        form.created_at = null;
        form.updated_at = null;
        form.deleted_at = null;
        return form;
    }
}

export default new DefaultFormStructureBuild();