import http from "@/axios";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";
import CaseGuardianForm from "../Cases/CaseGuardian/Offline/Formatters/CaseGuardianForm";

const sqlite = new SqliteProcessor;
const table = 'case_guardians';
const syncable = new Syncable;

export default class SyncGuardians {
    
    async renderCaseGuardians(isRenderData = false): Promise<any> {
        const onlineSync = new OnlineSynching;
        const items:Array<[]> = [];
        let hasError = false;
        const table = 'case_guardians';
        await sqlite.connect();
        const guardians = await onlineSync.defaultQuery(table);
        if (guardians.length > 0) {
            for (const index in guardians) {
                const item = guardians[index];
                const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
                const setForm = CaseGuardianForm.createForm();
                const form = onlineSync.setForm(CaseGuardianForm.updateForm(item), item, item);
                // unique object value per table
                form.is_created_offline = item.is_created_offline;
                form.cases_id = item.cases_id;
                form.table = table;
                form.type = "Case Guardians"
                if (cases.length > 0) {
                    form.url_view = `/patient/${cases[0].patient_id}/cases/${item.cases_id}/guardian/${item.id}/edit`;
                }
                form.can_view = item.cases_id ? true : false;
                form.person_related_types = form.person_related_types == null ? 'N/A' : form.person_related_types;

                if (isRenderData) {
                    items.push(form);
                }

                // process online synching
                if (!isRenderData) {
                    hasError = await this.processForm(form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async processForm(item: any) {
        const onlineSync = new OnlineSynching;
        let hasError = false;

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-guardian-delete', item).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.removeRecord(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_updated_offline && !item.is_created_offline) {
            const response = await http.post('/attemp-synching-guardian-update', item).catch(error => error);
            if (response?.status === 200) {
                await onlineSync.resetItemData(table, item.id);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }

            return hasError;
        }

        if (item.is_created_offline) {
            const response = await http.post('/attemp-synching-guardian-create', item).catch(error => error);
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.data.item.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                hasError = false;
            } else {
                hasError = await onlineSync.rollback(table, item.id, response);
            }
        }

        return hasError;
    }
}