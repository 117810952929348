import CaseMentalHealthOfflineForm from "../Formatters/CaseMentalHealthOfflineForm";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";

const sqlite = new SqliteProcessor;
const table = 'case_mental_health_diagnoses';
const syncable = new Syncable;
class CaseMentalHealthProcessor {
    async store(case_id: number, items: Array<object>) {
        const arr = [];
        for (const index in items) {
            const item: any = items[index];
            if (item.mental_health_diagnoses_id) {
                arr.push(CaseMentalHealthOfflineForm.renderStoreForm(case_id, item));
            }
        }

        if (arr.length > 0) {
            syncable.table = table;
            await syncable.removeCreateMany(
                sqlite.db,
                { cases_id: case_id},
                arr
            );
        }
    }

    async getMentalHealth(case_id: number) {
        return await sqlite.db.select(`SELECT * FROM ${table} WHERE cases_id=${case_id}`);
    }

    async view(item: any) {
        const response = await this.getMentalHealth(item.id);
        if (response.length > 0) {
            return response;
        }

        return null;
    }
}

export default new CaseMentalHealthProcessor();