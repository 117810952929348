<template>
    <case-conference-layout :patient="patient" v-if="loaded">
        <template #actionButtons>
            <div class="flex flex-col flex-shrink-0 space-y-3 sm:space-x-3 sm:space-y-0 sm:flex-row">
                <!-- Create -->
                <template v-if="!isView || isEdit">
                    <button-component 
                        btnWidth="quinary"
                        fill="outline"
                        @click="router.replace(`/patient/${patientId}/cases/${caseId}/edit/`)"
                        customClass="quinary sm:w-auto w-full"
                    >
                        Cancel
                    </button-component>
                    <button-component
                    @click="showConfirmation = showConfirmation = true"
                    customClass="primary sm:w-auto w-full">
                        Save
                    </button-component>
                </template>

                <!-- View -->
                <template v-if="isView && !isEdit">
                    <button-component @click="isEdit = true" customClass="primary sm:w-auto w-full">
                        <!-- <img
                            src="/assets/icon/ic-pencil-underline.svg"
                            alt="edit_icon"
                            class="object-contain w-4 h-4 mr-1 icon" /> -->
                        <pencil-underline-icon class="object-contain w-4 h-4 mr-1 icon" />
                        <span>Edit</span>
                    </button-component>
                    <button-component
                        v-if="isNetworkAvailable"
                        @click="generatePDFAccess()"
                        customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 sm:w-auto w-full"
                    >
                        <EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
                        <span>View PDF</span>
                    </button-component>
                </template>

                <!-- Edit -->
                <template v-if="!createMode && editMode">
                    <button-component 
                        btnWidth="quinary"
                        fill="outline"
                        @click="editMode = !editMode"
                        customClass="quinary sm:w-auto w-full"
                    >
                        Cancel
                    </button-component>
                    <button-component
                    @click="showConfirmation = showConfirmation = true"
                    customClass="primary sm:w-auto w-full">
                        Save Changes
                    </button-component>
                </template>
                
            </div>
        </template>

        <template v-if="!loading">
            <information-tab 
                :form="informationForm"
                :agencies="agencies"
                :invitees="invitees"
                :socialWorkers="socialWorkers"
                :recommendations="recommendations"
                :item="item"
                @setAgencies="setAgencies"
                @setInvitees="setInvitees"
                @setView="setView"
                @setLoading="setLoading"
                :isEdit="isEdit"
                :isCreate="isCreate"
                :errors="errors"
                :can-refresh="canRefresh"
                :selectedAgencies="selectedAgencies"
                :inviteeIds="inviteeIds"
                @update:form="(value) => informationForm = value"
            />
        </template>

        <!-- MODAL -->
        <confirmation-modal
            :typeTwo="true"
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="validate()"
            cancel-text="Cancel"
            action-text="Confirm"
            title="Save Changes"
            content="Are you sure do you want to save added details?" />
        <success-modal
            :show="showSuccess"
            @confirm="redirectSuccess()"
            action-text="Close"
            title="Success!"
            content="Details has been successfully saved!" />
        <page-loader :show="loading"/>
        
    </case-conference-layout>
</template>
<script setup lang="ts">
import PageLoader from "@/components/loaders/PageLoader.vue"
import CaseConferenceLayout from '../../Component/CaseConferenceLayout.vue';
import InformationTab from "../../Tabs/InformationTab.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { EyeIcon } from "@heroicons/vue/24/solid";

import { ref, onMounted, computed, watch } from 'vue';
import { useRouter, useRoute } from "vue-router";

import PersonalInformationService from "@/classes/Cases/IntakeForms/PersonalInformationService";
import CaseConferenceService from "@/classes/Cases/CaseConference/CaseConferenceService";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from '@/store/network';
import OfflineSyncCaseConference from "@/classes/Forms/Cases/CaseConference/OfflineSyncCaseConference";
import { onIonViewWillEnter } from "@ionic/vue";
import CaseConferenceInfoForm from "@/classes/Forms/Cases/CaseConferenceInfoForm";
import PencilUnderlineIcon from "@/components/icons/pencilUnderlineIcon.vue";

import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import OnlineSynching from "@/classes/Synching/OnlineSynching";


const router = useRouter();
const route = useRoute();
const loading = ref(false);
const loaded = ref(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const createMode = ref<boolean>(true);
const editMode = ref<boolean>(true);


const route_params = route.params;
const errors = ref<any>();
const patient = ref<string>('');
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const caseConferenceId = ref<number | null>(route.params.caseConference);

const item = ref([])
const agencies = ref([])
const invitees = ref([])
const socialWorkers = ref([])
const recommendations = ref([])
const inviteeIds = ref([])
const selectedInvitees = ref([])
const selectedAgencies = ref([])

const isEdit = ref(false)
const isCreate = ref(false)

const informationForm = ref<any>({
    id: null,
    case_conference_number: null,
    social_worker_id: null,
    date_time: null,
    invitees: null,
    agencies: null
})

const isView = ref(caseConferenceId.value ? true : false)
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const canRefresh = ref<boolean>(false);
const isIonWillEnter = ref<boolean>(false);
const formValidation = new CaseConferenceInfoForm({});
const onlineSync = new OnlineSynching();

const setAgencies = (value: any) => {
    informationForm.value.agencies = value
}

const setInvitees = (value: any) => {
    informationForm.value.invitees = value
}

const save = () => {
    showConfirmation.value = false;
    showSuccess.value = true;
}

const redirectSuccess = (): void => {
    showSuccess.value = false;
    createMode.value = false;
    editMode.value = false;

    router.replace(`/patient/${patientId.value}/cases/${caseId.value}/edit/`)
};

const fetchItem = async() => {
    loading.value = true

    if (caseId.value) {
		CaseConferenceService
            .edit(
                patientId.value,
                caseId.value,
                caseConferenceId.value,
                isNetworkAvailable.value
            )
			.then(({data}) => {
                agencies.value = data.agencies
                invitees.value = data.invitees
                patient.value = data.patient_name
                socialWorkers.value = data.socialWorkers
                item.value = data.item;
                
                if (item.value) {

                    Object.assign(informationForm.value,  data.item);

                    inviteeIds.value = data.item.invitees
                    selectedAgencies.value = data.item.agencies
                    patient.value = data.item.patient_name
                    recommendations.value = data.item.recommendations
                    setInvitees(inviteeIds.value)
                    
                    setTimeout(() => {
                        loading.value = false
                        loaded.value = true
                    }, 500);
                }

			})
			.catch((error: object) => {
                console.log(error)
                loaded.value = true
                loading.value = false
            });
	}
}

//Validations
const rules = computed(() => {
    return  {
        social_worker_id: {
            required,
        },
        date_time: {
            required,
        },
        invitees: {
            required,
            minLength: 1,
        },
        agencies: {
            required,
            minLength: 1,
        },

    }; 
})

const vuelidate = useVuelidate(rules, informationForm.value);

const validate = ():void => {
    const result = vuelidate.value.$validate();
	loading.value = true;
	showConfirmation.value = false;
    
    result
        .then((res) => {
            console.log(informationForm.value)
            console.log(vuelidate.value)
			if(res) {
				submit()
			} else {
				errors.value = ErrorValidation.getErrors(vuelidate.value)
			}
		}).catch((err) => {
			console.log(err);
		})
		.finally(() => {
			loading.value = false;
		});
}

const submit = () => {
    loading.value = true
    update()
};

const update = async (): Promise<void> => {
    if (caseId.value) {
		await CaseConferenceService.update(
			patientId.value,
			caseId.value,
            caseConferenceId.value,
            informationForm.value,
            isNetworkAvailable.value
		)
			.then((response: object) => {
                showConfirmation.value = false
                isView.value = true
                loading.value = false

                save();
                // window.location.reload()
			})
			.catch((error: object) => { 
                console.log(error)
                loading.value = false
                errors.value = errorResponse(error.response?.data?.errors)
            });
	}
}

const setView = (value) => {
    isView.value = value
}

const setLoading = (value) => {
    loading.value = value
}

const generatePDFAccess = ():void => {
	CaseConferenceService
		.generatePDFAccess(
			patientId.value,
			caseId.value,
			informationForm.value.id,
            isNetworkAvailable.value
		)
		.then(({data}) => {
			if(data.token){
				downloadPDF(data.token)
			}
		}).catch((errors) => {
			console.log(errors);
		})

}
const downloadPDF = (token:string):void => {
	CaseConferenceService
		.downloadPDF(
			informationForm.value.id,
			token,
            isNetworkAvailable.value
		)
		.then(({data}) => {
			console.log(data);
		}).catch((errors) => {
			console.log(errors);
		})

}

onMounted(async () => {
    await fetchItem();
})

onIonViewWillEnter(async () => {
    await fetchItem();
    await onlineSync.checkDrafts();
})

</script>

