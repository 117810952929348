import http from '@/axios'
import SqliteProcessor from '@/processors/SqliteProcessor';
// import IntakeForm  from './Formatter/IndexPageFormatter';
import OfflineProcessor from './Processor/OfflineProcessor';

const sqlite = new SqliteProcessor();

class IndexPageService { 
    async view(patientId:number | null, caseId: number | null){
        return await OfflineProcessor.index(patientId, caseId);
    }
}
export default new IndexPageService();