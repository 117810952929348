import { EnumsInterface } from "@/interfaces/EnumsInterface";

const Position:EnumsInterface[] = [
    {id: 1, value: 1, label: "Administrator"},
    {id: 2, value: 2, label: "Lawyer"},
    {id: 3, value: 3, label: "MSS"},
    {id: 4, value: 4, label: "Nurse"},
    {id: 5, value: 5, label: "OB Resident"},
    {id: 6, value: 6, label: "Pediatric Resident"},
    {id: 7, value: 7, label: "Physician"},
    {id: 8, value: 8, label: "Police Investigator"},
    {id: 9, value: 9, label: "Psychiatrist"},
    {id: 10, value: 10, label: "Psychologist"},
    {id: 11, value: 11, label: "Social Worker"},
    {id: 12, value: 12, label: "Trainee"},
    {id: 13, value: 13, label: "Triage Officer"},
    {id: 14, value: 14, label: "TeleConsultation Admin"},
]

export default Position

export enum getPosition {
    ADMINISTRATOR = 1,
    LAWYER = 2,
    MMS = 3,
    NURSE = 4,
    OB_RESIDENT = 5,
    PEDIATRIC_RESIDENT = 6,
    PHYSICIAN = 7,
    POLICE_INVESTIGATOR = 8,
    PSYCHIATRIST = 9,
    PSYCHOLOGIST = 10,
    SOCIAL_WORKER = 11,
    TRAINEE = 12,
    TRIAGE_OFFICER = 13,
    TELECONSULTATION_ADMIN = 14,
}

export const getPositionLabel = {
    [getPosition.ADMINISTRATOR]: 'Administrator',
    [getPosition.LAWYER]: 'Lawyer',
    [getPosition.MMS]: 'Mms',
    [getPosition.NURSE]: 'Nurse',
    [getPosition.OB_RESIDENT]: 'OB Resident',
    [getPosition.PEDIATRIC_RESIDENT]: 'Pediatruc Resident',
    [getPosition.PHYSICIAN]: 'Physician',
    [getPosition.POLICE_INVESTIGATOR]: 'Police Investigator',
    [getPosition.PSYCHIATRIST]: 'Psychiatrist',
    [getPosition.PSYCHOLOGIST]: 'Psychologist',
    [getPosition.SOCIAL_WORKER]: 'Social Worker',
    [getPosition.TRAINEE]: 'Trainee',
    [getPosition.TRIAGE_OFFICER]: 'Triage Officer',
    [getPosition.TELECONSULTATION_ADMIN]: 'Teleconsult Admin'
}