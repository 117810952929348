import http from "@/axios"; 
import OfflineProcessor from "./Processor/OfflineProcessor";

class PersonalInformationService  {

    async show(patient: number, patientCase: number, networkAvailable: boolean){
        if(networkAvailable){
            return await http.get(`patients/${patient}/cases/${patientCase}/intakeForm/personalInformation/show`);
        }else{
            return await OfflineProcessor.show(patient, patientCase);
        }
    }

    async update(personalInforId: number | null, patient: number, patientCase: number, payload: any, networkAvailable: boolean){
        if(networkAvailable){
            return await http.post(`patients/${patient}/cases/${patientCase}/intakeForm/personalInformation/update/${payload?.id}`, payload);
        }else{
            return await OfflineProcessor.updateCreate(personalInforId, patient, patientCase, payload);
        }
    }
 
}


export default new PersonalInformationService();