// import DB from "@/classes/Databases/Crud";

import { DraftTypes } from "@/views/enums/DraftTypes"
// import { SyncStatus } from "@/views/enums/SyncStatus";

// const db = new DB("patients");
// const dbCase = new DB("cases");

import { DraftsPageInterface } from "@/interfaces/Drafts/DraftsPageInterface";

import DateFormatter from "@/helpers/DateFormatter";


class DraftPatientProcessor {  

    public data: DraftsPageInterface[] = [];


    /**
     * Get all draft patients with cases data from SqlLite DB
     * 
     * @param patients 
     * @returns Promise DraftsPageInterface[]
     */
    async processData(patients: any): Promise<any> {
        console.log('offline')
        // patients.forEach((patient: any) => {
        //     this.getPatientCase(patient)
        //         .then((res) => (this.buildData(patient, res[0])))
        //         .then((drafts) => (this.data = drafts))
        // });

        // return this.data;
    }

    /**
     * Build data from sysncing and index page of drafts
     * 
     * @param patient 
     * @param cases 
     * 
     * @returns 
     */
    async buildData(patient: any, cases: any): Promise<any> {

        const data: DraftsPageInterface[] = [];
            
        patient['case'] = cases;

        data.push({
            id: patient.id || patient.offline_id,
            patient: patient.first_name +' '+ patient.last_name,
            birthday: DateFormatter.defaultTimeStamp(patient.birthdate),
            type: DraftTypes.PATIENT,
            status: patient.is_sync,
            date_created: DateFormatter.defaultTimeStamp(patient.updated_at ||  patient.created_at),
            data: patient,
        })

        return data;
    }

    /**
     * Gets patient case
     * 
     * @param patient 
     * @returns 
     */
    async getPatientCase(patient: any): Promise<any> {
        console.log('offline')
        // return await dbCase.select(`SELECT * FROM cases WHERE patient_id = ${patient.id || patient.offline_id}`);
    }

    /**
     * Process sycned results from admin/online
     * 
     * @param patient 
     */
    async processSyncResults(patient: any): Promise<void> {
        console.log('offline')
        // this.updateOrDeleteRecord(patient.data.case).then(() => this.updateOrDeletePatient(patient))
    }

     /**
     * Update or delete any record from admin/online
     * Successful sync delete the draft record
     * Unsuccessful/Conflict sync update sync status to SyncStatus.RECORD_CONFLICT
     * 
     * @param record 
     */
    async updateOrDeleteRecord(record: any): Promise<void> { 
        console.log('offline')
        // if (record) {      
        //     const params: { is_sync: number} = { is_sync: record.is_sync}
    
        //     const condition = record.id ? { id: record.id } : { offline_id: record.offline_id };
            
        //     if (record.is_sync == SyncStatus.SYNC) {
        //         db.delete(condition, 'patients')
        //     } else {
        //         db.update(params, condition, 'patients');
        //     }
        // }
    }

     /**
     * Update or delete patient record from admin/online
     * Successful sync delete the draft record
     * Unsuccessful/Conflict sync update sync status to SyncStatus.RECORD_CONFLICT
     * 
     * @param patient 
     */
    async updateOrDeletePatient(patient: any): Promise<void> { 
        console.log('offline')
        // const params: { is_sync: number} = { is_sync: patient.data.is_sync}

        // const condition = patient.data.id ? { id: patient.data.id } : { offline_id: patient.data.offline_id };
        
        // if (patient.data.is_sync == SyncStatus.SYNC) {
        //     db.delete(condition, 'patients')
        // } else {
        //     db.update(params, condition, 'patients');
		// }
    }
}

export default new DraftPatientProcessor();
