import http from "@/axios";
import { Guardian } from "./Offline/Interface/GuardianInterface";
import OfflineProcessor from "./Offline/Processor/OfflineProcessor";
import FormOptions  from "@/classes/Forms/FormOptions";
const options = new FormOptions();
class CaseGuardianService implements Guardian {
    /**
     * Render index page
     * @param patient 
     * @param cases_id 
     * @param payload 
     * @param networkAvailable 
     * @returns 
     */
   async index(patient: number, cases_id: number, payload: any, networkAvailable:boolean): Promise<any> {
       if(networkAvailable){
           return await http.get(`patients/${patient}/cases/${cases_id}/guardians`, { params: payload }).catch(error => error);
       }else{
           const data = await OfflineProcessor.index(cases_id);
           return {
               data: {
                   ...data.data,
               }
           };
       }
   }

   /**
    * Create page
    * @param patient 
    * @param patientCase 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async create(patient: number, patientCase: number, payload: object, networkAvailable:boolean): Promise<any> {
        if(networkAvailable){
            return await http.get(`patients/${patient}/cases/${patientCase}/guardians/create`, { params: payload }).catch(error => error);
        }else{ 
            const data = await OfflineProcessor.create(patient);
           return {
               data: {
                   ...data.data,
               }
           };
        }
        
    }


   /**
    * Store data to storage
    * @param patient
    * @param patientCase
    * @param payload
    * @param networkAvailable
    * @returns
    */
   async store(patient: number, patientCase: number, guardian:number, payload: any, networkAvailable:boolean): Promise<any> {
       if(networkAvailable){
           return await http.post(`patients/${patient}/cases/${patientCase}/guardians`, payload).catch(error => error);
       }else{   
           return await OfflineProcessor.updateCreate(guardian, patient, patientCase, payload);
       }
   }

   /**
    * Edit page
    * @param patient 
    * @param patientCase 
    * @param guardian 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async edit(
       patient: number,
       patientCase: number,
       guardian: number,
       payload: any,
       networkAvailable:boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.get(`patients/${patient}/cases/${patientCase}/guardians/${guardian}/edit`, {
               params: payload,
           }).catch(error => error);
       }else{
           return await OfflineProcessor.edit(patient, patientCase, guardian);
       }
   }

   /**
    * Update data from storage
    * @param patient 
    * @param patientCase 
    * @param guardian 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async update(
       patient: number,
       patientCase: number,
       guardian: number,
       payload: any,
       networkAvailable:boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.put(`patients/${patient}/cases/${patientCase}/guardians/${guardian}/update`, payload).catch(error => error);
       } else{  
           return await OfflineProcessor.updateCreate(guardian, patient, patientCase, payload);
       }
   }

   /**
    * Remove data from storage
    * @param patient 
    * @param patientCase 
    * @param guardian 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async delete(
       patient: number,
       patientCase: number,
       guardian: number,
       payload: any,
       networkAvailable:boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.delete(`patients/${patient}/cases/${patientCase}/guardians/${guardian}/delete`, payload).catch(error => error);
       }else{ 
           return await OfflineProcessor.delete(guardian, patientCase);
       }
   }
}


export default new CaseGuardianService();