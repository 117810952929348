<template>
	<user-layout
		hasBack
		:backLink="caseId ? `/patient/${patientId}/cases/${caseId}/interventions` : `/intervention/${patientId}/view`"
	>
		<template 
			#lowerRight
		>
			<download-court-hearing
				v-if="isCourtHearing"
				:intervention="item.id"
			/>

            <download-telepsychiatry v-if="isTelepsychiatry" :intervention="item.id"/>
            <DownloadPsychologicalTesting v-if="isPsychologicalTesting" :intervention="item.id"/>
		</template>

		<div class="max-w-screen-lg mx-auto my-6">
			<div class="flex flex-col w-full lg:items-start lg:justify-between lg:flex-row">
				<section-title fontSmall :title="`${formStructure.label} Intervention`">
					<p class="text-sm font-medium text-neutral-600">
						Please fill out all the required fields with the red asterisk (<span class="text-red-600">*</span>)
					</p>
				</section-title>
				<p class="mt-3 text-lg font-medium lg:pl-4 lg:mt-0 md:text-2xl text-neutral-600">Patient's Name:
					<span class="block font-semibold md:inline-block text-neutral-900">{{ patient?.name ?? "" }}</span>
				</p>
			</div>
			<form class="grid grid-cols-6 gap-6 mt-6" v-if="!loading">
				<div class="col-span-6 border-t border-gray-200"></div>
				<div class="col-span-full">
					<p
						v-if="errors?.message"
						class="text-[10px] mt-1"
						:class="errors?.message ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.message }}
					</p>
				</div>

				<template v-for="(field, index) in formStructure?.fields" :key="index">
					<form-field 
						:field="field"
						:form="form.params"
						:errors="errors"
						:patient="patient"
						:isEdit="true"
						:isDone="item.status === 'Done'"
						disabled
					/>
				</template>

				<div class="flex items-center col-span-6 space-x-3 justify end" >
					<div class="flex items-center justify-end w-full space-x-3">
						<button-component
							btnWidth="quinary"
							@click="
								router.replace(
									caseId
										? `/patient/${patientId}/cases/${caseId}/interventions`
										: `/intervention/${patientId}/view`
								)
							"
							>Cancel</button-component
						>
						
						<button-component btnWidth="primary" @click="showConfirmation = true"
							>Save Record</button-component
						>
					</div>
				</div>
			</form>
		</div>

		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="validate()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Save Record"
			content="Are you sure you wish to save this record?" 
		/>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Success!"
			:content="`${formStructure.label} Intervention has been Created`" 
		/>
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, type PropType, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minLength, helpers, integer, maxLength} from '@vuelidate/validators'

import pickBy from "lodash/pickBy";

import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import PageLoader from "@/components/loaders/PageLoader.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

import InterventionService from "@/classes/Interventions/InterventionService"
import ErrorValidation from "./Components/Classess/ErrorValidation";

import FormField from "@/views/interventions/Components/Input/FormField.vue"
import DownloadCourtHearing from "@/views/interventions/Components/Buttons/DownloadCourtHearing.vue";
import DownloadTelepsychiatry from "./Components/Buttons/DownloadTelepsychiatry.vue";

//Classes
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";

//Interfaces
import { PatientInterface } from "@/interfaces/pages/InterventionForm/InterventionFormInterface";
import OnlineSynching from "@/classes/Synching/OnlineSynching";
import DownloadPsychologicalTesting from "./Components/Buttons/DownloadPsychologicalTesting.vue";

//Classes
const router = useRouter();
const route = useRoute();
const networkStore = useNetworkStore();


//Route params
const patientId = ref<number | null>(route?.params?.patient);
const caseId = ref<number | null>(route?.params?.case ?? null);
const interventionType = ref<number>(route?.params?.interventionType);
const intervention = ref<number>(route?.params?.intervention);

//Interface
const patient = ref<PatientInterface>({});

//Any
const formStructure = ref<any>('');
const item =ref<any>({});

//Booleans
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);
const processing = ref<boolean>(false);
const isCourtHearing = ref<boolean>(false);
const isTelepsychiatry = ref<boolean>(false);
const isPsychologicalTesting = ref<boolean>(false);

//Number
const selectedCase = ref<number|null>(route?.params?.case ?? null);
const isNetworkAvailable = computed<boolean>(():boolean => networkStore.isNetworkAvailable);
const onlineSync = new OnlineSynching();

const form = ref<any>({
	cases_id: selectedCase.value,
	interventionType: interventionType.value,
	params: [],
})

const errors =ref<any>({});

const init = ():void => {
	loading.value = true;
	InterventionService
		.edit(interventionType.value, intervention.value, patientId.value, isNetworkAvailable.value)
		.then(({data}) => {
			console.log(data.item.status);
			patient.value = data.patient
			formStructure.value = data.form 
			item.value = data.item;
			selectedCase.value = data.item.cases_id
			form.value.params = data.item.data 
			isCourtHearing.value = data.isCourtHearing 
            isTelepsychiatry.value = data.isTelepsychiatry
            isPsychologicalTesting.value = data.isPsychologicalTesting
		})
		.finally(() => {
			loading.value = false;
		});
}

const isRequired = (value:any, vm:any):boolean => {
	console.log(vm);
	return vm.is_required;
}

//Validations
const rules = computed(() => {

	const rules = {
		cases_id : {
			required: helpers.withMessage('Case id is required', requiredIf(caseId.value != null)),
		},
		params: {
			$each: helpers.forEach({
				value: { 
					requiredIfFuction: requiredIf(isRequired),
				},
			})
		}
	}; 

	return rules;
})
const vuelidate = useVuelidate(rules, form);

const validate = async ():void => {
	const result = vuelidate.value.$validate();

	processing.value = false;
	loading.value = true;
	showConfirmation.value = false;

	await result
		.then(async (res) => {

			if(res) {
				await save()
			} else {
				errors.value = ErrorValidation.getErrors(vuelidate.value)
			}
		}).catch((err) => {
			console.log(err);
		})
		.finally(() => {
			loading.value = false;
		});
	}

const save = async (): void => {
	loading.value = true;
	showConfirmation.value = false;
	
	await InterventionService
		.update(
			item.value.id,
			patientId.value, 
			{
				cases_id: selectedCase.value,
				interventionType: interventionType.value,
				params: pickBy(form.value.params)
			},
			isNetworkAvailable.value
		)
		.then(() => {
			showSuccess.value = true;
		})
		.catch((error: object) => {
			errors.value = errorResponse(error.response?.data?.errors);
		})
		.finally(() => {
			loading.value = false;
		});
};

const redirectSuccess = (): void => {
	showSuccess.value = false;

	const route = caseId?.value ? `/patient/${patientId.value}/cases/${caseId.value}/interventions` : `/intervention/${patientId.value}/view`;

	router.replace(route);
};

watch(
	() => isNetworkAvailable.value,
	async (val) => {
        loading.value = false;
        await onlineSync.checkDrafts();
		init();
	}
);

onMounted(async () => {
    await onlineSync.checkDrafts();
	init()
})

</script>
