<template>
	<user-layout
	>
		<div class="w-full py-6 mx-auto">
			<div class="flex items-start justify-between" :class="resolveStatus ? 'mb-4' : 'mb-6'">
				<section-title title="Drafted Records" description="View the drafts in the system." />
				<!-- <button-component
                    v-if="items.length > 0"
					:disabled="resolveStatus"
					@click="reSync"
					:title="resolveStatus ? 'Resolve matched records below first' : ''"
					customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700
					disabled:bg-gray-300 disabled:cursor-disabled disabled:text-white
					">
					<ArrowPathIcon class="w-4 h-4 mr-1 text-white" aria-hidden="true" />
					<span>Sync</span>
				</button-component> -->
                <button-component
                    v-if="items.length > 0 && isActualNetworkAvailable"
					:disabled="!manualSync"
					@click="reSync"
					customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700
					disabled:bg-gray-300 disabled:cursor-disabled disabled:text-white
					">
					<ArrowPathIcon class="w-4 h-4 mr-1 text-white" aria-hidden="true" />
					<span>Sync</span>
				</button-component>
				<!-- <button-component
					@click="showPublish = true"
					customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700
					disabled:bg-gray-300 disabled:cursor-disabled disabled:text-white
					">
					<ArrowPathIcon class="w-4 h-4 mr-1 text-white" aria-hidden="true" />
					<span>Publish</span>
				</button-component> -->
			</div>
			<div v-if="!resolveStatus" class="flex items-center mb-2 text-red-600">
				<ExclamationCircleIcon class="w-6 h-6 mr-1" aria-hidden="true" />
				<span class="text-xs">The record highlighted in <strong>red</strong> has a match in the system. Please click check to resolve.</span>
			</div>
			<div>
				<data-table 
					:headers="headers" 
					:no-action="false"
					:count="items.length"
					tableClass="header-frm-table"
				>
					<template v-slot:body>
						<template 
							v-for="item in items" 
							:key="item"
						>
							<tr :class="item.status == 'To Resolve' ? 'bg-red-600 bg-opacity-10' : ''">
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.patient_name }}
                                    <p class="text-xs italic text-red-600">{{ item.synching_remarks }}</p>
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.birthdate_formatted }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.type }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ transform.dateFormat(item.updated_at, 'DD hh:mm a') }}
								</td>
								<td class="px-6 py-6 text-sm whitespace-nowrap"
									:class="item.status == SyncStatus.RECORD_CONFLICT ? 'text-red-600' :
									item.status ==  SyncStatus.SYNC ? 'text-green-600' : 'text-gray-900'"
								>
									{{ getLabel(item.status, SyncStatusOptions) }}
								</td>
								<td 
									class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap"
								>
									<div 
										class="flex items-center justify-center mx-auto"
									>
										<check-button
                                            v-if="item.table === 'patients' && item.is_created_offline && isActualNetworkAvailable"
											@click="lookupPatients(item)"
										/>
                                        
										<view-button
                                            class="mr-2"
                                            v-if="item.url_view && !isNetworkAvailable && item.can_view"
											@click="router.replace(item.url_view)"
										/>
										<delete-button
                                            v-if="item.status != SyncStatus.SYNC"
											@click="showDeleteModal(item)" 
										/>
									</div>
								</td>
							</tr>
						</template>
					</template>
					<!-- <template #tfoot>
						<div class="w-full px-6 border-t py-7">
							<minify-pagination num-rows="All"></minify-pagination>
						</div>
					</template> -->
				</data-table>
			</div>
		</div>

		<!-- Delete Records -->
		<delete-confirmation-modal
			:typeTwo="true"
			:show="showDeleteConfirmation"
			@cancel="showDeleteConfirmation = false"
			@confirm="deleteRecord"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Discard Draft"
			content="Are you sure you wish to discard this draft? "
		/>
		<success-modal
			:show="showDeleteSuccess"
			@confirm="closeDeleteModal"
			action-text="Close"
			title="Draft Discarded!"
			content="Draft has been successfully discarded"
		/>

		
		<!-- Initial Sync -->
		<sync-modal
			title="Record Syncing"
			content="Please don't close while the data is syncing"
			:show="showSync"
			@confirm="
				showSync = false;
				showSyncSuccess = true;
			"
			width="w-[480px]" 
		/>
		<success-modal
			:show="showSyncSuccess"
			@confirm="refreshPage()"
			action-text="Close"
			title="Record Synced!"
			content="Offline record has been successfully synced. Redirecting back to the dashboard"
		/>

		<!-- Publish Sync -->
		<sync-modal
			title="Publishing Records"
			content="Please don't close while the record is publishing"
			:show="showPublish"
			@confirm="
				showPublish = false;
				showPublishSuccess = true;
			"
			width="w-[480px]"
		/>
		<success-modal
			:show="showPublishSuccess"
			@confirm="showPublishSuccess = false"
			action-text="Close"
			title="Record Published!"
			content="Offline drafts has been successfully published" 
		/>

		<!-- Resolve Records -->
		<resolution-modal
			:show="showResolution"
			@cancel="showResolution = false"
			@confirm="syncCreatePatient(item, patientLists.form)"
			cancel-text="Cancel"
			action-text="Create New Record"
			title="Resolution Panel"
			description="Select patient you want to save the record"
            :is-network-available="isActualNetworkAvailable"
		>
			<template #content>
				<div>
					<data-table 
					:headers="resolutionHeaders" 
					:no-action="false"
					:count="patientLists.items.length"
					>
						<template #body>
							<template 
								v-for="item in patientLists.items" 
								:key="item"
							>
								<tr>
									<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
										{{ item.name }}
									</td>
									<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
										{{ item.latest_case_number }}
									</td>
									<td 
										class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap"
									>
										<div 
                                            v-if="isActualNetworkAvailable"
											class="flex items-center justify-center mx-auto"
										>
											<button-component
												@click="syncPatient(item, patientLists.form)"
												size="small"
												customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 px-2 mr-1">
												<ArrowPathIcon class="w-4 h-4 mr-1 text-white" aria-hidden="true" />
												<span>Sync</span>
											</button-component>
											<!-- <view-button
												@click="router.replace(`/patient/${item.id}/view`)"
											><span class="ml-1 font-semibold text-white">View</span></view-button> -->
										</div>
									</td>
								</tr>
							</template>
						</template>
					</data-table>
				</div>
			</template>
		</resolution-modal>

        <sync-confirmation-modal
			:typeTwo="true"
			:show="showResolutionConfirmation"
			@cancel="showResolutionConfirmation = false"
			@confirm="processSynching()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Sync Record"
			content="Are you sure you wish to sync this record to the existing patient?"
		/>
		
		<page-loader :show="loading"/>

	</user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import DataTable from "@/components/partials/DataTable.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import SyncModal from "@/components/modals/SyncModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ResolutionModal from './Components/ResolutionModal.vue';
import DeleteConfirmationModal from '@/components/modals/DeleteConfirmationModal.vue';
import SyncConfirmationModal from '@/components/modals/SyncConfirmationModal.vue';
import CheckButton from '@/components/buttons/CheckButton.vue';
import ViewButton from '@/components/buttons/ViewButton.vue';
import DeleteButton from '@/components/buttons/DeleteButton.vue';
import { ArrowPathIcon } from "@heroicons/vue/24/solid";
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";
import PageLoader from "@/components/loaders/PageLoader.vue"

import { EnumsInterface } from "@/interfaces/EnumsInterface";

import { SyncStatus } from "@/views/enums/SyncStatus";

import DraftTypesOptions from "@/enums/DraftTypesOptions"
import SyncStatusOptions from "@/enums/SyncStatusOptions";
import Storage from "@/helpers/storage";

import { useRouter } from "vue-router";

import DraftService from "@/classes/Drafts/DraftService";
import { onIonViewWillEnter } from "@ionic/vue";
import Relationships from "@/classes/Forms/Relationships";
import { useDraftLogger } from "@/store/draftlogger";
import http from "@/axios";
import { useNetworkStore } from "@/store/network";
import OnlineSynching from "@/classes/Synching/OnlineSynching";
import { RoleTypes } from "../enums/RoleTypes";

const router = useRouter();
const storage = new Storage();
const showSync = ref<boolean>(false);
const showSyncSuccess = ref<boolean>(false);

const showPublish = ref<boolean>(false);
const showPublishSuccess = ref<boolean>(false);
const transform = new Relationships();
const showResolution = ref<boolean>(false);
const showResolutionConfirmation = ref<boolean>(false);
const showResolutionNewConfirmation = ref<boolean>(false);
const manualSync = computed(() => localStorage.getItem('cpn-online-sync-attemp') ? true : false);

const tokens = new Storage();

const user = JSON.parse(tokens.get("user"));

const resolveStatus = computed<boolean>(() => {
    if (hasErrors.value) {
        return false;
    }
    return true;
});

const items = ref<any>([]);

const loading = ref<boolean>(true);

const showDeleteConfirmation = ref<boolean>(false);
const showDeleteSuccess = ref<boolean>(false);

const selectedRecord = ref<any | null>(null);
const draft = useDraftLogger();
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const isActualNetworkAvailable = computed(() => networkStore.isActualNetworkAvailable);
const onlineSync = new OnlineSynching();
const hasErrors = ref<boolean>(false)

const getLabel = (id: number, options:Array<EnumsInterface>): string => {
	const index = options.findIndex(option => option.value === id);
    if (index > -1) {
        return options[index].label;
    }
    return "";
}

const init = async (): Promise<void> => {
    items.value = [];
    await renderPatients(true);
    await renderCases(true);
    await renderCaseGuardians(true);
    await renderCaseInformant(true);
    await renderPerpetrators(true);
    await renderCasePerpetrator(true);
    await renderInterventions(true);
    await renderCaseConferenceInformation(true);
    await renderCaseConferenceFactor(true);
    await renderCaseConferenceRecommendation(true);
    await renderCasePatientAddress(true);
    await renderIntakePersonnalInformation(true);
    await renderIntakePersonRelatedToCase(true);
    await renderIntakeContactNumber(true);
    await renderIntakeEmail(true);
    await renderIntakeIncestCase(true);
    await renderCaseSafeAssessment(true);
    loading.value = false;
}

const defaultResponseBuilder = (response: any, isRenderData = false) => {
    items.value.push(...response.items);
    hasErrors.value = response.hasError;
    if (response.hasError && isRenderData) {
        loading.value = false;
    }
    if (response.hasError && !isRenderData) {
        showSync.value = false;
    }
    return response.hasError;    
}

const renderPatients = async (isRenderData = false) => {
    const response = await onlineSync.renderPatients(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCases = async (isRenderData = false) => {
    const response = await onlineSync.renderCases(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderPerpetrators = async (isRenderData = false) => {
    const response = await onlineSync.renderPerpetrators(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCasePerpetrator = async (isRenderData = false) => {
    const response = await onlineSync.renderCasePerpetrator(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCaseGuardians = async (isRenderData = false) => {
    const response = await onlineSync.renderCaseGuardians(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCaseInformant = async (isRenderData = false) => {
    const response = await onlineSync.renderCaseInformant(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCasePatientAddress = async (isRenderData = false) => {
    const response = await onlineSync.renderCasePatientAddress(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderInterventions = async (isRenderData = false) => {
    const response = await onlineSync.renderInterventions(isRenderData);
	return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderIntakePersonnalInformation = async (isRenderData = false) => {
    const response = await onlineSync.renderIntakePersonnalInformation(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderIntakePersonRelatedToCase = async (isRenderData = false) => {
    const response = await onlineSync.renderIntakePersonRelatedToCase(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderIntakeContactNumber = async (isRenderData = false) => {
    const response = await onlineSync.renderIntakeContactNumber(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderIntakeEmail = async (isRenderData = false) => {
    const response = await onlineSync.renderIntakeEmail(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderIntakeIncestCase = async (isRenderData = false) => {
    const response = await onlineSync.renderIntakeIncestCase(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCaseSafeAssessment = async (isRenderData = false) => {
    const response = await onlineSync.renderCaseSafeAssessment(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCaseConferenceInformation = async (isRenderData = false) => {
    const response = await onlineSync.renderCaseConferenceInformation(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCaseConferenceFactor = async (isRenderData = false) => {
    const response = await onlineSync.renderCaseConferenceFactor(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

// @TODO for updating
const renderCaseConferenceRecommendation = async (isRenderData = false) => {
    const response = await onlineSync.renderCaseConferenceRecommendation(isRenderData);
    return defaultResponseBuilder(response, isRenderData);
}

const showDeleteModal = (item: any): void => {
	showDeleteConfirmation.value = true;
	selectedRecord.value = item;
};

const deleteRecord = async (): Promise<void> => {
    showDeleteConfirmation.value = false;
	loading.value = true
    const item = selectedRecord.value;
    await onlineSync.deleteDraft(item);
    items.value = [];
    await init();
    draft.hasDraft = true;
    loading.value = false;
}

const closeDeleteModal = (): void => {
	showDeleteSuccess.value = false;
};

const patientLists = ref({});
const selectedForm = ref(null);
const lookupPatients = async (item: any) => {
    const response = await http.post('/attemp-synching-patient-lookup', item).catch(error => error);
    if (response?.status === 200) {
        patientLists.value = {
            items: response.data,
            form: item,
        };
        showResolution.value = true;
    }
}

const syncPatient = (item: any, formItem: any) => {
    selectedForm.value = null;
    if (!isActualNetworkAvailable.value) {
        return;
    }
    showResolution.value = false; 
    showResolutionConfirmation.value = true
    formItem.newId = item.id;
    selectedForm.value = formItem;
}

const syncCreatePatient = async (item: any, formItem: any) => {
    selectedForm.value = null;
    if (!isActualNetworkAvailable.value) {
        return;
    }
    showResolution.value = false; 
    showResolutionNewConfirmation.value = true
    selectedForm.value = formItem;
    await processCreateSynching();
}

const processSynching = async () => {
    if (!isActualNetworkAvailable.value) {
        return;
    }
    
    showSync.value = true; 
    showResolutionConfirmation.value = false;
    const response = await onlineSync.processSynching(selectedForm.value);
    hasErrors.value = response;
    await init();
    showSync.value = false; 
    draft.hasDraft = true;
}

const processCreateSynching = async () => {
    if (!isActualNetworkAvailable.value) {
        return;
    }
    
    showSync.value = true; 
    showResolutionNewConfirmation.value = false;
    const response = await onlineSync.processCreateSynching(selectedForm.value);
    hasErrors.value = response;
    await init();
    showSync.value = false; 
    draft.hasDraft = true;
}

const reSync = async () => {
    await attempSynching();
    await init();
}

const attempSynching = async () => {
    if (!isActualNetworkAvailable.value) {
        showSync.value = false;
        return;
    }
    showSync.value = true;
    draft.hasDraft = false;
    const resultPatient = await renderPatients();
    if (!resultPatient) {
        const resultCase = await renderCases();
        if (!resultCase) {
            await renderCaseGuardians();
            await renderCaseInformant();
            await renderCasePatientAddress();
            const resultPerpetrator = await renderPerpetrators();
            if (!resultPerpetrator) {
                await renderCasePerpetrator();                
            }
            await renderInterventions();
            const resultCaseConference = await renderCaseConferenceInformation();
            console.log(resultCaseConference, 'case conference synching result')
            if (!resultCaseConference) {
                // process related case conference
                await renderCaseConferenceFactor();
                await renderCaseConferenceRecommendation();
            }

            const resultIntake = await renderIntakePersonnalInformation();
            if (!resultIntake) {
                // process related case conference
                await renderIntakePersonRelatedToCase();
                await renderIntakeContactNumber();
                await renderIntakeEmail();
                await renderIntakeIncestCase();
                await renderCaseSafeAssessment();
            }
        }
    }
    await onlineSync.checkDrafts();
    // @TODO temporary hidden for fixing
    // if (!resultPatient) {
    //     const resultCase = await renderCases();
    //     if (!resultCase) {


    //         await renderCaseSafeAssessment();
    //     }
    // }

    if (resolveStatus.value)  {
        draft.count = 0;
    }
    draft.hasDraft = true;
    showSync.value = false;
    showSyncSuccess.value = true;
}

const refreshPage = () => {
    showSyncSuccess.value = false
    gotoDashboard(user.role_id);
}


const gotoDashboard = (role:number):void =>{
    const wcpuRoles = [
        RoleTypes.OFFICE_ADMIN,
        RoleTypes.OFFICE_NORMAL,
        RoleTypes.NURSE,
        RoleTypes.WCPU,
        RoleTypes.OFFICE_TRAINEE,
    ]

    if(wcpuRoles.includes(role)){
		router.push({ name: "DashboardIndexPageWcpu" })
    } else {
		router.push({ name: "DashboardIndexPage" })
    }
}


onIonViewWillEnter(async () => {
    items.value = [];
    draft.hasDraft = false;
    if (!localStorage.getItem('cpn-online-sync-attemp')) {
        // await attempSynching();
        localStorage.setItem('cpn-online-sync-attemp', 1);
    }
    loading.value = true;
    await init();
    await onlineSync.checkDrafts();
});

watch(
    () => isActualNetworkAvailable.value,
    async (val) => {
        if (!val) {
            showSyncSuccess.value = false;
            loading.value = false;
            await init();
            await onlineSync.checkDrafts();
        }
    }
);

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "Name" },
	{ text: "Birthday" },
	{ text: "Type" },
	{ text: "Date Created/Updated" },
	{ text: "Status" },
];

const resolutionHeaders: { text: string; haveSort?: boolean }[] = [
	{ text: "Patient Name" },
	{ text: "Latest Case No." }
];
</script>