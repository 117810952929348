import http from "@/axios";
import { Perpetrator } from "@/interfaces/PerpetratorInterface";
class PerpetratorService implements Perpetrator {
	async index(payload: object): Promise<any> {
		return await http.get("perpetrators", { params: payload }).catch(error => error);
	}

	async show(perpetrator: number, payload: any): Promise<any> {
		return await http.get(`perpetrators/${perpetrator}/show`, { params: payload }).catch(error => error);
	}

	async cases(perpetrator: number, payload: any): Promise<any> {
		return await http.get(`perpetrators/${perpetrator}/cases`, { params: payload }).catch(error => error);
	}

	async edit(perpetrator: number, payload: any): Promise<any> {
		return await http.get(`perpetrators/${perpetrator}/edit`, { params: payload }).catch(error => error);
	}

	async update(perpetrator: number, payload: any): Promise<any> {
		return await http.put(`perpetrators/${perpetrator}/update`, payload).catch(error => error);
	}
}

export default new PerpetratorService();
