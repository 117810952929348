import PatientService from "@/classes/PatientService";
import InterventionService from "@/classes/Interventions/InterventionService";
import { CaseHistory } from "./Offline/Interface/CaseHistory";
import CaseOfflineService from "./Offline/OfflineService";
import CaseService from "../CaseService";

class CaseHistoryService implements CaseHistory {
    async index(patient: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await PatientService.edit(patient,payload);
        } else {
            return await CaseOfflineService.index(patient, payload);
        }
    }

    async latestInterventions(patient: number, case_id: number|null = null, payload: object, networkAvailable = true): Promise<any> {
        return InterventionService.index(patient, case_id, payload, networkAvailable);
    }

    async create(patient: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CaseService.create(patient, payload);
        } else {
            return await CaseOfflineService.create(patient, payload);
        }
    }

    async store(patient: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CaseService.store(patient, payload);
        } else {
            return await CaseOfflineService.store(patient, payload);
        }
    }

    async edit(patient: number, case_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CaseService.edit(patient, case_id, payload);
        } else {
            return await CaseOfflineService.edit(patient, case_id, payload);
        }
    }

    async update(patient: number, case_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CaseService.update(patient, case_id, payload);
        } else {
            return await CaseOfflineService.update(patient, case_id, payload);
        }
    }
}

export default new CaseHistoryService();