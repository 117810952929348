<template>
	<user-layout
		hasBack
		hasRightCon
		:backLink="`/patient/${route.params.patient}/view`"
		backTitle="Patient and Case Details"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>

			<button-component
				v-if="newRecord && hasPermission('can-update-patient-incident')"
				customClass="primary md:w-auto w-full md:mt-0 mt-6"
				form="form"
				@click="showConfirmation = true">
				<!-- <img src="/assets/icon/ic-save.svg" alt="save_icon" class="object-contain w-5 h-5 mr-1 icon" /> -->
				<save-icon class="object-contain w-5 h-5 mr-1 icon" />
				<span>Save Record</span>
			</button-component>

			<div v-if="!newRecord" class="flex md:items-center md:space-x-3 md:space-y-0 space-y-3 md:w-auto w-full md:flex-row flex-col md:mt-0 mt-6">
				<button-component
					v-if="hasPermission('can-update-patient-incident')"
					@click="newRecord = true"
					customClass="primary md:w-auto w-full"
				>
					<!-- <img
						src="/assets/icon/ic-pencil-underline.svg"
						alt="edit_icon"
						class="object-contain w-4 h-4 mr-1 icon" /> -->
					<pencil-underline-icon class="object-contain w-4 h-4 mr-1 icon" />
					<span>Edit</span>
				</button-component>
				<a v-if="hasPermission('can-view-patient-case-inpdf') && isNetworkAvailable">
					<button-component
						@click="generatePDFAccess('profile')"
						size="large"
						customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 md:w-auto w-full"
					>
						<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
						<span>View Patient Record</span>
					</button-component>

					<p
						v-if="errors?.to"
						class="text-[10px] mt-1 text-red-600"
						id="email-error"
					>
						{{ errors?.to }}
					</p>
				</a>
				<button-component
                    v-if="isNetworkAvailable"
					size="large"
					@click="showContent = true"
					customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 md:w-auto w-full">
					<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
					<span>View SCAR</span>
				</button-component>
			</div>
		</template>
		<div class="mt-6">
			<form-section
				v-if="loaded"
				:newRecord="newRecord"
				:form="form"
				:errors="errors"
				:additional-data="additionalData"
				:item="item"
                @update:form="(value: any) => form = value"
                >
				<confirmation-modal
					:typeTwo="true"
					:show="showConfirmation"
					@cancel="showConfirmation = false"
					@confirm="save()"
					cancel-text="Cancel"
					action-text="Confirm"
					title="Update Record"
					content="Are you sure you wish to update this record?" />
				<success-modal
					:show="showSuccess"
					@confirm="redirectSuccess()"
					action-text="Close"
					title="Record Updated!"
					:content="successMessage" />
			</form-section>
		</div>
		<template #rightContent>
			<div class="lg:sticky static lg:my-10 top-10 lg:h-[70dvh] lg:pb-10 overflow-auto lg:mt-0 mt-6">
				<side-nav :hasRecord="newRecord" :item="item" :other-details-count="otherDetailsCount"/>
			</div>
		</template>

		<content-modal
			:show="showContent" 
			title="Add Recipient & Attention"
		>
			<div>
				<text-input
					label="To:"
					placeholder="To"
					name="to"
					id="to"
					required
					v-model="formContent.to"
					:error="errors.to"
				/>
			</div>

			<div>
				<text-input
					label="Attn:"
					placeholder="Attn"
					name="attn"
					id="attn"
					required
					v-model="formContent.attn"
					:error="errors.attn"
				/>
			</div>

			<template #button>
				<button-component  
					btnWidth="quinary"
					@click="showContent = false"
				>
					Cancel
				</button-component>
				<a
					v-if="hasPermission('can-view-patient-case-inpdf')"
					@click="generatePDFAccess('scar')"
				>
					<button-component customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700">
						<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
						<span>View PDF</span>
					</button-component>
				</a>
			</template>
		</content-modal>

		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import FormSection from "./FormSection.vue";
import SideNav from "./Components/SideNav.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { EyeIcon } from "@heroicons/vue/24/solid";
import { useRouter, useRoute } from "vue-router";
import { CreatePatient } from "@/interfaces/PatientInterface";
import CaseService from "@/classes/Cases/CaseService";
import { errorResponse } from "@/helpers/errorMessage";
import { hasPermission } from "@/classes/AuthService";
import PageLoader from "@/components/loaders/PageLoader.vue"; 
import pickBy from "lodash/pickBy"; 
import ContentModal from "./otherInformations/intakes/Component/ContentModal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import { onIonViewWillEnter } from "@ionic/vue"; 
import SaveIcon from "@/components/icons/saveIcon.vue";
import PencilUnderlineIcon from "@/components/icons/pencilUnderlineIcon.vue"; 
import CaseHistoryService from "@/classes/Cases/CaseHistory/CaseHistoryService";
import { useNetworkStore } from "@/store/network";
import PatientRecordService from "@/classes/Patients/PatientRecordService";
import PatientOfflineForm from "@/classes/Patients/Offline/Formatters/PatientOfflineForm";
import CaseOfflineForm from "@/classes/Cases/CaseHistory/Offline/Formatters/CaseOfflineForm";
import useVuelidate from "@vuelidate/core";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import Rules from "@/classes/Patients/Offline/Validation/Rules";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import OnlineSynching from "@/classes/Synching/OnlineSynching";

const router = useRouter();
const route = useRoute(); 
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const recordStatusChecker = useRecordStatusChecker();
const isRecordDeleted = computed(() => recordStatusChecker.isDeleted);
const onlineSync = new OnlineSynching();
// Set to False to enable create other details
const newRecord = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully saved!");
const additionalData = ref<object>({}); 
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const item = ref<object>({});
const loaded = ref<boolean>(false);
const form = ref<CreatePatient>(PatientOfflineForm.createPatientCaseForm());
const errors = ref<object>({});
const loading = ref<boolean>(false); 
const showContent = ref(false);  
const otherDetailsCount = ref<{
	guardians: number;
	companions: number;
	patientAddresses: number;
	perpetrators: number;
	interventions: number;
	caseConferences: number;
}>({
    guardians: 0,
    companions: 0,
    patientAddresses: 0,
    perpetrators: 0,
    interventions: 0,
    caseConferences: 0,
});

const formContent = ref({
	to: null,
	attn: null
})
const { createPatienCasetRules } = Rules

/**
 * Validate the form using Vuelidator
 */
 const validateForm = async () =>{
	const vuelidate = useVuelidate(createPatienCasetRules({...form.value}), form.value)
	const result = await vuelidate.value.$validate();

    if (result) {
        return null;
    } else {
        errors.value = ErrorValidation.getErrors(vuelidate.value);
        return errors.value;
    }
}

const save = async (): Promise<any> => {
	loading.value = true;
	errors.value = {};

    // Set validation rule for Online and Offline
    const validate = await validateForm();
    if (validate) {
        loading.value = false;
        showConfirmation.value = false;
        return;
    }

    const response = await CaseHistoryService.update(
		patientId.value,
		caseId.value,
		{ ...form.value },
        isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        await getData();
        loading.value = false;
        showSuccess.value = true;
        showConfirmation.value = false;
        successMessage.value = response.data.message;
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
        showConfirmation.value = false;
        loading.value = false;
    }

};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	newRecord.value = false;
    router.push(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
};

const template = (): void => {
	form.value.mental_health = additionalData.value.mentalHealthDiagnoses?.map((item) => {
		const newForm = form.value.mental_health?.filter((mental_health) => {
			if (mental_health.mental_health_diagnoses_id == item.id) {
				return {
					mental_health_diagnoses_id: item.id,
					type: mental_health.type,
					specify: mental_health.specify,
					behavior_type_id: mental_health.behavior_type_id,
					checked: true,
				};
			}
		}).map((mental_health) => {
            return {
                mental_health_diagnoses_id: item.id,
                type: mental_health.type,
                specify: mental_health.specify,
                behavior_type_id: mental_health.behavior_type_id,
                checked: true,
            };
        });
		return newForm?.length > 0
			? newForm[0]
			: { mental_health_diagnoses_id: "", type: "", specify: "", behavior_type_id: "", checked: false };
	});
	form.value.comorbidities = additionalData.value.comorbidities?.map((item) => {
		const newForm = form.value.comorbidities.filter((comorbidity) => {
			if (comorbidity.comorbidity_id == item.id) {
				return {
					comorbidity_id: item.id,
					checked: true,
				};
			}
		}).map((comorbidity) => {
            if (comorbidity.comorbidity_id == item.id) {
				return {
					comorbidity_id: item.id,
					checked: true,
				};
			}
        });
		return newForm?.length > 0 ? newForm[0] : { comorbidity_id: null, checked: false };
	});
};

const fillUpForm = (item: object): void => {
    form.value = {
        ...PatientOfflineForm.fillPatientForm(item.patient),
        ...CaseOfflineForm.fillCaseForm(item),
    }
	form.value.is_reabuse = form.value.is_reabuse ? 1 : 0;
};

const getData = async (): Promise<any> => {
	loading.value = true;
    const response = await CaseHistoryService.edit(
        patientId.value, 
        caseId.value,
        pickBy({}),
        isNetworkAvailable.value,
    );

    if (response?.status === 200 || response?.data != undefined) {
    
			additionalData.value = response.data;

            item.value = {
                ...response.data.item,
                patient: response.data.patient,
                hasCaseConference: response.data?.hasCaseConference,
            };
            
            if (isNetworkAvailable.value) {
                await PatientRecordService.syncToOffline(response.data?.downloadables);
            }

        fillUpForm(item.value);
        template();

        newRecord.value = route.query?.viewForm ? route.query.viewForm == 'true' : false;
        loaded.value = true;
        loading.value = false;
    } else {
        const error = response;
        loading.value = false;
        console.log(error);
    }
};

const generatePDFAccess = (type:string):void => {
	CaseService
		.generatePDFAccess(
			patientId.value,
			caseId.value,
			{
				...formContent.value,
				type: type
			}
		)
		.then(({data}) => {
			if(data.token){
				if(type == 'scar'){
					downloadScar(data.token)
					showContent.value = false
				} else {
					downloadPatientRecord(data.token)
				}
			}
		}).catch((error) => {
			errors.value = errorResponse(error.response?.data?.errors)
		})

}
const downloadPatientRecord = (token:string):void => {
	CaseService
		.downloadPatientRecord(
			patientId.value,
			caseId.value,
			token 
		)
		.then(({data}) => {
			console.log(data);
		}).catch((error) => {
			console.log(error);
			
		})
}

const downloadScar = (token:string):void => {
	CaseService
		.downloadScar(
			patientId.value,
			caseId.value,
			token, 
			{
				...formContent.value,
				type: 'scar'
			}
		)
		.then(({data}) => {
			console.log(data);
		}).catch((error) => {
			errors.value = errorResponse(error.response?.data?.errors)
		})
}

watch(
    () => isRecordDeleted.value,
    async (val) => {
        if (val) {
            loading.value = false;
            await getData();
            await onlineSync.checkDrafts();
        }
    }
);

watch(
    () => isNetworkAvailable.value,
    async () => {
        loading.value = false;
        await getData();
        await onlineSync.checkDrafts();
    }
);
 
onIonViewWillEnter(async () => {
    await getData(); 
    await onlineSync.checkDrafts();
});

 
</script>
