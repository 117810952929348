import { helpers, required } from "@vuelidate/validators";

const createRule = (form: any) => {
    return {
        agency_id: {
            required: helpers.withMessage('The other agencies is required', required),
        },
        name: {
            required: helpers.withMessage('The agency name is required', required),
        }
    }
}

export default {
    createRule
}