import PerpetratorOfflineForm from "../Formatters/PerpetratorOfflineForm";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import Relationships from "@/classes/Forms/Relationships";
import PerpetratorTemplateForm from "../Formatters/PerpetratorTemplateForm";
import PatientOfflineProcessor from "@/classes/Patients/Offline/processor/PatientOfflineProcessor";
import CaseOfflineProcessor from "@/classes/Cases/CaseHistory/Offline/processor/CaseOfflineProcessor";
import LinkPerpetratorOfflineForm from "../Formatters/LinkPerpetratorOfflineForm";

const sqlite = new SqliteProcessor;
const table = 'perpetrators';
const linkTable = 'case_perpetrators';
const syncable = new Syncable;
const relationship = new Relationships();
class PerpetratorProcessor {
    async syncToOffline(patient: number, case_id: number, payload: any) {
        await sqlite.connect();
        syncable.table = table;
        const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, payload.perpetrator_id || payload.id);
        if (checkRecord) {
            return false;
        }

        const form = PerpetratorOfflineForm.renderSyncToOffline(payload);
        form.id = payload.perpetrator_id || payload.id;
        form.first_name = PerpetratorOfflineForm.setFirstname(form);
        syncable.table = table;
        await syncable.sync(sqlite.db, [form]);

        // link perpetrator
        form.case_perpetrator_id = payload.case_perpetrator_id || payload.id;
        await this.linkPerpetrator(case_id, form, true);
    }

    async store(patient: number, case_id: number, payload: any) {
        await sqlite.connect();
        const form = PerpetratorOfflineForm.renderSyncToOffline(payload);
        form.is_sync = false;
        form.synching_remarks = null;
        form.is_created_offline = true;
        form.created_at = relationship.dateFormatFromIso(new Date);
        form.first_name = PerpetratorOfflineForm.setFirstname(form);
        await sqlite.db.insert(table, PerpetratorOfflineForm.defaultPayloadValue(form, payload));

        // link perpetrator
        const perpetrator = await this.getLatestPerpetrator();
        if (perpetrator) {
            await this.linkPerpetrator(case_id, perpetrator);
        }

        return await this.getLatestPerpetrator();
    }

    async update(patient: number | null, case_id: number | null, perpertrator_id: number, payload: any) {
        await sqlite.connect();
        const form = LinkPerpetratorOfflineForm.renderSyncToOffline(payload);
        form.is_sync = false;
        form.synching_remarks = null;
        form.is_updated_offline = true;
        form.cases_id = case_id;
        form.perpetrator_id = perpertrator_id;
        form.updated_at = relationship.dateFormatFromIso(new Date);
        form.first_name = PerpetratorOfflineForm.setFirstname(form);
        await sqlite.db.update(linkTable, PerpetratorOfflineForm.defaultPayloadValue(form, payload));

        return true;
    }

    async unlinkPerpetrator(case_id: number, perpetrator_id: number, forceDelete = false) {
        await sqlite.connect();
        const response = await this.checkPerpetratorLink(case_id, perpetrator_id);
        console.log(response)
         if (response) {
            // force delete the matched record once remove in online
            if (forceDelete) {
                await sqlite.db.delete(linkTable, { id: response.id });
                return true;
            }

            // if perpetrator link is created offline delete the record
            if (response.is_created_offline) {
                await sqlite.db.delete(linkTable, { id: response.id });
            } else {
                // if from online update status
                const form = {
                    id: response.id,
                    is_sync: false,
                    synching_remarks: null,
                    is_deleted_offline: true,
                    deleted_at: relationship.dateFormatFromIso(new Date),
                }
                await sqlite.db.updateWithFilters(linkTable, form, { cases_id: case_id, id: perpetrator_id });
            }
        }

        return true;
    }

    async getLatestPerpetrator(id: any = null, setTable: any = null) {
        const loadTable = setTable || table 
        let sql = '';
        if (id) {
            sql = `WHERE id=${id}`;
        }
        const response = await sqlite.db.select(`SELECT * FROM ${loadTable} ${sql} ORDER BY id DESC LIMIT 1`);
        if (response.length > 0) {
            return response[0];
        }

        return null;
    }

    async linkPerpetrator(case_id: number, payload: any, isSyncing = false) {
        await sqlite.connect();
        const perpetratorId = payload.perpetrator_id || payload.id;
        const form = LinkPerpetratorOfflineForm.renderSyncToOffline(payload);
        form.id = isSyncing ? (payload.case_perpetrator_id || payload.id) : null;
        form.cases_id = case_id;
        form.perpetrator_id = perpetratorId;
        form.is_created_offline = isSyncing ? false : true;
        form.created_at = isSyncing ? null : relationship.dateFormatFromIso(new Date);
        form.other_nationality = payload.other_nationality || null;
        form.other_religion = payload.other_religion || null;

        syncable.table = linkTable;
        await sqlite.db.delete(linkTable, {cases_id: case_id, perpetrator_id: perpetratorId});
        return await syncable.sync(sqlite.db, [form]);
    }
    
    async checkPerpetratorLink(case_id: number, perpetrator_id: number) {
        const response = await sqlite.db.select(`SELECT * FROM ${linkTable} WHERE cases_id=${case_id} AND id=${perpetrator_id}`);
        if (response.length > 0) {
            return response[0];
        }

        return null;
    }

    async create(patient: number) {
        await sqlite.connect();
        const response = await PatientOfflineProcessor.getLatestStoredPatient(patient);
        return await PerpetratorTemplateForm.createPerpetratorTemplate(response);
    }

    async index(patient: number, case_id: number, payload: any) {
        await sqlite.connect();
        const setTable = payload.is_searching ? '' : linkTable;
        const response = await this.perpertrators(case_id, payload, setTable)
        return {
            data: { 
                items: await PerpetratorOfflineForm.renderSearch(response),
                patient: await PatientOfflineProcessor.getFullName(patient),
             },
        }
    }

    async perpertrators(case_id: number, payload: any, setTable: any = null) {
        syncable.table = setTable || table;
        payload.rows = 10;
        let ids: any = null;
        if (setTable) {
            ids = PerpetratorOfflineForm.renderPerpetratorId(
                await this.getLinkedPerpetrators(case_id)
            )?.join(',');
        }

        const findColumn = setTable ? 'perpetrator_id' : 'id';
        const response = await syncable.paginate(sqlite.db, payload, (query: string) => {
            const where = setTable ? ` WHERE ${findColumn} IN (${ids})` : '';
            return PerpetratorOfflineForm.searchRule(payload, where);
        });

        return response;
    }

    async getCount(case_id: number) {
        const response = await sqlite.db.select(`SELECT count(id) as count FROM ${linkTable} WHERE cases_id=${case_id} AND deleted_at IS NULL`);
        return response.length > 0 ? response[0].count : 0;
    }

    async latestCaseNumber(perpetrator: number) {
        const response = await sqlite.db.select(`SELECT * FROM ${linkTable} WHERE perpetrator_id=${perpetrator} ORDER BY id DESC`);
        if (response.length) {
            const case_id = response[0].cases_id;

            const result = await CaseOfflineProcessor.getCase(case_id);
            if (result) {
                return result.case_number;
            }
        }

        return null;
    }

    async isLinkCreatedOffline(case_id: number, perpetrator_id: number) {
        const response = await this.checkPerpetratorLink(case_id, perpetrator_id);

        if (response) {
            return response.is_created_offline ? true : false;
        }

        return false;
    }
    
    async getLinkedPerpetrators(case_id: number) {
        return await sqlite.db.select(`SELECT * FROM ${linkTable} WHERE cases_id=${case_id} AND deleted_at IS NULL`);
    }

    async edit(patient: number | null, perpertrator_id: number, payload: any = null) {
        await sqlite.connect();
        const response = await PatientOfflineProcessor.getLatestStoredPatient(patient);
        const setTable = payload?.is_perpetrator ? table : linkTable;
        return await PerpetratorTemplateForm.editPerpetratorTemplate(response, perpertrator_id, setTable);
    }

    async search(payload: any) {
        await sqlite.connect();
        syncable.table = table;
        payload.rows = 10;
        const response = await syncable.paginate(sqlite.db, payload, (query: string) => {
            return PerpetratorOfflineForm.searchRule(payload);
        });

        return {
            data: {
                items: await PerpetratorOfflineForm.renderSearch(response),
            }
        };
    }

    async getPerpetrator(peretrator_id: number) {
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${peretrator_id}`);
        if (response.length > 0) {
            return response[0];
        }

        return null;
    }

    async view(perpertrator_id: number, payload: any) {
        await sqlite.connect();
        const peretrator = await this.getPerpetrator(perpertrator_id);
        let response = '';
        if (peretrator) {
            response = await PerpetratorOfflineForm.renderPerpetratorDetails(peretrator);
        }

        return {
            data: {
                item: response
            }
        };
    }

    async getLinkCases(perpertrator_id: number) {
        return await sqlite.db.select(`SELECT * FROM ${linkTable} WHERE perpetrator_id=${perpertrator_id} AND deleted_at IS NULL`);
    }

    async cases(peretrator_id: number, payload: any) {
        const cases = (await this.getLinkCases(peretrator_id)).map((item: any) => item.cases_id);
        return {
            data: {
                items: await CaseOfflineProcessor.viewPerpetratorCaseCardHistory(cases, payload)
            }
        }
    }

    async updateProfile(perpertrator_id: number, payload: any) {
        await sqlite.connect();
        const form = PerpetratorOfflineForm.renderSyncToOffline(payload);
        form.id = perpertrator_id;
        form.first_name = PerpetratorOfflineForm.setFirstname(form);
        syncable.table = table;
        await syncable.sync(sqlite.db, [form]);
    }

    async syncToOfflinePerpetrator(id: number, payload: any) {
        await sqlite.connect();
        syncable.table = table;
        const checkRecord = await syncable.checkUnsyncRecord(sqlite.db, id);
        if (checkRecord) {
            return false;
        }

        const form = PerpetratorOfflineForm.renderSyncToOffline(payload);
        form.id = id;
        syncable.table = table;
        form.first_name = PerpetratorOfflineForm.setFirstname(form);
        await syncable.sync(sqlite.db, [form]);
    }
}

export default new PerpetratorProcessor();