<template>
    <user-layout>
        <div class="w-full py-6 mx-auto">
            <div class="flex items-center justify-between">
                <section-title
                    title="Data Manifest"
                    description="Import data"
                />
            </div>
            <div class="mt-6">
                <!-- <p class="text-xs text-green-500 italic">{{ message }}</p> -->
				<div class="w-full border rounded-lg border-neutral-100">
					<div class="relative overflow-auto">
						<div class="absolute left-0 top-0 h-full whitespace-nowrap position w-[181px] shadow-[2px_0_16px_0px_rgb(0,0,0,0.08)]">
							<div class="px-6 py-[26px] bg-primary-100 text-sm font-semibold relative">Settings</div>
							<side-tab barangaySelected/>
						</div>

                        <div class="pl-[181px] min-h-[500px]">
                            <div>
                                <data-table 
                                    :headers="headers" 
                                    :no-action="false"
                                    :hasSearch="false"
                                    :count="items.length"
                                    searchPlaceholder="Search region"
                                    tableClass="header-frm-table"
                                    dataTableClass="border-0"
                                >
                                    <template v-slot:body>
                                        <template 
                                            v-for="item in items" :key="item"
                                        >
                                            <tr>
                                                <td class="px-6 py-[18px] text-sm text-gray-900 whitespace-nowrap">
                                                    {{ item.name }}
                                                </td>
                                                <td class="px-6 py-[18px] text-sm text-gray-900 whitespace-nowrap">
                                                    <div class="flex mb-2 w-max" v-if="item.hasSelector">
                                                        <select-options
                                                            :options="interventionOptions"
                                                            label="Select Intervention"
                                                            id="selector-id"
                                                            name="selector-name"
                                                            placeholder="Select Intervention"
                                                            v-model="item.intervention_id"
                                                            @update:modelValue="(value: any) => loadInterventionType(value)"
                                                        />
                                                        <multi-select
                                                            v-if="interventionTypeOptions.length > 0"
                                                            class="ml-5"
                                                            :options="interventionTypeOptions"
                                                            id="intervention"
                                                            name="intervention type"
                                                            label="Select Intervention Type"
                                                            placeholder="Select.."
                                                            searchable
                                                            required
                                                            v-model="item.intervention_type"
                                                        />
                                                    </div>
                                                    <div class="flex items-center justify-center mx-auto w-max">
                                                        <export-button class="mr-5" title="Download Manifest" @click="downloadManifest(item)"/>
                                                        <import-button @click="importManifest(item)" v-if="item.hasFile" />
                                                        <text-input type="file" @change="fileChanged($event, item)"/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
            
                                </data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

         <!-- <page-loader :show="loading"/> -->
          <!-- MODAL -->
		<success-modal
			noBtn
			:show="showSuccess"
			action-text="Close"
			title="Import Processing"
			:content="message" 
            @close="showSuccess = false"
		/>
    </user-layout>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UserLayout from '@/layouts/UserLayout.vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import SideTab from "@/views/settings/Components/SideTab.vue";
import DataTable from '@/components/partials/DataTable.vue';
import PageLoader from "@/components/loaders/PageLoader.vue"
import ImportButton from '@/components/buttons/ImportButton.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import http from "@/axios";
import SelectOptions from "@/components/inputs/SelectOptions.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import Storage from "@/helpers/storage";
import SuccessModal from "@/components/modals/SuccessModal.vue";

const storage = new Storage();
const headers: { text: string, haveSort?: boolean }[] = [
    { text: 'Manifest' },
];
const showSuccess = ref(false);
const message = ref(null);
const items = ref([
    {
        name: "Intervention",
        manifest_url: "manifest/intervention",
        import_url: "/manifest/intervention",
        file: null,
        hasFile: false,
        hasSelector: true,
        intervention_id: null,
        intervention_type: null,
    },
    {
        name: "Patients",
        manifest_url: "manifest/patient",
        import_url: "/manifest/patient",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Cases",
        manifest_url: "manifest/cases",
        import_url: "/manifest/cases",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Case: Other Information > Guardians",
        manifest_url: "manifest/case_guardians",
        import_url: "/manifest/case_guardians",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Case: Other Information > Informants",
        manifest_url: "manifest/case_informants",
        import_url: "/manifest/case_informants",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Case: Other Information > Patient Address",
        manifest_url: "manifest/case_patient_address",
        import_url: "/manifest/case_patient_address",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Perpetrators",
        manifest_url: "manifest/perpetrator",
        import_url: "/manifest/perpetrator",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Link Perpetrators to Cases",
        manifest_url: "manifest/case_perpetrator",
        import_url: "/manifest/case_perpetrator",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Case Intake",
        manifest_url: "manifest/case_intake",
        import_url: "/manifest/case_intake",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Case Safety Assessment",
        manifest_url: "manifest/case_safety_assessment",
        import_url: "/manifest/case_safety_assessment",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Case Conference",
        manifest_url: "manifest/case_conference",
        import_url: "/manifest/case_conference",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Office",
        manifest_url: "manifest/office",
        import_url: "/manifest/office",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
    {
        name: "Personnel",
        manifest_url: "manifest/personnel",
        import_url: "/manifest/personnel",
        file: null,
        hasFile: false,
        hasSelector: false,
    },
]);
const interventionOptions = ref([]);
const interventionTypeOptions = ref([]);

const downloadManifest = async (item: any) => {
    const user = JSON.parse(storage.get('user'));
    return window.open(`${process.env.VUE_APP_API_URL}${item.manifest_url}?intervention=${item.intervention_id}&intervention_type=${item.intervention_type}&office_id=${user.office_id}&role_id=${user.role_id}`, "_blank");
}

const importManifest = async (item: any) => {
    const formData = new FormData();
    formData.append('file', item.file);
    
    const response = await http.post(item.import_url, formData);
    message.value = response?.data?.message || '';
    showSuccess.value = true;
}

const fileChanged = (event: any, item: any) => {
    showSuccess.value = false;
    message.value = null;
    item.file = event.target.files[0];
    item.hasFile = item.file ? true : false;
}

const loadData = async () => {
    const response = await http.get('/manifest/load-intervention');
    if (response.status === 200) {
        interventionOptions.value = response?.data?.items || [];
    }
}

const loadInterventionType = async (value: any) => {
    const response = await http.post('/manifest/load-intervention-type', { intervention: value});
    if (response.status === 200) {
        interventionTypeOptions.value = response.data;
    }
}

onMounted(() => loadData());
</script>