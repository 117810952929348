export enum PrimaryDiagnosisReportTypes {
    SUICIDAL_RISK = 1, 
    PERPETRATOR_RELATION = 2, 
    REFERRAL_SOURCE = 3, 
    PWD = 4,  

    COMORBID = 5, 

    BARANGAY = 6,
    CITY = 7, 
    PROVINCE = 8, 
    REGION = 9, 
    OFFICE = 10, 

}
