import http from "@/axios";
import { SafetyAssessment } from "@/interfaces/Cases/SafetyAssessment";

class IntakeService implements SafetyAssessment {
	async show(
		patient: number,
		patientCase: number,
		assessment: number,
		payload: any
	): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/safety-assessment`, { params: payload });
	}

	async create(patient: number, patientCase: number, payload: object): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/safety-assessment/create`, {
			params: payload,
		});
	}

	async store(patient: number, patientCase: number, payload: any): Promise<any> {
		return await http.post(`patients/${patient}/cases/${patientCase}/safety-assessment`, payload);
	}

	async edit(
		patient: number,
		patientCase: number,
		assessment: number,
		payload: any
	): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/safety-assessment/${assessment}/edit`, {
			params: payload,
		});
	}

	async update(
		patient: number,
		patientCase: number,
		assessment: number,
		payload: any
	): Promise<any> {
		return await http.put(
			`patients/${patient}/cases/${patientCase}/safety-assessment/${assessment}/update`,
			payload
		);
	}

	public async generatePDFAccess(patient:string, cases:string, assessment: number,) : Promise<JSON> {
        return await http.post(`patients/${patient}/cases/${cases}/safety-assessment/${assessment}/generatePDFAccess`);
    }

    public async downloadPDF(assessment:number, token:string): Promise<void> {		
		window.open(`${process.env.VUE_APP_URL}pdf/${assessment}/downloadSafetyAssessment/${token}`);
	}

}

export default new IntakeService();
