class CaseMentalHealthOfflineForm {
    /**
     * Template form structure of case table
     */
    formStructure () {
        return {
            id: null,
            cases_id: null,
            mental_health_diagnoses_id: null,
            type: null,
            specify: null,
            behavior_type_id: null,
            encoded_by: null,
        }
    }

    renderStoreForm(case_id: number, item: any) {
        return {
            cases_id: case_id,
            mental_health_diagnoses_id: item.mental_health_diagnoses_id,
            type: item.type,
            specify: item.specify,
            behavior_type_id: item.behavior_type_id
        }
    }
    editForm(item: any) {
        return item?.mental_health_diagnoses?.map((mental_health: any) => {
			return {
				mental_health_diagnoses_id: mental_health.mental_health_diagnoses_id,
				type: mental_health.type,
				specify: mental_health.specify,
				behavior_type_id: mental_health.behavior_type_id,
				checked: true,
			};
		}) || [];
    }

    getMentalHealthPayload(fromCollection: boolean, payload: any) {
        if (fromCollection) {
            return payload.mental_health_diagnoses;
        }

        return payload.mental_health || payload.mental_health_diagnoses;
    }
}

export default new CaseMentalHealthOfflineForm();