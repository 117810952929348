<template>
	<div>
		<TransitionRoot as="template" :show="show">
			<Dialog as="div" class="relative z-10" @close="$emit('close')">
				<div class="fixed inset-0" />

				<div class="fixed inset-0 overflow-hidden">
					<div class="absolute inset-0 overflow-hidden bg-[#0000014d]">
						<div class="fixed inset-y-0 right-0 flex max-w-full md:pl-10 pointer-events-none">
							<TransitionChild
								as="template"
								enter="transform transition ease-in-out duration-300"
								enter-from="translate-x-full"
								enter-to="translate-x-0"
								leave="transform transition ease-in-out duration-300"
								leave-from="translate-x-0"
								leave-to="translate-x-full">
								<DialogPanel class="pointer-events-auto w-screen md:max-w-[660px]">
									<div class="flex flex-col h-full overflow-y-scroll bg-white md:rounded-l-lg shadow-xl">
										<div class="relative flex flex-col">
											<div
												class="flex items-center justify-between px-6 py-4 border-b border-gray-200">
												<p class="text-sm font-semibold">Guardian</p>
												<XMarkIcon
													class="w-6 h-6 transition cursor-pointer stroke-2 hover:text-primary-600"
													@click="$emit('close')" />
											</div>
											<div class="px-4 pt-1.5 pb-4">
												<div class="flex justify-end">
													<a
														@click="
															router.push(
																`/patient/${patientId}/cases/${caseId}/guardians/create`
															);
                                                            $emit('close')
														">
														<button-component>
															<PlusIcon
																class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
															<span>Add New</span>
														</button-component>
													</a>
												</div>
											</div>
											<div class="px-4 max-h-[calc(100vh-140px)] overflow-auto">
												<div
													v-if="!hasContent"
													class="flex border border-gray-200 rounded-2xl items-center p-4 h-[calc(100vh-140px)] justify-center">
													<p class="text-xs italic text-center">No data yet</p>
												</div>
												<template v-if="hasContent">  
													<div
														v-for="item in items.data"
														:key="item"
														class="relative grid grid-cols-2 gap-2 p-4 mb-4 border border-gray-200 rounded-2xl pr-11">
														<div class="col-span-1">
															<p class="mb-1 text-sm font-bold">
																{{ item?.gender_meta?.description }} &nbsp;
															</p>
															<p class="text-xs font-medium text-neutral-500">Gender</p>
														</div>
														<div class="col-span-1">
															<p class="mb-1 text-sm font-bold">{{ item?.name }} &nbsp;</p>
															<p class="text-xs font-medium text-neutral-500">Fullname</p>
														</div>
														<div class="col-span-1">
															<p class="mb-1 text-sm font-bold">{{ item?.birthdate ? DateFormatter.customDateFormat(item?.birthdate, 'MM/dd/yyy') : 'Unknown'}} &nbsp;</p>
															<p class="text-xs font-medium text-neutral-500">
																Birthdate
															</p>
														</div>
														<div class="col-span-1">
															<p class="mb-1 text-sm font-bold">{{ item?.computed_age }} &nbsp;</p>
															<p v-if="BirthdayClassification.APPROXIMATE_AGE == item.birthday_classification"  class="text-xs font-medium text-neutral-500"> Approximate Age </p>
															<p v-else class="text-xs font-medium text-neutral-500"> Age </p>
														</div>
														<div class="col-span-1">
															<p class="mb-1 text-sm font-bold">
																{{ item?.relationship?.name }} &nbsp;
															</p>
															<p class="text-xs font-medium text-neutral-500">
																Relationship
															</p>
														</div>
														<div class="col-span-1">
															<p class="mb-1 text-sm font-bold">{{ item?.contacts }} &nbsp;</p>
															<p class="text-xs font-medium text-neutral-500">
																Contact Number
															</p>
														</div>
														<div class="absolute top-4 right-4">
															<Menu>
																<MenuButton>
																	<!-- <img
																		src="/assets/icon/ic-three-dots.svg"
																		alt="menu_icon"
																		class="object-contain w-5 h-5 cursor-pointer" /> -->
																	<three-dots-icon class="object-contain w-5 h-5 cursor-pointer" />
																</MenuButton>
																<transition
																	enter-active-class="transition duration-100 ease-out"
																	enter-from-class="transform scale-95 opacity-0"
																	enter-to-class="transform scale-100 opacity-100"
																	leave-active-class="transition duration-75 ease-in"
																	leave-from-class="transform scale-100 opacity-100"
																	leave-to-class="transform scale-95 opacity-0">
																	<MenuItems
																		class="absolute right-0 z-10 mt-2 min-w-[180px] origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																		<div class="p-4">
																			<MenuItem>
																				<a
																					@click="
																						router.push(`/patient/${patientId}/cases/${caseId}/guardian/${item.id}/edit`); $emit('close')
																					"
																					class="flex items-center mb-2 text-sm cursor-pointer">
																					<!-- <img
																						src="/assets/icon/ic-pencil.svg"
																						alt="pencil_icon"
																						class="w-6 h-6 object-contain mr-1.5 invert" /> -->
																					<pencil-icon class="w-6 h-6 object-contain mr-1.5 invert" />
																					Edit
																				</a>
																			</MenuItem>
																			<MenuItem>
																				<p
																					class="flex items-center text-sm cursor-pointer"
																					@click="removeConfirmation(item)">
																					<TrashIcon class="w-6 h-6 mr-1.5" />
																					Remove
																				</p>
																			</MenuItem>
																		</div>
																	</MenuItems>
																</transition>
															</Menu>
														</div>
													</div>
												</template>
												<slot></slot>
											</div>
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</div>
			</Dialog>
		</TransitionRoot>
		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="deleteItem()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Delete Record?"
			content="Are you sure you wish to delete this record?" />
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Deleted!"
			:content="successMessage" />
	</div>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed, onUnmounted } from "vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import {
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
	Menu,
	MenuButton,
	MenuItems,
	MenuItem,
} from "@headlessui/vue";
import { PlusIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { TrashIcon } from "@heroicons/vue/24/solid";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { GuardianIndex } from "@/interfaces/Cases/Guardian";
import { useRouter, useRoute } from "vue-router";
import pickBy from "lodash/pickBy";
import { BirthdayClassification } from "@/views/enums/BirthdayClassification";
import DateFormatter from "@/helpers/DateFormatter";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import { useDraftLogger } from "@/store/draftlogger"
import ThreeDotsIcon from "@/components/icons/threeDotsIcon.vue";
import PencilIcon from "@/components/icons/pencilIcon.vue"; 

const router = useRouter();
const route = useRoute();
/******************************* For Offline Imports ************************************ */ 
import CaseGuardianService from "@/classes/Cases/CaseGuardian/CaseGuardianService";
import { useNetworkStore } from "@/store/network";
import CaseGuardianForm from "@/classes/Cases/CaseGuardian/Offline/Formatters/CaseGuardianForm";
import CaseGuardianOfflineProcessor from "@/classes/Cases/CaseGuardian/Offline/Processor/OfflineProcessor";
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable); 
/*************************************************************************************** */
const props = defineProps({
	show: {
		type: Boolean,
		default: false,
	},
}); 

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const items = ref<GuardianIndex[]>([]);
const hasContent = ref<boolean>(false);
const selectedItemId = ref<number | null>(null);
const successMessage = ref<string>("Selected record has been successfully deleted!"); 
const recordStatusChecker = useRecordStatusChecker();
const isRecordCreated = computed(() => recordStatusChecker.isCreatedGuardian);
const draft = useDraftLogger();

const removeConfirmation = (item) => {
	showConfirmation.value = true;
	selectedItemId.value = item.id;
};

const deleteItem = async () => {
    showConfirmation.value = false;
    const response = await CaseGuardianService.delete(
		patientId.value,
		caseId.value,
		selectedItemId.value,
		pickBy({ }),
		isNetworkAvailable.value
	);
    if (response?.status === 200 || response?.data != undefined) {
        if (isNetworkAvailable.value) {
            await CaseGuardianOfflineProcessor.delete(selectedItemId.value, caseId.value, true);
        }
        showSuccess.value = true;
        recordStatusChecker.isDeleted = true;
        draft.hasDraft = true; 
    } else {
        console.log(response);
    }
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
    draft.hasDraft = false;
    showConfirmation.value = false; 
};

const getData = async (): Promise<any> => {
	if (caseId.value) {
        const response = await CaseGuardianService.index(
			patientId.value,
			caseId.value,
			pickBy({  rows: 10 }),
			isNetworkAvailable.value
		); 
        console.log(response)
        if (response?.status === 200 || response?.data != undefined) {
			items.value = response.data.items;  
			hasContent.value = response.data.items.total > 0 ? true : false; 

			if(!isNetworkAvailable.value){ 
				await retrieveGuardianOffline(response);
			}
            
            if (isNetworkAvailable.value) {
                for (const item of items.value.data) {
                    await CaseGuardianOfflineProcessor.syncToOffline(caseId.value, item);
                }
            }
        }
	}
};

/**
 * Retrieve resource data 
 */
const retrieveGuardianOffline = async (response: object):any => { 
	hasContent.value = response.data.items.total > 0 ? true : false; 
	response.data.items = await Promise.all(response.data.items.data?.map(async (item: any) => {
        return  await CaseGuardianForm.viewForm(item);
    }));

	items.value = { data: response.data.items };    
};

watch(
    () => isRecordCreated.value,
    (val) => {
        if (val) {
            getData();
        }
    }
);

watch(
    () => props.show,
    (val) => {
        if (val) {
            recordStatusChecker.isDeleted = false;
            getData();
        }
    }
); 
</script>
