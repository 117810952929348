import { defaultCreateForm, defaultUpdateForm } from "@/classes/General/GeneralOfflineForms";


class IncestForm {

    createForm(){
        return {
            sleeping_arrangement: null,
            others: null,
            same_bed_with: [],
            same_room_with: [],
            abused_living_arrangement: [],
            present_living_arrangement: [],
            encoded_by: null,
            ...defaultCreateForm()
        }
    }

    updateForm(item: any){
        return {
            id: item.id,
            sleeping_arrangement: item.sleeping_arrangement,
            others: item.others ?? null,
            same_bed_with: item.same_bed_with,
            same_room_with: item.same_room_with,
            abused_living_arrangement: item.abused_living_arrangement,
            present_living_arrangement: item.present_living_arrangement,
            encoded_by: item.encoded_by,
            ...defaultUpdateForm()
        }
    }

    deleteForm(item:any){
        return {
            sleeping_arrangement: item.sleeping_arrangement,
            others: item.others,
            same_bed_with: item.same_bed_with,
            same_room_with: item.same_room_with,
            abused_living_arrangement: item.abused_living_arrangement,
            present_living_arrangement: item.present_living_arrangement,
            ...defaultUpdateForm()
        }
    }
}
export default new IncestForm();