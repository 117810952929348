import FormOptions from "@/classes/Forms/FormOptions";
import Behavior from "@/enums/Behavior";
import Gender from "@/enums/Gender";
import HighSuicidalRisk from "@/enums/HighSuicidalRisk";
import IncidentTime from "@/enums/IncidentTime";
import SexAtBirth from "@/enums/SexAtBirth";
import Pwd from "@/enums/Pwd";
import ChildType from "@/enums/ChildType";

const options = new FormOptions();

class PatientTemplateForm {
    async createTemplate() {
        return {
            'behaviorTypes': await options.behaviorTypes(),
            'behaviors': Behavior,
            'civilStatuses': await options.civilStatusOptions(),
            'comorbidities': await options.comorbidities(),
            'disabilities': await options.disabilities(),
            'endorsedToOptions': await options.endorsedToOptions(),
            'genders': Gender,
            'highSuicidalRisks': HighSuicidalRisk,
            'incidentTimes': IncidentTime,
            'indigeneous': await options.indigeneous(),
            'mentalHealthDiagnoses': await options.mentalHealthDiagnoses(),
            'mentalHealthStaticRadioButtonId': await options.mentalHealthStaticRadioButtonId(),
            'mms': await options.mms(),
            'nationalities': await options.nationalities(),
            'physicians': await options.physicians(),
            'primaryDiagnoses': await options.primaryDiagnoses(),
            'psychiatrists': await options.psychiatrists(),
            'pwdSpecific': [],
            'pwds': Pwd,
            'referralSources': await options.referralSources(),
            'religions': await options.religions(),
            'sexAtBirth': SexAtBirth,
            'socialWorkers': await options.socialWorkers(),
            'soughtServices': await options.soughtServices(),
            'trainees': await options.trainees(),
        }
    }

    async createPatientCaseTemplate() {
        const defaultTemplate = await this.createTemplate();
        return {
            ...defaultTemplate,
            ...{
                childTypes: ChildType,
                educationalAttainments: await options.educationalAttainments(),
                employmentStatuses: await options.employmentStatus(),
                hospitalAreas: await options.hospitalAreas()
            }
        }
    }
}

export default new PatientTemplateForm();