import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../defaultMigration";

export const case_guardians:SchemaTableOptions = {
    name:<string> 'case_guardians',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'cases_id', value: 'INTEGER UNSIGNED'},
        { column: 'first_name', value: 'VARCHAR(255) NOT NULL'},
        { column: 'middle_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'last_name', value: 'VARCHAR(255) NOT NULL'},
        { column: 'gender', value: 'TINYINT UNSIGNED'},
        { column: 'birthday_classification', value: 'TINYINT UNSIGNED'},
        { column: 'birthdate', value: 'DATE DEFAULT NULL'},
        { column: 'approximate_age_year', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'approximate_age_month', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'guardian_relationship_id', value: 'INTEGER UNSIGNED'},
        { column: 'other_relationship', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'contacts', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
        { column: 'person_related_types', value: 'LONGTEXT'},
        { column: 'companion_relationship_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'civil_status_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'is_employed', value: 'BOOLEAN DEFAULT FALSE'},
        { column: 'is_ofw', value: 'BOOLEAN DEFAULT FALSE'},
        { column: 'occupation', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'company', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'weekly_income', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'other_source_of_income', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'is_enrolled_in_school', value: 'BOOLEAN DEFAULT FALSE'},
        { column: 'educational_attainment_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'school', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'housing_classification_id', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'no_children_in_family', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'no_household_members', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'no_family_in_household', value: 'INTEGER UNSIGNED DEFAULT NULL'},
        { column: 'other_information', value: 'TEXT DEFAULT NULL'},
        ...defaultMigration
    ]
}