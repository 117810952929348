class CaseRemarksOfflineForm {
    /**
     * Template form structure of case table
     */
    formStructure () {
        return {
            id: null,
            cases_id: null,
            remarks: null,
            encoded_by: null,
        }
    }

    renderStoreForm(case_id: number, item: any) {
        return {
            cases_id: case_id,
            remarks: item.remarks,
        }
    }

    editForm(item: any) {
        return item?.remarks?.map((remark: any) => {
			return { remarks: remark.remarks };
		}) || [];
    }
}

export default new CaseRemarksOfflineForm();