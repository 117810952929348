<template>
    <button-component
        customClass="quaternary"
        @click="action"
    >
        <!-- <img src="/assets/icon/ic-import.svg" alt="import_icon" class="w-4 h-4 object-contain -ml-2 mr-1 icon"> -->
        <import-icon class="w-4 h-4 object-contain -ml-2 mr-1 icon" />
        <span>{{ title }}</span>
    </button-component>
</template>

<script setup lang="ts">
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import ImportIcon from "@/components/icons/importIcon.vue";

const props = defineProps({
    title: {
        type: String,
        default: "Import"
    },
});

const emit = defineEmits(['click'])

const action = () => {
    emit("click");
}
</script>
