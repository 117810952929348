<template>
    <user-layout>
        <div class="w-full py-6 mx-auto">
            <div class="flex md:items-end justify-between md:flex-row flex-col">
                <section-title title="Dashboard" description="View the data analytics in the system." />
                <div class="flex space-x-3 md:mt-0 mt-4">
                    <div class="font-semibold text-sm text-white py-2.5 px-3 rounded bg-red-600">
                        <span>Past Due Count: </span>
                        <span>{{ itemsCount.pastDueCount }}</span>
                    </div>
                    <div class="font-semibold text-sm text-white py-2.5 px-3 rounded bg-green-600">
                        <span>Scheduled Count: </span>
                        <span>{{ itemsCount.scheduledCount }}</span>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-5 gap-6 mt-6">
                <div class="xl:col-span-1 lg:col-span-2 col-span-5">
                    <div class="border rounded-lg border-neutral-100">
                        <div class="p-4">
                            <p class="text-xs font-bold">Patient with no Primary Diagnosis: {{ itemsCount.noDiagnosisPatientsCount }}</p>
                        </div>
                        
                        <data-table 
                            :headers="headers" 
                            :no-action="true"
                            :count="noDiagnosisPatients.data.length"
                            tableClass="header-frm-table"
                            dataTableClass="border-0"
                        >
                            <template v-slot:body>
                                <template 
                                    v-for="(item, index) in noDiagnosisPatients.data"
                                    :key="item"
                                >
                                    <tr>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ index+1 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            <a :href="`/patient/${item?.patient_id}/view`" class="transition cursor-pointer text-primary-600 hover:text-primary-800">
                                                {{ item.name }}
                                            </a>
                                            
                                        </td>
                                    </tr>
                                </template>
                            </template>
                            <template #tfoot>
                                <div class="w-full px-6 border-t py-7">
                                    <simple-pagination 
                                        :show-rows="true"
                                        :numRows="filterDiagnosis.rows ?? 10" 
                                        :currentPage="noDiagnosisPatients.current_page" 
                                        :last-page="noDiagnosisPatients.last_page" 
                                        @update="(value: any) => paginatePrimaryDiagnosis(value)"
                                    />
                                </div>
                            </template>
                        </data-table>
                    </div>
                </div>

                <div class="xl:col-span-4 lg:col-span-3 col-span-5 border rounded-lg border-neutral-100">
                    <div class="p-4 border-b border-neutral-100">
                        <p class="text-xs font-bold">Interventions</p>
                    </div>
                    <div class="flex lg:items-center lg:justify-between p-4 lg:overflow-auto border-b border-neutral-100 side-scroll lg:flex-row flex-col">
                        <div class="flex items-center pr-4 space-x-1 shrink-0">
                            <span class="pr-2 text-xs font-bold">Legend: </span>
                            <span class="rounded px-1 py-0.5 font-semibold text-xs text-white bg-red-600 cursor-pointer" @click="filterPastDue()">Past Due</span>
                            <span class="rounded px-1 py-0.5 font-semibold text-xs text-white bg-green-600 cursor-pointer" @click="filterScheduled()">Scheduled</span>
                        </div>
                        <div class="flex items-center space-x-1 dashboard_tabs lg:overflow-visible overflow-auto side-scroll lg:mt-0 mt-4 lg:pb-0 pb-2">
                            <tab-component
                                :tabs="tabs"
                                typeThree
                                :active-tab="filters.tab"
                                @update:tab="(value) => filters.tab = value"
                            />
                        </div>
                    </div>
                    <div class="p-4 border-b border-neutral-100">
                        <button type="button" class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 hover:bg-primary-100 w-max"
                        @click="showFilter = true">
                            <span class="text-xs font-medium text-neutral-600">Showing:</span>
                            <span class="text-xs font-medium">All Records</span>
                        </button>
                        <FilterPanel
                            :show="showFilter"
                            @close="showFilter = false"
                            @apply:reset="getData"
                            @apply:filters="getData(filters)"
                        >
                            <template #fields>
                                <div class="space-y-5">
                                    <multi-select
                                        :options="personnelOptions"
                                        label="Personnels"
                                        id="personnel"
                                        name="personnel"
                                        placeholder="Select.."
                                        v-model="filters.filterPersonnel" 
                                        searchable
                                    />
                                    <date-picker 
                                        label="Schedule Date/Time"
                                        placehlder="Schedule Date/Time"
                                        :range=true
                                        v-model="filters.filterScheduleDate"
                                    />
                                    <div class="flex flex-col space-y-3">
                                        <p class="text-sm font-medium">Intervention</p>
                                        <checkbox-input
                                            id="social_work"
                                            name="social_work"
                                            label="Social Work"
                                            :checked="filters.filterSocialWork"
                                            @update:modelValue="(checked) => filters.filterSocialWork = checked"
                                        />
                                        <checkbox-input
                                            id="medical"
                                            name="medical"
                                            label="Medical"
                                            :checked="filters.filterMedical"
                                            @update:modelValue="(checked) => filters.filterMedical = checked"
                                        />
                                        <checkbox-input
                                            id="mental_health"
                                            name="mental_health"
                                            label="Mental Health"
                                            :checked="filters.filterMentalHealth"
                                            @update:modelValue="(checked) => filters.filterMentalHealth = checked"
                                        />
                                        <checkbox-input
                                            id="developmental_pedia"
                                            name="developmental_pedia"
                                            label="Developmental Pedia"
                                            :checked="filters.filterDevelopmentalPedia"
                                            @update:modelValue="(checked) => filters.filterDevelopmentalPedia = checked"
                                        />
                                        <checkbox-input
                                            id="legal"
                                            name="legal"
                                            label="Legal"
                                            :checked="filters.filterLegal"
                                            @update:modelValue="(checked) => filters.filterLegal = checked"
                                        />
                                        <checkbox-input
                                            id="teleconsultation"
                                            name="teleconsultation"
                                            label="Teleconsultation"
                                            :checked="filters.filterTeleconsultation"
                                            @update:modelValue="(checked) => filters.filterTeleconsultation = checked"
                                        />
                                        <checkbox-input
                                            id="telepsychiatry"
                                            name="telepsychiatry"
                                            label="Telepsychiatry"
                                            :checked="filters.filterTelepsychiatry"
                                            @update:modelValue="(checked) => filters.filterTelepsychiatry = checked"
                                        />
                                        <checkbox-input
                                            id="admin"
                                            name="admin"
                                            label="Admin"
                                            :checked="filters.filterAdmin"
                                            @update:modelValue="(checked) => filters.filterAdmin = checked"
                                        />
                                    </div>
                                    <!-- <multi-select
                                        :options="typeOptions"
                                        label="Type"
                                        id="type_filter"
                                        name="type_filter"
                                        placeholder="Select.."
                                        v-model="filters.interventionType"
                                        searchable
                                    /> -->
                                    <multi-select
                                        :options="legendOption"
                                        label="Legend"
                                        id="legend"
                                        name="legend"
                                        placeholder="Select.."
                                        v-model="filters.filterLegend"
                                        searchable
                                    /> 
                                </div>
                            </template>
                        </FilterPanel>
                    </div>

                    <data-table 
                        :headers="interventionHeaders" 
                        :no-action="false"
                        :count="items.data.length"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                    >
                    
                        <template v-slot:body>
                            <template 
                                v-for="item in items.data" 
                                :key="item.id"
                            >   
                                <template v-if="item.status != 'Done'">
                                    <tr>
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            <div class="px-3 py-2 text-sm text-white rounded w-max" :class="[item.status == 'Past Due' ? 'bg-red-600' : '', item.status == 'Done' || item.status == 'Scheduled' ? 'bg-green-600' : '']">
                                                {{ item.scheduled_date_formatted }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.intervention_type_meta?.description }}
                                        </td>
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.intervention_sub_type}}
                                        </td>
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            <a :href="`/patient/${item.cases?.patient_id}/view`" class="transition cursor-pointer text-primary-600 hover:text-primary-800">
                                                {{ item.cases?.name }}
                                            </a>
                                        </td>
                                        <td 
                                            class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap"
                                        >
                                            <div 
                                                class="flex items-center justify-center mx-auto"
                                            > 
                                            <edit-button 
                                                :routeLink="`/patient/${item?.patient_id}/cases/${item?.cases_id}/intervention/${item.intervention_type}/edit/${item.id}`">
                                            </edit-button>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                        <template #tfoot>
                            <div class="w-full px-6 border-t py-7">
                                <simple-pagination 
                                    :show-rows="true"
                                    :numRows="filters.rows ?? 10" 
                                    :currentPage="items.current_page" 
                                    :last-page="items.last_page" 
                                    @update="(value: any) => paginate(value)"
                                />
                            </div>
                        </template>
                    </data-table>
                </div>
            </div>

        </div>
        
        <page-loader :show="loading"/>
    </user-layout>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import DataTable from '@/components/partials/DataTable.vue';
import EditButton from '@/components/buttons/EditButton.vue';
import TabComponent from '@/components/partials/TabComponent.vue';
import FilterPanel from '@/components/partials/FilterPanel.vue';
import DatePicker from "@/components/inputs/DatePicker.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";

import PageLoader from "@/components/loaders/PageLoader.vue";
import InterventionDashboard from "@/classes/Dashboard/InterventionDashboard";
import assignValues from '@/helpers/assignValues';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import { InterventionType } from "@/views/enums/InterventionType";
import { Legend } from '../enums/Legend';
import { useSecurityFeature } from '@/store/securityFeature';
import { onIonViewWillEnter } from '@ionic/vue';
import { useNetworkStore } from '@/store/network';

const legendOption = ref<Array<any>>([]);				

const personnelOptions = ref<Array<any>>([]);
/**--------------------------
 * INTERFACES
----------------------------*/
interface ItemsData {
    [key: string]: any;
    data: {
        [key: string]: any;
    }
}

interface SelectInterface {
    id: number;
    label: string;
    value: string;
}

interface PaginateParams {
    page: number;
    rows: number;
}

/**--------------------------
 * CONST
----------------------------*/
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const showFilter = ref(false);
const loading = ref(false);
const itemsCount = ref({
    allItemsCount: 0,
    socialWorkCount: 0,
    medicalCount: 0,
    mentalHealthCount: 0,
    developmentalPediaCount: 0,
    legalCount: 0,
    teleconsultationCount: 0,
    telepsychiatryCount: 0,
    adminCount: 0,
    pastDueCount: 0,
    scheduledCount: 0,
    noDiagnosisPatientsCount: 0,
});
const filters = ref({
    filterScheduleDate: null,
    filterSocialWork: false,
    filterMedical: false,
    filterMentalHealth: false,
    filterDevelopmentalPedia: false,
    filterLegal: false,
    filterTeleconsultation: false,
    filterTelepsychiatry: false,
    filterAdmin: false, 
    tab: null as unknown as string,
    interventionType: null,
    filterLegend : null,
    filterPersonnel: null,
});
const typeOptions = ref<SelectInterface[]>([])
const items = ref<ItemsData>({
    data: {}
});
const noDiagnosisPatients = ref<ItemsData>({
    data: {}
});
const filterDiagnosis = ref({});
const tabs = computed(() => [
    { name: 'All', count: itemsCount.value.allItemsCount, tab: null, allowed: true },
    { name: 'Social Work', count: itemsCount.value.socialWorkCount, tab: 'socialWork', allowed: true },
    { name: 'Medical', count: itemsCount.value.medicalCount, tab: 'medical', allowed: true },
    { name: 'Mental Health', count: itemsCount.value.mentalHealthCount, tab: 'mentalHealth', allowed: true },
    { name: 'Developmental Pedia', count: itemsCount.value.developmentalPediaCount, tab: 'developmentalPedia', allowed: true },
    { name: 'Legal', count: itemsCount.value.legalCount, tab: 'legal', allowed: true },
    { name: 'Teleconsultation', count: itemsCount.value.teleconsultationCount, tab: 'teleconsultation', allowed: true },
    { name: 'Telepsychiatry', count: itemsCount.value.telepsychiatryCount, tab: 'telepsychiatry', allowed: true },
    { name: 'Admin', count: itemsCount.value.adminCount, tab: 'admin', allowed: true }
]);

const headers = computed(() => [
    { text: "#" }, 
    { text: "Name" }
]);

const interventionHeaders = computed(() => [
    { text: 'Schedule Date/Time', haveSort: true },
    { text: 'Intervention', haveSort: true },
    { text: 'Type', haveSort: true },
    { text: 'Patient Name', haveSort: true },
]);

/**--------------------------
 * METHODS
----------------------------*/

const getData =(value?:any)=> {
    loading.value = true;
    InterventionDashboard.index(value)
        .then(({data}) => {
            items.value = data.items;
            typeOptions.value = data.typeOptions;
 
            itemsCount.value = assignValues(itemsCount.value, data);
            legendOption.value = data.legends;
            personnelOptions.value = data.personnelOptions;
            filters.value = assignValues(filters.value, data);
        })
        .finally(() => {
            loading.value = false;
            showFilter.value = false;
        });
}

const getPrimaryDiagData =(value?:any) => {
    loading.value = true;
    InterventionDashboard.index(value)
        .then(({data}) => { 
            noDiagnosisPatients.value = data.noDiagnosisPatients;
            filterDiagnosis.value = assignValues(filterDiagnosis.value, data);
        })
        .finally(()=>{
            loading.value = false;
            showFilter.value = false;
        })
}
//Added Filter from Legend
const filterScheduled = () => { 
    filters.value.filterLegend = Legend.Scheduled;//legendOption Scheduled
    getData(filters.value);
}
const filterPastDue = () => {
    filters.value.filterLegend = Legend.Past_Due; //legendOption Past Due
    getData(filters.value);
}

const paginate = ({ page, rows }: PaginateParams): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })
    getData(filters.value); 
};

const paginatePrimaryDiagnosis = ({page,rows}: PaginateParams): void => {
    filterDiagnosis.value = Object.assign(filterDiagnosis.value,{
        page,rows 
    }); 
    getPrimaryDiagData(filterDiagnosis.value); 
}

const checkHasPasswordBlocker = () => {
    const item = sessionStorage.getItem('hasPasswordBlocker');
    if (!item) {
        useSecurityFeature().isActivated = false;
        useSecurityFeature().isPasswordBlocker = true;
        sessionStorage.setItem('hasPasswordBlocker', true);
    }
}

/**--------------------------
 * WATCH
----------------------------*/
watch(
    () => filters.value.tab,
    () => {
        getData(filters.value);
    }
)

onMounted(()=>{
    getData();
    getPrimaryDiagData(); 
});

onIonViewWillEnter(() => {
    if (isNetworkAvailable.value) {
        checkHasPasswordBlocker();
    }
});

</script>