import http from "@/axios";
import { Companion } from "./Offline/Interface/CompanionInterface";
import OfflineProcessor from "./Offline/Processor/OfflineProcessor";


class CaseInformantService implements Companion {
    
    /**
     * Render index page
     * @param patient 
     * @param patientCase 
     * @param payload 
     * @param networkAvailable 
     * @returns 
     */
   async index(patient: number, patientCase: number, payload: any, networkAvailable: boolean): Promise<any> {
       if(networkAvailable){
           return await http.get(`patients/${patient}/cases/${patientCase}/companions`, { params: payload }).catch(error => error);
       }else{
           const data = await OfflineProcessor.index(patientCase);
           return {
               data: {
                   ...data.data,
               }
           };
       }
   }

   /**
    * Render additional data for form
    * @param patient 
    * @param patientCase 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async create(patient: number, patientCase: number, payload: object, networkAvailable: boolean): Promise<any> {
        if(networkAvailable){
            return await http.get(`patients/${patient}/cases/${patientCase}/companions/create`, { params: payload }).catch(error => error);
        }else{ 
            const data = await OfflineProcessor.create(patient);
            return {
                data: {
                    ...data.data,
                }
            };
        }
   }

   /**
    * Store data from storage
    * @param patient 
    * @param patientCase 
    * @param companion 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async store(patient: number, patientCase: number, companion: number, payload: any, networkAvailable: boolean): Promise<any> {
       if(networkAvailable){
           return await http.post(`patients/${patient}/cases/${patientCase}/companions`, payload).catch(error => error);
       }else{
           return await OfflineProcessor.updateCreate(companion, patient, patientCase, payload)
       }
   }

   /**
    * Edit data from storage
    * @param patient 
    * @param patientCase 
    * @param companion 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async edit(
       patient: number,
       patientCase: number,
       companion: number,
       payload: any,
       networkAvailable: boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.get(`patients/${patient}/cases/${patientCase}/companions/${companion}/edit`, {
               params: payload,
           }).catch(error => error);
       }else{
           return await OfflineProcessor.edit(patient, patientCase, companion);
       }
      
   }

   /**
    * Update data from storage
    * @param patient 
    * @param patientCase 
    * @param companion 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async update(
       patient: number,
       patientCase: number,
       companion: number,
       payload: any,
       networkAvailable: boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.put(`patients/${patient}/cases/${patientCase}/companions/${companion}/update`, payload).catch(error => error);
       }else{
           return await OfflineProcessor.updateCreate(companion, patient, patientCase, payload)
       }
   }

   /**
    * Remove data from storage
    * @param patient 
    * @param patientCase 
    * @param companion 
    * @param payload 
    * @param networkAvailable 
    * @returns 
    */
   async delete(
       patient: number,
       patientCase: number,
       companion: number,
       payload: any,
       networkAvailable: boolean
   ): Promise<any> {
       if(networkAvailable){
           return await http.delete(
               `patients/${patient}/cases/${patientCase}/companions/${companion}/delete`,
               payload
           ).catch(error => error);
       }else{
           return await OfflineProcessor.delete(companion, patientCase);
       }
   }
}


export default new CaseInformantService();