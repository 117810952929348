import Relationships from "@/classes/Forms/Relationships";
const relationship = new Relationships();
import FormOptions from "@/classes/Forms/FormOptions"; 
import { defaultCreateForm, defaultDeleteForm, defaultUpdateForm } from "@/classes/General/GeneralOfflineForms";


const options = new FormOptions();
 class CaseGuardianForm  {
   
    async viewForm(item: any) { 
     const birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : null;
     const relationships =  await relationship.guardianRelationship(item);
     return { 
       id: item.id,
       gender_meta: relationship.gender(item),
       name: `${item.first_name  } ${item.last_name }`,
       birthdate: item.birthdate,
       computed_age: birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month),
       relationship: relationships,
       contacts: item.contacts
     } 
   }


   updateForm(item: any){  
       return {
         id: item.id,
         first_name: item.first_name, 
         last_name: item.last_name, 
         middle_name: item.middle_name ?? null,  
         other_relationship: item.other_relationship ?? null, 
         guardian_relationship_id: item.guardian_relationship_id,
         gender: item.gender,  
         approximate_age_month: item.approximate_age_month,
         approximate_age_year: item.approximate_age_year,
         birthday_classification: item.birthday_classification,
         contacts: item.contacts,
         birthdate: item.birthdate ?? null,


         encoded_by:   null,
         person_related_types:   null,
         companion_relationship_id:   null,
         civil_status_id:   null,
         is_employed:  false,
         is_ofw:  false,
         occupation:  null,
         company:  null,
         weekly_income:   null,
         other_source_of_income:   null,
         is_enrolled_in_school:  false,
         educational_attainment_id:   null,
         school:  null,
         housing_classification_id:   null,
         no_children_in_family:   null,
         no_household_members:   null,
         no_family_in_household:   null,
         other_information:  null,

         //default migration form
        ...defaultUpdateForm()
       }
   }

   createForm(){
       return {  
           cases_id:  null,
           first_name:  null,
           middle_name: null,
           last_name: null,
           gender: null,
           birthday_classification: null,
           birthdate: null,
           approximate_age_year: null,
           approximate_age_month: null,
           guardian_relationship_id: null,
           other_relationship: null,
           contacts: null,
  
           encoded_by:   null,
           person_related_types:   null,
           companion_relationship_id:   null,
           civil_status_id:   null,
           is_employed:  false,
           is_ofw:  false,
           occupation:  null,
           company:  null,
           weekly_income:   null,
           other_source_of_income:   null,
           is_enrolled_in_school:  false,
           educational_attainment_id:   null,
           school:  null,
           housing_classification_id:   null,
           no_children_in_family:   null,
           no_household_members:   null,
           no_family_in_household:   null,
           other_information:  null,

          //default migration form
          ...defaultCreateForm()
       }
   }

   syncToOffline(payload: any) {
        const item = this.updateForm(payload);

        return item;
   }
}
export default new CaseGuardianForm();