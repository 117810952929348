import ModelInterface from "@/interfaces/models/ModelInterface"

export default class BaseModel implements ModelInterface {

    /**
     * SQLite table name.
     * @var string
     */
    table = ''

    /**
     * Primary key.
     * @var string
     */
    key = 'id'
}