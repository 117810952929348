import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../defaultMigration";

export const case_safety_assessment_questions:SchemaTableOptions = {
    name:<string> 'case_safety_assessment_questions',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'case_safety_assessment_id', value: 'INTEGER UNSIGNED'},
        { column: 'question_id', value: 'INTEGER UNSIGNED'},
        { column: 'selected_answer', value: 'VARCHAR(255) DEFAULT NULL'},  
        { column: 'supporting_information', value: 'VARCHAR(255) DEFAULT NULL'},  
        { column: 'status', value: 'TINYINT UNSIGNED'},  
        ...defaultMigration
    ]
}