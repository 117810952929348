import SqliteProcessor from "@/processors/SqliteProcessor";

const sqlite = new SqliteProcessor;
const table = 'case_conferences';
class CaseConferenceProcessor {
    async getCount(case_id: number) {
        const response = await sqlite.db.select(`SELECT count(id) as count FROM ${table} WHERE cases_id=${case_id} AND deleted_at IS NULL`);
        return response.length > 0 ? response[0].count : 0;
    }
}

export default new CaseConferenceProcessor();