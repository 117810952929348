import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";
import { createPerpetrator } from "../Interface/PerpetratorInterface";
import Relationships from "@/classes/Forms/Relationships";
import SqliteProcessor from "@/processors/SqliteProcessor";
import PatientOfflineForm from "@/classes/Patients/Offline/Formatters/PatientOfflineForm";
import { PerpetratorIdentity } from "@/views/enums/PerpetratorIdentity";
import PerpetratorProcessor from "../Processor/PerpetratorProcessor";

const relationship = new Relationships();
const sqlite = new SqliteProcessor;
class PerpetratorOfflineForm {
    /**
     * Template form structure of case table
     */
    formStructure () {
        return {
            id: null,
            first_name: null,
            last_name: null,
            middle_name: null,
            gender: null,
            birthday_classification: null,
            birthdate: null,
            approximate_age_year: null,
            approximate_age_month: null,
            contacts: null,
            nickname: null,
            perpetrator_relationship_id: null,
            other_relationship: null,
            occupation: null,
            nationality_id: null,
            religion_id: null,
            current_location: null,
            perpetrator_location: null,
            is_minor: false,
            parent_name: null,
            parent_contact_number: null,
            parent_relationship_id: null,
            perpetrator_information: null,
            encoded_by: null,
            identity: null,
            about_perpetrator: null,
            tag: null,
            details: null,
            other_nationality: null,
            other_religion: null,
        }
    }

    renderSyncToOffline (item: Array<createPerpetrator>, isUpdate = false) {
        const builder: any = {};
        const items = this.formStructure()
        // get default form structure template
        for (const index in items) {
            // match all existing indexes based on default form structure
            builder[index] = DefaultFormStructureBuild.fill(index, item);

            // filter all dates fields and convert value into ISO
            const check = this.checkFormStrucuteDates().find((item) => item === index);
            if (check) {
                builder[index] = relationship.dateFormatFromIso(builder[index]);
            }
        }
        // usable only when updating record
        if (isUpdate) {
            return this.updatePatientForm(builder, item);
        }
        return builder;
    }

    updatePatientForm(builder: any, item: any) {
        builder.id = item.id;
        builder.is_sync = false;
        builder.synching_remarks = null;
        builder.is_updated_offline = true;
        builder.updated_at = relationship.dateFormatFromIso(new Date);
        return builder;
    }

    private checkFormStrucuteDates () {
        return [
            'birthdate',
        ]
    }

    async renderSearch(response: any) {
        const arr:Array<[]> = [];
        const items = response.data;
        for (const index in items) {
            const item = items[index];
            const transform = await this.renderPerpetratorDetails(item);
            arr.push(transform);
        }

        response.data = arr;
        return response;
    }

    async renderPerpetratorDetails(item: any) {
        item.name = this.getName(item);
        item.computed_age = PatientOfflineForm.renderComputedAge(item);
        item.identity_meta = relationship.perpetratorIdentity(item);
        item.latest_case_number = await PerpetratorProcessor.latestCaseNumber(item.id);
        item.gender_meta = relationship.gender(item);
        item.relationship = await relationship.perpetratorRelationship(item.perpetrator_relationship_id);
        item.current_location_meta = relationship.currentLocation(item);
        item.birthdate = relationship.dateFormat(item.birthdate, 'yyyy-LL-dd');
        item.created_at_formatted = relationship.dateFormat(item.created_at, 'DDD - hh:mm:ss a');
        item.religion = await relationship.religion(item);
        item.nationality = await relationship.nationality(item.nationality_id);
        return item;
    }

    getName(item: any) {
        if (item.identity == PerpetratorIdentity.UNKNOWN) {
            return item.tag;
        }
        return `${item.last_name}, ${item.first_name}`;
    }

    setFirstname(item: any) {
        if (item.identity == PerpetratorIdentity.UNKNOWN) {
            return item.tag;
        }

        return item.first_name;
    }

    defaultPayloadValue(form: any, payload: any) {
        form.identity = payload.identity;
        form.current_location = payload.current_location;
        form.gender = payload.gender
        return form
    }
    
    renderPerpetratorId(items: any) {
        return items.map((item: any) => item.perpetrator_id);
    }

    searchRule(payload: any, query: any = null) {
        let sql = query ? query : ' WHERE';
        if (payload?.first_name || payload?.last_name) {
            if (query) {
                sql += ` AND (first_name LIKE "%${payload?.first_name || null}%" OR last_name LIKE "%${payload?.last_name || null}%")`;
            } else {
                sql += ` (first_name LIKE "%${payload?.first_name || null}%" OR last_name LIKE "%${payload?.last_name || null}%")`;
            }
        }
        
        if (payload?.birthdate) {
            sql += ` AND birthdate="${payload?.birthdate}"`;
        }

        return sql;
    }

    async renderPerpetrators(items: any) {
        const arr = [];
        for (const index in items) {
            const item = items[index];
            const response = await PerpetratorProcessor.getPerpetrator(item.perpetrator_id);

            if (response) {
                response.name = this.getName(response);
                arr.push(response);
            }
        }

        return arr;
    }
}

export default new PerpetratorOfflineForm();

