class CaseDiagnosesOfflineForm {
    /**
     * Template form structure of case table
     */
    formStructure () {
        return {
            id: null,
            cases_id: null,
            primary_diagnoses_id: null,
            other_diagnoses: null,
            high_suicidal_risk: null,
            is_hama: null,
            encoded_by: null,
        }
    }

    renderStoreForm(case_id: number, item: any) {
        return {
            cases_id: case_id,
            primary_diagnoses_id: item.primary_diagnoses_id,
            other_diagnoses: item.other_diagnoses,
            high_suicidal_risk: item.high_suicidal_risk,
            is_hama: item.is_hama,
        }
    }

    renderCaseHistoryCard(item: any) {
        if (item.length > 0) {
            return {
                primary_diagnoses_id: item[0].id,
                primary_diagnoses: {
                    id: item[0].id,
                    name: item[0].name,
                }
            }
        }
        return null;
    }
}

export default new CaseDiagnosesOfflineForm();