class CaseReferralOfflineForm {
    /**
     * Template form structure of case table
     */
    formStructure () {
        return {
            id: null,
            cases_id: null,
            referral_source_id: null,
            second_referral_source_id: null,
            sought_service_id: null,
            intake_social_worker_id: null,
            social_worker_id: null,
            physician_id: null,
            mental_health_personnel_id: null,
            mms_id: null,
            trainee_id: null,
            encoded_by: null,
            referral_specify: null,
        }
    }

    renderStoreForm (case_id: number, item: any) {
        return {
            cases_id: case_id,
            referral_source_id: item.referral_source_id,
            second_referral_source_id: item.second_referral_source_id,
            sought_service_id: item.sought_service_id,
            intake_social_worker_id: item.intake_social_worker_id,
            social_worker_id: item.social_worker_id,
            physician_id: item.physician_id,
            mental_health_personnel_id: item.mental_health_personnel_id,
            mms_id: item.mms_id,
            trainee_id: item.trainee_id,
            referral_specify: item.referral_specify,
        }
    }
}

export default new CaseReferralOfflineForm();