<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex flex-col items-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`
						)
					"
					class="w-full mt-6 md:w-auto md:mt-0">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form" 
					@click="save()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 text-lg font-medium md:text-2xl text-neutral-600">Patient's Name:
			<span class="block font-semibold md:inline-block text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full p-4 mx-auto my-6 border border-gray-200 rounded lg:p-6">
			<div class="flex items-center justify-between">
				<section-title title="Perpetrator Details" />
			</div>
			<form-section
				v-if="loaded"
				:form="form"
				:errors="errors"
				:genders="genders"
				:relationships="relationships"
				:nationalities="nationalities"
				:religions="religions"
				:currentLocations="currentLocations"
				:identities="identities"
				:item="item" 
				:isEdit="true"
			/>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "./FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { PerpetratorCreate } from "@/interfaces/Cases/Perpetrator";
import PerpetratorForm from "@/classes/Forms/Cases/PerpetratorForm";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import PerpetratorIdentity from "@/enums/PerpetratorIdentity";
import CurrentLocation from "@/enums/CurrentLocation";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import Relationships from "@/classes/Forms/Relationships";
import CasePerpetratorOfflineForm from "@/classes/Forms/Cases/CasePerpetratorOfflineForm";
import { onIonViewWillEnter } from "@ionic/vue";
import OfflineCasePerpetrator from "@/classes/Forms/Cases/OfflineCasePerpetrator";
import PerpetratorService from "@/classes/Perpetrators/PerpetratorService";
import PerpetratorOfflineForm from "@/classes/Perpetrators/Offline/Formatters/PerpetratorOfflineForm";
import PerpetratorOfflineService from "@/classes/Perpetrators/Offline/OfflineService";
import Rules from "@/classes/Perpetrators/Offline/Validation/Rules";
import useVuelidate from "@vuelidate/core";
import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
import OnlineSynching from "@/classes/Synching/OnlineSynching";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const perpetratorId = ref<number | null>(route.params.perpetrator);
const genders = ref<renderSelectV2[]>([]);
const identities = ref<renderSelectV2[]>([]);
const nationalities = ref<renderSelect[]>([]);
const religions = ref<renderSelect[]>([]);
const relationships = ref<renderSelect[]>([]);
const currentLocations = ref<renderSelect[]>([]);
const form = ref<PerpetratorCreate>(new PerpetratorForm());
const errors = ref<object>({});
const successMessage = ref<string>("Case perpetrator has beed updated!");
const loaded = ref<boolean>(false);
const item = ref<PerpetratorCreate>(PerpetratorOfflineForm.formStructure());
const offlineParams = ref<string>(route.query.offline);
const options = new FormOptions();
const patient = ref<string>('');
const recordStatusChecker = useRecordStatusChecker();
const relationship = new Relationships()
const { createRules } = Rules;
const onlineSync = new OnlineSynching();

/**
 * Validate the form using Vuelidator
 */
 const validateForm = async () =>{
	const vuelidate = useVuelidate(createRules({...form.value}), form.value)
	const result = await vuelidate.value.$validate();

    if (result) {
        return null;
    } else {
        errors.value = ErrorValidation.getErrors(vuelidate.value);
        return errors.value;
    }
}

const save = async (): Promise<any> => {
	errors.value = {};
 
    // Set validation rule for Online and Offline
    const validate = await validateForm();
    if (validate) {
        return;
    }

    const response = await  PerpetratorService.update(
		patientId.value,
		caseId.value,
		perpetratorId.value,
		{ ...form.value },
        isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        const data = response.data.data;
        showSuccess.value = true;
        successMessage.value = response.data.message;
        if (isNetworkAvailable.value) {
            await PerpetratorOfflineService.syncToOffline(patientId.value, caseId.value, data.item);
        }
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};
 
const redirectSuccess = async (): void => {
	showSuccess.value = false;
	router.replace(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
};

const getData = async () => {
    const response = await PerpetratorService.edit(
		patientId.value,
		caseId.value,
		perpetratorId.value,
		pickBy({}),
        isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        const itemData = response.data.item;
		genders.value = response.data.genders;
		identities.value = response.data.identities;
		nationalities.value = response.data.nationalities;
		religions.value = response.data.religions;
		relationships.value = response.data.perpetratorRelationships;
		currentLocations.value = response.data.currentLocations;
        form.value = itemData ? PerpetratorOfflineForm.renderSyncToOffline(itemData) : item.value;
		patient.value = response.data.patient;
		item.value = itemData;
         
        loaded.value = true;
    } else {
        console.log(response);
        redirectSuccess();
    }
};

watch(
    () => isNetworkAvailable.value,
    async () => {
        loaded.value = false;
        await getData();
        await onlineSync.checkDrafts();
    }
);
 
onIonViewWillEnter(async () => {
    recordStatusChecker.isCreatedGuardian = false; 
    await getData();
    await onlineSync.checkDrafts();
});
 
</script>
