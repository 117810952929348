import { EnumsInterface } from "@/interfaces/EnumsInterface";

const PersonRelatedTypes:EnumsInterface[] = [
    {id: 0, value: 0, label: "Lives with Child"},
    {id: 1, value: 1, label: "Present CareTaker"},
    {id: 2, value: 2, label: "Deceased"},
    {id: 3, value: 3, label: "Child"},
    {id: 4, value: 4, label: "Contact Person"},
]

export default PersonRelatedTypes