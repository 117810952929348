import {maxValue, minValue, numeric, required, requiredIf, helpers, minLength, maxLength } from "@vuelidate/validators"
const createRules = (form: any): any => { 
   return {
        first_name: {
            required:  helpers.withMessage(errorMessage('first name'), required)
        },
        last_name: {
            required: helpers.withMessage(errorMessage('last name'), required)
        },
        gender: {
            required:  helpers.withMessage(errorMessage('last name'), required)
        },
        birthdate: {
            required: helpers.withMessage('The birthdate field is required when approximate age month & year is not present.', requiredIf(!form.approximate_age_month && !form.approximate_age_year)),
        },
        approximate_age_month: {
            numeric: numeric,
            minValue: minValue(form.approximate_age_year > 0 ? 0 : 1),
            maxValue: maxValue(12),
            required: helpers.withMessage('The approximate age month field is required when birthdate is not present.', requiredIf(!form.birthdate)),
        },
        approximate_age_year: {
            numeric: numeric,
            minValue: minValue(form.approximate_age_month > 0 ? 0 : 1),
            required: helpers.withMessage('The approximate age year field is required when birthdate is not present.', requiredIf(!form.birthdate)),
        },
        birthday_classification: {
            required,
        }, 
        companion_relationship_id: {
            required : helpers.withMessage(errorMessage('relationship'), required)
        },
        contacts: {
            numeric: helpers.withMessage('The contact number must be numeric', numeric),
            minLength: helpers.withMessage('The contact number must be 10 numbers', minLength(10)),
            maxLength: helpers.withMessage('The contact number must be 10 numbers', maxLength(10)),
        }
   }
}


const errorMessage = (field: any) => {
   return `The ${field} field is required`;
}
export default {
   createRules,
}
