import PatientService from "../PatientService";
import { PatientServiceInterface } from "./Offline/Interfaces/PatientInterface";
import OfflineService from "./Offline/OfflineService";

class PatientRecordService implements PatientServiceInterface {
    async search(payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await PatientService.patients(payload);
        } else {
            return await OfflineService.search(payload);
        }
    }
    
    async create(payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await PatientService.create(payload);
        } else {
            return await OfflineService.create(payload);
        }
    }

    async store(payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await PatientService.store(payload);
        } else {
            return await OfflineService.store(payload);
        }
    }

    async syncToOffline(payload: object, isCreated = false) {
        return await OfflineService.syncToOffline(payload, isCreated);
    }
}

export default new PatientRecordService();