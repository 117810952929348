import http from "@/axios";
import Relationships from "../Forms/Relationships";
import { SyncStatus } from "@/views/enums/SyncStatus";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";
import OnlineSynching from "./OnlineSynching";

const relationship = new Relationships();
const sqlite = new SqliteProcessor;
const syncable = new Syncable;
const table = 'case_conference_aggravating_factors';

export default class SyncCaseConferenceFactors {
    resetItemData(item: any) {
        item.synching_remarks = null;
        item.is_sync_failed = false;
        item.is_sync = true;
        item.is_created_offline = false;
        item.is_updated_offline = false;
        item.is_deleted_offline = false;

        return item;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error.message || 'System Error';
        item.is_sync_failed = true;

        return item;
    }

    async render(isRenderData = false): Promise<any> {
        const items = [];

        let hasError = false;

        const factors = await sqlite.db.select(`SELECT * FROM ${table} WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC`);
        
        if (factors.length > 0) {
            for (const index in factors) {

                const factor = factors[index];

                const conferenceResponse = await sqlite.db.select(`SELECT * FROM case_conferences WHERE id=${factor.case_conference_id}`);
                
                if(conferenceResponse.length > 0){
                    const conference = conferenceResponse[0];
                    const cases = await sqlite.db.select(`SELECT * FROM cases WHERE id=${conference.cases_id}`);
                    const casesData = cases.length > 0 ? cases[0] : {};
    
                    const form:any = {...conference};
    
                    form.type = "Case Conference Information Factors"
                    form.table = table;
    
                    form.cases_id = conference.cases_id;
                    form.patient_id = conference.patient_id;
                    form.patient_name = `${casesData.first_name} ${casesData.last_name}`;
                    form.birthdate_formatted = relationship.dateFormat(casesData.birthdate);
                    form.created_at_formatted = relationship.dateFormat(factor.updated_at);
    
                    form.url_view = `/patient/${conference.patient_id}/cases/${casesData.id}/case-conference/information/${conference.id}/edit`;
                    form.can_view =  true;
                    
                    form.status = conference.is_sync_failed == false || conference.is_sync_failed == undefined 
                        ? (conference.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                        : SyncStatus.RECORD_CONFLICT;
    
                    if (isRenderData) {
                        items.push(form);
                    }
    
                    // // do something for online synching
                    if (!isRenderData) {
                        hasError = await this.processSync(factor, form);
                    }
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async processSync(item: any, form: any): Promise<any> {
        const onlineSync = new OnlineSynching;
        try {    
            
            item.aggravatingFactors = JSON.parse(item.aggravatingFactors);
            item.mitigatingFactors = JSON.parse(item.mitigatingFactors);

            const response = await http.post(`/sync/case-conference/storeFactor/${form.patient_id}/${item.case_conference_id}`, item).catch(error => error);
    
            if (response?.status === 200) {
                const oldId = item.id;
                const newId = response.data.data.item.id;
                await onlineSync.resetItemData(table, oldId);
                await onlineSync.updateNewItemId(table, oldId, newId);
                return false;
            } else {
                return await this.rollback(item, response);
            }
        } catch (error) {
            console.log(error);
        }

        return false;
    }
}