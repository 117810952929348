import DefaultFormStructureBuild from "@/classes/Cases/CaseHistory/Offline/Formatters/DefaultFormStructureBuild";

const useFormatFormStructure = (payload: any, form: any) => { 
    const builder: any = {};
    for (const index in form) {
        builder[index] = DefaultFormStructureBuild.fill(index, payload);
    }
    return builder;
}

export default useFormatFormStructure;