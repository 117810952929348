import Syncable from "@/extenders/models/Syncable";
import SqliteProcessor from "@/processors/SqliteProcessor";
import DataDictionaryOfflineForm from "../Formatters/DataDictionaryOfflineForm";

const sqlite = new SqliteProcessor;
const table = 'data_dictionaries';

class DataDictionaryOfflineProcessor extends Syncable{

    async index(payload: any) {
        await sqlite.connect();
        this.table = table;
        return await this.paginate(sqlite.db, payload, () => {
            return payload.query ? ` WHERE name LIKE '%${payload.query}%'` : '';
        });
    }

    async view(dataDictionary: number) {
        await sqlite.connect();
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE id=${dataDictionary}`);
        return response.length > 0 ? response[0] : null;
    }

    async syncToOffline(payload: any) {
        await sqlite.connect();
        this.table = table;
        const form = DataDictionaryOfflineForm.fillForm(payload);
        await this.sync(sqlite.db, [form]);
    }
}

export default new DataDictionaryOfflineProcessor();