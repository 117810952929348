<template>
	<intake-layout 
		:isShowNav="isShowNav"
		:isWarningMessage="true"
		:patient="patient"
	>
		<template #header>
			<p class="text-xl font-semibold">Edit Address</p>
		</template>

		<!-- <template #actionButtons>
			<template v-if="isShowNav">
				<button-component btnWidth="quinary">Cancel</button-component>
				<button-component 
					customClass="primary" 
					@click="save"
				>
					Save Changes
				</button-component>
			</template> -->

			<!-- Edit -->
			<!-- <template v-else>
				<button-component 
					btnWidth="quinary"
					fill="outline"
					@click="isShowNav = true"
				>
					Cancel
				</button-component>
				<button-component>Save Changes</button-component>
			</template>
		</template> -->

		<!-- Address Information -->
		<template v-if="isShowNav">
			<div class="col-span-12 space-y-3">
				<div
					v-if="!hasContent"
					class="flex border border-gray-200 rounded-2xl items-center p-4 h-[calc(100vh-140px)] justify-center">
					<p class="text-xs italic text-center">No data yet</p>
				</div>
				<template v-if="hasContent">
					<div 
						v-for="(item, index) in items.data"
						class="relative w-full p-4 pr-8 border border-gray-200 rounded-2xl"
						:key="index"
					>
						<table class="w-full">
							<tbody>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Address Type:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item?.residence_type_meta?.description }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Address:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.address }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Barangay:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.barangay?.name }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">City/Municipality/District:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.city?.name }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Province:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.province?.name }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Region:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.region?.name }}</p>
									</td>
								</tr>
								<tr>
									<td class="w-[200px] py-2">
										<p class="font-medium">Direction:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.direction }}</p>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="absolute z-10 top-2 right-2">
							<Menu>
								<MenuButton>
									<EllipsisVerticalIcon class="w-5 h-5 cursor-pointer" />
								</MenuButton>
								<transition
									enter-active-class="transition duration-100 ease-out"
									enter-from-class="transform scale-95 opacity-0"
									enter-to-class="transform scale-100 opacity-100"
									leave-active-class="transition duration-75 ease-in"
									leave-from-class="transform scale-100 opacity-100"
									leave-to-class="transform scale-95 opacity-0">
									<MenuItems
										class="absolute right-2 w-[178px] rounded z-10 p-4 space-y-2 bg-white shadow-[0_2px_2px_0_rgba(0,0,0,.15)]">
										<MenuItem>
											<a 
												@click="
													router.replace(
														`/patient/${patientId}/cases/${caseId}/patient-addresses/${
															item.id ||
															item?.offlineData
																?.offline_id
														}/edit${
															offlineParams
																? '?offline=true'
																: ''
														}?intake=true`
													)
												"
												class="flex items-center space-x-2.5 cursor-pointer"
											>
												<!-- <img 
													src="/assets/icon/ic-pencil-black.svg" 
													alt="ic"
													class="w-6 h-6" 
												/> -->
												<pencil-black-icon class="w-6 h-6" />
												<p class="text-sm text-[#333333]">Edit</p>
											</a>
										</MenuItem>
										<MenuItem>
											<div 
												@click="removeConfirmation(item)"
												class="flex items-center space-x-2.5 cursor-pointer"
											>
												<!-- <img 
													src="/assets/icon/ic-trash.svg" 
													alt="ic"
													class="w-6 h-6" 
												/> -->
												<trash-icon class="object-contain w-6 h-6 cursor-pointer" />
												<p class="text-sm text-[#333333]">Remove</p>
											</div>
										</MenuItem>
									</MenuItems>
								</transition>
							</Menu>
						</div>
					</div>
				</template>
			</div>

			<div class="flex justify-end col-span-12">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/patient-addresses/create${
								offlineParams ? '?offline=true' : ''
							}?intake=true`
						)
					"
					class="sm:w-auto w-full">
					<button-component customClass="primary sm:w-auto w-full">
						<PlusIcon class="w-3.5 h-3.5 "/>
						<p>Add New</p>
					</button-component>
				</a>
			</div>
		</template>

		<!-- Edit Address -->
		<template v-else>
			<div class="col-span-12">
				<edit-page 
					:form="form" 
					:optionAdressType="optionAdressType"
					:optionRegion="optionRegion"
					:optionProvince="optionProvince"
					:optionCityMunicipality="optionCityMunicipality"
					:optionBarangay="optionBarangay	"
				/>
			</div>
		</template>

		<!-- MODAL -->
		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="deleteItem()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Delete Record?"
			content="Are you sure you wish to delete this record?" 
		/>

		<!-- Delete Record Success -->
		<success-modal	
			noBtn
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Deleted!"
			content="Selected record has been successfully deleted." 
		/>

		<!-- Record Save -->
		<success-modal
			noBtn
			:show="showSuccessSave"
			action-text="Close"
			title="Record Save"
			content="Intake has been updated" 
		/>
	</intake-layout>
</template>

<script setup lang="ts">
import IntakeLayout from '../../Component/IntakeLayout.vue';
import { ref, onMounted,computed } from 'vue';
import { EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { PlusIcon } from "@heroicons/vue/24/solid";
import EditPage from './EditPage.vue';
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRouter, useRoute } from "vue-router";
import { Menu, MenuButton, MenuItems, MenuItem, } from "@headlessui/vue";
import PatientAddressService from "@/classes/Cases/PatientAddressService";
import pickBy from "lodash/pickBy";  
import { onIonViewWillEnter } from '@ionic/vue';  
import TrashIcon from "@/components/icons/trashIcon.vue";
import PencilBlackIcon from "@/components/icons/pencilBlackIcon.vue";

  /******************************* For Offline Imports************************************ */
  import { useNetworkStore } from "@/store/network";
 import Rules from "@/classes/Cases/CasePatientAddress/Offline/Validation/Rules";
 import ErrorValidation from "@/views/interventions/Components/Classess/ErrorValidation";
 import useVuelidate from "@vuelidate/core"; 
 import CasePatientAddressForm from "@/classes/Cases/CasePatientAddress/Offline/Formatters/CasePatientAddressForm";
 import { PatientAddressCreate } from "@/classes/Cases/CasePatientAddress/Offline/Interface/CasePatientInterface";
 import CasePatientAddressService from "@/classes/Cases/CasePatientAddress/CasePatientAddressService";
 import OnlineSynching from '@/classes/Synching/OnlineSynching';
 
 const { createRules } = Rules;
 const networkStore = useNetworkStore();
 const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable); 
 /*************************************************************************************** */

const isShowNav = ref(true);
const showConfirmation = ref(false);
const showSuccess = ref(false);
const showSuccessSave = ref(false);
const router = useRouter();
const route = useRoute();
const hasContent = ref<boolean>(false);
const patient = ref<string>('');
const selectedItemId = ref<number | null>(null);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const offlineParams = ref<string>(route.query.offline); 
const items = ref<PatientAddressIndex[]>([]); 
const isIonWillEnter = ref<boolean>(false);
const onlineSync = new OnlineSynching();


const removeConfirmation = (item) => {
	showConfirmation.value = true;
	selectedItemId.value = item.id || item?.offlineData?.offline_id;
};

const deleteItem = () => {
	CasePatientAddressService.delete(
		patientId.value,
		caseId.value,
		selectedItemId.value,
		pickBy({ isOfflineData: offlineParams.value ? true : false }),
		isNetworkAvailable.value
	)
		.then(() => {
            items.value.data = items.value.data.filter((item: any) => item.id != selectedItemId.value);
			showSuccess.value = true;
			showConfirmation.value = false;
		})
		.catch((error: object) => console.log(error));
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
    if (isNetworkAvailable.value) {
        setTimeout(() => {
            window.location.reload();
        }, 300);
    }
};

const getData = async () => {
	const intake = ref(true);
	if (caseId.value) { 
		const response = await CasePatientAddressService.index(
			patientId.value,
			caseId.value,
			pickBy({ rows: 10 }),
			isNetworkAvailable.value
		);
 
 
		if (response?.status === 200 || response?.data != undefined) {
			items.value = response.data.items;
			hasContent.value = response.data.items.total > 0 ? true : false;
            patient.value = response.data.patient;
 
			if(!isNetworkAvailable.value){
				await retrieveGuardianOffline(response);
			}
		}
	}
};
  /**
 * Retrieve resource data
 */
 const retrieveGuardianOffline = async (response: object):any => { 
	hasContent.value = response.data.items.total > 0 ? true : false; 
	response.data.items = await Promise.all(response.data.items.data?.map(async (item: any) => {
        return  await CasePatientAddressForm.viewForm(item);
    }));
	console.log(response.data.items);
	items.value = { data: response.data.items };     
};
 
onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {	
        await getData();
        await onlineSync.checkDrafts();
    }
});
onMounted(async () => {
    await getData();
    await onlineSync.checkDrafts();
    isIonWillEnter.value = true;
});
</script>