<template>
    <case-conference-layout :patient="patient" >
        <template #actionButtons>
            <div class="flex flex-col flex-shrink-0 space-y-3 sm:space-x-3 sm:space-y-0 sm:flex-row">
                <!-- Create -->
                <template v-if="!isView">
                    <button-component 
                        btnWidth="quinary"
                        fill="outline"
                        @click="router.replace(`/patient/${patientId}/cases/${caseId}/edit/`)"
                        customClass="quinary md:w-auto w-full"
                    >
                        Cancel
                    </button-component>
                    <button-component
                    @click="showConfirmation = showConfirmation = true"
                    customClass="primary md:w-auto w-full">
                        Save
                    </button-component>
                </template>

                <!-- View -->
                <template v-if="isView">
                    <button-component @click="isView = !isView" customClass="primary md:w-auto w-full">
                        <!-- <img
                            src="/assets/icon/ic-pencil-underline.svg"
                            alt="edit_icon"
                            class="object-contain w-4 h-4 mr-1 icon" /> -->
                        <pencil-underline-icon class="object-contain w-4 h-4 mr-1 icon" />
                        <span>Edit</span>
                    </button-component>
                    <button-component
                        v-if="isNetworkAvailable"
                        @click="generatePDFAccess()"
                        customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 md:w-auto w-full"
                    >
                        <EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
                        <span>View PDF</span>
                    </button-component>
                </template>

                <!-- Edit -->
                <template v-if="!createMode && editMode">
                    <button-component 
                        btnWidth="quinary"
                        fill="outline"
                        @click="editMode = !editMode"
                        customClass="quinary md:w-auto w-full"
                    >
                        Cancel
                    </button-component>
                    <button-component
                    @click="showConfirmation = showConfirmation = true"
                    customClass="primary md:w-auto w-full">
                        Save Changes
                    </button-component>
                </template>
                
            </div>
        </template>

        <recommendation-tab 
            :recommendationOptions="recommendationOptions"
            :form="form"
            :isView="isView"
        />

        <!-- MODAL -->
        <confirmation-modal
            :typeTwo="true"
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="save()"
            cancel-text="Cancel"
            action-text="Confirm"
            title="Save Changes"
            content="Are you sure do you want to save added details?" 
        />
        
        <success-modal
            :show="showSuccess"
            @confirm="redirectSuccess()"
            action-text="Close"
            title="Success!"
            content="Details has been successfully saved!" 
        />
        <page-loader :show="loading"/>
        <error-modal @close="closeError" :show="errors?.message ? true : false" :content="errors?.message" />
    </case-conference-layout>
</template>
<script setup lang="ts">
import PageLoader from "@/components/loaders/PageLoader.vue"
import CaseConferenceLayout from '../../Component/CaseConferenceLayout.vue';
import RecommendationTab from "../../Tabs/RecommendationTab.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { EyeIcon } from "@heroicons/vue/24/solid";

import { ref, onMounted, computed, watch } from 'vue';
import { useRouter, useRoute } from "vue-router";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import CaseConferenceService from "@/classes/Cases/CaseConference/CaseConferenceService";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from '@/store/network';
import DB from '@/classes/Databases/Crud';
import { onIonViewWillEnter } from "@ionic/vue";
import PencilUnderlineIcon from "@/components/icons/pencilUnderlineIcon.vue";
import OnlineSynching from "@/classes/Synching/OnlineSynching";

const router = useRouter();
const route = useRoute();
const loading = ref(false);
const loaded = ref(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const createMode = ref<boolean>(true);
const editMode = ref<boolean>(true);
const isView = ref(false)

const errors = ref<any>();
const patient = ref<string>('');
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const caseConferenceId = ref<number | null>(route.params.caseConference);
const item = ref()
const recommendationOptions = ref<any>([])
const form = ref<any>({
    id: null,
    recommendations: [],
})
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const isIonWillEnter = ref<boolean>(false);
const onlineSync = new OnlineSynching();

const fetch = async ():Promise<void> => {
    loading.value = true;

    await CaseConferenceService
        .indexRecommendations(
            patientId.value,
            caseId.value,
            caseConferenceId.value,
            isNetworkAvailable.value
        )
        .then(async ({data}) => {
            patient.value = data.patient_name;
            item.value = data.item
            recommendationOptions.value = data.recommendationOptions

            if (item.value.recommendations.length > 0) {
                isView.value = true
            }

            await storeOffline(data.item);
        })
        .catch((error: object) => { 
            console.log(error)
            loading.value = false
            errors.value = errorResponse(error?.response?.data?.message);
        })
        .finally(()=> {
            loaded.value = true;
            loading.value = false
        });
	
}

const storeOffline = async (item:any): Promise<void> =>{
    if(isNetworkAvailable.value){
        await CaseConferenceService.storeRecommendationsOffline(item);             
    }
}

const save = async () => {
    loading.value = true
    showConfirmation.value = false

    form.value.recommendations.push(...recommendationOptions.value)

    CaseConferenceService
        .storeRecommendations(
            patientId.value,
            caseId.value,
            caseConferenceId.value,
            form.value,
            isNetworkAvailable.value
        )
        .then((response: object) => {
            isView.value = true
            loading.value = false
            showSuccess.value = true;
            form.value.recommendations = []
        })
        .catch((error: object) => { 
            console.log(error)
            loading.value = false
            showConfirmation.value = false
            errors.value = errorResponse(error.response?.data?.message);
        });

}

const closeError = () => {
    errors.value = ''
}

const generatePDFAccess = ():void => {
    console.log(caseConferenceId.value);
	CaseConferenceService
		.generatePDFAccess(
			patientId.value,
			caseId.value,
			caseConferenceId.value,
            isNetworkAvailable.value
		)
		.then(({data}) => {
			if(data.token){
				downloadPDF(data.token)
			}
		}).catch((errors) => {
			console.log(errors);
		})

}
const downloadPDF = (token:string):void => {
	CaseConferenceService
		.downloadPDF(
			caseConferenceId.value,
			token,
            isNetworkAvailable.value
		)
		.then(({data}) => {
			console.log(data);
		}).catch((errors) => {
			console.log(errors);
		})

}

const redirectSuccess = (): void => {
    showSuccess.value = false;
    createMode.value = false;
    editMode.value = false;
};


onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {
        await fetch();
        await onlineSync.checkDrafts();
    }
});

onMounted(async () => {
    await fetch();
    isIonWillEnter.value = true;
})

</script>

