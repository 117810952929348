import Relationships from "@/classes/Forms/Relationships";
const relationship = new Relationships();
import FormOptions from "@/classes/Forms/FormOptions";
import { defaultCreateForm, defaultDeleteForm, defaultUpdateForm } from "@/classes/General/GeneralOfflineForms";
import { RelatedToCaseCreate } from "../Interface/RelatedToCaseInterface";
import parseArray from "@/helpers/Offlinehelpers/parseArray";




const options = new FormOptions();
 class RelatedToCaseForm  {
   
    async viewForm(items: any) {  
     if (Object.keys(items).length > 0) {
        const processedItems = await Promise.all(items.map(async (item: any) => { 
          const birthdate = item.birthdate ? relationship.dateFormatFromIso(item?.birthdate) : null;
          const relationships = item ?  await relationship.companionRelationship(item) : null;
          const gender: any = relationship.gender(item);

            return { 
                id: item?.id,
                gender_formatted: gender['description'],
                name: `${item?.first_name} ${item?.last_name}`,
                birthdate: item?.birthdate,
                computed_age: birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item?.approximate_age_year, item?.approximate_age_month),
                companion_relationship: relationships?.name ?? null,
                contacts: item?.contacts,
                birthday_classification: item?.birthday_classification,
 
                first_name: item?.first_name,
                last_name: item?.last_name,
                middle_name: item?.middle_name ?? null,  
                other_relationship: item?.other_relationship ?? null, 
                gender: item?.gender,  
                approximate_age_month: item?.approximate_age_month ?? null,
                approximate_age_year: item?.approximate_age_year ?? null, 


                encoded_by:   item?.encoded_by,
                person_related_types: parseArray(item?.person_related_types),
                companion_relationship_id:   item?.companion_relationship_id,
                civil_status_id:   item?.civil_status_id,
                is_employed:  item?.is_employed ?? null,
                is_ofw:  item?.is_ofw ?? null,
                occupation:  item?.occupation ?? null,
                company:  item?.company ?? null,
                weekly_income:   item?.weekly_income ?? null,
                other_source_of_income:   item?.other_source_of_income ?? null,
                is_enrolled_in_school:  item?.is_enrolled_in_school ?? null,
                educational_attainment_id:   item?.educational_attainment_id ?? null,
                school:  item?.school ?? null,
                housing_classification_id:   item?.housing_classification_id ?? null,
                no_children_in_family:   item?.no_children_in_family ?? null,
                no_household_members:   item?.no_household_members ?? null,
                no_family_in_household:   item?.no_family_in_household ??  null,
                other_information:  item?.other_information ?? null,

            };
        }));
        return processedItems;
      } else {
        return [];
      }
    
   }

   updateForm(item: any){  
       return {
         id: item.id,
         first_name: item.first_name,
         last_name: item.last_name,
         middle_name: item.middle_name,  
         other_relationship: item.other_relationship ?? null, 
         gender: item.gender,  
         approximate_age_month: item.approximate_age_month ?? null,
         approximate_age_year: item.approximate_age_year ?? null,
         birthday_classification: item.birthday_classification,
         contacts: item.contacts ?? null,
         birthdate: item.birthdate ?? null,


         encoded_by:   item.encoded_by,
         person_related_types:   item.person_related_types,
         companion_relationship_id:   item.companion_relationship_id,
         civil_status_id:   item.civil_status_id,
         is_employed:  item.is_employed ?? null,
         is_ofw:  item.is_ofw ?? null,
         occupation:  item.occupation ?? null,
         company:  item.company ?? null,
         weekly_income:   item.weekly_income ?? null,
         other_source_of_income:   item.other_source_of_income ?? null,
         is_enrolled_in_school:  item.is_enrolled_in_school ?? null,
         educational_attainment_id:   item.educational_attainment_id ?? null,
         school:  item.school ?? null,
         housing_classification_id:   item.housing_classification_id ?? null,
         no_children_in_family:   item.no_children_in_family ?? null,
         no_household_members:   item.no_household_members ?? null,
         no_family_in_household:   item.no_family_in_household ??  null,
         other_information:  item.other_information ?? null,


         //default migration form
        ...defaultUpdateForm()
       }
   }
 
   createForm(patientCase: number | null){
       return {  
           cases_id:  patientCase,
           first_name:  null,
           middle_name: null,
           last_name: null,
           gender: null,
           birthday_classification: null,
           birthdate: null,
           approximate_age_year: null,
           approximate_age_month: null, 
           other_relationship: null,
           contacts: null,
 
           encoded_by:   null,
           person_related_types: [],
           companion_relationship_id:  null,
           civil_status_id:   null,
           is_employed:  false,
           is_ofw:  false,
           occupation:  null,
           company:  null,
           weekly_income:   null, 
           other_source_of_income:   null,
           is_enrolled_in_school:  false,
           educational_attainment_id:   null,
           school:  null,
           housing_classification_id:   null,
           no_children_in_family:   null,
           no_household_members:   null,
           no_family_in_household:   null,
           other_information:  null,


          //default migration form
          ...defaultCreateForm()
       }
   }
}
export default new RelatedToCaseForm();