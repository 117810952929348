<template>
	<div class="w-full">
		<label :for="id" class="block text-xs font-semibold text-neutral-600">{{ label }}</label>
		<div class="relative rounded" :class="label ? 'mt-2' : ''" @click="showPassword = showPassword">
			<input
				:id="id"
				:type="showPassword ? 'text' : 'password'"
				class="block w-full h-12 text-sm rounded disabled:bg-gray-100 focus:outline-none focus:ring-0 focus:border-neutral-900 text-neutral-800 border-neutral-100 placeholder-neutral-400 placeholder:font-medium pr-11"
				:placeholder="placeholder"
				:required="isRequired"
				:name="name"
				:readonly="readonly"
				:maxlength="maxlength"
				@input="$emit('update:modelValue', $event.target.value)" />
			<div
				@click="showPassword = !showPassword"
				class="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer">
				<template v-if="!showPassword">
					<EyeIcon class="w-6 h-6 text-neutral-400" aria-hidden="true" />
				</template>
				<template v-else>
					<EyeSlashIcon class="w-6 h-6 text-neutral-400" aria-hidden="true" />
				</template>
			</div>
		</div>
		<p v-if="helper" class="text-[10px] text-neutral-400 mt-1">
			{{ helper }}
		</p>

		<p v-if="error" class="text-[10px] mt-1" :class="error ? 'text-red-600' : 'text-gray-800'" id="email-error">
			{{ error }}
		</p>
	</div>
</template>

<script setup lang="ts">
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/solid";
import { ref } from "vue";

defineProps({
	modelValue: {
		default: null,
		required: true,
	},
	id: {
		type: String,
		default: null,
	},
	placeholder: {
		type: String,
		default: null,
	},
	label: {
		type: String,
		default: null,
	},
	name: {
		type: String,
		default: null,
	},
	helper: {
		type: String,
		default: null,
	},
	error: {
		type: String,
		default: null,
	},
	isRequired: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	maxlength: {
		type: Number,
	},
});

const showPassword = ref<boolean>(false);
</script>
