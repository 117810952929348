class CaseComorbiditiesOfflineForm {
    /**
     * Template form structure of case table
     */
    formStructure () {
        return {
            cases_id: null,
            comorbidity_id: null,
        }
    }

    renderSyncToOffline(case_id: number, comorbidity: number) {
        return {
            cases_id: case_id,
            comorbidity_id: comorbidity,
        }
    }

    renderCaseHistoryCard(items: any) {
        return items.map((item: any) => {
            return {
                id: item.id,
                name: item.name,
                comorbidity_id: item.id,
            };
        });
    }

    editForm(item: any) {
        return item?.comorbidities?.map((comorbidity: any) => {
			return {
				comorbidity_id: comorbidity.id,
				checked: true,
			};
		}) || [];
    }
}

export default new CaseComorbiditiesOfflineForm();