import PrxSqlite from "@/vendors/prx-sqlite/PrxSqlite";
import { schema } from '@/migrations'
import PrxLocalStorage from "@/vendors/prx-sqlite/PrxLocalStorage";

/* prx-sqlite */
const db = new PrxSqlite;
const localStorage = new PrxLocalStorage

export default class SqliteProcessor {

    /**
     * Getter: database plugin.
     * 
     * @return  PrxSqlite
     */
    get db():PrxSqlite {
        return db
    }
    
    /**
     * Initialize database.
     * 
     * @return  Promise<void>
     */
    async init():Promise<void> {
        await this.connect()
        await db.dropAll(schema.tables.map((table) => table.name));
        const status = await db.keyExists('migrations')
        if (status) {
            await this.clearTables();
        }
        if(!status) {
            await this.migrate()
        }
    }

    /**
     * Connect to sqlite.
     * 
     * @return  Promise<void>
     */
    async connect():Promise<void> {
        const encrypted = await localStorage.get('encrypted')
        const secret = !encrypted ? process.env.VUE_APP_DB_SECRET : ''
        const dbName = process.env.VUE_APP_DB_NAME ?? ''
        await db.connect(dbName, secret)
        await localStorage.set('encrypted', true)
    }

    /**
     * Migrate.
     * 
     * @return  Promise<void>
     */
    async migrate():Promise<void> {
        await db.create(schema)
        await this.connect()
    }

    async dropTable(): Promise<void> {
        schema.tables.map(async (table: any) => {
            await db.drop(table.name);
        });
    }

    async clearTables():Promise<void> {
        await db.clear('patients');
        await db.clear('cases');

        // Options
        await db.clear('agencies');
        await db.clear('aggravating_factors');
        await db.clear('barangays');
        await db.clear('behavior_types');
        await db.clear('civil_statuses');
        await db.clear('comorbidities');
        await db.clear('companion_relationships');
        await db.clear('departments');
        await db.clear('disabilities');
        await db.clear('educational_attainments');
        await db.clear('employment_statuses');
        await db.clear('guardian_relationships');
        await db.clear('hospital_areas');
        await db.clear('housing_classifications');
        await db.clear('indigeneous_peoples');
        await db.clear('intervention_type_lists');
        await db.clear('intervention_types');
        await db.clear('mental_health_diagnoses');
        await db.clear('mitigating_factors');
        await db.clear('mms');
        await db.clear('nationalities');
        await db.clear('offices');
        await db.clear('perpetrator_relationships');
        await db.clear('personnels');
        await db.clear('physicians');
        await db.clear('positions');
        await db.clear('primary_diagnoses');
        await db.clear('provinces');
        await db.clear('psychiatrists');
        await db.clear('recommendation_options');
        await db.clear('referral_sources');
        await db.clear('regions');
        await db.clear('relationships');
        await db.clear('religions');
        await db.clear('risk_codes');
        await db.clear('safety_assessment_questions');
        await db.clear('social_workers');
        await db.clear('socio_economic_statuses');
        await db.clear('sought_services');
        await db.clear('telcpu_locations');
        await db.clear('telcpus');
        await db.clear('trainees');
        await db.clear('wcpu_personnels');
    }
}