import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../../defaultMigration";

export const case_conferences:SchemaTableOptions = {
    name:<string> 'case_conferences',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'patient_id', value: 'INTEGER UNSIGNED'},
        { column: 'cases_id', value: 'INTEGER UNSIGNED'},
		{ column: "social_worker_id", value: "INTEGER UNSIGNED" },
		{ column: "case_conference_number", value: "INTEGER UNSIGNED" },
		{ column: "date_time", value: "TIMESTAMP" },
		{ column: "other_aggravating", value: "LONGTEXT NULL" },
		{ column: "other_mitigating", value: "LONGTEXT NULL" },
		{ column: "meeting_minutes", value: "LONGTEXT NULL" },
		{ column: "invitees", value: "LONGTEXT NULL" },
		{ column: "agencies", value: "LONGTEXT NULL" },
		{ column: "recommendations", value: "LONGTEXT NULL" },
		{ column: "encoded_by", value: "INTEGER UNSIGNED" },
		{ column: "social_worker", value: "TEXT" },
		{ column: "patient_name", value: "TEXT NULL" },
		{ column: "action", value: "INT UNSIGNED" },
        ...defaultMigration
    ]
}