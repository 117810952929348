<template>
	<div class="frm-datepicker" :id="id">
		<label :for="id" class="block mb-2 text-xs font-semibold text-neutral-600"
			>{{ label }} <span v-if="required" class="text-red-600">*</span></label
		>
		<DatePicker
			ref="picker"
			:uid="id"
			:name="name"
			v-model="value"
			:disabled="disabled"
			:placeholder="placeholder"
			:timePicker="timePicker"
			:spaceConfirm="spaceConfirm"
			:enableTimePicker="enableTimePicker"
			:enableSeconds="enableSeconds"
			:partialRange="true"
			:range="range"
			:multiCalendars="multiCalendars"
			:presetRanges="presetRanges"
			input-class-name="frm__date-picker"
			@update:modelValue="updateDate"
			:minDate="minDate"
			:inline="inline"
			:timePickerInline="timePickerInline"
			:weekPicker="weekPicker"
			:autoApply="autoApply"
			:weekStart="weekStart"
			:minTime="minTime"
			:maxTime="maxTime"
			:startTime="startTime"
			:auto-apply="true"
			month-name-format="long"
			week-start="0"
			:disabled-dates="fullyBookedDates"
			:highlight="fullyBookedDates"
			:highlight-disabled-days="highlightDisabledDays"
			:hide-offset-dates="hideOffsetDates"
			:month-change-on-scroll="monthChangeOnScroll"
			:format="format"
			@update-month-year="handleMonthYear"
			:clearable="clearable"
			:required="required"
			position="left"
			:readonly="readonly"
			:maxDate="maxDate"
			:timezone="timezone"
			:hide-input-icon="hideInputIcon"
			:auto-position="false"
			:is-24="isTwentyFour"
			@cleared="$emit('cleared')"
			>
			<template #input-icon>
				<!-- <img class="input-slot-image" src="/assets/icon/ic-input-date.svg" /> -->
				<input-date-icon class="input-slot-image" />
			</template>
		</DatePicker>
		<!-- :highlight="highlight" -->
		<!-- :disabledWeekDays="disabledWeekDays" -->
		<!-- :allowedDates="allowedDates" -->
		<p 
			v-if="helper"
			class="text-[10px] text-gray-600 mt-1"
		>
			{{ helper }}
		</p>
		<p v-if="error" class="text-[10px] mt-1" :class="error ? 'text-red-600' : 'text-gray-800'" id="email-error">
			{{ error }}
		</p>
	</div>
</template>

<script>
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import InputDateIcon from "@/components/icons/inputDateIcon.vue";
import { ref } from "vue";

export default {
	components: {
		DatePicker,
		InputDateIcon
		// ExclamationCircleIcon,
	},
	props: {
		modelValue: {
			default: null,
			required: true,
		},
		helper: {
			type: String,
			default: null,
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		error: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		name: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		customClass: {
			type: String,
			default: null,
		},
		timePicker: {
			type: Boolean,
			default: false,
		},
		enableTimePicker: {
			type: Boolean,
			default: false,
		},
		enableSeconds: {
			type: Boolean,
			default: false,
		},
		range: {
			type: Boolean,
			default: false,
		},
		multiCalendars: {
			type: Boolean,
			default: false,
		},
		presetRanges: {
			default: [],
		},
		spaceConfirm: {
			type: Boolean,
			default: true,
		},
		hideInputIcon: {
			type: Boolean,
			default: false,
		},
		timePickerInline: {
			type: Boolean,
			default: false,
		},
		minDate: {
			type: Date,
			default: null,
		},
		// highlight: {
		//     type: Array,
		//     default: [],
		// },
		// disabledWeekDays: {
		//     type: Array,
		//     default: [],
		// },
		inline: {
			type: Boolean,
			default: false,
		},
		weekPicker: {
			type: Boolean,
			default: false,
		},
		autoApply: {
			type: Boolean,
			default: true,
		},
		// allowedDates: {
		//     type: Array,
		//     default: [],
		// },
		weekStart: {
			type: Number,
			default: 1,
		},
		minTime: {
			default: null,
		},
		maxTime: {
			default: null,
		},
		startTime: {
			default: null,
		},
		fullyBookedDates: {
			type: Array,
			default: () => {
				return [];
			},
		},
		highlightDisabledDays: {
			type: Boolean,
			default: false,
		},
		hideOffsetDates: {
			type: Boolean,
			default: false,
		},
		monthChangeOnScroll: {
			type: Boolean,
			default: false,
		},
		isTwentyFour: {
			type: Boolean,
			default: true,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		format: {
			type: String,
			default: null,
		},
		maxDate: {
			type: String,
			default: null
		},
		timezone: {
			type: String,
			default: 'Asia/Manila'
		}
	},
	setup(props, { emit }) {
		const value = ref(props.modelValue);
		const picker = ref(null);

		const updateDate = (value) => {
			emit("update:modelValue", value);
		};

		const handleMonthYear = ({ month, year }) => {
			emit("updateMonth", { month, year });
		};

		return {
			updateDate,
			value,
			handleMonthYear,
			picker,
		};
	},
};
</script>
<style lang="scss">
.dp__cell_inner {
	margin: auto;
}
</style>
