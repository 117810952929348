<template>
    <ion-page>
        <top-navigation :hasBack="hasBack" :backLink="backLink" :backTitle="backTitle" :pageTitle="pageTitle" :hasBackClass="hasBackClass">
            <template #lowerMiddle>
                <slot name="lowerMiddle"></slot>
            </template>
            <template #lowerRight>
                <slot name="lowerRight"></slot>
            </template>
        </top-navigation>
        <ion-content :fullscreen="true">
            <div class="grid lg:mx-6 mx-4 min-h-screen pb-16"
                :class="[
                    (hasLeftCon && hasRightCon)
                        ? 'grid-cols-[210px_auto_368px]'
                        : hasLeftCon
                        ? 'lg:grid-cols-[210px_auto] grid-cols-1'
                        : hasRightCon
                        ? 'lg:grid-cols-[auto_368px] grid-cols-1'
                        : 'grid-cols-1',
                    mainCustomClass
                ]"
            >
                <div v-if="hasLeftCon" class="main_left_con lg:pr-10">
                    <slot name="leftContent" />
                </div>
                <div class="main_middle_con">
                    <slot />
                </div>
                <div v-if="hasRightCon" class="main_right_con lg:pl-7 lg:order-2 -order-1">
                    <slot name="rightContent" />
                </div>
            </div>
            <bottom-navigation v-if="showFooter"/>
            <SecurityFeatureModal :title="title"/>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import { 
    IonPage, 
    IonContent, 
} from '@ionic/vue';

import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import SecurityFeatureModal from "@/components/modals/SecurityFeatureModal.vue"
import { useSecurityFeature } from '@/store/securityFeature';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useNetworkStore } from "@/store/network";
import { useSessionCookie } from '@/helpers/useSessionCookie';

defineProps ({
    hasBack: {
        type: Boolean,
        default: false
    },
    backLink: {
        type: String,
        default: null
    },
    backTitle: {
        type: String,
        default: 'Back'
    },
    pageTitle: {
        type: String,
        default: null
    },
    showFooter: {
        type: Boolean,
        default: true,
    },
    color: {
        type: String
    },
    hasLeftCon: {
        type: Boolean,
        default: false,
    },
    hasRightCon: {
        type: Boolean,
        default: false,
    },
    mainCustomClass: {
        type: String,
        default: null,
    },
    hasBackClass: {
        type: String,
        default: "flex justify-between items-center",
    }
});

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const title = ref("Device is back online!");
const { setSessionCookie, getSessionCookie } = useSessionCookie('hasPasswordBlocker');

watch(
    () => useSecurityFeature().isPasswordBlocker,
    (status) => {
        if (isNetworkAvailable.value && status) {
            useSecurityFeature().isActivated = status;
            title.value = "Input your password!";
        }
    }
);

// document.addEventListener('visibilitychange', function() {
//     if (document.visibilityState === 'visible') {
//         // Show password prompt here
//         useSecurityFeature().isPasswordBlocker = true;
//         sessionStorage.setItem('hasPasswordBlocker', true)
//     }
// });
window.addEventListener('load', () => {
    const hasPasswordBlocker = eval(sessionStorage.getItem('hasPasswordBlocker'));
    if (hasPasswordBlocker) {
        useSecurityFeature().isPasswordBlocker = true;
    }
});
window.addEventListener('beforeunload', (event) => {
    if (!isNetworkAvailable.value) {
        event.preventDefault();
        event.returnValue = null;
        return null;
    }
});
</script>