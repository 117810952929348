import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";
import defaultMigration from "../defaultMigration";


export const case_companions: SchemaTableOptions = {
   name:<string> 'case_companions',
   schema:<Array<SchemaColumnOptions>> [
       { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
       { column: 'cases_id', value: 'INTEGER UNSIGNED'},
       { column: 'first_name', value: 'VARCHAR(255) NOT NULL'},
       { column: 'middle_name', value: 'VARCHAR(255) DEFAULT NULL'},
       { column: 'last_name', value: 'VARCHAR(255) NOT NULL'},
       { column: 'gender', value: 'TINYINT UNSIGNED'},
       { column: 'birthday_classification', value: 'TINYINT UNSIGNED'},
       { column: 'birthdate', value: 'DATE DEFAULT NULL'},
       { column: 'approximate_age_year', value: 'INTEGER UNSIGNED DEFAULT NULL'},
       { column: 'approximate_age_month', value: 'INTEGER UNSIGNED DEFAULT NULL'},
       { column: 'companion_relationship_id', value: 'INTEGER UNSIGNED'},
       { column: 'other_relationship', value: 'VARCHAR(255) DEFAULT NULL'},
       { column: 'contacts', value: 'VARCHAR(255) DEFAULT NULL'},
       { column: 'encoded_by', value: 'INTEGER UNSIGNED'},
       ...defaultMigration
   ]
}
