import { Perpetrator } from "./Offline/Interface/PerpetratorInterface";
import CasePerpetratorService from "../Cases/PerpetratorService";
import OfflineService from "./Offline/OfflineService";
import OnlinePerpetratorService from "../PerpetratorService";
class PerpetratorService implements Perpetrator{
    async getPatient(patient: number, case_id: number, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.getPatient(patient, case_id);
        } else {
            return await OfflineService.getPatient(patient, case_id);
        }
    }

    async index(patient: number, case_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.index(patient, case_id, payload);
        } else {
            return await OfflineService.index(patient, case_id, payload);
        }
    }

    async link(patient: number, case_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.link(patient, case_id, payload);
        } else {
            return await OfflineService.link(patient, case_id, payload);
        }
    }

    async create(patient: number, case_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.create(patient, case_id, payload);
        } else {
            return await OfflineService.create(patient, case_id, payload);
        }
    }

    async store(patient: number, case_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.store(patient, case_id, payload);
        } else {
            return await OfflineService.store(patient, case_id, payload);
        }
    }

    async edit(patient: number, case_id: number, perpetrator_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.edit(patient, case_id, perpetrator_id, payload)
        } else {
            return await OfflineService.edit(patient, case_id, perpetrator_id, payload);
        }
    }

    async update(patient: number, case_id: number, perpetrator_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.update(patient, case_id, perpetrator_id, payload);
        } else {
            return await OfflineService.update(patient, case_id, perpetrator_id, payload);
        }
    }

    async view(perpetrator_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await OnlinePerpetratorService.show(perpetrator_id, payload)
        } else {
            return await OfflineService.view(perpetrator_id, payload);
        }
    }

    async editProfile(perpetrator_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await OnlinePerpetratorService.edit(perpetrator_id, payload);
        } else {
            return await OfflineService.editProfile(perpetrator_id, payload);
        }
    }

    async updateProfile(perpetrator_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await OnlinePerpetratorService.update(perpetrator_id, payload);
        } else {
            return await OfflineService.updateProfile(perpetrator_id, payload);
        }
    }

    async delete(patient: number, case_id: number, perpetrator_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.delete(patient, case_id, perpetrator_id, payload);
        } else {
            return await OfflineService.delete(patient, case_id, perpetrator_id, payload);
        }
    }

    async lists(patient: number, case_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await CasePerpetratorService.lists(patient, case_id, payload);
        } else {
            return await OfflineService.index(patient, case_id, payload);
        }
    }

    async search(payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await OnlinePerpetratorService.index(payload);
        } else {
            return await OfflineService.search(payload);
        }
    }

    async cases(perpetrator_id: number, payload: object, networkAvailable: boolean): Promise<any> {
        if (networkAvailable) {
            return await OnlinePerpetratorService.cases(perpetrator_id, payload);
        } else {
            return await OfflineService.cases(perpetrator_id, payload);
        }
    }
}

export default new PerpetratorService();