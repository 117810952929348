import http from "@/axios";
import OfflineProcessor from "./Processor/OfflineProcessor";
class PersonsRelatedToCaseService{


    async index(patient:number, patientCase:number, networkAvailable: boolean){
        if(networkAvailable){
            return await http.get(`patients/${patient}/cases/${patientCase}/intakeForm/personnelCase/index`);
        }else{
            return await OfflineProcessor.index(patient, patientCase);
        }
    }


    async store(id:number, patient:number, patientCase: number, networkAvailable: boolean, payload:any){
        if(networkAvailable){
            return await http.post(`patients/${patient}/cases/${patientCase}/intakeForm/personnelCase/store`, payload);
        }else{
            return await OfflineProcessor.updateItem(id, patient, patientCase, payload)
        }
    }
    async update(id:number, patient:number, patientCase: number, networkAvailable: boolean, payload:any){
        if(networkAvailable){
            return await http.post(`patients/${patient}/cases/${patientCase}/intakeForm/personnelCase/update/${payload?.id}`, payload);
        }else{
            return await OfflineProcessor.updateItem(id, patient, patientCase, payload)
        }
    }

    async archive(patient:number,patientCase: number, companionId: number, networkAvailable: boolean){
       if(networkAvailable){
        return await http.delete(`patients/${patient}/cases/${patientCase}/intakeForm/personnelCase/${companionId}/delete`);
       }else{
        return await OfflineProcessor.delete(patient, patientCase, companionId);
       }
    }
}
export default new PersonsRelatedToCaseService();