import http from "@/axios";
import { Intervention } from "@/interfaces/Cases/Interevention";
// import DB from "@/classes/Databases/Crud"
// import InterventionOfflineForm from "../Forms/Cases/Interventions/InterventionOfflineForm";
import OfflineService from "../Interventions/Classes/OfflineService";

const offline = OfflineService;

// const dbInterventions = new DB('intervention_data');
// const dbCase = new DB('cases');
class InterventionService implements Intervention {

	async getFormStructure(): Promise<any> {
		return await offline.getFormStructure();
	}

	async index(patient: number, patientCase: number, payload: object, isNetworkAvailable = true): Promise<any> {
		// If network is availble
		if(isNetworkAvailable) {
			const url = patientCase == undefined
				? `interventions/${patient}`
				: `patients/${patient}/cases/${patientCase}/interventions`
			return await http.get(url, { params: payload })
		} else {
            console.log('offline')
			// const condition = patientCase == undefined
			// 	? ` WHERE patient_id = ${patient}`
			// 	: ` WHERE patient_id = ${patient} AND case_id = ${patientCase}`
			// dbInterventions.index(payload, condition, true, 'intervention_data')
		}
	}

	async create(patient: number, patientCase: number, payload: object, isNetworkAvailable = true): Promise<any> {
		const url = patientCase == undefined
			? `interventions/${patient}/create`
			: `patients/${patient}/cases/${patientCase}/interventions/create`
		return await http.get(url, { params: payload });
	}

	async store(patient: number, patientCase: number, payload: any, isNetworkAvailable = true): Promise<any> {
		// If network is availble
		if(isNetworkAvailable) {
			const url = patientCase == undefined
				? `interventions/${patient}`
				: `patients/${patient}/cases/${patientCase}/interventions`
			return await http.post(url, payload);
		} else {
            console.log('offline')
			// if(patientCase) {
			// 	let result = await dbCase.edit(patientCase, false, 'cases');
			// 	if (result.data.item.length != undefined) {
			// 		payload.isOfflineData = true;
			// 		result = await dbCase.edit(patientCase, false, 'cases');
			// 	}

			// 	if (result.data.item.length == undefined) {
			// 		const form = new InterventionOfflineForm({}, {});
			// 		const createForm = form.createForm(payload, result.data.item);
			// 		return dbInterventions.store(createForm, true, false, 'intervention_data');
			// 	}
			// } else {
			// 	const form = new InterventionOfflineForm({}, {});
			// 	const createForm = form.createForm(payload, {});
			// 	return dbInterventions.store(createForm, true, false, 'intervention_data');
			// }
		}
	}

	async edit(patient: number, patientCase: number, intervention: number, payload: object, isNetworkAvailable = true): Promise<any> {
		// If network is availble
		if(isNetworkAvailable) {
			const url = patientCase == undefined
				? `interventions/${patient}/edit/${intervention}`
				: `patients/${patient}/cases/${patientCase}/interventions/${intervention}/edit`
			return await http.get(url, { params: payload })
		} else {
            console.log('offline')
			// dbInterventions.edit(intervention, false, 'intervention_data')
		}
	}

	async update(patient: number, patientCase: number, intervention: number, payload: any, isNetworkAvailable = true): Promise<any> {
		// If network is availble
		if(isNetworkAvailable) {
			const url = patientCase == undefined
				? `interventions/${patient}/update/${intervention}`
				: `patients/${patient}/cases/${patientCase}/interventions/${intervention}/update`
			return await http.put(url, payload);		
		} else {
            console.log('offline')
			// let result = await dbInterventions.edit(intervention, false, 'intervention_data');
			// if (result.data.item.length != undefined) {
			// 	payload.isOfflineData = true;
			// 	result = await dbInterventions.edit(intervention, false, 'intervention_data');
			// }

			// if (result.data.item.length == undefined) {
			// 	const form = new InterventionOfflineForm({}, {});
			// 	const updateForm = form.updateForm(payload, result.data.item);
			// 	return dbInterventions.store(updateForm, true, false, 'intervention_data');
			// }
		}
	}

	async delete(patient: number, patientCase: number, intervention: number, payload: any, isNetworkAvailable = true): Promise<any> {
		// If network is availble
		if(isNetworkAvailable) {
			const url = patientCase == undefined
				? `interventions/${intervention}/delete`
				: `patients/${patient}/cases/${patientCase}/interventions/${intervention}/delete`
			return await http.delete(url, payload);
		} else {
            return await OfflineService.delete(intervention)
		}
	}

	async restore(patient: number, patientCase: number, intervention: number, payload: object): Promise<any> {
		const url = patientCase == undefined
			? `interventions/${intervention}/restore`
			: `patients/${patient}/cases/${patientCase}/interventions/${intervention}/restore`
		return await http.patch(url, payload);
	}

}

export default new InterventionService();
