import http from '@/axios'

class IntakeFormService {

    public async index(patient:string, cases:string) : Promise<JSON> {
        return await http.get(`patients/${patient}/cases/${cases}/intakeForm/index`);
    }

    public async generatePDFAccess(patient:string, cases:string) : Promise<JSON> {
        return await http.post(`patients/${patient}/cases/${cases}/intakeForm/generatePDFAccess`);
    }

    public async downloadPDF(patient:number, patientCase:number, token:string): Promise<void> {		
		window.open(`${process.env.VUE_APP_URL}pdf/${patientCase}/downloadIntake/${token}`);
	}

}
export default new IntakeFormService();
