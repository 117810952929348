import {required, helpers, email } from "@vuelidate/validators"
const createRules = (form: any): any => { 
   return {
        label: {
            required:  helpers.withMessage(errorMessage('label'), required)
        },
        email: {
            email: helpers.withMessage(errorMessage('email'), email),
            required: helpers.withMessage(errorMessage('email'), required)
        },  
   }
}

const errorMessage = (field: any) => {
   return `The ${field} field is required`;
}
export default {
   createRules,
}
