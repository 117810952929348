import { SchemaColumnOptions, SchemaTableOptions } from "@/vendors/prx-sqlite/Interfaces/SchemaInterfaces";

export const personnels:SchemaTableOptions = {
    name:<string> 'personnels',
    schema:<Array<SchemaColumnOptions>> [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT'},
        { column: 'first_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'last_name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'name', value: 'VARCHAR(255) DEFAULT NULL'},
        { column: 'department_id', value: 'INTEGER UNSIGNED'},
        { column: 'office_id', value: 'INTEGER UNSIGNED'},
        { column: 'position_id', value: 'INTEGER UNSIGNED'},
        { column: 'is_wpcu', value: 'BOOLEAN DEFAULT FALSE'},
    ]
}