 
/**
 * Convert the Uint8Array to array for the form values.
 * @param item 
 * @returns 
 */
const parseArray = (item: any) => { 
    if(item){
        const str = JSON.stringify(item);
        const obj = JSON.parse(str);
        const result = Object.values(obj);
        return result;
    }
    return [];
}

export default parseArray;