import CaseComorbiditiesOfflineForm from "../Formatters/CaseComorbiditiesOfflineForm";
import SqliteProcessor from "@/processors/SqliteProcessor";
import Syncable from "@/extenders/models/Syncable";

const sqlite = new SqliteProcessor;
const table = 'cases_comorbidity';
const syncable = new Syncable;
class CaseComorbiditiesProcessor {
    async store(case_id: number, items: Array<object>) {
        const arr = [];
        for (const index in items) {
            const item: any = items[index];
            if (item.comorbidity_id) {
                arr.push(CaseComorbiditiesOfflineForm.renderSyncToOffline(case_id, item.comorbidity_id))
            }
        }

        if (arr.length > 0) {
            syncable.table = table;
            await syncable.removeCreateMany(
                sqlite.db,
                { cases_id: case_id},
                arr
            );
        }
    }

    async getComobidities(case_id: number) {
        return await sqlite.db.select(`SELECT * FROM ${table} WHERE cases_id=${case_id}`);
    }

    async getCardCaseHistory(case_id: number) {
        const response = await sqlite.db.select(`SELECT * FROM ${table} WHERE cases_id=${case_id}`);
        if (response.length > 0) {
            const findIn = response.map((item) => item.comorbidity_id).join(',');
            return await sqlite.db.select(`SELECT * FROM comorbidities WHERE id IN (${findIn})`);
        }
        return [];
    }
}

export default new CaseComorbiditiesProcessor();