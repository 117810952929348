<template>
    <TransitionRoot appear :show="show"  as="template">
        <Dialog as="div" @close="$emit('close')" class="relative z-50">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 w-full h-full bg-white" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                
                <div class="flex items-center justify-center min-h-full p-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <div 
                            role="status" 
                            class="fixed h-20 -translate-x-1/2 -translate-y-1/2 w-fit top-2/4 left-1/2 "
                        >   
                            <img src="/assets/CPN-Logo.png" alt="logo" class="w-[200px] h-[87px] object-contain lock mx-auto"/>
                            <div class="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                                <div class="bg-blue-600 h-2.5 rounded-full dark:bg-blue-500" :style="{ width: `${percentage}%`}"></div>
                                <!-- <div class="bg-blue-600 h-2.5 rounded-full dark:bg-blue-500" style="width: 60%"></div> -->
                            </div>
                            <div>
                                <p>
                                    Please wait, downloading resources...
                                </p>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import http from "@/axios";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
} from '@headlessui/vue';

import { Network } from '@capacitor/network';
import Storage from "@/helpers/storage";
import DownloadResourcesService from "@/classes/Synching/Offline/DownloadResources/DownloadResourcesService";
import InterventionService from "@/classes/Interventions/InterventionService";

const percentage = ref<number>(0)
const show = ref<boolean>(false)

const storage = new Storage();

const currentUser = JSON.parse(storage.get("user"));

const offlineDropdowns: object[] = DownloadResourcesService.setTable();

const config = {
    onUploadProgress: function (progressEvent: any) {
        percentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    }
};

const chunkArray = (data:string[], size: number): any => {

    const chunkedArray:any[] = [];
    
    data.forEach((item, index) => {
        if (index % size === 0) {
            chunkedArray.push(data.slice(index, index + size));
        }
    });

    return chunkedArray;
}

const sync = async (): Promise<any> => {
    // stop processing when downloading resources was already finised
    if (eval(storage.get('isDownloadedResources'))) {
        console.log('Resources already downloaded');
        return;
    }

    show.value = true;
    const unloadedOptions:string[] = [];
    offlineDropdowns.forEach(
        (item:any) => {
            unloadedOptions.push(item.params)
        }
    );
    const chunk = chunkArray(unloadedOptions, 10);
    for (const index in chunk) {
        const items = chunk[index];
        const { data } = await http.post(`sync/options/get`,{ options: items }).catch(error => error)
        await DownloadResourcesService.storeOptions(data);
    } 
    // download intervention form structures
    await InterventionService.getFormStructure();
    storage.set('isDownloadedResources', true);
    show.value = false;
}

const logCurrentNetworkStatus = async (): Promise<any> => {
    const status = await Network.getStatus();

    if (status.connected) {
        sync()
    }
};

onMounted(() => {
    sync();
})
</script>