import { defineStore } from "pinia";

interface SecurityFeature {
    isActivated: boolean,
    isPasswordBlocker: boolean,
}

export const useSecurityFeature = defineStore({
    id: 'securityFeature',
    state: (): SecurityFeature => {
        return {
            isActivated: false, 
            isPasswordBlocker: false,
        }
    }
})